import { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [codemp, setCodemp] = useState(null);
    const [loading, setLoading] = useState(true);

    const updateCodemp = (value) => {
        setCodemp(value);
        localStorage.setItem('CODEMP', Number(value));
    }
  
    const getCodemp = () => {
        let storedCodemp = localStorage.getItem('CODEMP');
        storedCodemp = storedCodemp ? Number(storedCodemp) : null;
        return storedCodemp;
    };

    const getToken = () => {
        let storedToken = localStorage.getItem('token');
        return storedToken;
    };
      
    useEffect(() => {
        const savedToken = getToken();
        const savedCodemp = getCodemp();
        if (savedToken) {
            setToken(savedToken);
        }
        if (savedCodemp) {
            setCodemp(savedCodemp);
        }
        setLoading(false);
    }, []);
  
    return (
        <AuthContext.Provider value={{ token, setToken, codemp, setCodemp, updateCodemp, loading }}>
            {children}
        </AuthContext.Provider>
    );
};