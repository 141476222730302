import $ from "jquery";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import api, { setAuthToken } from "../../Services/api";
import { AuthContext } from "../../Services/AuthContext";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";

import styles from "./ProdutoEstoqueModal.module.css";

const ProdutoEstoqueModal = ({ onSelect, onLoading}) => {
  const { token, codemp } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedProdutos, setSelectedProdutos] = useState(null);
  const [showError, setShowError] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    setAuthToken(token);
    api
      .get(`/produtos/listar?CODEMP=${codemp}`)
      .then((response) => {
        setProdutos(response.data);
        setLoaded(true);
        setShowModal(true);
      })
      .catch((error) => {
        console.error("Erro ao obter produtos:", error);
      });
  }, [codemp, token, loaded]);

  useEffect(() => {
    if (
      produtos.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
        order:[2,'asc']
      });
    }
  }, [produtos]);

  const handleClose = () => {
    console.log("handleClose chamado. Modal será fechado.");
    onLoading("Selecionar Produto")
    setShowModal(false);
  };

  return (
    <>
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title className={styles["modal-title"]}>
            Selecione um Produto
          </Modal.Title>
          <Button variant="none" className="btn btn-none" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <table
            ref={tableRef}
            className={`table align-items-center justify-content-center mb-0 ${styles.table} `}
          >
            <thead>
              <tr>
              <th className="text-center">Selecionar</th>
                <th className="text-center">Código</th>
                <th>Produto</th>
                <th>Descrição</th>    
              </tr>
            </thead>
            <tbody>
              {produtos.map((produto, index) => (
                <tr key={index}>
                  <td className="text-center">
                    <button
                      className={styles.select}
                      type="button"
                      onClick={() => {
                        if (!produto || !produto.PRODUTO) {
                          console.error("Informações do produto incompletas.");
                          return;
                        }

                        if (
                          selectedProdutos &&
                          selectedProdutos.CODPROD === produto.CODPROD
                        ) {
                          console.log(
                            "Produto já selecionado. Removendo da seleção."
                          );
                          setSelectedProdutos(null);
                        } else {
                          console.log(
                            "Produto não estava selecionado. Adicionando à seleção.",
                            produto
                          );
                          setSelectedProdutos({
                            CODPROD: produto.CODPROD,
                            PRODUTO: produto.PRODUTO,
                            DESCRICAO: produto.DESCRICAO,
                          });
                        }
                      }}
                    >
                      <i
                        className={`bi ${
                          selectedProdutos &&
                          selectedProdutos.CODPROD === produto.CODPROD
                            ? "bi-check-circle-fill"
                            : "bi-circle"
                        }`}
                      ></i>
                    </button>
                  </td>
                  <td className="text-center">{produto.CODPROD}</td>
                  <td className="text-center">
                    {capitalizeFirstLetter(produto.PRODUTO)}
                  </td>
                  <td className="text-center">{produto.DESCRICAO}</td>
                  
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          {showError && (
            <p style={{ color: "red" }}>
              ⚠️Selecione um produto antes de salvar
            </p>
          )}
          <Button
            onClick={() => {
              if (
                !selectedProdutos ||
                !selectedProdutos.PRODUTO ||
                !selectedProdutos.CODPROD
              ) {
                console.error(
                  "Dados do produto selecionado estão incompletos ou ausentes."
                );
                setShowError(true);
                onLoading("Selecionar Produto")
                return;
              } else {
                onSelect(selectedProdutos);
                setShowError(false);
                console.log(
                  `Salvando: ${selectedProdutos.PRODUTO} , CODPROD: ${selectedProdutos.CODPROD}`
                );
                onLoading("Selecionar Produto")
                handleClose();
              }
            }}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProdutoEstoqueModal;
