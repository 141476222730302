import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import { Form, Tab, Tabs } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import AddImagem from "../../Assets/Images/addImage.jpg";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";

import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import MessageModal from "../../Components/MessageModal/MessageModal";
import styles from "./EditarProduto.module.css";
import UnAlternativaTable from "./UnAlternativaTable/UnAlternativaTable";
import EditarUnidadeAlternativa from "./EditarUnidadeAlternativa/EditarUnidadeAlternativa";
import { validarCamposVazios } from "../../Utils/validarCamposVazios";

const EditarProduto = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const location = useLocation();
  const { codProd, codLoc, codUnMed } = location.state;

  const [codProduto, setCodProduto] = useState("");

  const [nome, setNome] = useState("");
  const [fornecedores, setFornecedores] = useState("");
  const [listaFornecedores, setListaFornecedores] = useState([]);
  const [locais, setLocais] = useState([]);
  const [pesoBruto, setPesoBruto] = useState("");
  const [pesoLiquido, setPesoLiquido] = useState("");
  const [descricao, setDescricao] = useState("");
  const [unidadesMedida, setUnidadesMedida] = useState([]);

  const [categoria, setCategoria] = useState("");
  const [categoriaSelecionada, setCategoriaSelecionada] = useState([]);

  const [sku, setSku] = useState("");
  const [ncm, setNcm] = useState("");
  const [formValues, setFormValues] = useState({
    local: { codigo: "", descricao: "" },
    unidadeMedida: { codigo: "", descricao: "" },
    unidadeMedidaAlternativa: { codigo: "", descricao: "" },
  });
  const [altura, setAltura] = useState("");
  const [largura, setLargura] = useState("");
  const [comprimento, setComprimento] = useState("");
  const [profundidade, setProfundidade] = useState("");
  const [volume, setVolume] = useState("");
  const [tipo, setTipo] = useState("");
  const [fator, setFator] = useState(1);
  const [quantidade, setQuantidade] = useState(0);
  const [controle, setControle] = useState("");

  const [imagens, setImagens] = useState([null, null, null, null]);

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [status, setStatus] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false)
  const [botaoSalvar, setBotaoSalvar] = useState("Salvar")

  useEffect(() => {
    setAuthToken(token);
    setCodProduto(codProd);
    setLoading(true)
    api
      .get(`/produtos/buscar/?CODEMP=${codemp}&CODPROD=${Number(codProduto)}`)
      .then((response) => {
        console.log("--->", response.data);
        setNome(response.data.PRODUTO);

        const detalhes = response.data.Detalhes || {};
        setAltura(detalhes.ALTURA);
        setComprimento(detalhes.COMPRIMENTO);
        setProfundidade(detalhes.PROFUNDIDADE);
        setLargura(detalhes.LARGURA);
        setVolume(detalhes.VOLUME);
        setPesoBruto(detalhes.PESOBRUTO);
        setPesoLiquido(detalhes.PESOLIQUIDO);
        setCategoria(response.data.CODCATPROD);
        setStatus(response.data.STATUS);

        setFormValues({
          local: {
            codigo: Number(response.data.CODLOC),
            descricao: response.data.LOCAL,
          },
          unidadeMedida: {
            codigo: Number(response.data.CODUNMED),
            descricao: response.data.TIPO,
          },
          unidadeMedidaAlternativa: {
            codigo: Number(response.data.CODUNMED),
            descricao: response.data.TIPO,
          },
        });

        setFornecedores(response.data.FABRICANTE);
        setDescricao(response.data.DESCRICAO);
        setSku(response.data.SKU);
        setNcm(response.data.NCM);
        setImagens([
          response.data.ImagemProd.IMAGEM,
          response.data.ImagemProd.IMAGEM1,
          response.data.ImagemProd.IMAGEM2,
          response.data.ImagemProd.IMAGEM3,
        ]);
      })
      .catch((error) => {
        console.error("Erro ao obter produto:", error);
      })
      .finally(()=>{
        setLoading(false)
      })
  }, [codProduto, codemp, token]);

  useEffect(() => {
    const getFornecedores = async () => {
      try {
        const response = await api.get(`/fornecedor/listar?CODEMP=${codemp}`);
        const activeFornecedores = response.data.filter(
          (fornecedor) => fornecedor.STATUS
        );
        setListaFornecedores(activeFornecedores);
        console.log("fornecedores ativos", activeFornecedores);
      } catch (error) {
        console.log("Error: ", error);
      }
    };
    getFornecedores();
  }, [codemp]);

  useEffect(() => {
    const getCategoria = async () => {
      try {
        const response = await api.get(
          `/categoria/listar/?CODEMP=${Number(codemp)}`
        );
        setCategoriaSelecionada(response.data);
      } catch (error) {
        console.log("Error: ", error);
      }
    };
    getCategoria();
  }, [codemp]);

  useEffect(() => {
    const fetchLocais = async () => {
      try {
        const response = await api.get(`/locais/listar?CODEMP=${codemp}`);
        setLocais(response.data);
      } catch (error) {
        console.error("Erro ao obter locais:", error);
      }
    };

    fetchLocais();
  }, [codemp, codLoc]);

  useEffect(() => {
    const fetchUnidadesMedida = async () => {
      try {
        const response = await api.get(`/unidademed/listar?CODEMP=${codemp}`);
        setUnidadesMedida(response.data);
        console.log("unidades", response.data);
      } catch (error) {
        console.error("Erro ao obter unidades de medida:", error);
      }
    };

    fetchUnidadesMedida();
  }, [codemp, codUnMed]);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Prefix = "data:image/jpeg;base64,";
        const base64ImageData = reader.result.substring(base64Prefix.length);
        resolve(base64ImageData);
      };
    });
  };

  const resizeAndEncode = async (file, setImageState) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      70,
      0,
      async (uri) => {
        let base64Image = await getBase64(uri);
        setImageState(base64Image);
      },
      "blob",
      200,
      200
    );
  };

  const handleFileInputChange = (index) => async (event) => {
    const file = event.target.files[0];
    if (file) {
      const setImageState = (base64Image) => {
        const updatedImagens = [...imagens];
        updatedImagens[index] = base64Image;
        setImagens(updatedImagens);
      };

      await resizeAndEncode(file, setImageState);
    }
  };

  const handleExcluirImagem = () => {
    const updatedImagens = [...imagens];
    updatedImagens[selectedImageIndex] = null; // aqui esta setando as imagens selecionadas como null
    setImagens(updatedImagens);
  };

  const handleSelectChange = (event, campo) => {
    setFormValues({
      ...formValues,
      [campo]: { codigo: event.target.value, descricao: "" },
    });
  };

  const handleSalvar = async () => {

    
    const produtoData = {
      CODPROD: codProduto,
      CODEMP: Number(codemp),
      CODLOC: +formValues.local.codigo,
      CODUNMED: +formValues.unidadeMedida.codigo,
      PRODUTO: nome,
      SKU: sku,
      NCM: ncm,
      CODCATPROD: +categoria,
      STATUS: status,
      PESOBRUTO: pesoBruto,
      PESOLIQUIDO: pesoLiquido,
      ALTURA: altura,
      LARGURA: largura,
      COMPRIMENTO: comprimento,
      PROFUNDIDADE: profundidade,
      VOLUME: volume,
      FABRICANTE: fornecedores,
      DESCRICAO: descricao,
      IMAGENS: {
        IMAGEM: imagens[0] ? imagens[0] : '',
        IMAGEM1: imagens[1],
        IMAGEM2: imagens[2],
        IMAGEM3: imagens[3],
      },
    };

    const dadosObrigatorios = {
      'Nome do Produto': nome,
      'Código': sku,
      'Descrição': descricao,
      NCM: ncm,
      'Fornecedor': fornecedores,
      'Unidade de Medida': +formValues.unidadeMedida.codigo,
      'Categoria': categoria ? categoria : '',
      'Local Padrão': +formValues.local.codigo      
    }

    if(validarCamposVazios(dadosObrigatorios).length > 0) {
      setModalMessage(`Preencha os campos obrigatorios: ${validarCamposVazios(dadosObrigatorios)}!`);
      setShowMessageModal(true);
      return;
    } 

    setBotaoSalvar("Carregando...")
    try {
      await api.patch(`/produtos/atualizar`, produtoData);
      setModalMessage("Produto atualizado com sucesso!");
      setIsSuccess(true);
      setShowMessageModal(true);
    } catch (error) {
      console.error("Erro ao salvar produto:", error);
      let errorMessage = "";
      if (error.response && error.response.status === 500) {
        errorMessage =
          "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
      } else {
        errorMessage = "Erro ao atualizar produto.Tente novamente!";
      }
      setModalMessage(errorMessage);
      setIsSuccess(false);
      setShowMessageModal(true);
    } finally {
      setBotaoSalvar("Salvar")
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErro("");
    // checar se há inputs vazios e setar erro
    if (
      formValues.unidadeMedidaAlternativa.codigo === "" ||
      formValues.unidadeMedidaAlternativa.codigo === 0 ||
      tipo === "" ||
      quantidade === "" ||
      fator === "" 
    ) {
      setModalMessage("Preencha todos os campos!");
      setShowMessageModal(true);
      return;
    }

    // criar objeto com novoMarca a ser enviado para o banco de dados
    const unidadeAlternativa = {
      CODEMP: Number(codemp),
      CODPROD: codProduto,
      CODUNMED: +formValues.unidadeMedidaAlternativa.codigo,
      QUANTIDADE: quantidade,
      DIVISAO: tipo === "ativo",
      MULTIPLICACAO: tipo === "inativo",
      VALORMULTDIV: fator,
      CONTROLE: controle,
    };

    api
      .post(`/unidademed/cadastrar/alt`, unidadeAlternativa)
      .then((response) => {
        console.log("Unidade alternativa criada com sucesso:", response.data);
        setMessage("Unidade alternativa criada com sucesso!");
        setErro("");
        setShowMessage(true);
        setShowForm(false);

        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao criar unidade alternativa. Tente novamente!");
        }
        setMessage("");
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      });
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/produtos");
  };

  const handleSelectFocus = () => {
    if (tipo === "") {
      setTipo("");
    }
  };

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            className="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/produtos");
            }}
          ></i>
          Editar Produto
        </h1>

      </div>

      <Form className={styles.form}>
        <Tabs defaultActiveKey="dadosProduto" className={styles.abas}>
          <Tab eventKey="dadosProduto" title="Descrição">
            <div className={styles.corpo}>
              <fieldset className={styles.fieldsetTopo}>
                {showMessage && (
                  <div
                    className={`${styles.message} ${
                      message ? styles.successMessage : styles.errorMessage
                    }`}
                  >
                    <p>{message || erro}</p>
                  </div>
                )}
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <p style={{fontSize:'.7rem'}}>Campos obrigatórios(*)</p>
                  {loading && <p style={{color: 'red'}}>Carregando...</p>}
                </div>
                <div className={styles.inputsFlex}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="nome" className={styles.label}>
                      Nome do Produto*
                    </Form.Label>
                    <Form.Control
                      id="nome"
                      value={nome}
                      onChange={(e) => setNome(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="descricao" className={styles.label}>
                      Descrição*
                    </Form.Label>
                    <Form.Control
                      id="descricao"
                      value={descricao}
                      onChange={(e) => setDescricao(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label
                      htmlFor="codigoProduto"
                      className={styles.label}
                    >
                      Código*
                    </Form.Label>
                    <Form.Control
                      id="codigoProduto"
                      placeholder="sku"
                      value={sku}
                      onChange={(e) => setSku(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="ncmProduto" className={styles.label}>
                      NCM*
                    </Form.Label>
                    <Form.Control
                      id="ncmProduto"
                      value={ncm}
                      onChange={(e) => setNcm(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <div className={styles.segmento}>
                      <Form.Label
                        htmlFor="fornecedores"
                        className={styles.label}
                      >
                        Fornecedor*:
                      </Form.Label>
                    </div>
                    <Form.Select
                      className="form-control"
                      id="fornecedores"
                      value={fornecedores}
                      onChange={(e) => {
                        setFornecedores(e.target.value);
                      }}
                    >
                      <option value=''>Selecione uma opção</option>
                      {listaFornecedores.map((fornecedor) => (
                        <option
                          key={fornecedor.CODFOR}
                          value={fornecedor.CODFOR}
                        >
                          {fornecedor.FORNECEDOR}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              </fieldset>

              {/* <fieldset className={styles.fieldsetTopo}> */}
              {/* <div className={styles.inputsFlex}> */}
              {/* <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="estoque" className={styles.label}>
                    Quantidade em Estoque*
                  </Form.Label>
                  <Form.Control
                    id="estoque"
                    value={estoque}
                    onChange={(e) => setEstoque(e.target.value)}
                  />
                </Form.Group> */}
              {/* </div> */}
              {/* </fieldset> */}
            </div>
            
          </Tab>

          <Tab eventKey="detalhesProduto" title="Informações Gerais">
            <div className={styles.corpo}>
              <fieldset className={styles.fieldsetTopo}>
                <h1>Campos obrigatórios(*)</h1>
                <div className={styles.inputsFlex}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label
                      htmlFor="unidadeMedida"
                      className={styles.label}
                    >
                      Unidade de Medida*
                    </Form.Label>
                    <Form.Control
                      as="select"
                      id="unidadeMedida"
                      value={formValues.unidadeMedida.codigo}
                      onChange={(e) => handleSelectChange(e, "unidadeMedida")}
                    >
                      <option value="">Selecione uma opção</option>
                      {unidadesMedida &&
                        unidadesMedida.map((unidade) => (
                          <option
                            key={unidade.CODUNMED}
                            value={unidade.CODUNMED}
                          >
                            {capitalizeFirstLetter(unidade.TIPO)}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <div className={styles.segmento}>
                      <Form.Label htmlFor="categoria" className={styles.label}>
                        Categoria*:
                      </Form.Label>
                    </div>
                    <Form.Select
                      className="form-control"
                      id="categoria"
                      value={categoria}
                      onChange={(e) => {
                        setCategoria(e.target.value);
                      }}
                    >
                      <option value=''>Selecione uma categoria</option>
                      {categoriaSelecionada.map((categoria) => (
                        <option
                          key={categoria.CODCATPROD}
                          value={categoria.CODCATPROD}
                        >
                          {categoria.CATEGORIA}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="local" className={styles.label}>
                      Local padrão*
                    </Form.Label>
                    <Form.Control
                      as="select"
                      id="local"
                      value={formValues.local.codigo}
                      onChange={(e) => handleSelectChange(e, "local")}
                    >
                      <option value="">Selecione uma opção</option>
                      {locais &&
                        locais.map((local) => (
                          <option key={local.CODLOC} value={local.CODLOC}>
                            {capitalizeFirstLetter(local.LOCAL)}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <h1>Informações opcionais</h1>
                {showMessage && (
                  <div
                    className={`${styles.message} ${
                      message ? styles.successMessage : styles.errorMessage
                    }`}
                  >
                    <p>{message || erro}</p>
                  </div>
                )}
                <div className={styles.inputsFlex}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="pesoBruto" className={styles.label}>
                      Peso Bruto:
                    </Form.Label>
                    <Form.Control
                      id="pesoBruto"
                      value={pesoBruto}
                      onChange={(e) => setPesoBruto(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="pesoLiquido" className={styles.label}>
                      Peso Líquido:
                    </Form.Label>
                    <Form.Control
                      id="pesoLiquido"
                      value={pesoLiquido}
                      onChange={(e) => setPesoLiquido(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label className={styles.label} htmlFor="altura">
                      Altura:
                    </Form.Label>
                    <Form.Control
                      id="altura"
                      type="text"
                      value={altura}
                      onChange={(e) => setAltura(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label className={styles.label} htmlFor="largura">
                      Largura:
                    </Form.Label>
                    <Form.Control
                      id="largura"
                      type="text"
                      value={largura}
                      onChange={(e) => setLargura(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label className={styles.label} htmlFor="comprimento">
                      Comprimento:
                    </Form.Label>
                    <Form.Control
                      id="comprimento"
                      type="text"
                      value={comprimento}
                      onChange={(e) => setComprimento(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label className={styles.label} htmlFor="profundidade">
                      Profundidade:
                    </Form.Label>
                    <Form.Control
                      id="profundidade"
                      type="text"
                      value={profundidade}
                      onChange={(e) => setProfundidade(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label className={styles.label} htmlFor="volume">
                      Volume:
                    </Form.Label>
                    <Form.Control
                      id="volume"
                      type="text"
                      value={volume}
                      onChange={(e) => setVolume(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </fieldset>
            </div>
          </Tab>

          <Tab eventKey="editarImagem" title="Imagem">
            {showMessage && (
              <div
                className={`${styles.message} ${
                  message ? styles.successMessage : styles.errorMessage
                }`}
              >
                <p>{message || erro}</p>
              </div>
            )}
            <div className={styles.imagens}>
              <div
                className={styles.largeImage}
                style={{
                  width: "260px",
                  height: "200px",
                  backgroundColor: "#fff",
                  marginBottom: "1rem",
                }}
              >
                <img
                  src={`data:image/jpeg;base64,${imagens[selectedImageIndex]}`}
                  alt={selectedImageIndex ? `Imagem ${selectedImageIndex + 1}` : "Sem imagem"}
                />
              </div>

              <div className={styles.imageList}>
                {imagens.map((imagem, index) => (
                  <div key={index} className={styles.imageContainer}>
                    {imagem ? (
                      <img
                        src={`data:image/jpeg;base64,${imagem}`}
                        alt={`Imagem ${index + 1}`}
                        onClick={() => setSelectedImageIndex(index)}
                      />
                    ) : (
                      <div className={styles.addImagem}>
                        <label
                          htmlFor="inputFile"
                          className={styles.uploadLabel}
                        >
                          <img
                            src={AddImagem}
                            alt="Imagem de uma câmera para add imagem"
                          />
                        </label>
                        <input
                          type="file"
                          id="inputFile"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleFileInputChange(index)}
                        />
                      </div>
                    )}
                    {index === selectedImageIndex && imagem && (
                      <button
                        type="button"
                        className={styles.closeButton}
                        onClick={handleExcluirImagem}
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    )}
                  </div>
                ))}

                {/* <div className={styles.novaImagem}>
                  <label
                    htmlFor="inputFile"
                    className={`${styles.submit} ${styles.salvarButton}`}
                  >
                    Adicionar Imagem
                  </label>
                  <input
                    type="file"
                    id="inputFile"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileInputChange(1)}
                  />
                </div> */}
              </div>
              <div className={styles.infartiveText}>
                <p>
                  Formatos aceitos: JPG, JPEG ou PNG.Tamanho máximo por foto
                  20MB.
                </p>
              </div>
            </div>
          </Tab>

          <Tab eventKey="unidadeAlternativa" title="Unidade alternativa">
            {showForm ? (
              <fieldset className={styles.fieldsetTopo}>
                <h1>Campos obrigatórios(*)</h1>
                {showMessage && (
                  <div
                    className={`${styles.message} ${
                      message ? styles.successMessage : styles.errorMessage
                    }`}
                  >
                    <p>{message || erro}</p>
                  </div>
                )}
                <div className={styles.inputsFlex}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label
                      htmlFor="unidadeMedida"
                      className={styles.label}
                    >
                      Unidade de Medida Padrão*
                    </Form.Label>
                    <Form.Control
                      as="select"
                      id="unidadeMedida"
                      value={formValues.unidadeMedida.codigo}
                      disabled
                      onChange={(e) => handleSelectChange(e, "unidadeMedida")}
                    >
                      <option value="">Selecione uma opção</option>
                      {unidadesMedida &&
                        unidadesMedida.map((unidade) => (
                          <option
                            key={unidade.CODUNMED}
                            value={unidade.CODUNMED}
                          >
                            {capitalizeFirstLetter(unidade.TIPO)}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label
                      htmlFor="unidadeMedida"
                      className={styles.label}
                    >
                      Unidade de Medida alternativa*
                    </Form.Label>
                    <Form.Control
                      as="select"
                      id="unidadeMedida"
                      value={formValues.unidadeMedidaAlternativa.codigo}
                      onChange={(e) =>
                        handleSelectChange(e, "unidadeMedidaAlternativa")
                      }
                    >
                      <option value="">Selecione uma opção</option>
                      {unidadesMedida &&
                        unidadesMedida.map((unidade) => (
                          <option
                            key={unidade.CODUNMED}
                            value={unidade.CODUNMED}
                          >
                            {capitalizeFirstLetter(unidade.TIPO)}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="tipo" className={styles.label}>
                      Tipo*
                    </Form.Label>
                    <Form.Select
                      id="tipo"
                      value={tipo}
                      className="form-control"
                      onChange={(e) => setTipo(e.target.value)}
                      onFocus={handleSelectFocus}
                    >
                      <option value="">Selecione o tipo</option>
                      <option value="ativo">Divide</option>
                      <option value="inativo">Multiplica</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="fator" className={styles.label}>
                      Fator:
                    </Form.Label>
                    <Form.Control
                      id="fator"
                      type="number"
                      value={fator}
                      onChange={(e) => {
                        const newValue = Math.max(
                          0,
                          parseFloat(e.target.value)
                        );
                        setFator(newValue);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="quantidade" className={styles.label}>
                      Quantidade*:
                    </Form.Label>
                    <Form.Control
                      id="quantidade"
                      type="number"
                      value={quantidade}
                      onChange={(e) => {
                        const newValue = Math.max(
                          0,
                          parseFloat(e.target.value)
                        );
                        setQuantidade(newValue);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="controle" className={styles.label}>
                      Controle*:
                    </Form.Label>
                    <Form.Control
                      id="controle"
                      value={controle}
                      onChange={(e) => setControle(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className={styles.containerBtnsUnAlt}>
                  <button
                    className={` ${styles.cancelButton} `}
                    type="button"
                    onClick={()=>setShowForm(false)}
                  >
                    Cancelar
                  </button>
                  <button
                    className={`${styles.submit} ${styles.salvarButton} `}
                    type="button"
                    onClick={handleSubmit}
                  >
                    <i className="bi bi-play-fill"></i>
                    Adicionar
                  </button>
                </div>
              </fieldset>
            ) : (
              <UnAlternativaTable
                showForm={showForm}
                onToggleFormVisibility={toggleFormVisibility}
              />
            )}
          </Tab>
        </Tabs>
      </Form>
      <div className={styles.rodape}>
              <Form.Group className={styles.formCheck}>
                <Form.Check
                  className={styles.checkbox}
                  type="checkbox"
                  label="Ativar"
                  checked={status}
                  onClick={() => setStatus(!status)}
                />
              </Form.Group>
              <button
                className={`${styles.submit} ${styles.salvarButton}`}
                type="button"
                onClick={handleSalvar}
              >
                {botaoSalvar}
              </button>
            </div>
    </div>
    
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default EditarProduto;
