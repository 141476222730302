import $ from "jquery";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import api, { setAuthToken } from "../../../Services/api";
import { AuthContext } from "../../../Services/AuthContext";
import { capitalizeFirstLetter } from "../../../Utils/stringCapitalizer";

import styles from "./ModalCliente.module.css";
import axios from "axios";

const ClientesModal = ({ onSelect, onClose, onLoading }) => {
  const { token, codemp } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedClientes, setSelectedClientes] = useState(null);
  const [showError, setShowError] = useState(false);
  const tableRef = useRef(null);
  const [semDados, setSemDados] = useState('');

  useEffect(() => {
    setAuthToken(token);
  }, [token]);
  // useEffect(() => {
  //   setAuthToken(token);
  //   onLoading(true,'cliente')
  //   api
  //     .get(`/clientes/listar?CODEMP=${codemp}`)
  //     .then((response) => {
  //       setClientes(response.data);
  //       setLoaded(true);
  //       setShowModal(true);
  //     })
  //     .catch((error) => {
  //       console.error("Erro ao obter clientes:", error);
  //     })
  //     .finally(() =>{
  //       onLoading(false,'cliente')
  //     });
  // }, [codemp, token, loaded]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    setClientes([])
    setSemDados('')
    onLoading(true,'cliente')
    const getClientes = async () => {
      try {
        const response = await api.get(`/clientes/listar?CODEMP=${CODEMP}`, {
          cancelToken: source.token
        });
        if(response.data.length === 0 ){
          setSemDados("Sem dados")
        } else {
          setClientes(response.data);
        }
        setShowModal(true);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
      } finally {
        onLoading(false,'cliente')
      }
    };
    getClientes();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp]);

  useEffect(() => {
    if (
      clientes.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
      });
    }
  }, [clientes]);

  const handleClose = () => {
    console.log("handleClose chamado. Modal será fechado.");
    onClose();
  };

  return (
    <>
      <Modal
        show={showModal}
        size="xl"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title className={styles["modal-title"]}>
            Selecione um Cliente
          </Modal.Title>
          <Button variant="none" className="btn btn-none" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Modal.Header>
        <Modal.Body style={{ overflow: "auto" }}>
          <table
            ref={tableRef}
            className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
          >
            <thead>
              <tr>
                <th className="text-center">Selecionar</th>
                <th className="text-center">Código</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Cpf/Cnpj</th>
              </tr>
            </thead>
            <tbody>
              {semDados === 'Sem dados' ? <tr>
                <td style={{ textAlign: "center" }} colSpan="8">
                A tabela está vazia no momento.
                </td>
              </tr> : clientes.map((cliente, index) => (
                <tr key={index}>
                  <td className="text-center">
                    <button
                      className={styles.select}
                      type="button"
                      onClick={() => {
                        if (!cliente || !cliente.Pessoa) {
                          console.error("Informações do cliente incompletas.");
                          return;
                        }

                        const newSelectedCliente = {
                          CODCLI: cliente.CODCLI,
                          NOME: cliente.Pessoa.NOME,
                          SOBRENOME: cliente.Pessoa.SOBRENOME,
                          CPFCNPJ: cliente.Pessoa.CPFCNPJ,
                        };

                        if (
                          selectedClientes &&
                          selectedClientes.CODCLI === newSelectedCliente.CODCLI
                        ) {
                          console.log(
                            "Cliente já selecionado. Removendo da seleção."
                          );
                          setSelectedClientes(null);
                        } else {
                          console.log(
                            "Cliente não estava selecionado. Adicionando à seleção.",
                            newSelectedCliente
                          );
                          setSelectedClientes(newSelectedCliente);

                          // Lógica para salvar aqui
                          setShowError(false);
                          console.log(
                            `Salvando: ${newSelectedCliente.NOME} ${newSelectedCliente.SOBRENOME}, CODCLI: ${newSelectedCliente.CODCLI}`
                          );
                          onSelect(newSelectedCliente);
                          handleClose(); // Feche o modal após salvar
                        }
                      }}
                    >
                      <i
                        className={`bi ${
                          selectedClientes &&
                          selectedClientes.CODCLI === cliente.CODCLI
                            ? "bi-check-circle-fill"
                            : "bi-circle"
                        }`}
                      ></i>
                    </button>
                  </td>
                  <td className="text-center">{cliente.CODCLI}</td>
                  <td className="text-center">{`${capitalizeFirstLetter(
                    cliente.Pessoa.NOME
                  )} ${capitalizeFirstLetter(cliente.Pessoa.SOBRENOME)}`}</td>
                  <td className="text-center">{cliente.Pessoa.EMAIL}</td>
                  <td className="text-center">{cliente.Pessoa.CPFCNPJ}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        {showError && (
          <p className={styles.errorContainer} style={{ color: "red" }}>
            ⚠️Selecione um cliente antes de salvar
          </p>
        )}
        {/* <Modal.Footer>
          <Button
            onClick={() => {
              if (
                !selectedClientes ||
                !selectedClientes.NOME ||
                !selectedClientes.CODCLI
              ) {
                console.error(
                  "Dados do cliente selecionado estão incompletos ou ausentes."
                );
                setShowError(true);
                return;
              }
              setShowError(false);
              console.log(
                `Salvando: ${selectedClientes.NOME} ${selectedClientes.SOBRENOME}, CODCLI: ${selectedClientes.CODCLI}`
              );
              onSelect(selectedClientes);
              handleClose();
            }}
          >
            Salvar
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ClientesModal;
