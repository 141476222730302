// ESTILO
import styles from "./Dashboard.module.css";

// HOOKS
import { useState, useEffect, useContext } from "react";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { Navigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import { AuthContext } from "../../Services/AuthContext";
import api from "../../Services/api";
import { Chart } from "react-google-charts";

// LIBS
import jwt_decode from "jwt-decode";

const Dashboard = () => {
  const { token, codemp } = useContext(AuthContext);
  const [diasRestantes, setDiasRestantes] = useState(null);
  const [licencaProximaVencer, setLicencaProximaVencer] = useState(false);
  const [licencaVenceu, setLicencaVenceu] = useState(false);
  const [exibirMensagem, setExibirMensagem] = useState(true);
  const PERMISSAO = sessionStorage.permissao;
  console.log(PERMISSAO);

  const [loadingChartsPizza, setLoadingChartsPizza] = useState(false)
  const [loadingChartsBarra, setLoadingChartsBarra] = useState(false)

  const handleApiError = (error, customMessage) => {
    console.error(error);
    console.log(`${customMessage}: ${error.message}`);
    console.log("Mensagem de erro da API:", error.response.data);
  };

  //tempo que a mensagem de licenças fica na tela
  useEffect(() => {
    const tempoMensagem = setTimeout(() => {
      setExibirMensagem(false);
    }, 8000);

    return () => clearTimeout(tempoMensagem);
  }, []);

  //pega datas para ver se a licença esta valida ou vencendo
  useEffect(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      const dataVencimento = new Date(decodedToken.DATAVENC);
      const dataAtual = new Date();

      //Calcula a diferença em dias
      const diferencaDeTempo = dataVencimento - dataAtual;
      const diasRestantes = Math.floor(
        diferencaDeTempo / (1000 * 60 * 60 * 24)
      );

      // Define o estado com os dias restantes
      setDiasRestantes(diasRestantes);

      // Verificar se faltam 30 dias ou menos para a data de vencimento
      const dataProximaVencer = new Date();
      dataProximaVencer.setDate(dataProximaVencer.getDate() + 30);
      setLicencaProximaVencer(
        dataVencimento.getTime() <= dataProximaVencer.getTime()
      );

      // Verificar se a data de vencimento é anterior à data atual
      setLicencaVenceu(dataVencimento.getTime() < dataAtual.getTime());
    }
  }, [token]);

  // const handleLogout = () => {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("userData");
  //   Navigate("/login");
  // };

  const [inicioPizza, setInicioPizza] = useState((new Date()).toISOString())
  const [fimPizza, setFimPizza] = useState((new Date()).toISOString())
  const [showInicioFimPizza, setShowInicioFimPizza] = useState(false)
  const [inicioPizzaData, setInicioPizzaData] = useState('')
  const [fimPizzaData, setFimPizzaData] = useState('')
  const [dataModificadaPizza, setDataModificadaPizza] = useState(false)

  const [inicioBarra, setInicioBarra] = useState((new Date()).toISOString())
  const [fimBarra, setFimBarra] = useState((new Date()).toISOString())
  const [showInicioFimBarra, setShowInicioFimBarra] = useState(false)
  const [inicioBarraData, setInicioBarraData] = useState('')
  const [fimBarraData, setFimBarraData] = useState('')
  const [dataModificadaBarra, setDataModificadaBarra] = useState(false)

  const [dataPie, setDataPie] = useState([])
  const [dataBarra, setDataBarra] = useState([])

  useEffect(() => {
    const fetchDataPizza = async () => {
      setLoadingChartsPizza(true)
      const newCodemp = codemp ? codemp : localStorage.CODEMP
      if (newCodemp, inicioPizza, fimPizza) {
        try {
          const response = await api.get(`/apontamento/get?CODEMP=${newCodemp}&INICIO=${inicioPizza}&FIM=${fimPizza}`);
          const arrayVendedores = response.data.ADMINISTRADOR.VENDEDORES;

          //let pieInicialData = []
          //criando os dados da pie
          setDataPie([["Vendedor", "Valor por data"]]);
          for (let index = 0; index < arrayVendedores.length; index++) {
            let item = [arrayVendedores[index].VENDEDOR.INFOS[0].NOME, arrayVendedores[index].VENDEDOR.GERAL.VALOR];
            setDataPie((prevData) => [...prevData, item]);
            //pieInicialData.push(item)
          }

          //setDataPie((prevData) => [...prevData, pieInicialData]);
          //console.log(pieInicialData);

        } catch (error) {
          setDataPie([])
          handleApiError(error, "Houve um erro ao buscar os dados do gráfico de Pizza");
        } finally {
          setLoadingChartsPizza(false)
        }
      }
    };
  
    fetchDataPizza();
  }, [token, codemp,dataModificadaPizza]);

  console.log(dataPie);
  console.log(dataBarra);

  useEffect(() => {
    const fetchDataBarra = async () => {
      setLoadingChartsBarra(true)
      const newCodemp = codemp ? codemp : localStorage.CODEMP
      if (newCodemp, inicioBarra, fimBarra) {
        try {
          const response = await api.get(`/apontamento/get?CODEMP=${newCodemp}&INICIO=${inicioBarra}&FIM=${fimBarra}`);
          const arrayVendedores = response.data.ADMINISTRADOR.VENDEDORES;

          //criando os dados da barra
          let arrayInicialBarra = []
          for (let index = 0; index < arrayVendedores.length; index++) {
            let item = [arrayVendedores[index].VENDEDOR.INFOS[0].NOME, arrayVendedores[index].VENDEDOR.GERAL.VALOR];
            arrayInicialBarra.push(item)
          }

          let arrayFinalBarra = []
          let arrayNome = ['']
          let arrayValor = ['']
          for (let index = 0; index < arrayInicialBarra.length; index++) {
             let itemNome = arrayInicialBarra[index][0];
             let itemValor = arrayInicialBarra[index][1];
            arrayNome.push(itemNome)
            arrayValor.push(itemValor)
          }
          arrayFinalBarra.push(arrayNome, arrayValor)
          setDataBarra(arrayFinalBarra);
          console.log(arrayInicialBarra);
          if(arrayFinalBarra?.length == 0){
            setDataBarra([])
          }

        } catch (error) {
          setDataBarra([])
          handleApiError(error, "Houve um erro ao buscar os dados do gráfico de Barra");
        } finally {
          setLoadingChartsBarra(false)
        }
      }
    };
  
    fetchDataBarra();
  }, [token, codemp,dataModificadaBarra]);
  
  const optionsPie = {
    title: "Porcentagem por vendedor",
  };
  
  const optionsBar = {
    title: "Valor total por vendedor",
    hAxis: {
      title: "Valor total",
      minValue: 0,
    },
    vAxis: {
      title: "Vendedores",
    }
  };  

  const vendedoresLength = 2

  const children = (
    <div className={styles.container}>
      <div className={styles.topo}>
        <h1 className={styles.titleTable}>Dashboard</h1>
        {exibirMensagem &&
          diasRestantes !== null &&
          diasRestantes <= 30 &&
          !licencaVenceu && (
            <div className={styles.mensagemProximaVencer}>
              <p>
                Sua licença está próxima de vencer! Faltam {diasRestantes} dias
                para o vencimento!
              </p>
            </div>
          )}
      </div>
      <div className={styles.containerCharts}>
      {vendedoresLength === 1 ? <div>
        <p className={styles.nomeCard}>Vendedor: Fulano de tal</p>
        <div className={styles.containerCard}>
          <div className={styles.itemCard}>
            <div className={styles.legendCard}>
              <p>Valor total</p>
              <p>Hoje</p>
            </div>
            <p className={styles.valorCard}><span>R$ </span>10,00</p>
          </div>
          <div className={styles.itemCard}>
            <div className={styles.legendCard}>
              <p>Valor total</p>
              <p>Últimos 7 dias</p>
            </div>
            <p className={styles.valorCard}><span>R$ </span>200,00</p>
          </div>
          <div className={styles.itemCard}>
            <div className={styles.legendCard}>
              <p>Valor total</p>
              <p>Últimos 30 dias</p>
            </div>
            <p className={styles.valorCard}><span>R$ </span>1.500,00</p>
          </div>
        </div>
      </div> : 
      <><div>
          <div className={styles.containerFiltros}>
            <select onChange={(e) => {
              setInicioPizza(e.target.value)
              setFimPizza((new Date()).toISOString())
              setDataModificadaPizza(!dataModificadaPizza)
              if(e.target.value === 'showInicioFimPizza'){ 
                setInicioPizza('')
                setFimPizza('')
                setInicioPizzaData('')
                setFimPizzaData('')
                setShowInicioFimPizza(true)
              } else {
                setShowInicioFimPizza(false)
              }
            }} name="periodoPizza" id="periodoPizza">

              <option value={(new Date()).toISOString()}>Hoje</option>
              <option value={(new Date(Date.now() - (7 * 24 * 60 * 60 * 1000))).toISOString()}>Últimos 7 dias</option>
              <option value={(new Date(Date.now() - (30 * 24 * 60 * 60 * 1000))).toISOString()}>Últimos 30 dias</option>
              <option value="showInicioFimPizza">Escolher outra data</option>

            </select>

            {showInicioFimPizza && 
            <div className={styles.inicioFim}>

              <div className={styles.containerDatas}>
                <div>
                  <span>Início:</span><input onChange={(e) => {
                    setInicioPizza((new Date(e.target.value)).toISOString())
                    setInicioPizzaData(e.target.value)
                  }} value={inicioPizzaData} type="date" name="inicioPizza" id="inicioPizza" />
                </div>

                <div>
                  <span>Fim:</span><input onChange={(e) => {
                    setFimPizza((new Date(e.target.value)).toISOString())
                    setFimPizzaData(e.target.value)
                  }} value={fimPizzaData} type="date" name="fimPizza" id="fimPizza" />
                </div>
              </div>

              <i onClick={() => {
                if(inicioPizza === '' || fimPizza === ''){
                  alert("Adicione a data de início e de fim para continuar!")
                  return
                }
                setDataModificadaPizza(!dataModificadaPizza)
              }} class="bi bi-search"></i>

            </div>}
          </div>
          {loadingChartsPizza ? <div className={styles.loadingCharts}>
            <p>Carregando...</p>
          </div> : (dataPie[1]?.length > 0 ?
            <Chart
            style={{height: '60vh'}}
            chartType="PieChart"
            data={dataPie}
            options={optionsPie}
          /> : <div className={styles.loadingCharts}>
          <p>Não há dados para este <br></br>período de tempo.</p>
        </div>
          )}
      </div>

      <div>
        <div className={styles.containerFiltros}>
          <select onChange={(e) => {
              setInicioBarra(e.target.value)
              setFimBarra((new Date()).toISOString())
              setDataModificadaBarra(!dataModificadaBarra)
              if(e.target.value === 'showInicioFimBarra'){ 
                setInicioBarra('')
                setFimBarra('')
                setInicioBarraData('')
                setFimBarraData('')
                setShowInicioFimBarra(true)
              } else {
                setShowInicioFimBarra(false)
              }
            }} name="periodoBarra" id="periodoBarra">

              <option value={(new Date()).toISOString()}>Hoje</option>
              <option value={(new Date(Date.now() - (7 * 24 * 60 * 60 * 1000))).toISOString()}>Últimos 7 dias</option>
              <option value={(new Date(Date.now() - (30 * 24 * 60 * 60 * 1000))).toISOString()}>Últimos 30 dias</option>
              <option value="showInicioFimBarra">Escolher outra data</option>

            </select>

            {showInicioFimBarra && 
            <div className={styles.inicioFim}>
            
              <div className={styles.containerDatas}>
                <div>
                  <span>Início:</span><input onChange={(e) => {
                    setInicioBarra((new Date(e.target.value)).toISOString())
                    setInicioBarraData(e.target.value)
                  }} value={inicioBarraData} type="date" name="inicioBarra" id="inicioBarra" />
                </div>

                <div>
                  <span>Fim:</span><input onChange={(e) => {
                    setFimBarra((new Date(e.target.value)).toISOString())
                    setFimBarraData(e.target.value)
                  }} value={fimBarraData} type="date" name="fimBarra" id="fimBarra" />
                </div>
              </div>

              <i onClick={() => {
                if(inicioBarra === '' || fimBarra === ''){
                  alert("Adicione a data de início e de fim para continuar!")
                  return
                }
                setDataModificadaBarra(!dataModificadaBarra)
              }} class="bi bi-search"></i>

            </div>}
        </div>
          {loadingChartsBarra ? <div className={styles.loadingCharts}>
            <p>Carregando...</p>
          </div> : (dataBarra[0]?.length > 1 && dataBarra[1]?.length > 1 ?
            <Chart
            style={{height: '60vh'}}
            chartType="BarChart"
            data={dataBarra}
            options={optionsBar}
          /> : <div className={styles.loadingCharts}>
          <p>Não há dados para este <br></br>período de tempo.</p>
        </div>
          )}
      </div>
      </>}
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />

      {licencaVenceu && (
        <div className={styles.modal}>
          <h2>Sua licença está vencida!</h2>
          <p>Por favor, entre em contato conosco para renovar sua licença.</p>
          <Button
            variant="success"
            href="https://api.whatsapp.com/send?phone=551236000269"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-whatsapp"></i> Entre em contato pelo WhatsApp
          </Button>
        </div>
      )}
    </>
  );
};

export default Dashboard;
