// ESTILO
import styles from "./Perfil.module.css";

import Resizer from "react-image-file-resizer";

// MODULOS
import { Form, Button, Modal } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";
import InputMask from "react-input-mask";

// HOOKS
import { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ValidaCNPJ from "./ValidacaoCNPJ";

// COMPONENTES
//import Loading from "../../Components/Loading/Loading";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import { filter, set } from "lodash";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import MessageModal from "../../Components/MessageModal/MessageModal";


  // const [imagem, setImagem] = useState(null);
  // const [imagemEnviada, setImagemEnviada] = useState(false);
  //const [erro, setErro] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  // const [selectedButton, setSelectedButton] = useState(1);
  // const isDisabled =
  // !userData ||
  // !userData.PERMISSAO ||
  // !allowedRoles.includes(userData.PERMISSAO.toLowerCase());
  // const userData = JSON.parse(localStorage.getItem("userData"));
  // const allowedRoles = ["comercial", "administrador", "desenvolvedor"];

function EditarPerfil() {
  //configs
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);

  //usuario
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [cpfcnpj, setCpfcnpj] = useState("");
  const [telefone, setTelefone] = useState("");

  //empresa
  const [cnpj, setCnpj] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [ramoAtividade, setRamoAtividade] = useState("");

  //endereço
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [codEnd, setCodEnd] = useState("");
  const [codDet,setCodDet] = useState('')
  const [complemento, setComplemento] = useState("");
  const [referencia, setReferencia] = useState("");

  //certificado
  const [certificado, setCertificado] = useState(null);
  const [certificadoFile, setCertificadoFile] = useState(null);
  const [certificadoFormatError, setCertificadoFormatError] = useState(false);

  //modal
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [modalAberto, setModalAberto] = useState(false);

  //imagem
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const [imageDeleted, setImageDeleted] = useState(false);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [imagemUsuario, setImagemUsuario] = useState("");
  const [imagemVisualizar, setImagemVisualizar] = useState("");
  const [logoEmpresa, setLogoEmpresa] = useState("");

  //outros
  const fileInput = useRef(null);
  const [iconVisible, setIconVisible] = useState(true);
  const [estouCiente, setEstouCiente] = useState(false);
  const [mostrarCancelarDados, setMostrarCancelarDados] = useState(false);
  const [cancelarDados, setCancelarDados] = useState("");

  const { CODEMP } = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  // Bucar Imagem logo
  useEffect(() => {
    if (CODEMP && token) {
      const getLogoImage = async () => {
        try {
          const response = await api.get(
            `/empresas/buscar?CODEMP=${CODEMP}`
          );
          console.log(response.data);
          if (response.data.LOGO) {
            setLogoEmpresa(response.data.LOGO.IMAGEM);
            setImageDeleted(false);
            // localStorage.setItem(
            //   "selectedImage",
            //   `data:image/jpeg;base64,${response.data.LOGO.IMAGEM}`
            // );

            const img = new Image();
            img.onload = () => {
              setSelectedImageURL(`data:image/jpeg;base64,${response.data.LOGO.IMAGEM}`);
            };
            img.onerror = () => {
              setSelectedImageURL(`data:image/png;base64,${response.data.LOGO.IMAGEM}`);
            };
            img.src = `data:image/jpeg;base64,${response.data.LOGO.IMAGEM}`;

          } else {
            setImageDeleted(true);
            //localStorage.removeItem("selectedImage");
          }
        } catch (error) {
          console.error(error);
          setImageDeleted(true);
          //localStorage.removeItem("selectedImage");
        }
      };
      getLogoImage();

      // const savedImageURL = localStorage.getItem("selectedImage");
      // if (savedImageURL && !imageDeleted) {
      //   setSelectedImageURL(savedImageURL);
      // }
    }
  }, [CODEMP, token, imageDeleted]);

  // Rota buscar dados da empresa
  useEffect(() => {
    api
      .get(`/empresas/buscar/?CODEMP=${Number(codemp)}`)
      .then((response) => {
        setCnpj(response.data.CNPJ);
        setNomeFantasia(response.data.NOMEFANTASIA);
        setInscricaoEstadual(response.data.INSCRICAOESTADUAL);
        setRazaoSocial(response.data.RAZAOSOCIAL);
        setRamoAtividade(response.data.RAMO);
        setLogoEmpresa(response.data.LOGO);
        setCertificado(response.data.Certificado)
      })
      .catch((error) => {
        console.error("Erro ao obter usuário:", error);
      });
  }, [codemp]);

  // rota de buscar pessoa
  const { CODPES } = JSON.parse(localStorage.getItem("userData"));
  useEffect(() => {
    api
      .get(`/pessoas/buscar/?CODEMP=${Number(codemp)}&CODPES=${Number(CODPES)}`)
      .then((response) => {
        setNome(response.data.NOME);
        setSobrenome(response.data.SOBRENOME);
        setCpfcnpj(response.data.CPFCNPJ);
        setTelefone(response.data.TELEFONE);
        setCodDet(response.data.CODDET);
      })
      .catch((error) => {
        console.error("Erro ao obter usuário:", error);
      });

  }, [codemp, CODPES]);

// rota de buscar endereço
  useEffect(() => {
    if(codDet){
      api
      .get(`/enderecos/buscar/codend?CODDET=${codDet}`)
      .then((response) => {
          const enderecoData = response.data.Endereco;
          const endereco = response.data;
          setEstado(enderecoData.ESTADO);
          setCidade(enderecoData.CIDADE);
          setBairro(enderecoData.BAIRRO);
          setCep(enderecoData.CEP);
          setEndereco(`${enderecoData.TIPOEND} ${enderecoData.NOMEEND}`);
          setNumero(endereco.NUMERO);
          setComplemento(endereco.COMPLEMENTO);
          setReferencia(endereco.REFERENCIA);
      })
      .catch((error) => {
        console.error("Erro ao obter usuário:", error);
      });
    }
  }, [codemp, CODPES,codDet]);

  // Empresa
  const handleSubmitEmpresa = async (e, tipo) => {
    e.preventDefault();
    console.log(certificadoFile);
   
    if(tipo === 'certificado'){
    if (!certificadoFile) {
      setModalMessage("Adicione um certificado antes de salvar!");
      setShowMessageModal(true);
      return;
    }

    const formData = new FormData();
    formData.append(`file`, certificadoFile);
  
    try {
      const response = await api.post(`/empresas/cadastrar/certificado?CODEMP=${CODEMP}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setModalMessage("Certificado atualizado com sucesso!");
      setIsSuccess(true);
      setShowMessageModal(true);
      setCertificado(response.data)
    } catch (error) {
      if (error.response.status === 409) {
        setModalMessage("Conflito: Certificado já cadastrado.");
        setShowMessageModal(true);
        return;
      } else {
        let errorMessage = "Erro ao cadastrar certificado. Tente novamente!";
        if (error.response && error.response.status === 500) {
          errorMessage = "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        }
        setModalMessage(errorMessage);
        setShowMessageModal(true);
        return;
      }
    }
  
    // If the certificate is successfully processed, proceed to update company data.
    // if (certificateUploaded) {
    //   const updateEmpresa = {
    //     CNPJ: cnpj,
    //     RAZAOSOCIAL: razaoSocial,
    //     NOMEFANTASIA: nomeFantasia,
    //     RAMO: ramoAtividade,
    //     CODEMP: codemp,
    //   };
  
    //   try {
    //     await api.patch(`/empresas/atualizar/`, updateEmpresa);
    //   } catch (error) {
    //     console.error("Erro ao atualizar empresa:", error);
    //     let errorMessage = "Erro ao atualizar empresa. Tente novamente!";
    //     if (error.response && error.response.status === 500) {
    //       errorMessage = "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
    //     }
    //     setModalMessage(errorMessage);
    //     setIsSuccess(false);
    //     setShowMessageModal(true);
    //     return;
    //   }
    // }
  
  } else {

    if (!logoEmpresa) {
      setModalMessage("Adicione um logo antes de salvar!");
      setShowMessageModal(true);
      return;
    }

    const logoDaEmpresa = {
      CODEMP: +codemp,
      LOGO: logoEmpresa,
    };
  
    try {
      await api.post(`/empresas/editar/imagem`, logoDaEmpresa);
      setModalMessage("Logo da empresa atualizada com sucesso!");
      setIsSuccess(true);
      setShowMessageModal(true);
    } catch (error) {
      console.error("Erro ao adicionar logo da empresa:", error);
      console.log("não enviou para a API");
    }
  }

  
    // Show the success modal only if both certificate and logo are uploaded successfully.
    //if (certificateUploaded && logoUploaded) {

  };
  
  

  // Usuario
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (nome === "" || telefone === "" || cpfcnpj === "" || cep === "") {
    //   setModalMessage("Preencha todos os campos!");
    //   setShowMessageModal(true);
    //   return;
    // }
    // const novoEndereco = {
    //   CODEND: +codEnd,
    //   NUMERO: numero,
    //   REFERENCIA: referencia ? referencia : "-",
    //   COMPLEMENTO: complemento ? complemento : "-",
    // };
    // api
    //   .post(`/enderecos/cadastrar`, novoEndereco)
    //   .then((response) => {
    //     console.log("Endereço cadastrado com sucesso:", response.data);
    //     setCodEnd(response.data.CODEND);
    //     // resetar inputs
    //     resetarInputsUser();
    //   })
    //   .catch((error) => {
    //     console.error("Erro ao criar endereço:", error);
    //     console.log("não enviou para api");
    //   });

    // const usuarioAtualizado = {
    //   NOME: nome,
    //   SOBRENOME: sobrenome,
    //   CPFCNPJ: cpfcnpj,
    //   TELEFONE: telefone,
    //   CODEMP: codemp,
    //   CODPES: CODPES,
    //   CODEND: codEnd,
    // };
    // console.log("usuarioAtualizado:", usuarioAtualizado);
    // api
    //   .patch(`/pessoas/editar/`, usuarioAtualizado)
    //   .then((response) => {
    //     console.log("Usuário atualizado com sucesso:", response.data);
    //     setModalMessage("Usuário atualizado com sucesso!");
    //     setIsSuccess(true);
    //     setShowMessageModal(true);
    //   })
    //   .catch((error) => {
    //     console.error("Erro ao atualizar usuário:", error);
    //     let errorMessage = "";
    //     if (error.response && error.response.status === 500) {
    //       errorMessage =
    //         "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
    //     } else {
    //       errorMessage = "Erro ao atualizar usuário.Tente novamente!";
    //     }
    //     setModalMessage(errorMessage);
    //     setIsSuccess(false);
    //     setShowMessageModal(true);
    //   });

    // Imagem
    const { CODUSU } = JSON.parse(localStorage.getItem("userData"));
    const fotoPerfil = {
      CODUSU: +CODUSU,
      IMAGEM: imagemUsuario,
    };
    console.log(fotoPerfil);
    api
      .post(`/pessoas/editar/imagem`, fotoPerfil)
      .then((response) => {
        console.log("Foto de perfil adicionada com sucesso:", response.data);
        setModalMessage("Usuário atualizado com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        console.error("Erro ao adicionar foto de perfil:", error);
        console.log("não enviou para api");
      });
    console.log(imagemUsuario);
  };

  const resetarInputsUser = () => {
    setNome("");
    setSobrenome("");
    setCpfcnpj("");
    setTelefone("");
    setImagemUsuario("");
    setCep("");
    setEndereco("");
    setNumero("");
    setBairro("");
    setCidade("");
    setEstado("");
    setCodEnd("");
    setComplemento("");
    setReferencia("");
    setCnpj("");
    setRazaoSocial("");
    setNomeFantasia("");
    setInscricaoEstadual("");
    setRamoAtividade("");
  };

  const handleCertificadoChange = (event) => {
    const file = event.target.files[0];

    const acceptedFormats = [".pem", ".pfx", ".cer"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (acceptedFormats.includes(`.${fileExtension}`)) {
      setCertificadoFile(file);
      setCertificadoFormatError(false);
    } else {
      setCertificadoFormatError(true);
      setCertificadoFile(null);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Remove o prefixo da mensagem da imagem em base64
        const base64Prefix = `data:${file.type};base64,`;
        const base64ImageData = reader.result.substring(base64Prefix.length);
        resolve(base64ImageData);
      };
    });
  };

  const resizeAndEncode = async (file, setImageState) => {
    const formatosAceitos = ["image/jpeg", "image/png", "image/jpg"];
if (formatosAceitos.includes(file.type)) {
      const base64Image = await getBase64(file);
      setImageState(base64Image);
    } else {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        file.type.split("/")[1], // Usa o formato original do arquivo
        70,
        0,
        async (uri) => {
          let base64Image = await getBase64(uri);
          setImageState(base64Image);
        },
        "blob",
        200,
        200
      );
    }
  };

  //ler a imagem do usuario adicionada no input
  const handleFileInputChange = (setImageState) => async (event) => {
    let file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagemVisualizar(reader.result);
      };
      reader.readAsDataURL(file);
    }
    await resizeAndEncode(file, setImageState);
  };

  const handleFileInputChangeLogo = (setImageState) => async (event) => {
    let file = event.target.files[0];
    setSelectedImageFile(file);

    setSelectedImageURL(URL.createObjectURL(file));

    await resizeAndEncode(file, setImageState);
  };

  const handleCepChange = (event) => {
    const cep = event.target.value.replace(/\D/g, "");
    setCep(cep);
    console.log("CEP:", cep);
  };

  const handleCancelRequest = () => {
    alert("Solicitação de cancelamento enviada com sucesso!");

    setCancelarDados("");
    handleFecharCancelModal();
  };

  const handleFecharCancelModal = () => {
    setMostrarCancelarDados(false);
  };

  const handleCheckboxChange = (e) => {
    setEstouCiente(e.target.checked);
  };

  const toggleModal = () => {
    setModalAberto(!modalAberto);
  };

  const openModal = () => {
    setModalAberto(true);
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {};

  const handleRemoverCertificado = () => {

    const empresaRemover = {
      CODEMP: Number(codemp)
    }

    api
      .delete(`/empresas/remover/certificado`, { data: empresaRemover })
      .then((response) => {
        console.log("Certificado removido com sucesso:", response.data);
        setCertificado('')
        setModalMessage("Certificado removido com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        console.log(error, "Houve um erro ao remover este certificado.");
      });    
  }

  const renderModal = () => {
    return (
      <Modal id="testando" show={modalAberto} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Exclusão de dados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.ModalContent}>
            <p>
              • Eles serão excluídos após 185 dias a partir da última compra do
              cliente. Caso o cliente não tenha feito nenhuma compra nos últimos
              180 dias, os dados serão deletados dentro de 5 dias.
            </p>
            <p>• Os pedidos deste cliente ficarão anônimos.</p>
            <p>• Depois de excluídos, você não poderá recuperá-los.</p>
            <Form.Group className={styles.estouCienteCheckbox}>
              <Form.Check
                type="checkbox"
                label="Estou ciente e quero continuar"
                checked={estouCiente}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.Footer}>
          <Button
            variant="secondary"
            onClick={() => {
              setModalAberto(false);
            }}
            className={`${styles.BotaoCancelarExcluir} ${styles.button}`}
          >
            Cancelar sem excluir os dados
          </Button>

          <Button
            variant="primary"
            onClick={handleCancelRequest}
            className={`${styles.BotaoExcluir} ${styles.button}`}
            disabled={!estouCiente}
          >
            Excluir dados
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  function formatarData(data) {
    const newDate = new Date(data);

    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formatoData = new Intl.DateTimeFormat("pt-BR", options);
    
    return formatoData.format(newDate);
  }

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          {" "}
          <i
            className="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/dashboard");
            }}
          ></i>
          Editar Perfil
        </h1>
      </div>

      <div className={styles.corpo}>
        <Form className={styles.form}>
          <Tabs defaultActiveKey="dadosEmpresa" className={styles.abas}>
            {/* {userData && userData.PERMISSAO && userData.PERMISSAO.toLowerCase() === "administrador" &&(

            )} */}
            <Tab eventKey="dadosEmpresa" title="Dados Empresa">
              <h1>Campos obrigatórios(*)</h1>
              <div className={styles.corpo}>
                <fieldset className={styles.fieldsetTopo}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="CNPJ" className={styles.label}>
                      CNPJ*:
                    </Form.Label>
                    <Form.Control
                      
                      disabled
                      type="text"
                      pattern="^[0-9]*$"
                      name="cnpj"
                      id="cnpj"
                      as={InputMask}
                      mask="99.999.999/9999-99"
                      value={cnpj}
                      onChange={(e) => setCnpj(e.target.value)}
                      onInput={(e) => {
                        if (cnpj.length === 14) {
                          if (!ValidaCNPJ(cnpj)) {
                            alert("CNPJ inválido");
                          }
                        }
                      }}
                      required
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="razaoSocial" className={styles.label}>
                      Razão social*:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="razaoSocial"
                      id="razaoSocial"
                      value={capitalizeFirstLetter(razaoSocial)}
                      onChange={(e) => setRazaoSocial(e.target.value)}
                      
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="nomeFantasia" className={styles.label}>
                      Nome fantasia*:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="nomeFantasia"
                      id="nomeFantasia"
                      value={capitalizeFirstLetter(nomeFantasia)}
                      onChange={(e) => setNomeFantasia(e.target.value)}
                      
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="atividade" className={styles.label}>
                      Ramo*:
                    </Form.Label>
                    <Form.Control
                      className={styles.Input}
                      as="select"
                      name="ramoAtividade"
                      id="ramoAtividade"
                      value={ramoAtividade}
                      onChange={(e) => setRamoAtividade(e.target.value)}
                      
                      disabled
                    >
                      <option value="" disabled>
                        Selecione
                      </option>
                      <option value="Indústria">Indústria</option>
                      <option value="Comercial">Comercial</option>
                      <option value="Distribuição">Distribuição</option>
                      <option value="Outros">Outros</option>
                    </Form.Control>
                  </Form.Group>
                </fieldset>
                <fieldset className={styles.fieldsetTopo}>
                  <div className={styles.FormImagem}>
                    <Form.Group className={styles.grupo}>
                      <div className="text-center">
                        <Form.Label htmlFor="logo" className={styles.label}>
                          Logo da empresa
                        </Form.Label>
                        <div
                          className={styles.Imagem}
                          onClick={() => fileInput.current.click()}
                        >
                          {selectedImageURL ? (
                            <img
                              src={selectedImageURL}
                              alt="Preview"
                              className="img-thumbnail img-fluid"
                            />
                          ) : (
                            <div>
                              {iconVisible && (
                                <i className="bi bi-cloud-arrow-up"></i>
                              )}
                            </div>
                          )}
                          <input
                            type="file"
                            ref={fileInput}
                            onChange={handleFileInputChangeLogo(setLogoEmpresa)}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                      <span className={styles.legenda}>Formatos suportados: JPG e PNG.</span>
                    </Form.Group>
                  </div>
                </fieldset>
                <div className={styles.rodape2}>
                  <button
                    className={`${styles.submit} ${styles.salvarButton2}`}
                    onClick={handleSubmitEmpresa}
                  >
                    Salvar Empresa
                  </button>
                </div>
              </div>
            </Tab>
            <Tab eventKey="dadosUsuario" title="Dados Usuário">
              <h1>Campos obrigatórios(*)</h1>
              <div className={styles.corpo}>
                <fieldset className={styles.fieldsetTopo}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="nome" className={styles.label}>
                      Nome*:
                    </Form.Label>
                    <Form.Control
                      disabled
                      className={styles.Input}
                      id="nome"
                      value={nome}
                      onChange={(e) => setNome(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="sobrenome" className={styles.label}>
                      Sobrenome*:
                    </Form.Label>
                    <Form.Control
                      disabled
                      className={styles.Input}
                      id="sobrenome"
                      value={sobrenome}
                      onChange={(e) => setSobrenome(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="telefone" className={styles.label}>
                      Telefone*:
                    </Form.Label>
                    <Form.Control
                      disabled
                      className={styles.Input}
                      id="telefone"
                      as={InputMask}
                      mask="(99) 99999-9999"
                      value={telefone}
                      onChange={(e) => setTelefone(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="cpfcnpj" className={styles.label}>
                      CPF*:
                    </Form.Label>
                    <Form.Control
                      disabled
                      
                      className={styles.Input}
                      id="cpfcnpj"
                      as={InputMask}
                      mask="999.999.999-99"
                      value={cpfcnpj}
                      onChange={(e) => setCpfcnpj(e.target.value)}
                    />
                  </Form.Group>
                </fieldset>

                <fieldset className={styles.fieldsetTopo}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="cep" className={styles.label}>
                      CEP*:
                    </Form.Label>
                    <Form.Control
                      disabled
                      id="cep"
                      as={InputMask}
                      mask="99.999-999"
                      value={cep}
                      onChange={handleCepChange}
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="endereco" className={styles.label}>
                      Endereço*:
                    </Form.Label>
                    <Form.Control
                      id="endereco"
                      value={endereco}
                      onChange={(e) => setEndereco(e.target.value)}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="numero" className={styles.label}>
                      Número*:
                    </Form.Label>
                    <Form.Control
                    disabled
                      id="numero"
                      value={numero}
                      onChange={(e) => setNumero(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="bairro" className={styles.label}>
                      Bairro*:
                    </Form.Label>
                    <Form.Control
                      id="bairro"
                      value={bairro}
                      onChange={(e) => setBairro(e.target.value)}
                      disabled
                    />
                  </Form.Group>
                </fieldset>

                <fieldset className={styles.fieldsetTopo}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="cidade" className={styles.label}>
                      Cidade:
                    </Form.Label>
                    <Form.Control
                      id="cidade"
                      value={cidade}
                      onChange={(e) => setCidade(e.target.value)}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="estado" className={styles.label}>
                      Estado:
                    </Form.Label>
                    <Form.Control
                      id="estado"
                      value={estado}
                      onChange={(e) => setEstado(e.target.value)}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label className={styles.label}>
                      Referência:
                    </Form.Label>
                    <Form.Control
                    disabled
                      className={styles.InputRef}
                      type="text"
                      name="referencia"
                      id="referencia"
                      value={referencia}
                      onChange={(e) => setReferencia(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="complemento" className={styles.label}>
                      Complemento:
                    </Form.Label>
                    <Form.Control
                    disabled
                      id="complemento"
                      value={complemento}
                      onChange={(e) => setComplemento(e.target.value)}
                    />
                  </Form.Group>
                </fieldset>

                <fieldset className={styles.fieldsetTopo}>
                  <Form.Group controlId="formFile" className={styles.grupo}>
                    <Form.Label className={styles.label}>
                      Importar foto
                    </Form.Label>
                    <Form.Control
                      className={styles.Input}
                      type="file"
                      onChange={handleFileInputChange(setImagemUsuario)}
                    />
                    <span className={styles.legenda}>Formatos suportados: JPG e PNG.</span>
                  </Form.Group>
                  {imagemVisualizar && <img className={styles.imagemVisualizar} src={imagemVisualizar} alt="" />}
                </fieldset>

                <div className={styles.rodape}>
                  <button
                    className={`${styles.excluirButton} ${styles.submit}`}
                    type="button"
                    onClick={openModal}
                  >
                    Solicitar cancelamento
                  </button>
                  <button
                    className={`${styles.salvarButton} ${styles.submit}`}
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Salvar Usuário
                  </button>
                </div>
              </div>
            </Tab>
            <Tab eventKey="dadosFiscais" title="Dados Fiscais">
              <h1>Campos obrigatórios(*)</h1>
              <div className={styles.corpo}>
                <fieldset className={`${styles.fieldsetTopo} ${styles.fieldsetCertificado}`}>
                  <div className={styles.FormDadosEmpresa}>
                    <Form.Group className={styles.grupo}>
                      <Form.Label
                        htmlFor="certificado"
                        className={styles.label}
                      >
                        Certificado da Empresa:
                      </Form.Label>
                      <Form.Control
                        onClick={(e) => {
                          if(certificado){
                            e.preventDefault()
                            alert("Já existe um certificado cadastrado. Remova o atual para adicionar um novo.")
                          }
                        }}
                        type="file"
                        accept=".pem, .pfx, .cer" // campo para vc colocar qual formato vc deseja colocar
                        onChange={handleCertificadoChange}
                      />
                      {certificadoFormatError && (
                        <div className={styles.error}>
                          Formato de certificado inválido. Use um arquivo: .cer, .pem, .pfx
                        </div>
                      )}
                    </Form.Group>
                    <span className={styles.legenda}>Formatos suportados: .cer, .pem, .pfx</span>
                  </div>
                  {certificado && <div className={styles.infosCertificado}>
                    <i title="Remover certificado" onClick={handleRemoverCertificado} class="bi bi-trash"></i>
                    <fieldset className={styles.txtInfo}>
                      <span>Nome: {certificado.FILENAME ? certificado.FILENAME : certificado.name}</span>
                      <span>Data de inclusão: {formatarData(certificado.DATAINC ? certificado.DATAINC : certificado.lastModifiedDate)}</span>
                    </fieldset>
                  </div>}
                </fieldset>
                
                <div className={styles.rodape2}>
                  <button
                    className={`${styles.submit} ${styles.salvarButton2}`}
                    onClick={(e) => handleSubmitEmpresa(e, 'certificado')}
                  >
                    Salvar Certificado
                  </button>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Form>
      </div>
    </div>
  );

  return (
    <>
      {children}
      {renderModal()}
      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
}
export default EditarPerfil;
