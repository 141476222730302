import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const exportToPdf = (data) => {
  const tableBody = [];
console.log(data);
  // Cria as linhas da tabela
  data.forEach((cliente) => {
    tableBody.push([
      // index + 1,
      {
        text: `${cliente.Nome}`,
        fontSize: 10, 
      },
      {
        text: `${cliente.Telefone}`,
        fontSize: 10, 
      },
      {
        text: `${cliente.Email}`,
        fontSize: 10, 
      },
      {
        text: `${cliente.CpfCnpj}`,
        fontSize: 10, 
      },
    ]);
  });

  // Define a estrutura do documento PDF
  const docDefinition = {
    content: [
      { text: 'Lista de Clientes', style: 'header' },
      {
        table: {
          headerRows: 1,
          widths: ['auto', '*', 'auto', '*'],
          body: [
            [ 'Nome', 'Telefone', 'Email', 'Cpf/Cnpj', ],
            ...tableBody,
          ],
        },
        layout: 'lightHorizontalLines',
      },
    ],
    styles: {
      header: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 10],
      },
    },
  };

  // Gera o PDF e abre-o em uma nova janela do navegador
  pdfMake.createPdf(docDefinition).open();
};

export default exportToPdf;
