import { useState, useEffect, useRef, useCallback } from "react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

import styles from "./Vendedores.module.css";
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";

import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";
import { formatarCpfCnpj } from "../../Utils/formatarCpfCnpj";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import axios from "axios";

const Vendedores = () => {
  const { token, codemp } = useContext(AuthContext);
  const [vendedores, setVendedores] = useState([]);
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [semDados, setSemDados] = useState('')
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    setLoading(true);
    const getVendedores = async () => {
      try {
        const response = await api.get(`/vendedor/listar?CODEMP=${CODEMP}`, {
          cancelToken: source.token
        });
        if(response.data.length === 0 ){
          setVendedores([])
          setSemDados("Sem dados")
        } else {
          setVendedores(response.data);
        }
        setLoading(false);
        setReload(false)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
        setLoading(false);
        setReload(false)
      }
    };
    getVendedores();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp,reload]);
  
  useEffect(() => {
    if (
      vendedores.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
          zeroRecords: "Nenhum registro correspondente encontrado.",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
      });
    }
  }, [vendedores,reload]);

  const editarVendedor = (codvend) => {
    navigate("/editar-vendedor", { state: { codVend: codvend } });
  };

  const showTable = () => {
    return vendedores.map((item, index) => {
      const pessoa = item.Usuario.Pessoa;
      const NOME = pessoa && pessoa.NOME ? pessoa.NOME : "-";
      const SOBRENOME = pessoa && pessoa.SOBRENOME ? pessoa.SOBRENOME : "-";
      const email = pessoa && pessoa.EMAIL ? pessoa.EMAIL : "-";
      const gestor = item.Gestor;
      const Gestor = gestor ? gestor.Usuario.Pessoa.NOME : "";
      const Cpf = pessoa && pessoa.CPFCNPJ ? pessoa.CPFCNPJ : "-";

      return (
        <tr key={index}>
          <td className="text-center">
            <i style={{cursor: 'pointer'}}
              className="bi bi-pen"
              onClick={() => editarVendedor(item.CODVEND)}
            ></i>
          </td>
          <td className="text-center">{item.CODVEND}</td>
          <td>{`${capitalizeFirstLetter(NOME)} ${capitalizeFirstLetter(
            SOBRENOME
          )}`}</td>
          <td style={{whiteSpace: 'nowrap'}}>{`${formatarCpfCnpj(Cpf)}`}</td>
          <td>{email}</td>
          <td className="text-center">{`${Gestor}`}</td>
          
        </tr>
      );
    });
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
          <div className={styles.topoButtons}>
            <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} onClick={() => setReload(true)} title="Atualizar" ></i>
          </div>
          </div>
          <div className={styles.corpo}>
            <div className={`container-fluid py-4 ${styles.boxTable}`}>
              <div className="table-responsive p-0 pb-2">
                <h1 className={styles.titleTable}>Vendedores</h1>
                <table
                  ref={tableRef}
                  className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                >
                  <thead>
                    <tr>
                      <th className="text-center">
                        Ação
                      </th>
                      <th>Código</th>
                      <th>Nome</th>
                      <th>CPF/CNPJ</th>
                      <th>E-mail</th>
                      <th>Gestor</th>
                    </tr>
                  </thead>
                  <tbody>{loading ? <tr>
                    <td colSpan="8">
                      <LoadingCentroTelas />
                    </td>
                  </tr> : semDados === "Sem dados" ? <tr>
                    <td style={{ textAlign: "center" }} colSpan="8">
                    A tabela está vazia no momento.
                    </td>
                  </tr> : showTable()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </CaixaConteudo>
    </>
  );
};

export default Vendedores;
