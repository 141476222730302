import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import styles from "./Clientes.module.css";

import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";

import { CSVLink } from "react-csv";
import exportToPdf from "./ExportPDF";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import { formatarCpfCnpj } from "../../Utils/formatarCpfCnpj";
import { formatarTelefone } from "../../Utils/formatarTelefone";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import axios from "axios";

const Clientes = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(false)
  const tableRef = useRef(null);
  const [semDados, setSemDados] = useState('')
  const [reload, setReload] = useState(false)

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  // useEffect(() => {
  //   setLoading(true)
  //   const fetchClientes = async () => {
  //     try {
  //       if (codemp && token) {
  //         setAuthToken(token);
  //         const response = await api.get(`/clientes/listar?CODEMP=${codemp}`);
  //         setClientes(response.data);
  //         setLoading(false)
  //       }
  //     } catch (error) {
  //       console.error(`Houve um erro ao buscar os clientes: ${error.message}`);
  //       console.error("Mensagem de erro da API:", error.response?.data);
  //       setLoading(false)
  //     }
  //   };
  
  //   fetchClientes();
  // }, [codemp,token]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    const getClientes = async () => {
      setLoading(true);
      setSemDados("")
      setClientes([])
      try {
        const response = await api.get(`/clientes/listar?CODEMP=${CODEMP}`, {
          cancelToken: source.token
        });
        if(response.data.length === 0 ){
          setSemDados("Sem dados")
        } else {
          setClientes(response.data);
        }
        setLoading(false);
        console.log(response.data);
        setReload(false)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
        setLoading(false);
        setReload(false)
      }
    };
    getClientes();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp,reload]);
  
  const editarCliente = (codpes, codCli) => {
    console.log("codpes, codCli", codpes, codCli);
    navigate(`/editar-cliente`, { state: { codpes, codCli } });
  };

  const csvData = clientes.map((cliente) => ({
    Nome: `${cliente.Pessoa.NOME} ${cliente.Pessoa.SOBRENOME}`,
    Telefone: cliente.Pessoa.TELEFONE,
    Email: cliente.Pessoa.EMAIL,
    CpfCnpj: cliente.Pessoa.CPFCNPJ,
  }));

  const pdfData = clientes.map((cliente) => ({
    Nome: `${cliente.Pessoa.NOME} ${cliente.Pessoa.SOBRENOME}`,
    Telefone: cliente.Pessoa.TELEFONE,
    Email: cliente.Pessoa.EMAIL,
    CpfCnpj: cliente.Pessoa.CPFCNPJ,
  }));

  const exportButtons = (
    <div className={styles.exportButtonsContainer}>
      <CSVLink
        data={csvData}
        filename="clientes.csv"
        className={`${styles.exportButton} btn btn-sm btn-primary`}
      >
        Exportar CSV
      </CSVLink>
      <button
        className={`${styles.exportButton} btn btn-sm btn-primary`}
        onClick={() => exportToPdf(pdfData)}
      >
        Exportar PDF
      </button>
    </div>
  );

  useEffect(() => {
    if (
      clientes.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        //destroy: true,
        ordering: true,
        order: [[6, 'asc'],[1,'desc']]
      });
    }
  }, [clientes]);  

  const showTable = () => {
    return clientes.map((cliente, index) => {
      return (
        <tr key={index}>
          <td className="text-center">
            <button
              className={styles.icone}
              onClick={() =>
                editarCliente(cliente.CODPES, cliente.CODCLI)
              }
            >
              <i className="bi bi-pen"></i>
            </button>
          </td>
          <td className="text-center">{cliente.CODCLI}</td>
          <td>
            {`${capitalizeFirstLetter(cliente.Pessoa.NOME)}
              ${cliente.Pessoa.TIPO === "F" || cliente.Pessoa.CPFCNPJ.replace(/\D/g, '').length === 11
                ? capitalizeFirstLetter(cliente.Pessoa.SOBRENOME)
                : ''}`}
          </td>
          <td className={styles.colunaSemQuebra}>{cliente.Pessoa.TELEFONE ? formatarTelefone(cliente.Pessoa.TELEFONE) : '-'}</td>
          <td className={styles.colunaSemQuebra}>{formatarCpfCnpj(cliente.Pessoa.CPFCNPJ)}</td>
          <td>
            {
              cliente.Pessoa.TIPO === "F" || cliente.Pessoa.TIPO === "PF"
                ?  "Pessoa Física"
                : cliente.Pessoa.TIPO === "J" || cliente.Pessoa.TIPO === "PJ" 
                ? "Pessoa Jurídica"
                : cliente.Pessoa.CPFCNPJ.replace(/\D/g, '').length === 11
                  ? "Pessoa Física"
                  : "Pessoa Jurídica"
            }
          </td>
          <td>{cliente.STATUS ? 'ATIVO' : 'INATIVO'}</td>
        </tr>
      );
    });
  };  
  
  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
          <div className={styles.exportButtonsContainer}>
                {exportButtons}
              </div>
            <div className={styles.topoButtons}>
            
              <button onClick={() => navigate("/novo-cliente")}>
                Adicionar Cliente
              </button>
              <button onClick={() => navigate("/importar-clientes")}>
                <i className="bi bi-box-arrow-up" /> Importar Clientes
              </button>
              <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} title="Atualizar" onClick={() => window.location.reload()}></i>
            </div>
          </div>
          <div className={styles.corpo}>
            <div className={styles.container}>
              
              <div className={styles.corpo}>
                <div className={`container-fluid py-4 ${styles.boxTable}`}>
                  <div className="table-responsive p-0 pb-2">
                    <h1 className={styles.titleTable}>Clientes</h1>
                    <table
                      id="clientesTable"
                      ref={tableRef}
                      className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">
                            Ação
                          </th>
                          <th>Código</th>
                          <th>Nome</th>
                          <th>Telefone</th>
                          <th>CPF/CNPJ</th>
                          <th>Tipo</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>{clientes.length === 0 && semDados === '' ? <tr>
                        <td colSpan="8">
                          <LoadingCentroTelas />
                        </td>
                      </tr> : semDados === 'Sem dados' ? <tr>
                        <td style={{ textAlign: "center" }} colSpan="8">
                        A tabela está vazia no momento.
                        </td>
                      </tr> : showTable()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CaixaConteudo>
    </>
  );
};

export default Clientes;
