import styles from "./EditarTransportadora.module.css";
import Form from "react-bootstrap/Form";
import InputMask from "react-input-mask";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { Modal, Button } from "react-bootstrap";
import MessageModal from "../../Components/MessageModal/MessageModal";

import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import { validarCamposVazios } from "../../Utils/validarCamposVazios";
import { validarCPFouCNPJ } from "../../Utils/validarCPFouCNPJ";

const EditarTransportadora = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const location = useLocation();
  const { codtrans } = location.state;
  console.log("codtrans:", codtrans);
  console.log("codemp:", codemp);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [codigo, setCodigo] = useState("");
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [cpf, setCpf] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cpfOuCnpj, setcpfOuCnpj] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState(null);

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [botaoSalvar, setBotaoSalvar] = useState("Salvar")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
    setLoading(true)
    api
      .get(
        `/transportadora/buscar/?CODEMP=${Number(CODEMP)}&CODTRANS=${Number(
          codtrans
        )}`
      )
      .then((response) => {
        setCodigo(response.data.CODTRANS);
        setNome(response.data.Pessoa.NOME);
        setSobrenome(response.data.Pessoa.SOBRENOME);

        setTelefone(response.data.Pessoa.TELEFONE);
        setEmail(response.data.Pessoa.EMAIL);
        const userCpfCnpj = response.data.Pessoa.CPFCNPJ.replace(/[^\d]/g, '');
        setCpf(response.data.Pessoa.CPFCNPJ);
        setCnpj(response.data.Pessoa.CPFCNPJ);
        if (response.data.Pessoa.TIPO === 'F' || userCpfCnpj.length === 11) {
          setcpfOuCnpj("cpf");
        } else if (response.data.Pessoa.TIPO === 'J' || userCpfCnpj.length === 14) {
          setcpfOuCnpj("cnpj");
        }
      })
      .catch((error) => {
        console.error("Erro ao obter transportadora:", error);
      })
      .finally(()=>{
        setLoading(false)
      })
  }, [codemp, codtrans]);



  const handleSubmit = (e) => {
    e.preventDefault();

    const dadosObrigatorios = {
      ...(cpfOuCnpj === "cnpj" ? {
        'Razão Social': nome,
        'Nome Fantasia': sobrenome,
        'CNPJ': cnpj,
      } : {
        'Nome': nome,
        'Sobrenome': sobrenome,
        'CPF': cpf,
      }),
      'Telefone': telefone,
      'E-mail': email
    };

    if(validarCamposVazios(dadosObrigatorios).length > 0) {
      setModalMessage(`Preencha os campos obrigatorios: ${validarCamposVazios(dadosObrigatorios)}!`);
      setShowMessageModal(true);
      return;
    } 

    if(validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj) === 'CPF' || validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj) === 'CNPJ') {
      setModalMessage(`${validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj)} inválido!`);
      setShowMessageModal(true);
      return;
    } 

    const transportadoraAtualizado = {
      CODTRANS: codtrans,
      CODEMP: codemp,
      NOME: nome,
      SOBRENOME: sobrenome,
      TELEFONE: telefone,
      EMAIL: email,
      CPFCNPJ: cpfOuCnpj === "cnpj" ? cnpj : cpf,
      TIPO: cpfOuCnpj === "cnpj" ? "J" : "F",
    };

    setBotaoSalvar("Carregando...")

    api
      .patch(`/transportadora/atualizar`, transportadoraAtualizado)
      .then((response) => {
        console.log("Transportadora atualizada com sucesso:", response.data);
        setModalMessage("Transportadora atualizada com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao atualizar transportadora.Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
      })
      .finally(()=>{
        setBotaoSalvar("Salvar")
      })
  };

  const handleDeleteTransportadora = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(false);

    const requestData = {
      CODEMP: Number(codemp),
      CODTRANS: Number(codtrans),
    };
    console.log("requestData:", requestData);

    api
      .delete(`/transportadora/remover`, { data: requestData })
      .then((response) => {
        console.log("Transportadora excluído com sucesso:", response.data);
        setMessage("Transportadora excluída com sucesso:");
        setErro("");
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
          navigate("/transportadora");
        }, 3000);
      })
      .catch((error) => {
        console.error("Erro ao excluir local:", error);
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao deletar transportadora.Tente novamente!");
        }
        setMessage("");
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
        console.log("codtrans:", codtrans, "codemp:", codemp);
      });
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // Função para alternar entre Pessoa Física e Pessoa Jurídica
  const handleTipoPessoaChange = (event) => {
    const novoTipo = event.target.value;
    setcpfOuCnpj(novoTipo);

    if (novoTipo === "cnpj") {
      setCpf("");
    } else {
      setCnpj("");
    }
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/transportadora");
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <h1>
              <i
                className="bi bi-arrow-left-circle-fill"
                onClick={() => {
                  navigate("/transportadora");
                }}
              ></i>
              Editar Transportadora
            </h1>
          </div>

          <div className={styles.corpo}>
            <Form className={styles.form} onSubmit={handleSubmit}>
              {showMessage && (
                <div
                  className={`${styles.message} ${
                    message ? styles.successMessage : styles.errorMessage
                  }`}
                >
                  <p>{message || erro}</p>
                </div>
              )}
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <p style={{fontSize:'.7rem'}}>Campos obrigatórios(*)</p>
                  {loading && <p style={{color: 'red'}}>Carregando...</p>}
                </div>
              <fieldset className={styles.fieldsetTopo}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="codigo" className={styles.label}>
                    Código:
                  </Form.Label>
                  <Form.Control
                    id="codigo"
                    value={codigo}
                    onChange={(e) => setCodigo(e.target.value)}
                    disabled
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="nome" className={styles.label}>
                    {cpfOuCnpj === "cnpj" ? "Razão Social*" : "Nome*:"}
                  </Form.Label>
                  <Form.Control
                    id="nome"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="sobrenome" className={styles.label}>
                    {cpfOuCnpj === "cnpj" ? "Nome Fantasia*" : "Sobrenome*:"}
                  </Form.Label>
                  <Form.Control
                    id="sobrenome"
                    value={sobrenome}
                    onChange={(e) => setSobrenome(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="telefone" className={styles.label}>
                    Telefone*:
                  </Form.Label>
                  <Form.Control
                    id="telefone"
                    as={InputMask}
                    mask="(99) 99999-9999"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  />
                </Form.Group>
              </fieldset>

              <fieldset className={styles.fieldsetTopo}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="email" className={styles.label}>
                    E-mail*:
                  </Form.Label>
                  <Form.Control
                    id="email"
                    mask=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="tipoPessoa" className={styles.label}>
                    Tipo de Pessoa*:
                  </Form.Label>
                  <Form.Select
                    id="tipoPessoa"
                    className="form-control"
                    value={cpfOuCnpj}
                    onChange={handleTipoPessoaChange}
                  >
                    <option value="cpf">Pessoa Física</option>
                    <option value="cnpj">Pessoa Jurídica</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="cpfCnpj" className={styles.label}>
                    {cpfOuCnpj === "cnpj" ? "CNPJ*" : "CPF*"}:
                  </Form.Label>
                  <Form.Control
                    id="cpfCnpj"
                    as={InputMask}
                    mask={
                      cpfOuCnpj === "cnpj"
                        ? "99.999.999/9999-99"
                        : "999.999.999-99"
                    }
                    value={cpfOuCnpj === "cnpj" ? cnpj : cpf}
                    onChange={(e) => {
                      if (cpfOuCnpj === "cnpj") {
                        setCnpj(e.target.value);
                      } else {
                        setCpf(e.target.value);
                      }
                    }}
                  />
                </Form.Group>
              </fieldset>

              <fieldset className={styles.fieldsetButton}>
                <div className={styles.rodape}>
                  <button
                    className={`${styles.submit} ${styles.excluirButton}`}
                    type="button"
                    onClick={handleDeleteTransportadora}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-trash-fill"></i>
                    Excluir
                  </button>
                  <button
                    className={`${styles.submit} ${styles.salvarButton}`}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    <i className="bi bi-play-fill"></i>
                    {botaoSalvar}
                  </button>
                  {erro && <p className={styles.erro}>{erro}</p>}
                </div>
              </fieldset>
            </Form>
          </div>
        </div>
      </CaixaConteudo>
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja excluir esta transportadora?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancelar
          </Button>
          <Button
            className={`${styles.submit} ${styles.excluirButton}`}
            onClick={handleConfirmDelete}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default EditarTransportadora;
