import styles from "./DispositivosPendentes.module.css";

import $ from "jquery";

import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import React, { useContext } from "react";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

// COMPONENTES
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import Menu from "../../Components/Menu/Menu";
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import axios from "axios";

const handleApiError = (error, customMessage) => {
    console.error(error);
    console.log(`${customMessage}: ${error.message}`);
    console.log("Mensagem de erro da API:", error.response.data);
  };
const DispositivosPendentes = () => {
    const { token, codemp } = useContext(AuthContext);
    const tableRef = useRef(null);
    const [carregando, setCarregando] = useState(true);
    const [dispositivos, setDispositivos] = useState([]);

    const [showMessageModal, setShowMessageModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [semDados, setSemDados] = useState('');

    useEffect(() => {
        setAuthToken(token);
      }, [token]);
    
      // useEffect(() => {
      //   const fetchData = async () => {
      //     if (codemp) {
      //       try {
      //         const response = await api.get(`/sessao/listar/perm?CODEMP=${codemp}`);
      //         const dispositivosPendentes = response.data.filter(dispositivo => dispositivo.PERMISSAO === false)
      //         setDispositivos(dispositivosPendentes);
      //       } catch (error) {
      //         handleApiError(error, "Houve um erro ao buscar os acessos");
      //       } finally {
      //         setCarregando(false);
      //       }
      //     }
      //   };
      
      //   fetchData();
      // }, [codemp]); 

      useEffect(() => {
        const source = axios.CancelToken.source();
        const CODEMP = codemp || sessionStorage.getItem("CODEMP");
        setLoading(true);
        setDispositivos([])
        setSemDados('')
        const getDispositivos = async () => {
          try {
            const response = await api.get(`/sessao/listar/perm?CODEMP=${CODEMP}`, {
              cancelToken: source.token
            });
            if(response.data.filter(dispositivo => dispositivo.PERMISSAO === false).length === 0 ){
              setSemDados("Sem dados")
            } else {
              setDispositivos(response.data.filter(dispositivo => dispositivo.PERMISSAO === false));
            }
          } catch (error) {
            if (axios.isCancel(error)) {
              console.log('Request canceled', error.message);
            } else {
              console.error(error);
            }
          } finally {
            setLoading(false);
            setReload(false)
          }
        };
        getDispositivos();
        return () => {
          source.cancel('Component unmounted');
        };
      }, [codemp,reload]);  

    const aprovarDispositivo = (codigo) => {

      const targetIndex = dispositivos.findIndex(dispositivo => dispositivo.CODACEPER === codigo);
    
      if (targetIndex !== -1) {
        const updatedDispositivos = [...dispositivos];  // Cria uma cópia do array para não modificar o estado diretamente
        updatedDispositivos[targetIndex].PERMISSAO = true;
      
        setDispositivos(updatedDispositivos);
      }
      
      try {
        const objectPermissao = {
          CODEMP: codemp,
          CODACEPER: codigo,
          PERMISSAO: true
      }
    
        const response = api.patch(`/sessao/permissao?CODEMP=${codemp}`, objectPermissao);
        console.log(response.data);
        setModalMessage(`Dispositivo aprovado com sucesso!`)
        setShowMessageModal(true)
        setIsSuccess(true)
      } catch (error) {
        handleApiError(error, `Houve um erro ao aprovar dispositivo.`);
        setModalMessage(`Houve um erro ao aprovar dispositivo.`)
        setShowMessageModal(true)
        setIsSuccess(false)
      } finally {
        setCarregando(false);
      }
    }

    useEffect(() => {
      if (
        dispositivos.length > 0 &&
        tableRef.current &&
        !$.fn.DataTable.isDataTable(tableRef.current)
      ) {
        $(tableRef.current).DataTable({
          language: {
            search: "Pesquisar",
            info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
            zeroRecords: "Não há dispositivos pendentes de aprovação."
          },
          lengthChange: false,
          //destroy: true,
          ordering: true,
          order: [1, 'desc']
        });
      }      
    }, [dispositivos]);

    function formatarData(dataString) {
      const data = new Date(dataString);
      
      const hh = String(data.getHours()).padStart(2, '0');
      const mm = String(data.getMinutes()).padStart(2, '0');
      const ss = String(data.getSeconds()).padStart(2, '0');
      const dd = String(data.getDate()).padStart(2, '0');
      const mmFormatado = String(data.getMonth() + 1).padStart(2, '0'); // Os meses em JavaScript são baseados em zero
      const aaaa = data.getFullYear();
    
      return `${dd}/${mmFormatado}/${aaaa} ${hh}:${mm}:${ss}`;
    }
    
return (
  <React.Fragment>
    <Menu />
    <Cabecalho />
    <CaixaConteudo>
      <div className={styles.grid}>
      <div className={styles.topo}>
          <div className={styles.topoButtons}>
            <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} onClick={() => setReload(true)} title="Atualizar" ></i>
          </div>
          </div>
        <div className={styles.corpo}>
          <div className={`${styles.boxTable} container-fluid py-4`}>
            <div className="table-responsive p-0 pb-2">
              <h1 className={styles.titleTable}>Dispositivos Pendentes</h1>

                  <table
                    ref={tableRef}
                    id="tableLogs"
                    className={`${styles.table} table align-items-center justify-content-center mb-0`}
                  >
                    <thead>
                      <tr>
                        <th>Ação</th>
                        <th>Cód.Acesso</th>
                        <th>ID</th>
                        <th>Data</th>
                        <th>Usuário</th>
                        <th>Dispositivo</th>
                        <th>Permissão</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dispositivos.length === 0 && semDados === '' ? <tr>
                      <td colSpan="8">
                        <LoadingCentroTelas />
                      </td>
                    </tr> : semDados === 'Sem dados' ? <tr>
                      <td style={{ textAlign: "center" }} colSpan="8">
                      A tabela está vazia no momento.
                      </td>
                    </tr> : (
                        dispositivos.map((dispositivo) => {
                          if (!dispositivo.Usuario) {
                            return null;
                          }
                          return (
                            <React.Fragment key={dispositivo.CODACEPER}>
                                <tr key={dispositivo.CODACEPER}>
                                  <td>
                                      <i
                                        title="Aprovar"
                                        style={{cursor: "pointer"}}
                                        className={dispositivo.PERMISSAO ? "bi bi-toggle-on" : "bi bi-toggle-off"}
                                        onClick={() => aprovarDispositivo(dispositivo.CODACEPER)}
                                      ></i>
                                  </td>
                                  <td>{dispositivo.CODACEPER}</td>
                                  <td title={dispositivo.CODIGO}>{dispositivo.CODIGO.length > 10 ? `${dispositivo.CODIGO.slice(0, 10)}...` : dispositivo.CODIGO}</td>

                                  <td>{dispositivo.DATAINC ? formatarData(dispositivo.DATAINC) : ""}</td>

                                  <td>
                                    {dispositivo.EMAIL
                                      ? dispositivo.EMAIL.toLowerCase()
                                      : ""}
                                  </td>
                                  <td title={dispositivo.DISPOSITIVO}>{dispositivo.DISPOSITIVO.length > 10 ? `${dispositivo.DISPOSITIVO.slice(0, 30).toUpperCase()}...` : dispositivo.DISPOSITIVO.toUpperCase()}</td>

                                  <td>{dispositivo.PERMISSAO ? "ATIVO" : "INATIVO"}</td>
                                </tr>
                            
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </table>
            </div>
          </div>
        </div>
      </div>
    </CaixaConteudo>
  </React.Fragment>
)
}

export default DispositivosPendentes