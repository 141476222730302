//HOOKS
import React from "react";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//COMPONENTS
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import styles from "./ImportarProduto.module.css";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import MessageModal from "../../Components/MessageModal/MessageModal";

import CSV from "../../Assets/produtoFormatado.xltx";
import MANUAL from './manualimportproduto.pdf';

//LIBS
import Papa from "papaparse";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import * as XLSX from 'xlsx';
import ModalCamposVaziosImport from "../../Components/ModalCamposVaziosImport/ModalCamposVaziosImport";
import ModalLocalInexistente from "../../Components/ModalLocalInexistente/ModalLocalInexistente";
import ModalUnidadeErrada from "../../Components/ModalUnidadeErrada/ModalUnidadeErrada";

function ImportarProduto() {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [erroImportar, setErroImportar] = useState(false);
  const [locais,setLocais]  = useState([])

  const [linhasComCamposVazios, setLinhasComCamposVazios] = useState([]);
  const [modalCamposVaziosImport, setModalCamposVaziosImport] = useState(false);

  const [linhasUnidadeErrada, setLinhasUnidadeErrada] = useState([]);
  const [modalUnidadeErradaImport, setModalUnidadeErradaImport] = useState(false);

  const [linhasLocalInexistente, setLinhasLocalInexistente] = useState([]);
  const [modalLocalInexistenteImport, setModalLocalInexistenteImport] = useState(false);

  const CODEMP = codemp || sessionStorage.getItem("CODEMP");

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    const getLocais = async () => {
      try {
        const response = await api.get(`/locais/listar?CODEMP=${CODEMP}`);
        response.data.map((item)=>{
          setLocais((oldArray) => [...oldArray, item.CODLOC]);
        })
      } catch (error) {
        console.error(error);
      }
    };
    getLocais();
  }, [codemp]);

  const handleDownloadClick = (arquivo) => {
    // const jsonArray = [
    //   {
    //     PRODUTO: "",
    //     DESCRICAO: "",
    //     SKU: "",
    //     NCM:'',

    //     'LOCALESTOQUE': '',
    //     'QUANTIDADEESTOQUE': '',
    //     'LOTEESTOQUE': '',
    //     'DATAVALIDADEESTOQUE': '',
    //     'UNIDADEMEDIDA': '',
    //     'CODIGOLOCAL':'',

    //     PESOBRUTO: '',
    //     PESOLIQUIDO: '',
    //     ALTURA: '',
    //     LARGURA: '',
    //     COMPRIMENTO: '',
    //     PROFUNDIDADE: '',
    //     VOLUME: ''
    //   },
    // ];

    const urlDoArquivoCSV = arquivo === 'manual' ? MANUAL : CSV; // Substitua pelo caminho real do seu arquivo CSV

    const link = document.createElement("a");
    link.setAttribute("href", urlDoArquivoCSV);
    link.setAttribute("download", arquivo === 'manual' ? 'manualimportproduto.pdf' : 'produtos.xltx');
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setMessage(arquivo === 'manual' ? 'Manual baixado com sucesso!' : 'Modelo baixado com sucesso!');
    setErro("");
    setShowMessage(true);

    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleFileUpload = async (event) => {

    let [selectedFile] = [];
    if(event.target.files){
      [selectedFile] = event.target.files;
    } else {
      [selectedFile] = [file];
    }
    //const fileType = selectedFile.type;
    setIsLoading(true)

    const arrayBuffer = await selectedFile.arrayBuffer();
    const workbook = XLSX.read(arrayBuffer, { type: 'arraybuffer' });

    // Assume que estamos interessados apenas na primeira planilha
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];

    // Obtém os dados da planilha como um array de objetos JSON
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "" });
    // Converte o array para o formato desejado
    const parsedData = jsonData.slice(1).map(row => {
      const obj = {};
      jsonData[0].forEach((header, index) => {
        if(header.trim() !== ''){
          obj[header] = row[index];
        }
      });
      return obj;
    });
    setLinhasComCamposVazios([])   
    setLinhasUnidadeErrada([]) 
    setLinhasLocalInexistente([])
    let isCamposVazios = false;
    let isUnidadeErrada = false;
    let isLocalInexistente = false

    parsedData.forEach((item, index) => {
      const camposVazios = [];
      if (item['PRODUTO'] === '') camposVazios.push('PRODUTO');
      if (item['DESCRICAO'] === '') camposVazios.push('DESCRICAO');
      if (item['SKU'] === '') camposVazios.push('SKU');
      if (item['NCM'] === '') camposVazios.push('NCM');
      if (item['UNIDADEMEDIDA'] === '') camposVazios.push('UNIDADEMEDIDA');
      if (item['CODIGOLOCAL'] === '') camposVazios.push('CODIGOLOCAL');

      //formatando data de validade para isostring
      if(item['DATAVALIDADEESTOQUE'] === '') {
        item['DATAVALIDADEESTOQUE'] = undefined
      } else {
        const dataExcel = item['DATAVALIDADEESTOQUE'];
        const dataBase = new Date('1899-12-30');
        const dataConvertida = new Date(dataBase.getTime() + (dataExcel * 24 * 60 * 60 * 1000));
        item['DATAVALIDADEESTOQUE'] = dataConvertida.toISOString()
      }

      if (camposVazios.length > 0) {
        setLinhasComCamposVazios((prevLinhas) => [...prevLinhas, { linha: index + 1, campos: camposVazios }]);
        isCamposVazios = true
      }
      if(item['UNIDADEMEDIDA'].length > 2){
        setLinhasUnidadeErrada((prevLinhas) => [...prevLinhas,  index + 2]);
        isUnidadeErrada = true
      }
      if(!locais.includes(item['CODIGOLOCAL'])){
        setLinhasLocalInexistente((prevLinhas) => [...prevLinhas,  index + 2]);
        isLocalInexistente = true
      }

    const keysToConvert = ['SKU', 'NCM', 'LOTEESTOQUE', 'PESOBRUTO', 'PESOLIQUIDO', 'ALTURA', 'LARGURA', 'PROFUNDIDADE', 'COMPRIMENTO', 'VOLUME'];

    for (const key of keysToConvert) {
      if (item[key]) {
        item[key] = String(item[key]);
      }
    }

    });

    if(isCamposVazios){
      isUnidadeErrada = false
      isLocalInexistente = false
      setLinhasUnidadeErrada([])
      setLinhasLocalInexistente([])
      setModalCamposVaziosImport(true);
    }
    if(isUnidadeErrada){
      isLocalInexistente = false
      setLinhasLocalInexistente([])
      setModalUnidadeErradaImport(true);
    }
    if(isLocalInexistente){
      setModalLocalInexistenteImport(true);
    }
    if(isCamposVazios || isUnidadeErrada || isLocalInexistente){
      setIsLoading(false)
      return
    }

    // if (true) {
      setErro("");
      //setErroImportar(false)

      try {
        // const csvText = await selectedFile.text();
        // const jsonArray = Papa.parse(csvText, { header: true }).data.filter(
        //   (row) => {
        //     return Object.values(row).some((val) => val.trim() !== "");
        //   }
        // );
        // .map((row) => {
        //   return {
        //     ...row,
        //     TIPO: "",
        //   };
        // });

        let CODLOGCAB;
        try {
          const response = await api.post("/produtos/importar/cabecalho");
          console.log(response);
          CODLOGCAB = response.data.CODLOGCAB;
        } catch (error) {
          console.error("Ocorreu um erro na requisição:", error);
          return
        }

        let newArray;
        if (parsedData.length > 1) {
          newArray = parsedData?.map((item) => {  
              const newItem = {
                ...item,
                CODLOC: item['CODIGOLOCAL'],
                UNIDADE: item['UNIDADEMEDIDA'],
                CODLOCESTOQUE: item['CODLOCALESTOQUE'],
                QTDESTOQUE: item['QUANTIDADEESTOQUE'],
                LOTE: item['LOTEESTOQUE'],
                DATAVENC: item['DATAVALIDADEESTOQUE'],
                CODLOGCAB: CODLOGCAB,
                CODEMP: CODEMP
              };
              delete newItem['CODIGOLOCAL'];
              delete newItem['UNIDADEMEDIDA'];
              delete newItem['CODLOCALESTOQUE'];
              delete newItem['QUANTIDADEESTOQUE'];
              delete newItem['LOTEESTOQUE'];
              delete newItem['DATAVALIDADEESTOQUE'];
              return newItem
            }
          );
        } else {
          const newObj = {
            ...parsedData[0],
            CODLOC: parsedData[0]['CODIGOLOCAL'],
            UNIDADE: parsedData[0]['UNIDADEMEDIDA'],
            CODLOCESTOQUE: parsedData[0]['CODLOCALESTOQUE'],
            QTDESTOQUE: parsedData[0]['QUANTIDADEESTOQUE'],
            LOTE: parsedData[0]['LOTEESTOQUE'],
            DATAVENC: parsedData[0]['DATAVALIDADEESTOQUE'],
            CODLOGCAB: CODLOGCAB,
            CODEMP: CODEMP
          };
          delete newObj['CODIGOLOCAL'];
          delete newObj['UNIDADEMEDIDA'];
          delete newObj['CODLOCALESTOQUE'];
          delete newObj['QUANTIDADEESTOQUE'];
          delete newObj['LOTEESTOQUE'];
          delete newObj['DATAVALIDADEESTOQUE'];
          newArray = [newObj];
        }

        console.log(newArray);
          
        const columns = ["PRODUTO", "DESCRICAO", "SKU", 'NCM', 'UNIDADE', 'CODLOC'];
        const compareNames = Object.keys(newArray[0]);
        const missingColumns = columns.filter(
          (column) => !compareNames.includes(column)
        );

        // const columns = ["PRODUTO", "DESCRICAO", "FABRICANTE", "SKU"];
        // const compareNames = Object.keys(jsonArray[0]);
        // const missingColumns = columns.filter(
        //   (column) => !compareNames.includes(column)
        // );

        let errorAlert = false;
        console.log(newArray);
        if (missingColumns.length === 0) {
          // Divide os clientes em lotes de 100 ou menos
          const fileSize = 100;
          for (let i = 0; i < newArray.length; i += fileSize) {
            const filesImport = newArray.slice(i, i + fileSize);
            try {
              const response = await api.post("/produtos/importar", filesImport, {
                params: {
                  CODEMP: codemp,
                },
                headers: {

                }
              });

                if (response.status === 201) {
                  // Status 200 - Sucesso
                  // setModalMessage(
                  //   "Produtos importados com sucesso, você será redirecionado para tela de produtos!"
                  // );
                  // setIsSuccess(true);
                  // setShowMessageModal(true);
                  console.log(response.data);
                }

              } catch (error) {
                console.log(error);
                let errorMessage = '';
                if (error.response.status === 409) {
                  // Status 409 - Conflito
                  errorMessage = "Conflito: produtos já cadastrados.";
                } else {
                  errorMessage = error.response.data.message;
                  console.log(error);
                  errorAlert = true;
                }
                setErroImportar(true)
                setModalMessage(errorMessage);
                setShowMessageModal(true);
                break;
              };
          }

          if(errorAlert === false){
          // Mostra o alerta no final do processo
          setIsSuccess(true);
          setModalMessage(
            "Produtos importados com sucesso, você será redirecionado para a tela de produtos."
          );
          setShowMessageModal(true)
          }


        } else {
          let errorMessage = `O arquivo não contém as seguintes colunas necessárias: ${missingColumns.join(
            ", "
          )}. Verifique o modelo de CSV.`;
          setModalMessage(errorMessage);
          setShowMessageModal(true);
        }
      } catch (error) {
        let errorMessage = "Erro ao processar o arquivo CSV.";
        setModalMessage(errorMessage);
        setShowMessageModal(true);
      } finally {
        setIsLoading(false)
      }
    // } else {
    //   let errorMessage =
    //     "Tipo de arquivo não suportado. Por favor, selecione um arquivo CSV.";
    //   setModalMessage(errorMessage);
    //   setShowMessageModal(true);
    //   setIsLoading(false)
    // }
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/produtos");
  };

  const handleClose = () => {
    setModalCamposVaziosImport(false);
    setModalLocalInexistenteImport(false);
    setModalUnidadeErradaImport(false)
  }

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          {" "}
          <i
            className="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/produtos");
            }}
          ></i>
          Importar Produtos
        </h1>
        <div className={styles.Buttons}>
        <button className={styles.btnLog} onClick={() => navigate('/log-imports', {state: {tipo: 'produtos'}})}>
            <i class="bi bi-file-earmark-text mr-2"></i>
            Log de importação
          </button>
          <button className={styles.btnManual} onClick={() => handleDownloadClick('manual')}>
            <i className="bi bi-download mr-2"></i>
            Manual de importação
          </button>
          <button className={styles.btnModelo} onClick={() => handleDownloadClick('csv')}>
            <i className="bi bi-download mr-2"></i>
            Baixar modelo
          </button>
        </div>
        {showMessage && (
          <div
            className={`${styles.message} ${
              message ? styles.successMessage : styles.errorMessage
            }`}
          >
            <p>{message || erro}</p>
          </div>
        )}
      </div>

      <div
        className={styles.container}
        onDrop={handleFileDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <div className={styles.Selecao}>
          <div className={styles.upload}>
          {!isLoading ?  <label htmlFor="formFile" className={styles.icone}>
              <i
                className="bi bi-cloud-upload h1"
                style={{ fontSize: "4rem" }}
              ></i>
              <p>Arraste e solte o arquivo aqui ou clique para selecionar</p>
              <span className={styles.legenda}>Formato suportado: XLSX</span>
            </label> : <label className={styles.icone}>
            <LoadingCentroTelas/>
            <p className={styles.processando}>Seu arquivo está sendo processado. Permaneça nesta tela até o final do processo!</p></label>}
            <input
              className="form-control"
              type="file"
              id="formFile"
              accept=".xltx,.xlsx,.csv"
              onChange={(e) => {
                console.log(e.target.files[0]);
                setFile(e.target.files[0])
              }}
              hidden
            />
          </div>
          <div className={styles.Buttons}>
            
            {!isLoading && !file ? <button className={styles.btnEnviar} onClick={() => document.getElementById("formFile").click()}>
              Enviar arquivo
            </button> : <><button className={styles.btnRemover} onClick={() => {
                setFile("")
                const fileInput = document.getElementById("formFile");
                if (fileInput) {
                  fileInput.value = "";
                }}}>
              Remover arquivo
            </button><button className={styles.btnEnviar} onClick={handleFileUpload}>
              Enviar arquivo
            </button></>}
            
            
          </div>
          {file && <span className={styles.nomeFile}>Arquivo selecionado: {`${file.name}`}</span>}
        </div>
      </div>

      {modalCamposVaziosImport && <ModalCamposVaziosImport linhas={linhasComCamposVazios} handleClose={handleClose}/>}
      {modalLocalInexistenteImport && <ModalLocalInexistente linhas={linhasLocalInexistente} handleClose={handleClose}/>}
      {modalUnidadeErradaImport && <ModalUnidadeErrada linhas={linhasUnidadeErrada} handleClose={handleClose}/>}

    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
        erroImportar={erroImportar}
      />
    </>
  );
}

export default ImportarProduto;
