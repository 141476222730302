// STYLES
import styles from "./AddVendedorNaMeta.module.css";

// LIBS
import $ from "jquery";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

// HOOKS
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useContext, useRef } from "react";

// COMPONENTES
import { AuthContext } from "../../../Services/AuthContext";
import api, { setAuthToken } from "../../../Services/api";
import { capitalizeFirstLetter } from "../../../Utils/stringCapitalizer";
import Cabecalho from "../../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../../Components/Menu/Menu";
import MessageModal from "../../../Components/MessageModal/MessageModal";
import VendedoresModal from "../../../Components/VendedoresModal/VendedoresModal";

const AddVendedorNaMeta = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, codemp } = useContext(AuthContext);

  const [vendedores, setVendedores] = useState([]);
  const [vendedorVinculado, setVendedorVinculado] = useState([]);

  const [showVendedorModal, setShowVendedorModal] = useState(false);
  const [selectedVendedor, setSelectedVendedor] = useState(null);
  console.log(selectedVendedor);

  const [codVend, setCodVend] = useState("");
  console.log("dados", codVend);

  const [codVendMeta, setCodVendMeta] = useState("");

  const { CODMETA } = location.state || {};
  console.log(CODMETA);

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const tableRef = useRef(null);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    api
      .get(`/vendedor/listar?CODEMP=${Number(codemp)}`)
      .then((response) => {
        setVendedores(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar vendedores:", error);
      });
  }, [codemp]);

  const getTabelaDeVendedor = () => {
    if (codemp) {
      api
        .get(
          `/metas/listar/vendedor?CODEMP=${Number(codemp)}&CODMETA=${Number(
            CODMETA
          )}`
        )
        .then((response) => {
          setVendedorVinculado(response.data);
          console.log("lista de vendedores vinculados", response.data);
          return response.data;
        })
        .catch((error) => {
          console.error(error);
        })
    }
  };

  useEffect(() => {
    getTabelaDeVendedor();
  }, [codemp, isLoading]);

  const handleDeleteVendedorMeta = async (codVendMeta) => {
    setShowModalDelete(true);

    console.log("CODVENDMETA", codVendMeta);
    setCodVendMeta(codVendMeta);
  };

  const handleConfirmDelete = () => {
    setShowModalDelete(false);
    setIsLoading(true);
    const requestData = {
      CODEMP: Number(codemp),
      CODVENDMETA: Number(codVendMeta),
    };
    console.log("request data-->", requestData);
    api
      .delete(`/metas/remover/vendedor`, { data: requestData })
      .then((response) => {
        console.log("Meta excluída do vendedor com sucesso:", response.data);
        setMessage("Meta excluída do vendedor com sucesso!");
        setErro("");
        setShowMessage(true);
        setIsLoading(false);

        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Erro ao excluir meta do  vendedor:", error);
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao excluir meta do  vendedor:");
        }
        setMessage("");
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseDeleteModal = () => {
    setShowModalDelete(false);
  };

  useEffect(() => {
    if (
      vendedorVinculado.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
          // emptyTable: "Nenhum registro encontrado na tabela",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
      });
    }
  }, [vendedorVinculado]);
  console.log(vendedorVinculado);

  const dados = {
    CODMETA: +CODMETA,
    CODEMP: +codemp,
    CODVEND: +codVend,
  };
  console.log("Entrando no submitttt", dados);

  const handleSubmit = async () => {
    setErro("");
    setIsLoading(true);
    try {
      const response = await api.post(`/metas/inserir/vendedor`, dados);
      console.log("Vendedor cadastrado na meta", response.data);

      setModalMessage("Vendedor cadastrado na meta com sucesso!");
      setIsSuccess(true);
      setShowMessageModal(true);
    } catch (error) {
      console.error("Erro ao cadastrar vendedor na meta:", error);
      let errorMessage = "";
      if (error.response && error.response.status === 500) {
        errorMessage =
          "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
      } else {
        errorMessage = "Erro ao cadastrar vendedor na meta.";
      }
      setModalMessage(errorMessage);
      setIsSuccess(false);
      setShowMessageModal(true);
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {};

  const handleCloseModalVendedor = () => {
    setShowVendedorModal(null);
  };

  const showTable = () => (
    <div className={styles.corpo}>
      <div className={`container-fluid py-4 ${styles.boxTable}`}>
        <div className="table-responsive p-0 pb-2">
          <h1 className={styles.titleTable}>Tabela de Vendedores Vinculados</h1>
          <table
            ref={tableRef}
            className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
          >
            <thead>
              <tr>
                <th>Código</th>
                <th>Nome</th>
                <th>CPF</th>
                <th>Telefone</th>
                <th>Email</th>
                <th className="text-center">
                  <i className="bi bi-trash"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              {vendedorVinculado.map((item, index) => {
                const pessoa = item.Vendedor.Usuario.Pessoa;
                const NOME = pessoa && pessoa.NOME ? pessoa.NOME : "-";
                const SOBRENOME =
                  pessoa && pessoa.SOBRENOME ? pessoa.SOBRENOME : "-";
                const email = pessoa && pessoa.EMAIL ? pessoa.EMAIL : "-";
                const Telefone =
                  pessoa && pessoa.TELEFONE ? pessoa.TELEFONE : "-";
                const Cpf = pessoa && pessoa.CPFCNPJ ? pessoa.CPFCNPJ : "-";

                return (
                  <tr key={index}>
                    <td className="text-center">{item.CODVEND}</td>
                    <td>{`${capitalizeFirstLetter(
                      NOME
                    )} ${capitalizeFirstLetter(SOBRENOME)}`}</td>
                    <td>{`${Cpf}`}</td>
                    <td>{`${Telefone}`}</td>
                    <td>{email}</td>
                    <td>
                      <button
                        type="button"
                        onClick={() =>
                          handleDeleteVendedorMeta(item.CODVENDMETA)
                        }
                        className={styles.deleteButton}
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <h1>
              <i
                className="bi bi-arrow-left-circle-fill"
                onClick={() => {
                  navigate("/metas");
                }}
              ></i>
              Adicionar Vendedor na Meta
            </h1>
          </div>

          <div className={styles.corpo}>
            <Form className={styles.form}>
              {showMessage && (
                <div
                  className={`${styles.message} ${
                    message ? styles.successMessage : styles.errorMessage
                  }`}
                >
                  <p>{message || erro}</p>
                </div>
              )}

              <Form.Group>
                <fieldset className={styles.fieldsetTabela}>
                  <div className={styles.inputsFlex}>
                    <div className={styles.tabela}>
                      <div className={styles.adicionar}>
                        <button
                          type="button"
                          onClick={() => setShowVendedorModal(true)}
                          className={`customSelectBtn ${styles.customSelectBtn}`}
                        >
                          Selecionar Vendedor
                        </button>
                      </div>
                    </div>
                  </div>
                </fieldset>
                {showTable()}
              </Form.Group>
            </Form>
          </div>
        </div>
      </CaixaConteudo>

      {showVendedorModal && (
        <VendedoresModal
          onClose={handleCloseModalVendedor}
          onSelect={(selectedVendedor) => {
            setCodVend(selectedVendedor.CODVEND);
            setSelectedVendedor({
              nome: selectedVendedor.NOME,
              sobrenome: selectedVendedor.SOBRENOME,
            });
          }}
          handleSubmit={handleSubmit}
        />
      )}

      <Modal show={showModalDelete} onHide={handleCloseDeleteModal} centered>
        <Modal.Header>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja excluir este vendedor da meta?
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="secondary"
            onClick={handleCloseDeleteModal}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            className={`${styles.submit} ${styles.excluirButton}`}
            onClick={handleConfirmDelete}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default AddVendedorNaMeta;
