import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./UnidadeMedida.module.css";
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";

import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import ModalNovaUnidadeMedida from "./ModalNovaUnidade/ModalNovaUnidade";
import { Modal, Button } from "react-bootstrap";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";
import MessageModal from "../../Components/MessageModal/MessageModal";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import axios from "axios";

const UnidadeMedida = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const [unidadesMedida, setUnidadesMedida] = useState([]);
  const [selectedTableId, setSelectedTableId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [novaUnidadeMedida, setNovaUnidadeMedida] = useState(false);
  const tableRef = useRef(null);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [semDados, setSemDados] = useState("");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  // const fetchMeta = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await api.get(`/unidademed/listar?CODEMP=${codemp}`);
  //     setUnidadesMedida(response.data);
  //     console.log("retorno", response.data);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchMeta();
  // }, [codemp]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    setLoading(true);
    const getUnidades = async () => {
      try {
        const response = await api.get(`/unidademed/listar?CODEMP=${CODEMP}`, {
          cancelToken: source.token
        });
        if(response.data.length === 0 ){
          setUnidadesMedida([])
          setSemDados("Sem dados")
        } else {
          setUnidadesMedida(response.data);
        }
        setLoading(false);
        setReload(false)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
        setLoading(false);
        setReload(false)
      }
    };
    getUnidades();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp,reload]);

  console.log(unidadesMedida);

  useEffect(() => {
    if (
      unidadesMedida.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
          zeroRecords: "Nenhum registro correspondente encontrado.",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
      });
    }
  }, [unidadesMedida,reload]);

  const fecharModalNovaUnidadeMedida = () => {
    //fetchMeta();
    setNovaUnidadeMedida(false);
  };

  const openModal = (CODUNMED) => {
    setSelectedTableId(CODUNMED);
    setShowModal(true);
  };

  const handleDeleteUnidadeMedida = () => {
    if (selectedTableId) {
      const requestData = {
        CODEMP: codemp,
        CODUNMED: selectedTableId,
      };

      api
        .delete(`/unidademed/deletar`, { data: requestData })
        .then((response) => {
          setShowModal(false);
          //fetchMeta();

          console.log("Unidade de medida excluída com sucesso:", response.data);
          setModalMessage("Unidade de medida excluída com sucesso!");
          setIsSuccess(true);
          setShowMessageModal(true);
        })
        .catch((error) => {
          let errorMessage = "";
          if (error.response.status === 409) {
            errorMessage = (
              <>
                Não é possível excluir esta unidade de medida, pois ela está
                vinculada a um ou mais produtos no sistema. <br />
                Para continuar, primeiro remova os vínculos desta unidade de
                medida com os produtos associados.
              </>
            );

            setModalMessage(errorMessage);
            setShowErrorModal(true);
            setShowModal(false);
          } else if (error.response && error.response.status === 500) {
            errorMessage =
              "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
          } else {
            errorMessage =
              "Erro ao deletar unidade de medida. Tente novamente!";
          }
          setModalMessage(errorMessage);
          setIsSuccess(false);
          setShowMessageModal(true);
        });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {};

  const renderModal = () => {
    return (
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header>
          <Modal.Title>Confirmar exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Deseja excluir unidade de medida?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleDeleteUnidadeMedida}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const showTable = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan="8">
            <LoadingCentroTelas />
          </td>
        </tr>
      );
    } else if (semDados === "Sem dados") {
      return (
        <tr>
          <td style={{ textAlign: "center" }} colSpan="8">
            A tabela está vazia no momento.
          </td>
        </tr>
      );
    } else if (unidadesMedida.length > 0) {
      try {
        return unidadesMedida.map((item, index) => {
          let tipo = item.TIPO;
          if (
            tipo === "CAIXAS" ||
            tipo === "LITROS" ||
            tipo === "KILOS" ||
            tipo === "METROS"
          ) {
            tipo = capitalizeFirstLetter(tipo);
          } else {
            tipo = capitalizeFirstLetter(tipo);
          }
  
          return (
            <tr key={index}>
              <td className="col-1 text-center">
                <i
                  className="bi bi-trash"
                  onClick={() => openModal(item.CODUNMED)}
                  style={{ cursor: "pointer" }}
                ></i>
              </td>
              <td className="text-center">{item.CODUNMED}</td>
              <td className="text-center">{tipo}</td>
              <td className="text-center">
                {capitalizeFirstLetter(item.DESCRICAO) || "-"}
              </td>
            </tr>
          );
        });
      } catch (e) {
        alert(e.message);
      }
    }   
       
  };
  

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <div className={styles.topoButtons}>
          <button onClick={() => setNovaUnidadeMedida(true)}>
            Adicionar Unidade de Medida Livre
          </button>
          <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} title="Atualizar" onClick={()=>setReload(true)}></i>
        </div>
      </div>
      <div className={styles.corpo}>
        <div className={`container-fluid py-4 ${styles.boxTable}`}>
          
          <div className="table-responsive p-0 pb-2">
            <h1 className={styles.titleTable}> Unidade de Medida </h1>
            
            <table
              ref={tableRef}
              className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
            >
              <thead>
                <tr>
                  <th className="col-1 text-center">Ação</th>
                  <th className="col-1 text-center">Código</th>
                  <th className="col-3 text-center">Unidade de Medida</th>
                  <th className="col-3 text-center">Descrição</th>
                </tr>
              </thead>
              <tbody>{showTable()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
      {novaUnidadeMedida && (
        <ModalNovaUnidadeMedida
          handleFecharModal={fecharModalNovaUnidadeMedida}
        />
      )}
      <div className={styles.grid}>{renderModal()}</div>

      <MessageModal
        show={showErrorModal}
        message={modalMessage}
        onClose={() => {
          setShowErrorModal(false);
          handleCloseModal();
        }}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default UnidadeMedida;
