import Form from "react-bootstrap/Form";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

import styles from "./AdicionarMedida.module.css";
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import ModalNovaUnidadeMedida from "./ModalNovaUnidade/ModalNovaUnidade";
import { Modal, Button } from "react-bootstrap";

const unidadesMedida = {
  L: "Litros",
  KG: "Quilos",
  M: "Metros",
  CX: "Caixas",
};

const AdicionarMedida = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);

  const [unidade, setUnidade] = useState("");
  const [descricao, setDescricao] = useState("");
  const [erro, setErro] = useState("");
  const [novaUnidadeMedida, setNovaUnidadeMedida] = useState(false);
  const [modalAviso, setModalAviso] = useState(false);
  const [unidadeJaExiste, setUnidadeJaExiste] = useState(false);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const atualizarDescricao = (unidade) => {
    const descricaoUnidade = unidadesMedida[unidade];
    setDescricao(descricaoUnidade);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErro("");

    if (unidade === "" || descricao === "") {
      setErro("Preencha todos os campos.");
      return;
    }

    const adicionarMedida = {
      CODEMP: codemp,
      TIPO: unidade,
    };

    try {
      const response = await api.post(
        "/unidademed/cadastrar/padrao",
        adicionarMedida
      );
      console.log("Unidade de medida adicionada com sucesso:", response.data);
      resetarInputs();
      navigate("/unidade-medida");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // A unidade de medida já existe, exibir o modal de aviso
        setUnidadeJaExiste(true);
        setModalAviso(true);
      } else {
        console.error("Erro ao adicionar unidade de medida:", error);
        setErro("Ocorreu um erro ao adicionar a unidade de medida.");
      }
    }
  };

  const resetarInputs = () => {
    setDescricao("");
    setUnidade("");
  };

  const fecharModalNovaUnidadeMedida = () => {
    setNovaUnidadeMedida(false);
  };

  const fecharModalAviso = () => {
    setModalAviso(false);
  };

  const renderModal = () => {
    if (unidadeJaExiste) {
      return (
        <Modal show={modalAviso} onHide={fecharModalAviso} centered>
          <Modal.Header>
            <Modal.Title>Unidade de Medida Existente</Modal.Title>
          </Modal.Header>
          <Modal.Body>Essa unidade de medida já existe.</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={fecharModalAviso}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    return null;
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            class="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/unidade-medida");
            }}
          ></i>
          Nova Unidade de Medida
        </h1>
        <div className={styles.topoButtons}>
          <button onClick={() => setNovaUnidadeMedida(true)}>
            Adicionar Unidade de Medida Livre
          </button>
        </div>
      </div>

      <div className={styles.corpo}>
        <Form className={styles.form}>
          <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="unidade">Unidade de medida:</Form.Label>
              <div className={styles.flexItem}>
                <Form.Select
                  className={styles.select}
                  id="unidade"
                  value={unidade}
                  onChange={(e) => {
                    setUnidade(e.target.value);
                    atualizarDescricao(e.target.value);
                  }}
                >
                  <option value="">Selecione uma unidade</option>
                  <option value="L">L</option>
                  <option value="KG">KG</option>
                  <option value="M">M</option>
                  <option value="CX">CX</option>
                </Form.Select>
              </div>
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="descricao" className={styles.label}>
                Descrição:
              </Form.Label>
              <div className={styles.flexItemDescricao}>
                <Form.Control
                  id="descricao"
                  value={descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                />
              </div>
            </Form.Group>
          </fieldset>

          <div className={styles.rodape}>
            <button className={styles.submit} onClick={handleSubmit}>
              <i className="bi bi-play-fill"></i>
              Salvar
            </button>
            {erro && <p className={styles.erro}>{erro}</p>}
          </div>
        </Form>
      </div>
    </div>
  );
  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
      {novaUnidadeMedida && (
        <ModalNovaUnidadeMedida
          handleFecharModal={fecharModalNovaUnidadeMedida}
        />
      )}
      <div className={styles.grid}>{renderModal()}</div>
    </>
  );
};

export default AdicionarMedida;
