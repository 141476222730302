// ESTILO
import styles from "./AdicionarFornecedor.module.css";

// MODULOS
import Form from "react-bootstrap/Form";

// HOOKS
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

// COMPONENTES
import Cabecalho from "../../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../../Components/Menu/Menu";
import { AuthContext } from "../../../Services/AuthContext";
import api, { setAuthToken } from "../../../Services/api";
import MessageModal from "../../../Components/MessageModal/MessageModal";
import estadosBrasil from "../../../Utils/estadosBrasil";

//LIBS
import InputMask from "react-input-mask";
import { validarCamposVazios } from "../../../Utils/validarCamposVazios";
import { validarCPFouCNPJ } from "../../../Utils/validarCPFouCNPJ";

const AdicionarFornecedor = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const [nomeFornecedor, setNomeFornecedor] = useState("");
  const [descricao, setDescricao] = useState("");

  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cpf, setCpf] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cpfOuCnpj, setcpfOuCnpj] = useState("cpf");
  const [cargo, setCargo] = useState("");
  const [tipo, setTipo] = useState("");
  const [pessoa, setPessoa] = useState(null);
  const [erro, setErro] = useState("");
  const [cepError, setCepError] = useState("");
  const [cnpjError, setCnpjError] = useState("");

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [codEnd, setCodEnd] = useState("");
  const [cep, setCep] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [referencia, setReferencia] = useState("");
  const [complemento, setComplemento] = useState("");
  const [manualAddressEntry, setManualAddressEntry] = useState(false);

  const [botaoSalvar, setBotaoSalvar] = useState("Salvar")

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const buscarCNPJ = async () => {
    try {
      if (cnpj) {
        const { data } = await api.get(`/empresas/buscar/cnpj?CNPJ=${cnpj}`);
        console.log("dadosss", data);

        if (data.error) {
          setCnpjError("⚠️CNPJ não encontrado");
          setTimeout(() => {
            setCnpjError("");
          }, 3000);
        } else {
          setNome(data.RAZAOSOCIAL);
          setSobrenome(data.NOMEFANTASIA);
          setEmail(data.EMAIL);
          setTelefone(data.TELEFONE);
          setNumero(data.NUMERO);
          setComplemento(data.COMPLEMENTO);

          const cepSemPontos = data.CEP ? data.CEP.replace(/\D/g, "") : "";
          setCep(cepSemPontos);

          if (cepSemPontos && cepSemPontos !== "") {
            handleCepChange(cepSemPontos);
          }
        }
      }
    } catch (error) {
      console.error("Erro ao buscar CNPJ:", error);
    }
  };

  const handleCepChange = async (newCep) => {
    newCep = newCep.replace(/\D/g, "");
    setCep(newCep);

    if (newCep === "" || newCep.length !== 8) {
      setCodEnd(0);
      setEstado("");
      setCidade("");
      setBairro("");
      setEndereco("");
      setNumero("");
      setComplemento("");
      setReferencia("");
      setCepError("");
      setManualAddressEntry(false);
      return;
    }

    try {
      if (newCep.length === 8) {
        const response = await api.get(`/enderecos/buscar?CEP=${newCep}`);
        const enderecoData = response.data;
        setCodEnd(enderecoData.CODEND);
        setEstado(enderecoData.ESTADO);
        setCidade(enderecoData.CIDADE);
        setBairro(enderecoData.BAIRRO);
        setEndereco(`${enderecoData.TIPOEND} ${enderecoData.NOMEEND}`);
        setNumero(enderecoData.NUMERO);
        setComplemento(enderecoData.COMPLEMENTO);
        setReferencia(enderecoData.REFERENCIA || "");
        setCepError("");

        if (
          enderecoData.TIPOEND === undefined &&
          enderecoData.NOMEEND === undefined
        ) {
          setManualAddressEntry(true);
          setCepError(
            "⚠️CEP não encontrado, digite manualmente seu endereço para cadastro."
          );
          setEndereco("");
          setBairro("");
          setCidade("");
          setEstado("");
        } else {
          setManualAddressEntry(false);
          setEndereco(`${enderecoData.TIPOEND} ${enderecoData.NOMEEND}`);
        }
      } else {
        setCepError("⚠️CEP não encontrado");
      }
    } catch (error) {
      console.log("API error:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErro("");

    const dadosObrigatorios = {
      'Fornecedor': nomeFornecedor,
      'Descrição': descricao,
      ...(cpfOuCnpj === "cnpj" ? {
        'Razão Social': nome,
        'Nome Fantasia': sobrenome,
        'CNPJ': cnpj,
      } : {
        'Nome': nome,
        'Sobrenome': sobrenome,
        'CPF': cpf,
      }),
      'Telefone': telefone,
      'E-mail': email,
      "CEP": cep,
      'Número': numero
    };    


    if(validarCamposVazios(dadosObrigatorios).length > 0) {
      setModalMessage(`Preencha os campos obrigatorios: ${validarCamposVazios(dadosObrigatorios)}!`);
      setShowMessageModal(true);
      return ;
    } 

    if(validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj) === 'CPF' || validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj) === 'CNPJ') {
      setModalMessage(`${validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj)} inválido!`);
      setShowMessageModal(true);
      return;
    } 

    const novoFornecedor = {
      CODEMP: codemp,
      Pessoa: {
        NOME: nome,
        SOBRENOME: sobrenome,
        EMAIL: email,
        TELEFONE: telefone,
        CARGO: "Fornecedor",
        CPFCNPJ: cpfOuCnpj === "cnpj" ? cnpj : cpf,
        TIPO: cpfOuCnpj === "cnpj" ? "J" : "F",
        CODEND: codEnd,
      },
      Fornecedor: {
        DESCRICAO: descricao,
        FORNECEDOR: nomeFornecedor,
        CODEMP: codemp,
      },
      Endereco: {
        CODEND: +codEnd,
        NUMERO: numero,
        REFERENCIA: referencia ? referencia : "-",
        COMPLEMENTO: complemento ? complemento : "-",
      },
    };

    setBotaoSalvar("Carregando...")

    api
      .post(`/pessoas/cadastrar/pessoa/fornecedor`, novoFornecedor)
      .then((response) => {
        console.log("Fornecedor criado com sucesso:", response.data);
        resetarInputs();
        setModalMessage("Fornecedor criado com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao criar fornecedor.Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
      })
      .finally(()=>{
        setBotaoSalvar("Salvar")
      })
  };

  //RESETAR O VALOR DE TODOS OS INPUTS
  const resetarInputs = () => {
    setNomeFornecedor("");
    setDescricao("");
    setNome("");
    setSobrenome("");
    setEmail("");
    setTelefone("");
    setCargo("");
    setcpfOuCnpj();
    setTipo("");
    setCep("");
    setEndereco("");
    setNumero("");
    setBairro("");
    setCidade("");
    setEstado("");
    setErro("");
  };

  // Função para alternar entre Pessoa Física e Pessoa Jurídica
  const handleTipoPessoaChange = (event) => {
    const novoTipo = event.target.value;
    setcpfOuCnpj(novoTipo);

    if (novoTipo === "cnpj") {
      setCpf("");
    } else {
      setCnpj("");
    }
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/fornecedor");
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            class="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/fornecedor");
            }}
          ></i>
          Novo Fornecedor
        </h1>
      </div>

      <div className={styles.corpo}>
        <Form className={styles.form} id="novoFornecedorForm">
          <h1>Campos obrigatórios(*)</h1>
          {cnpjError && (
            <span
              style={{ color: "red", textAlign: "right", marginRight: "40%" }}
            >
              {cnpjError}
            </span>
          )}
          <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="tipoPessoa" className={styles.label}>
                Tipo de Pessoa*:
              </Form.Label>
              <Form.Select
                id="tipoPessoa"
                className="form-control"
                value={cpfOuCnpj}
                onChange={handleTipoPessoaChange}
              >
                <option value="cpf">Pessoa Física</option>
                <option value="cnpj">Pessoa Jurídica</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="cpfCnpj" className={styles.label}>
                {cpfOuCnpj === "cnpj" ? "CNPJ*" : "CPF*"}:
              </Form.Label>
              <div className={styles.inputWithIcon}>
                <Form.Control
                  id="cpfCnpj"
                  as={InputMask}
                  mask={
                    cpfOuCnpj === "cnpj"
                      ? "99.999.999/9999-99"
                      : "999.999.999-99"
                  }
                  value={cpfOuCnpj === "cnpj" ? cnpj : cpf}
                  onChange={(e) => {
                    if (cpfOuCnpj === "cnpj") {
                      setCnpj(e.target.value);
                    } else {
                      setCpf(e.target.value);
                    }
                  }}
                />
                {cpfOuCnpj === "cnpj" && (
                  <i
                    className={`bi bi-search ${styles.searchIcon}`}
                    onClick={buscarCNPJ}
                  ></i>
                )}
              </div>
            </Form.Group>
          </fieldset>

          <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="nomeFornecedor" className={styles.label}>
                Fornecedor*:
              </Form.Label>
              <Form.Control
                className={styles.inputStyle}
                id="nomeFornecedor"
                value={nomeFornecedor}
                onChange={(e) => setNomeFornecedor(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="descricao" className={styles.label}>
                Descrição*:
              </Form.Label>
              <Form.Control
                className={styles.inputStyle}
                id="descricao"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
              />
            </Form.Group>
          </fieldset>
          <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="nome" className={styles.label}>
                {cpfOuCnpj === "cnpj" ? "Razão Social*" : "Nome*"}
              </Form.Label>
              <Form.Control
                id="nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="sobrenome" className={styles.label}>
                {cpfOuCnpj === "cnpj" ? "Nome Fantasia*" : "Sobrenome*:"}
              </Form.Label>
              <Form.Control
                id="sobrenome"
                value={sobrenome}
                onChange={(e) => setSobrenome(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="telefone" className={styles.label}>
                Telefone*:
              </Form.Label>
              <Form.Control
                className={styles.inputStyle}
                id="telefone"
                as={InputMask}
                mask="(99) 99999-9999"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="email" className={styles.label}>
                E-mail*:
              </Form.Label>
              <Form.Control
                className={styles.inputStyleEmail}
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </fieldset>

          {cepError && <span style={{ color: "red" }}>{cepError}</span>}
          <fieldset className={styles.fieldsetEndereco}>
            <div className={styles.inlineDiv}>
              <Form.Group className={`${styles.grupo} w-100`}>
                <Form.Label htmlFor="cep" className={styles.label}>
                  CEP*:
                </Form.Label>
                <Form.Control
                  id="cep"
                  as={InputMask}
                  mask="99.999-999"
                  value={cep}
                  onChange={(e) => handleCepChange(e.target.value)}
                />
              </Form.Group>

              <div className={styles.inlineDiv}>
                <Form.Group className={`${styles.grupo} w-100`}>
                  <Form.Label htmlFor="endereco" className={styles.label}>
                    Endereço*:
                  </Form.Label>
                  <Form.Control
                    id="endereco"
                    value={endereco}
                    onChange={(e) => setEndereco(e.target.value)}
                    disabled={!manualAddressEntry}
                  />
                </Form.Group>
                <Form.Group className={`${styles.grupo} w-100`}>
                  <Form.Label htmlFor="numero" className={styles.label}>
                    Número*:
                  </Form.Label>
                  <Form.Control
                    id="numero"
                    value={numero}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      setNumero(numericValue);
                    }}
                  />
                </Form.Group>

                <Form.Group className={`${styles.grupo} w-100`}>
                  <Form.Label htmlFor="complemento" className={styles.label}>
                    Complemento:
                  </Form.Label>
                  <Form.Control
                    id="complemento"
                    value={complemento}
                    onChange={(e) => setComplemento(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>


            <div className={styles.inlineDiv}>
              <Form.Group className={`${styles.grupo} w-100`}>
                <Form.Label htmlFor="bairro" className={styles.label}>
                  Bairro*:
                </Form.Label>
                <Form.Control
                  id="bairro"
                  value={bairro}
                  onChange={(e) => setBairro(e.target.value)}
                  disabled={!manualAddressEntry}
                />
              </Form.Group>

              
                <Form.Group className={`${styles.grupo} w-100`}>
                  <Form.Label htmlFor="cidade" className={styles.label}>
                    Cidade*:
                  </Form.Label>
                  <Form.Control
                    id="cidade"
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                    disabled={!manualAddressEntry}
                  />
                </Form.Group>

                <Form.Group className={`${styles.grupo} w-100`}>
                  <Form.Label htmlFor="estado" className={styles.label}>
                    Estado*:
                  </Form.Label>
                  {manualAddressEntry ? (
                    <Form.Control
                      as="select"
                      id="estado"
                      value={estado}
                      onChange={(e) => setEstado(e.target.value)}
                    >
                      <option value="" disabled>
                        Selecione um estado
                      </option>
                      {estadosBrasil.map((sigla) => (
                        <option key={sigla} value={sigla}>
                          {sigla}
                        </option>
                      ))}
                    </Form.Control>
                  ) : (
                    <Form.Control id="estado" value={estado} disabled={true} />
                  )}
                </Form.Group>

                <Form.Group className={`${styles.grupo} w-100`}>
                  <Form.Label className={styles.label}>Referência:</Form.Label>
                  <Form.Control
                    className={styles.InputRef}
                    type="text"
                    name="referencia"
                    id="referencia"
                    value={referencia}
                    onChange={(e) => setReferencia(e.target.value)}
                  />
                </Form.Group>
              </div>

          </fieldset>

          
        </Form>
        
      </div>
      <div className={styles.rodape}>
            <button className={styles.submit} onClick={handleSubmit}>
              <i className="bi bi-play-fill"></i>
              {botaoSalvar}
            </button>
          </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default AdicionarFornecedor;
