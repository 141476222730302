import styles from "./Cabecalho.module.css";

import { useState, useEffect, useContext  } from "react";
import { Nav, Alert } from "react-bootstrap";
import { AuthContext } from '../../Services/AuthContext';
import api, { setAuthToken } from "../../Services/api";
import avatar from "./avatar.jpg";
import { useNavigate } from "react-router-dom";

const Cabecalho = () => {
  const { token, codemp } = useContext(AuthContext);
  const navigate = useNavigate()
  const [subMenu, setSubMenu] = useState(false);
  const [mudarSenha, setMudarSenha] = useState(false);
  const [senhaAtual, setSenhaAtual] = useState("");
  const [novaSenha, setNovaSenha] = useState("");
  const [confirmarNovaSenha, setConfirmarNovaSenha] = useState("");
  const [mostrarMudarSenhaModal, setMostrarMudarSenhaModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imagemUsuario, setImagemUsuario] = useState("");
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { CODUSU } = JSON.parse(localStorage.getItem("userData"));
  const { userName } = localStorage;

  const handleApiError = (error, customMessage) => {
    console.error(error);
    console.log(`${customMessage}: ${error.message}`);
    console.log("Mensagem de erro da API:", error.response.data);
    if(error.response.data.statusCode === 401){
      sessionStorage.clear()
      navigate(`/`)
    }
  };

useEffect(() => {
  setAuthToken(token);
}, [token]);

  useEffect(() => {
    if(CODUSU && token) {
    const getImagemUsuario = async () => {
      try {
        const response = await api.get(
          `/pessoas/buscar/imagem?CODUSU=${CODUSU}`);

        setImagemUsuario(response.data);
      } catch (error) {
        console.error(error);
        handleApiError(error, "Houve um erro ao buscar a imagem do usuário.");
      }
    };
    getImagemUsuario();
  }
  }, [CODUSU, token]);

  const handleLogoutClick = () => {
    setShowModal(true);
  };

  const handleLogout = () => {
        
    setAuthToken(sessionStorage.getItem("token"))
    if(sessionStorage.getItem("token")){
    api
    .post(`/autenticacao/sair`)
    .then((response) => {
      console.log("Logout efetuado com sucesso:", response.data);

      sessionStorage.clear()
      localStorage.clear()
      // localStorage.removeItem("token");
      // localStorage.removeItem("userName");
      window.location.href = "/";

    })
    .catch((error) => {
      console.error("Erro ao fazer logout:", error);
      if (error.response && error.response.status === 500) {
        alert(
          "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
        );
      } else {
        alert(
          "Erro ao fazer logout, recarregue a página e tente novamente."
        );
      }
    })
  } else {
    sessionStorage.clear()
    localStorage.clear()
    // localStorage.removeItem("token");
    // localStorage.removeItem("userName");
    window.location.href = "/";
  }
  };

  const handleSubMenuClick = () => {
    setSubMenu(!subMenu);
  };

  const handleChange = (event, campo) => {
    const fieldMapping = {
      senhaAtual: setSenhaAtual,
      novaSenha: setNovaSenha,
      confirmarNovaSenha: setConfirmarNovaSenha,
    };
  
    const setValue = fieldMapping[campo];
    if (setValue) {
      setValue(event.target.value);
    }
  };

  const handleMudarSenhaSubmit = (event) => {
    event.preventDefault();
  
    if (!verificarNovaSenha()) {
      return;
    }
  
    const novaSenhaData = { CODUSU, SENHA: novaSenha };

    setAuthToken(token); 
  
    api
      .patch('/usuarios/atualizar', novaSenhaData)
       .then(() => {
        setSuccessMessage('Senha alterada com sucesso!');
        setTimeout(() => {
          handleMudarSenhaClose();
        }, 3000); 
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message === 'Unauthorized') {
          setErrorMessage('Atualização não autorizada. Contate o administrador.');
        } else {
          setErrorMessage('Ocorreu um erro ao tentar alterar a senha.');
        }
        console.log(error);
      });
  };

  const verificarNovaSenha = () => {
    if (novaSenha !== confirmarNovaSenha) {
      setErrorMessage('A nova senha não é igual ao campo confirmar nova senha.');
      return false;
    }
    if (novaSenha === senhaAtual) {
      setErrorMessage('A nova senha deve ser diferente da senha atual.');
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (mostrarMudarSenhaModal) {
      setMudarSenha(true);
      setMostrarMudarSenhaModal(false);
    }
  }, [mostrarMudarSenhaModal]);

  const handleMudarSenhaClose = () => {
    setMudarSenha(false);
    setSenhaAtual('');
    setNovaSenha('');
    setConfirmarNovaSenha('');
    setSuccessMessage('');
    setErrorMessage('');
  };

  return (
    <div className={styles.barra}>
      <div className={styles.infos}>
        <Nav.Link href="/meu-plano" className={styles.plano}>
          Meu Plano
        </Nav.Link>
        <i className="bi bi-bell"></i>
        <span className={styles.nome}>{userName}</span>
        <div
          className={`${styles.subMenu} ${subMenu ? styles.subMenuActive : ""}`}
          onClick={handleSubMenuClick}
          onMouseLeave={() => setSubMenu(false)}
        >
          {imagemUsuario && imagemUsuario.IMAGEM ? (
            <img style={{cursor: 'pointer'}}
              src={`data:image/jpeg;base64,${imagemUsuario.IMAGEM}`}
              alt="Foto de perfil"
              className={styles.foto}
            />
          ) : (
            <img style={{cursor: 'pointer'}} src={avatar} alt="" className={styles.foto} />
          )}
          {subMenu && (
            <div className={`${styles.subMenuContent} ${styles.infoUsuario}`}>
              <div
                className={`${styles.subMenuItem} ${styles.noWrap}`}
                onClick={() => navigate('/dispositivos-pendentes')}
              >
                Dispositivos Pendentes
              </div>
              <div
                className={styles.subMenuItem}
                onClick={() => navigate('/log-acessos')}
              >
                Log de Acessos
              </div>
              <div
                className={styles.subMenuItem}
                onClick={() => setMostrarMudarSenhaModal(true)}
              >
                Alterar Senha
              </div>
              <div
                className={`${styles.subMenuItem} ${styles.logout}`}
                onClick={handleLogoutClick}
              >
                Logout
              </div>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <div className={styles.modal}>
          <p>Tem certeza que deseja fazer logout?</p>
          <div>
            <button onClick={handleLogout}>Sim</button>
            <button onClick={() => setShowModal(false)}>Não</button>
          </div>
        </div>
      )}

      {mudarSenha && (
        <div className={styles.modal}>
          <form onSubmit={handleMudarSenhaSubmit}>
            <h2>Alterar Senha</h2>
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <label>
              Senha Atual:
              <input
                type="password"
                required
                value={senhaAtual}
                onChange={(event) => handleChange(event, "senhaAtual")}
              />
            </label>
            <label>
              Nova Senha:
              <input
                type="password"
                required
                value={novaSenha}
                onChange={(event) => handleChange(event, "novaSenha")}
              />
            </label>
            <label>
              Confirmar Nova Senha:
              <input
                type="password"
                required
                value={confirmarNovaSenha}
                onChange={(event) => handleChange(event, "confirmarNovaSenha")}
              />
            </label>
            <div className={styles.modalButtons}>
              <button onClick={handleMudarSenhaClose}>Cancelar</button>
              <button type="submit">Salvar</button>
            </div>
          </form>
        </div>
      )}

    </div>
  );
};

export default Cabecalho;


