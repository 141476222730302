import styles from './DetalheLogImport.module.css';
import React, { useContext, useEffect, useRef, useState } from 'react'
import Menu from '../../Components/Menu/Menu';
import Cabecalho from '../../Components/Cabecalho/Cabecalho';
import CaixaConteudo from '../../Components/CaixaConteudo/CaixaConteudo';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Services/AuthContext';
import api, { setAuthToken } from '../../Services/api';
import axios from 'axios';
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import LoadingCentroTelas from '../../Components/LoadingCentroTelas/LoadingCentroTelas';
import { capitalizeFirstLetter } from '../../Utils/stringCapitalizer';
import {formatarCpfCnpj} from '../../Utils/formatarCpfCnpj';

const DetalheLogImport = () => {
    const { token, codemp } = useContext(AuthContext);
    const tableRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const [logs, setLogs] = useState([]);
    const [semDados, setSemDados] = useState('');
    const [reload, setReload] = useState(false);
    const [TIPO, setTIPO] = useState(null)
    const [CODLOGCAB, setCODLOGCAB] = useState(null)
    
    useEffect(() => {
        setAuthToken(token);
        setTIPO(location.state ? location.state.tipo : null)
        setCODLOGCAB(location.state ? location.state.codlogcab : null)
      }, []);

    useEffect(() => {
        const source = axios.CancelToken.source();
        const CODEMP = codemp || sessionStorage.getItem("CODEMP");
        setLogs([])
        setSemDados('')
        if(location.state?.tipo){
            const getLogs = async () => {
                try {
                  let response;
                  if (location.state.tipo === 'clientes') {
                      response = await api.get(`/clientes/importar/cabecalho/buscar?CODLOGCAB=${location.state.codlogcab}&CODEMP=${CODEMP}`, {
                        cancelToken: source.token
                      });
                    } else {
                      response = await api.get(`/produtos/importar/cabecalho/buscar?CODLOGCAB=${location.state.codlogcab}&CODEMP=${CODEMP}`, {
                        cancelToken: source.token
                      });
                    }
                  if(response.data.IMPORTS.length === 0 ){
                    setSemDados("Sem dados")
                  } else {
                    setLogs(response.data.IMPORTS);
                  }
                } catch (error) {
                  if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                  } else {
                    console.error(error);
                  }
                } finally {
                  setReload(false)
                }
              };
              getLogs();
            }
        return () => {
          source.cancel('Component unmounted');
        };
      }, [codemp,reload]);  

      useEffect(() => {
        if (
          logs.length > 0 &&
          tableRef.current &&
          !$.fn.DataTable.isDataTable(tableRef.current)
        ) {

          $(tableRef.current).DataTable({
            language: {
              search: "Pesquisar",
              info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
            },
            lengthChange: false,
            //destroy: true,
            ordering: true,
            order: [0, 'desc']
          });
        } 
      }, [logs]);   

    return (
        <>
          <Menu />
          <Cabecalho />
          <CaixaConteudo>
          <div className={styles.grid}>
        <div className={styles.topo}>
          <div className={styles.topoButtons}>
            <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} onClick={() => window.location.reload()} title="Atualizar" ></i>
          </div>
          </div>
          <div className={styles.corpo}>
            <div className={`${styles.boxTable} container-fluid py-4`}>
              <div className="table-responsive p-0 pb-2">
                <h1 className={styles.titleTable}>
                <i onClick={() => navigate(-1)} class="bi bi-arrow-left-circle-fill"></i>
                    Detalhes Log de Importação - {capitalizeFirstLetter(TIPO)} - Cód. {CODLOGCAB}</h1>
                    <table
                      ref={tableRef}
                      id="tableLogs"
                      className={`${styles.table} table align-items-center justify-content-center mb-0`}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">Cód.Import</th>
                          {location.state?.tipo === 'clientes' ? (
                            <>
                              <th>CPFCNPJ</th>
                              <th>Nome</th>
                            </>
                          ) : <th>Produto</th>}
                          <th className='text-center'>Resultado</th>
                          <th>Detalhes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {logs.length === 0 && semDados === '' ? <tr>
                      <td colSpan="8">
                        <LoadingCentroTelas />
                      </td>
                    </tr> : semDados === 'Sem dados' ? <tr>
                      <td style={{ textAlign: "center" }} colSpan="8">
                      A tabela está vazia no momento.
                      </td>
                    </tr> : (
                          logs.map((log) => {
                            return (
                              location.state?.tipo === 'clientes' ? (
                                <React.Fragment key={log.CODLOGCAB}>
                                  <tr key={log.CODLOGCAB}>
                                    <td>{log.CODLOGIMP}</td>
                                    <td className='text-left' style={{whiteSpace: 'nowrap'}}>{formatarCpfCnpj(log.IMPORT[0]?.cpf) }</td>
                                    <td className='text-left'>{log.IMPORT[0]?.cpf.length === 11 ? log.IMPORT[0]?.nome : `${log.IMPORT[0]?.nome} ${log.IMPORT[0]?.sobrenome}`}</td>
                                    <td>{log.STATUS ? 'OK' : 'ERRO'}</td>
                                    <td className='text-left'>{log.RETORNO}</td>
                                  </tr>
                              </React.Fragment>
                              ) : 
                            (
                              <React.Fragment key={log.CODLOGCAB}>
                                  <tr key={log.CODLOGCAB}>
                                    <td>{log.CODLOGIMP}</td>
                                    <td className='text-left'>{log.IMPORT}</td>
                                    <td>{log.STATUS ? 'OK' : 'ERRO'}</td>
                                    <td className='text-left'>{log.RETORNO}</td>
                                  </tr>
                              </React.Fragment>
                            )
                            );
                          })
                        )}
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
          </CaixaConteudo>
          
          {/* <MessageModal
            show={showMessageModal}
            message={modalMessage}
            onClose={handleCloseModal}
            isSuccess={isSuccess}
            onSuccess={handleRedirecionar}
          /> */}
    
        </>
      );
}

export default DetalheLogImport