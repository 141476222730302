// ESTILO
import styles from "./Menu.module.css";

// MODULOS
import Nav from "react-bootstrap/Nav";

// HOOKS
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// COMPONENTES
import logo from "../../Assets/Logos/logoThinkSale.png";
import abrirMenu from "./Assets/openMenu.svg";
import fecharMenu from "./Assets/closeMenu.svg";

const Menu = () => {
  const navigate = useNavigate();

  //seta estado do Menu como aberto ou fechado
  const [show, setShow] = useState(false);

  const [openCadastro, setOpenCadastro] = useState(false);
  const [openMovimentos, setOpenMovimentos] = useState(false);
  const [openProdutos, setOpenProdutos] = useState(false);
  const [openEmpresas, setOpenEmpresas] = useState(false);
  const [openClientes, setOpenClientes] = useState(false);

  function toggleCadastro() {
    setOpenCadastro(!openCadastro);
    setOpenMovimentos(false);
    setOpenProdutos(false);
    setOpenEmpresas(false);
    setOpenClientes(false);
  }

  function toggleMovimentos() {
    setOpenMovimentos(!openMovimentos);
    setOpenCadastro(false);
    setOpenProdutos(false);
    setOpenEmpresas(false);
    setOpenClientes(false);
  }

  function toggleProdutos() {
    setOpenProdutos(!openProdutos);
    setOpenCadastro(false);
    setOpenMovimentos(false);
    setOpenEmpresas(false);
    setOpenClientes(false);
  }

  function toggleEmpresa() {
    setOpenEmpresas(!openEmpresas);
    setOpenCadastro(false);
    setOpenMovimentos(false);
    setOpenProdutos(false);
    setOpenClientes(false);
  }

  function toggleClientes() {
    setOpenClientes(!openClientes);
    setOpenCadastro(false);
    setOpenMovimentos(false);
    setOpenProdutos(false);
    setOpenEmpresas(false);
  }

  return (
    <div className={styles.container}>
      <img
        className={styles.abrirMenu}
        src={abrirMenu}
        alt="Ícone de abrir menu"
        onClick={() => setShow(true)}
      />

      <aside className={show ? styles.thinkMenu : styles.lateralMenu}>
        <img
          className={styles.fecharMenu}
          src={fecharMenu}
          alt="Ícone de fechar menu"
          onClick={() => setShow(false)}
        />

        <img
          src={logo}
          alt="Logo ThinkSale"
          className={styles.logo}
          onClick={() => navigate("/dashboard")}
        />
        <Nav defaultActiveKey="/dashboard" className={styles.nav}>
          <Nav.Link href="/dashboard" className={styles.navItem}>
            {/*DASHBOARD*/}
            <i class="bi bi-border-all"></i>
            Dashboard
          </Nav.Link>

          <Nav.Link
            href="#"
            className={`${styles.navItem} ${openCadastro ? styles.active : ""}`}
            onClick={toggleCadastro}
          >
            {/*CADASTRO*/}
            <i class="bi bi-file-earmark-plus"></i>
            Cadastro
            <div className={openCadastro ? styles.submenu : styles.noneSubmenu}>
              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/vendedores");
                  setShow(false);
                }}
              >
                <i class="bi bi-briefcase"></i>
                Vendedores
              </Nav.Link>

              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={() => {
                  navigate("/transportadora");
                  setShow(false);
                }}
              >
                <i class="bi bi-truck"></i>
                Transportadora
              </Nav.Link>
              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={() => {
                  navigate("/pagamento");
                  setShow(false);
                }}
              >
                <i class="bi bi-credit-card"></i>
                Pagamento
              </Nav.Link>

              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={() => {
                  navigate("/unidade-medida");
                  setShow(false);
                }}
              >
                <i class="bi bi-rulers"></i>
                Unidade de medida
              </Nav.Link>

              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={() => {
                  navigate("/locais");
                  setShow(false);
                }}
              >
                <i class="bi bi-geo-alt-fill"></i>
                Locais
              </Nav.Link>

              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={() => {
                  navigate("/marcas");
                  setShow(false);
                }}
              >
                <i class="bi bi-award-fill"></i>
                Marcas
              </Nav.Link>

              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={() => {
                  navigate("/fornecedor");
                  setShow(false);
                }}
              >
                <i class="bi bi-truck"></i>
                Fornecedor
              </Nav.Link>
            </div>
          </Nav.Link>

          <Nav.Link
            href="#"
            className={`${styles.navItem} ${openClientes ? styles.active : ""}`}
            onClick={toggleClientes}
          >
            {/*Clientes*/}
            <i class="bi bi-people"></i>
            Clientes
            <div className={openClientes ? styles.submenu : styles.noneSubmenu}>
              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={() => {
                  navigate("/clientes");
                  setShow(false);
                }}
              >
                <i class="bi bi-people-fill"></i>
                Clientes
              </Nav.Link>
              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/segmentos");
                  setShow(false);
                }}
              >
                <i class="bi bi-segmented-nav"></i>
                Segmentos
              </Nav.Link>
            </div>
          </Nav.Link>

          <Nav.Link
            href="#"
            className={`${styles.navItem} ${openProdutos ? styles.active : ""}`}
            onClick={toggleProdutos}
          >
            {/*Produtos*/}
            <i class="bi bi-box-seam"></i>
            Produtos
            <div className={openProdutos ? styles.submenu : styles.noneSubmenu}>
              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={() => {
                  navigate("/produtos");
                  setShow(false);
                }}
              >
                <i class="bi bi-cart-check-fill"></i>
                Produtos
              </Nav.Link>
              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/tabela-preco");
                  setShow(false);
                }}
              >
                <i class="bi bi-table"></i>
                Tabela de preço
              </Nav.Link>

              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/estoque");
                  setShow(false);
                }}
              >
                <i class="bi bi-archive"></i>
                Estoque
              </Nav.Link>

              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/categoria");
                  setShow(false);
                }}
              >
                <i class="bi bi-list-ul"></i>
                Categorias
              </Nav.Link>
            </div>
          </Nav.Link>

          <Nav.Link
            href="#"
            className={`${styles.navItem} ${
              openMovimentos ? styles.active : ""
            }`}
            onClick={toggleMovimentos}
          >
            {" "}
            {/*MOVIMENTOS*/}
            <i class="bi bi-clipboard"></i>
            Movimentos
            <div
              className={openMovimentos ? styles.submenu : styles.noneSubmenu}
            >
              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={() => {
                  navigate("/pedidos");
                  setShow(false);
                }}
              >
                <i class="bi bi-briefcase"></i>
                Pedidos
              </Nav.Link>
              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={() => {
                  navigate("/orcamento");
                  setShow(false);
                }}
              >
                <i class="bi bi-wallet"></i>
                Orçamento
              </Nav.Link>
              {/* <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={() => {
                  navigate("/bonificacoes");
                  setShow(false);
                }}
              >
                <i class="bi bi-cash"></i>
                Bonificações
              </Nav.Link>
              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={() => {
                  navigate("/trocas");
                  setShow(false);
                }}>
                <i class="bi bi-arrow-left-right"></i>
                Trocas
              </Nav.Link> */}
            </div>
          </Nav.Link>

          {/* <Nav.Link
            href="#"
            className={`${styles.navItem} ${openEmpresas ? styles.active : ""}`}
            onClick={toggleEmpresa}
          >
            <i class="bi bi-building"></i>
            Empresas
            <div className={openEmpresas ? styles.submenu : styles.noneSubmenu}>
              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/Empresas");
                  setShow(false);
                }}
              >
                <i class="bi bi-building"></i>
                Administrar Empresas
              </Nav.Link>

              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/licencas");
                  setShow(false);
                }}
              >
                <i class="bi bi-card-text"></i>
                Administrar Licenças
              </Nav.Link>

              <Nav.Link
                href="#"
                className={styles.navSubItem}
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/metas");
                  setShow(false);
                }}
              >
                <i class="bi bi-bar-chart-line-fill"></i>
                Metas
              </Nav.Link>
            </div>
          </Nav.Link> */}
        </Nav>
        <Nav.Link
          href="/editar-perfil"
          className={`${styles.navItem} ${styles.navEditarPerfil}`}
        >
          <i className="bi bi-gear"></i>
          Minha Conta
        </Nav.Link>
      </aside>
    </div>
  );
};

export default Menu;
