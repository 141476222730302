import styles from './ModalUnidadeErrada.module.css'
const ModalUnidadeErrada = ({linhas, handleClose}) => {
  return (
    <div className={styles.modal}>
    <div className={styles.topoModal}>
        <h2>Erro: Unidade de Medida!</h2>
        <span onClick={handleClose}>X</span>
    </div>
    {linhas.length > 0 ? (
      <div className={styles.linhas}>
        <p>As seguintes linhas do seu arquivo apresentam Unidade de Medida inválidas:</p>
        {linhas.map((linhaInfo) => (
          <p key={linhaInfo}>
            Linha {linhaInfo}.
          </p>
        ))}
      </div>
    ) : null}
    <div className={styles.footer}>
        <p className={styles.erro}>UNIDADEMEDIDA pode ter no máximo 2 caracteres! Corrija e tente novamante.</p>
        <button className={styles.btn} onClick={handleClose}>Fechar</button>
    </div>
  </div>
  )
}

export default ModalUnidadeErrada