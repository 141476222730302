import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NovoUsuario.module.css";
import Form from "react-bootstrap/Form";
import InputMask from "react-input-mask";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import MessageModal from "../../Components/MessageModal/MessageModal";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import estadosBrasil from "../../Utils/estadosBrasil";

const NovoUsuario = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);

  const [cpf, setCpf] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cpfOuCnpj, setcpfOuCnpj] = useState("");
  const [cargo, setCargo] = useState("");
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [tipo, setTipo] = useState("");
  const [pessoa, setPessoa] = useState(null);
  const [novoCargo, setNovoCargo] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [codEnd, setCodEnd] = useState("");
  const [complemento, setComplemento] = useState("");
  const [referencia, setReferencia] = useState("");
  const [cepError, setCepError] = useState("");
  const [cnpjError, setCnpjError] = useState("");
  const [manualAddressEntry, setManualAddressEntry] = useState(false);

  const [erro, setErro] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [permissao, setPermissao] = useState(""); // valor padrão para o campo "Permissão"

  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const handleTelefoneChange = (event) => {
    const telefone = event.target.value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    const telefoneRegex = /^\d{10,11}$/; // Regex para validar o telefone (10 ou 11 dígitos)

    setTelefone(telefone);

    if (!telefoneRegex.test(telefone)) {
      setErro("Telefone inválido!");
    } else {
      setErro("");
    }
  };

  const handleEmailChange = (event) => {
    const email = event.target.value.trim();
    const emailRegex = /^[^@]+@[^@]+\.[^@]+$/; // Regex para validar o e-mail

    setEmail(email);

    if (!emailRegex.test(email)) {
      setErro("E-mail inválido!");
    } else {
      setErro("");
    }
  };

  const buscarCNPJ = async () => {
    try {
      if (cnpj) {
        const { data } = await api.get(`/empresas/buscar/cnpj?CNPJ=${cnpj}`);
        console.log("dadosss", data);

        if (data.error) {
          setCnpjError("⚠️CNPJ não encontrado");
          setTimeout(() => {
            setCnpjError("");
          }, 3000);
        } else {
          setNome(data.RAZAOSOCIAL);
          setSobrenome(data.NOMEFANTASIA);
          setEmail(data.EMAIL);
          setTelefone(data.TELEFONE);
          setNumero(data.NUMERO);
          setComplemento(data.COMPLEMENTO);

          const cepSemPontos = data.CEP ? data.CEP.replace(/\D/g, "") : "";
          setCep(cepSemPontos);

          if (cepSemPontos && cepSemPontos !== "") {
            handleCepChange(cepSemPontos);
          }
        }
      }
    } catch (error) {
      console.error("Erro ao buscar CNPJ:", error);
    }
  };

  const handleCepChange = async (newCep) => {
    newCep = newCep.replace(/\D/g, "");
    setCep(newCep);

    if (newCep === "" || newCep.length !== 8) {
      setCodEnd(0);
      setEstado("");
      setCidade("");
      setBairro("");
      setEndereco("");
      setNumero("");
      setComplemento("");
      setReferencia("");
      setCepError("");
      setManualAddressEntry(false);
      return;
    }

    try {
      if (newCep.length === 8) {
        const response = await api.get(`/enderecos/buscar?CEP=${newCep}`);
        const enderecoData = response.data;
        setCodEnd(enderecoData.CODEND);
        setEstado(enderecoData.ESTADO);
        setCidade(enderecoData.CIDADE);
        setBairro(enderecoData.BAIRRO);
        setEndereco(`${enderecoData.TIPOEND} ${enderecoData.NOMEEND}`);
        setNumero(enderecoData.NUMERO);
        setComplemento(enderecoData.COMPLEMENTO);
        setReferencia(enderecoData.REFERENCIA || "");
        setCepError("");

        if (
          enderecoData.TIPOEND === undefined &&
          enderecoData.NOMEEND === undefined
        ) {
          setManualAddressEntry(true);
          setCepError(
            "⚠️CEP não encontrado, digite manualmente seu endereço para cadastro."
          );
          setEndereco("");
          setBairro("");
          setCidade("");
          setEstado("");
        } else {
          setManualAddressEntry(false);
          setEndereco(`${enderecoData.TIPOEND} ${enderecoData.NOMEEND}`);
        }
      } else {
        setCepError("⚠️CEP não encontrado");
      }
    } catch (error) {
      console.log("API error:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErro("");

    if (
      (cpfOuCnpj === "cpf" && (cpf === "" || cpf.length !== 14)) ||
      (cpfOuCnpj === "cnpj" && (cnpj === "" || cnpj.length !== 18)) ||
      cargo === "" ||
      nome === "" ||
      sobrenome === "" ||
      telefone === "" ||
      email === "" ||
      cep === "" ||
      endereco === "" ||
      numero === "" ||
      bairro === "" ||
      cidade === "" ||
      estado === ""
    ) {
      setModalMessage("Preencha todos os campos!");
      setShowMessageModal(true);
      return;
    }

    let cargoEnviar = cargo;
    if (cargo === "Outro" && novoCargo !== "") {
      cargoEnviar = novoCargo; // Atualiza a variável cargoEnviar com o valor digitado no campo novoCargo
    }

    const novoUsuario = {
      CODEMP: codemp,
      Pessoa: {
        NOME: nome,
        SOBRENOME: sobrenome,
        EMAIL: email,
        TELEFONE: telefone,
        CARGO: cargoEnviar,
        CPFCNPJ: cpfOuCnpj === "cnpj" ? cnpj : cpf,
        TIPO: cpfOuCnpj === "cnpj" ? "J" : "F",
        CODEND: codEnd,
      },
      Usuario: {
        PERMISSAO: permissao,
        EMAIL: email,
      },
      Endereco: {
        CODEND: codEnd,
        NUMERO: numero,
        REFERENCIA: referencia ? referencia : "-",
        COMPLEMENTO: complemento ? complemento : "-",
      },
    };
    console.log("aaaaaaa", novoUsuario);
    const usuario = api.post(`/pessoas/cadastrar/pessoa/usuario`, novoUsuario);

    usuario
      .then((usuarioResponse) => {
        console.log("Usuário criado com sucesso:", usuarioResponse.data);

        // reset inputs
        resetarInputs();

        setModalMessage("Usuário criado com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        console.error("Erro ao criar usuário:", error);
        console.log("não enviou para a API");
        let errorMessage = "";
        if (error.response.status === 409) {
          errorMessage = "Conflito: Usuário já cadastrado.";
          setModalMessage(errorMessage);
          setShowMessageModal(true);
        } else if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao criar usuário. Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
      });
  };

  const resetarInputs = () => {
    setcpfOuCnpj("");
    setCnpj("");
    setCpf("");
    setCargo("");
    setNome("");
    setSobrenome("");
    setTelefone("");
    setEmail("");
    setCep("");
    setEndereco("");
    setNumero("");
    setBairro("");
    setCidade("");
    setEstado("");
    setCodEnd("");
    setErro("");
    setPermissao("");
    setTipo("");
    setComplemento("");
    setReferencia("");
  };

  // Função para alternar entre Pessoa Física e Pessoa Jurídica
  const handleTipoPessoaChange = (event) => {
    const novoTipo = event.target.value;
    setcpfOuCnpj(novoTipo);

    if (novoTipo === "cnpj") {
      setCpf("");
    } else {
      setCnpj("");
    }
  };

  const handleSelectFocus = () => {
    if (cargo === "" || pessoa === "") {
      setCargo("");
    }
  };

  const handlePermissaoChange = (event) => {
    setPermissao(event.target.value);
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/meu-plano");
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            class="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/meu-plano");
            }}
          ></i>
          Novo Usuário
        </h1>
      </div>

      <div className={styles.corpo}>
        <h1>Campos obrigatórios(*)</h1>
        {cnpjError && (
          <span
            style={{ color: "red", textAlign: "right", marginRight: "40%" }}
          >
            {cnpjError}
          </span>
        )}
        <fieldset className={styles.fieldsetTopo}>
          <Form.Group className={styles.grupo}>
            <Form.Label htmlFor="tipoPessoa" className={styles.label}>
              Tipo de Pessoa*:
            </Form.Label>
            <Form.Select
              id="tipoPessoa"
              className="form-control"
              value={cpfOuCnpj}
              onChange={handleTipoPessoaChange}
            >
              <option value="cpf">Pessoa Física</option>
              <option value="cnpj">Pessoa Jurídica</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className={styles.grupo}>
            <Form.Label htmlFor="cpfCnpj" className={styles.label}>
              {cpfOuCnpj === "cnpj" ? "CNPJ*" : "CPF*"}:
            </Form.Label>
            <div className={styles.inputWithIcon}>
              <Form.Control
                id="cpfCnpj"
                as={InputMask}
                mask={
                  cpfOuCnpj === "cnpj" ? "99.999.999/9999-99" : "999.999.999-99"
                }
                value={cpfOuCnpj === "cnpj" ? cnpj : cpf}
                onChange={(e) => {
                  if (cpfOuCnpj === "cnpj") {
                    setCnpj(e.target.value);
                  } else {
                    setCpf(e.target.value);
                  }
                }}
              />
              {cpfOuCnpj === "cnpj" && (
                <i
                  className={`bi bi-search ${styles.searchIcon}`}
                  onClick={buscarCNPJ}
                ></i>
              )}
            </div>
          </Form.Group>
        </fieldset>

        <fieldset className={styles.fieldsetTopo}>
          <Form.Group className={styles.grupo}>
            <Form.Label htmlFor="cargo" className={styles.label}>
              Cargo*:
            </Form.Label>
            <Form.Select
              id="cargo"
              value={cargo}
              className="form-control"
              onChange={(e) => setCargo(e.target.value)}
              onFocus={handleSelectFocus}
            >
              <option value="">Selecione um cargo</option>
              <option value="Gerente">Gerente</option>
              <option value="Colaborador">Colaborador</option>
              <option value="Outro">Outro</option>
            </Form.Select>
          </Form.Group>

          {cargo === "Outro" && (
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="novoCargo" className={styles.label}>
                Novo Cargo:
              </Form.Label>
              <Form.Control
                id="novoCargo"
                value={novoCargo}
                onChange={(e) => setNovoCargo(e.target.value)}
                placeholder="Digite o novo cargo"
              />
            </Form.Group>
          )}

          {userData.PERMISSAO.toLowerCase() === "administrador" && (
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="permissao" className={styles.label}>
                Permissão:
              </Form.Label>
              <Form.Control
                as="select"
                id="permissao"
                value={permissao}
                onChange={handlePermissaoChange}
              >
                <option value="ADMINISTRADOR">Administrador</option>
                <option value="GESTOR">Gestor</option>
                <option value="VENDEDOR">Vendedor</option>
              </Form.Control>
            </Form.Group>
          )}
          {userData.PERMISSAO.toLowerCase() === "Gestor" && (
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="permissao" className={styles.label}>
                Permissão:
              </Form.Label>
              <Form.Control
                as="select"
                id="permissao"
                value={permissao}
                onChange={handlePermissaoChange}
              >
                <option value="Vendedor">Vendedor</option>
              </Form.Control>
            </Form.Group>
          )}
        </fieldset>

        <fieldset className={styles.fieldsetTopo}>
          <Form.Group className={styles.grupo}>
            <Form.Label htmlFor="nome" className={styles.label}>
              {cpfOuCnpj === "cnpj" ? "Razão Social*" : "Nome*:"}
            </Form.Label>
            <Form.Control
              id="nome"
              value={capitalizeFirstLetter(nome)}
              onChange={(e) => setNome(e.target.value)}
            />
          </Form.Group>

          <Form.Group className={styles.grupo}>
            <Form.Label htmlFor="sobrenome" className={styles.label}>
              {cpfOuCnpj === "cnpj" ? "Nome Fantasia*" : "Sobrenome*:"}
            </Form.Label>
            <Form.Control
              id="sobrenome"
              value={capitalizeFirstLetter(sobrenome)}
              onChange={(e) => setSobrenome(e.target.value)}
            />
          </Form.Group>

          <Form.Group className={styles.grupo}>
            <Form.Label htmlFor="email" className={styles.label}>
              E-mail*:
            </Form.Label>
            <Form.Control
              id="email"
              value={email}
              onChange={handleEmailChange}
            />
          </Form.Group>
          <Form.Group className={styles.grupo}>
            <Form.Label htmlFor="telefone" className={styles.label}>
              Telefone*:
            </Form.Label>
            <Form.Control
              id="telefone"
              as={InputMask}
              mask="(99) 99999-9999"
              value={telefone}
              onChange={handleTelefoneChange}
            />
          </Form.Group>
        </fieldset>

        {cepError && <span style={{ color: "red" }}>{cepError}</span>}
        <fieldset className={styles.fieldsetEndereco}>
          <fieldset className={styles.fieldsetTopo}>
            <div className={styles.inlineDiv}>
              <Form.Group className={styles.grupo}>
                <Form.Label htmlFor="cep" className={styles.label}>
                  CEP*:
                </Form.Label>
                <Form.Control
                  id="cep"
                  as={InputMask}
                  mask="99.999-999"
                  value={cep}
                  onChange={(e) => handleCepChange(e.target.value)}
                />
              </Form.Group>

              <div className={styles.inlineDiv}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="endereco" className={styles.label}>
                    Endereço*:
                  </Form.Label>
                  <Form.Control
                    id="endereco"
                    value={endereco}
                    onChange={(e) => setEndereco(e.target.value)}
                    disabled={!manualAddressEntry}
                  />
                </Form.Group>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="numero" className={styles.label}>
                    Número*:
                  </Form.Label>
                  <Form.Control
                    id="numero"
                    value={numero}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      setNumero(numericValue);
                    }}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="complemento" className={styles.label}>
                    Complemento:
                  </Form.Label>
                  <Form.Control
                    id="complemento"
                    value={complemento}
                    onChange={(e) => setComplemento(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>

            <div>
              <Form.Group className={styles.grupo}>
                <Form.Label htmlFor="bairro" className={styles.label}>
                  Bairro*:
                </Form.Label>
                <Form.Control
                  id="bairro"
                  value={bairro}
                  onChange={(e) => setBairro(e.target.value)}
                  disabled={!manualAddressEntry}
                />
              </Form.Group>

              <div className={styles.inlineDiv}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="cidade" className={styles.label}>
                    Cidade*:
                  </Form.Label>
                  <Form.Control
                    id="cidade"
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                    disabled={!manualAddressEntry}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="estado" className={styles.label}>
                    Estado*:
                  </Form.Label>
                  {manualAddressEntry ? (
                    <Form.Control
                      as="select"
                      id="estado"
                      value={estado}
                      onChange={(e) => setEstado(e.target.value)}
                    >
                      <option value="" disabled>
                        Selecione um estado
                      </option>
                      {estadosBrasil.map((sigla) => (
                        <option key={sigla} value={sigla}>
                          {sigla}
                        </option>
                      ))}
                    </Form.Control>
                  ) : (
                    <Form.Control id="estado" value={estado} disabled={true} />
                  )}
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label className={styles.label}>Referência:</Form.Label>
                  <Form.Control
                    className={styles.InputRef}
                    type="text"
                    name="referencia"
                    id="referencia"
                    value={referencia}
                    onChange={(e) => setReferencia(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
          </fieldset>
        </fieldset>

        <div className={styles.rodape}>
          {erro && <p className={styles.erro}>{erro}</p>}
          <button className={styles.submit} onClick={handleSubmit}>
            <i className="bi bi-play-fill"></i>
            Salvar
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default NovoUsuario;
