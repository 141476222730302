// ESTILO
import styles from "./AdicionarLocal.module.css";

// MODULOS
import Form from "react-bootstrap/Form";

// HOOKS
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

// COMPONENTES
import Cabecalho from "../../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../../Components/Menu/Menu";
import { AuthContext } from "../../../Services/AuthContext";
import api, { setAuthToken } from "../../../Services/api";
import MessageModal from "../../../Components/MessageModal/MessageModal";

const AdicionarLocal = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);

  const [codigoLocSankhya, setCodigoLocSankhya] = useState("");
  const [nomelocal, setNomeLocal] = useState("");
  const [descricao, setDescricao] = useState("");
  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [botaoSalvar, setBotaoSalvar] = useState("Salvar")

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErro("");

    if (nomelocal === "") {
      setModalMessage("Preencha o campo obrigatório: Nome!");
      setShowMessageModal(true);
      return;
    }

    const novoLocal = {
      CODEMP: codemp,
      LOCAL: nomelocal,
      DESCRICAO: descricao,
      CODLOCSAN: codigoLocSankhya
    };

    setBotaoSalvar("Carregando...")

    api
      .post("/locais/cadastrar", novoLocal)
      .then((response) => {
        console.log("Local criado com sucesso:", response.data);

        resetarInputs();

        setModalMessage("Local criado com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response.status === 409) {
          errorMessage = "Conflito: Local já cadastrado.";
          setModalMessage(errorMessage);
          setShowMessageModal(true);
        } else if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao criar local. Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
      })
      .finally(()=>{
        setBotaoSalvar("Salvar")
      })
  };

  //RESETAR O VALOR DE TODOS OS INPUTS
  const resetarInputs = () => {
    setNomeLocal("");
    setDescricao("");
    setErro("");
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/locais");
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            class="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/locais");
            }}
          ></i>
          Novo Local
        </h1>
      </div>

      <div className={styles.corpo}>
        <Form className={styles.form} id="novoLocalForm">
          <h1>Campos obrigatórios(*)</h1>
          {showMessage && (
            <div
              className={`${styles.message} ${
                message ? styles.successMessage : styles.errorMessage
              }`}
            >
              <p>{message || erro}</p>
            </div>
          )}
          <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="codigoLocSankhya" className={styles.label}>
                Código Sankhya:
              </Form.Label>
              <Form.Control
                id="codigoLocSankhya"
                value={codigoLocSankhya}
                onChange={(e) => setCodigoLocSankhya(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="nomeLocal" className={styles.label}>
                Nome Local*:
              </Form.Label>
              <Form.Control
                className={styles.inputStyle}
                id="nomeLocal"
                value={nomelocal}
                onChange={(e) => setNomeLocal(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="descricao" className={styles.label}>
                Descrição:
              </Form.Label>
              <div className={styles.flexItemDescricao}>
                <Form.Control
                  id="descricao"
                  value={descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                />
              </div>
            </Form.Group>
          </fieldset>
          <div className={styles.rodape}>
            <button className={styles.submit} onClick={handleSubmit}>
              <i className="bi bi-play-fill"></i>
              {botaoSalvar}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default AdicionarLocal;
