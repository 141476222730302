// ESTILO
import styles from "./NovoEstoque.module.css";

// MODULOS
import Form from "react-bootstrap/Form";

// HOOKS
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import MessageModal from "../../Components/MessageModal/MessageModal";
import ProdutoEstoqueModal from "../../Components/ProdutoEstoqueModal/ProdutoEstoqueModal";
import { validarCamposVazios } from "../../Utils/validarCamposVazios";

const NovoEstoque = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);

  const [estoque, setEstoque] = useState("");
  const [dtVencimento, setDtVencimento] = useState("");
  const [lote, setLote] = useState("");

  const [formValues, setFormValues] = useState({
    produto: { codigo: "", descricao: "" },
  });

  const [showProdutoEstoqueModal, setShowProdutoEstoqueModal] = useState(false);
  const [productSelected, setProductSelected] = useState(false);

  const [erro, setErro] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [locais, setLocais] = useState([])
  const [local, setLocal] = useState('')
  const [sankhya, setSankhya] = useState('')

  const [botaoSalvar,setBotaoSalvar] = useState("Salvar")
  const [loading,setLoading] = useState(false)
  const [botaoSelecionar,setBotaoSelecionar] = useState("Selecionar Produto")


//PEGAR TODOS OS LOCAIS CADASTRADOS
useEffect(() => {
  setAuthToken(token);
}, [token]);

useEffect(() => {
  const getLocais = async () => {
    try {
      const response = await api.get(`/locais/listar?CODEMP=${codemp}`);
      setLocais(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  getLocais();
}, [codemp, token]);


  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErro("");

    const dadosObrigatorios = {
      'Produto': formValues.produto.codigo,
      'Estoque': estoque,
      'Local': local,
    }

    if(validarCamposVazios(dadosObrigatorios).length > 0) {
      setModalMessage(`Preencha os campos obrigatorios: ${validarCamposVazios(dadosObrigatorios)}!`);
      setShowMessageModal(true);
      return;
    } 

    const novoEstoque = {
      CODEMP: +codemp,
      ESTOQUE: +estoque,
      DATAVENC: dtVencimento ? new Date(dtVencimento).toISOString() : null,
      LOTE: lote,
      CODPROD: formValues.produto.codigo,

      CODLOC: Number(local),
      CODEMPSAN: sankhya
    };

    setBotaoSalvar("Carregando...")

    api
      .post(`/estoque/cadastrar`, novoEstoque)
      .then((response) => {
        console.log("Estoque criado com sucesso:", response.data);

        resetarInputs();

        setModalMessage("Estoque criado com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
        setBotaoSalvar("Salvar")
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response.status === 409) {
          errorMessage = "Conflito: Estoque já cadastrado.";
          setModalMessage(errorMessage);
          setShowMessageModal(true);
        } else if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao criar estoque. Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
        setBotaoSalvar("Salvar")
      });
  };

  const resetarInputs = () => {
    setEstoque("");
    setDtVencimento("");
    setLote("");
  };

  // Modal mensagem
  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/estoque");
  };

  // Modal do Produto
  const handleOpenModal = () => {
    setBotaoSelecionar("Carregando...")
    setShowProdutoEstoqueModal(true);
  };

  const handleCloseModalProduto = () => {
    setShowProdutoEstoqueModal(false);
  };

  const handleMudarBotao = (valor) => {
    setBotaoSelecionar(valor)
  }

  const isEstoqueDisabled = !productSelected;

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            class="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/estoque");
            }}
          ></i>
          Novo Estoque
        </h1>
      </div>
      <div className={styles.corpo}>
        <Form className={styles.form} id="novaMarcaForm">
          <h1>Campos obrigatórios(*)</h1>
          <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="produto" className={styles.label}>
                Produto*
              </Form.Label>
              <Form.Control
                className={styles.inputStyle}
                value={
                  formValues.produto ? `${formValues.produto.descricao}` : ""
                }
                readOnly
              />
            </Form.Group>

            <div className={styles.buttonContainer}>
              <button
                type="button"
                onClick={handleOpenModal}
                className={`customSelectBtn ${styles.customSelectBtn}`}
              >
                <i class="bi bi-bag-plus"></i>
                {botaoSelecionar}
              </button>
            </div>

            {/* Renderiza o modal apenas quando showModal for verdadeiro */}
            {showProdutoEstoqueModal && (
              <ProdutoEstoqueModal
                onClose={handleCloseModalProduto}
                onSelect={(selectedProdutos) => {
                  setFormValues((prevState) => ({
                    ...prevState,
                    produto: {
                      codigo: selectedProdutos.CODPROD,
                      descricao: selectedProdutos.PRODUTO,
                    },
                  }));

                  handleCloseModalProduto();
                  setProductSelected(true);
                }}
                onLoading={handleMudarBotao}
              />
            )}
          </fieldset>

          <fieldset className={styles.fieldsetTopo}>
          <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="estoque" className={styles.label}>
                Estoque*
              </Form.Label>
              <Form.Control
                type="number"
                className={styles.inputStyle}
                id="estoque"
                value={estoque}
                onChange={(e) => setEstoque(e.target.value)}
                disabled={isEstoqueDisabled}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="lote" className={styles.label}>
                Lote
              </Form.Label>
              <Form.Control
                className={styles.inputStyle}
                id="lote"
                value={lote}
                onChange={(e) => setLote(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="dtVencimento" className={styles.label}>
                Data de Vencimento
              </Form.Label>
              <Form.Control
                type="date"
                id="dtVencimento"
                value={dtVencimento}
                onChange={(e) => setDtVencimento(e.target.value)}
              />
            </Form.Group>
          </fieldset>

          <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={`${styles.grupo}`}>
              <Form.Label htmlFor="local" className={styles.label}>
                Local*
              </Form.Label>
              <Form.Select
                id="local"
                className="form-control"
                value={local}
                onChange={(e) => setLocal(e.target.value)}
              >
                {!local && <option value="">Selecionar local</option>}
                {locais.map((item) => (
                  <option key={item.CODLOC} value={item.CODLOC}>{item.LOCAL}</option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="sankhya" className={styles.label}>
                Empresa Sankhya
              </Form.Label>
              <Form.Control
                className={styles.inputStyle}
                id="sankhya"
                value={sankhya}
                onChange={(e) => setSankhya(e.target.value)}
              />
            </Form.Group>
          </fieldset>
          <div className={styles.rodape}>
            <button className={styles.submit} onClick={handleSubmit}>
              <i className="bi bi-play-fill"></i>
              {botaoSalvar}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default NovoEstoque;
