import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

import styles from "./NovaMeta.module.css";
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import api, { setAuthToken } from "../../Services/api";
import { AuthContext } from "../../Services/AuthContext";
import MessageModal from "../../Components/MessageModal/MessageModal";

const NovaMeta = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const [meta, setMeta] = useState("");
  const [mensal, setMensal] = useState("");
  const [anual, setAnual] = useState("");
  const [erro, setErro] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErro("");

    if (meta === "" || mensal === "" || anual === "") {
      setModalMessage("Preencha todos os campos!");
      setShowMessageModal(true);
      return;
    }

    const novaMeta = {
      CODEMP: Number(codemp),
      META: meta,
      MENSAL: Number(mensal),
      ANUAL: Number(anual),
    };
    api
      .post(`/metas/cadastrar`, novaMeta)
      .then((response) => {
        console.log("Meta criado com sucesso:", response.data);
        resetarInputs();
        setModalMessage("Meta criado com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        console.error("Erro ao criar Meta:", error);
        let errorMessage = "";
        if (error.response.status === 409) {
          errorMessage = "Conflito: Meta já cadastrada.";
          setModalMessage(errorMessage);
          setShowMessageModal(true);
        } else if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao criar meta. Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
      });
  };

  const resetarInputs = () => {
    setAnual("");
    setMensal("");
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/metas");
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            class="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/metas");
            }}
          ></i>
          Nova Meta
        </h1>
      </div>

      <div className={styles.corpo}>
        <Form className={styles.form} id="novaMeta">
          <h1>Campos obrigatórios(*)</h1>
          <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="meta" className={styles.label}>
                Meta*:
              </Form.Label>
              <Form.Control
                id="meta"
                value={meta}
                onChange={(e) => setMeta(e.target.value)}
                placeholder="Nome ou descrição"
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="mensal" className={styles.label}>
                Valor Mensal*:
              </Form.Label>
              <Form.Control
                id="mensal"
                value={mensal}
                onChange={(e) => setMensal(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="anual" className={styles.label}>
                Valor Anual*:
              </Form.Label>
              <Form.Control
                id="anual"
                value={anual}
                onChange={(e) => setAnual(e.target.value)}
              />
            </Form.Group>
          </fieldset>

          <div className={styles.rodape}>
            <button className={styles.submit} onClick={handleSubmit}>
              <i className="bi bi-play-fill"></i>
              Salvar
            </button>
          </div>
        </Form>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default NovaMeta;
