// ESTILO
import styles from "./Categoria.module.css";

// HOOKS
import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

// LIBS
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import axios from "axios";

const Categoria = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const tableRef = useRef(null);

  const [categorias, setCategorias] = useState([]);
  const [loading,setLoading] = useState(false)
  const [reload,setReload] = useState(false)
  const [semDados, setSemDados] = useState('')

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  // useEffect(() => {
  //   setLoading(true)
  //   const getCategorias = async () => {
  //     try {
  //       const response = await api.get(`/categoria/listar?CODEMP=${codemp}`);
  //       setCategorias(response.data);
  //     } catch (error) {
  //       setErro(
  //         "Lista de categorias não encontrada, tente novamente mais tarde ou entre em contato conosco."
  //       );
  //     } finally {
  //       setLoading(false)
  //     }
  //   };
  //   getCategorias();
  // }, [codemp, token]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    setLoading(true);
    setCategorias([])
    setSemDados('')
    const getCategorias = async () => {
      try {
        const response = await api.get(`/categoria/listar?CODEMP=${CODEMP}`, {
          cancelToken: source.token
        });
        if(response.data.length === 0 ){
          setSemDados("Sem dados")
        } else {
          setCategorias(response.data);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
      } finally {
        setLoading(false);
        setReload(false)
      }
    };
    getCategorias();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp,reload]);

  useEffect(() => {
    if (
      categorias.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
      });
    }
  }, [categorias,reload]);

  const editarCategoria = (codcatprod) => {
    navigate("/editar-categoria", {
      state: {
        codcatprod: codcatprod,
      },
    });
  };

  const showTable = () => {
    return categorias.map((item, index) => {
      const { CODCATPROD, CATEGORIA, DESCRICAO } = item;
      return (
        <tr key={index}>
          <td style={{width: '50px'}} className="text-center">
            <i
              style={{ cursor: "pointer" }}
              className="bi bi-pen"
              onClick={() => editarCategoria(item.CODCATPROD)}
            ></i>
          </td>
          <td className="text-center">{CODCATPROD}</td>
          <td>{capitalizeFirstLetter(CATEGORIA)}</td>
          <td>{capitalizeFirstLetter(DESCRICAO)}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <div className={styles.topoButtons}>
              <button
                onClick={() => {
                  navigate("/adicionar-categoria");
                }}
              >
                Adicionar uma nova categoria
              </button>
              <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} title="Atualizar" onClick={() => setReload(true)}></i>
            </div>
          </div>
          <div className={styles.corpo}>
            <div className={`container-fluid py-4 ${styles.boxTable}`}>
              <div className="table-responsive p-0 pb-2">
                <h1 className={styles.titleTable}>Categorias</h1>
                <table
                  ref={tableRef}
                  className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                >
                  <thead>
                    <tr>
                      <th className="text-center">
                        Ação
                      </th>
                      <th>Código</th>
                      <th>Categoria</th>
                      <th>Descrição</th>
                    </tr>
                  </thead>
                  <tbody>{categorias.length === 0 && semDados === '' ? <tr>
                      <td colSpan="8">
                        <LoadingCentroTelas />
                      </td>
                    </tr> : semDados === 'Sem dados' ? <tr>
                      <td style={{ textAlign: "center" }} colSpan="8">
                      A tabela está vazia no momento.
                      </td>
                    </tr> : showTable()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </CaixaConteudo>
    </>
  );
};

export default Categoria;
