import $ from "jquery";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import api, { setAuthToken } from "../../../Services/api";
import { AuthContext } from "../../../Services/AuthContext";
import { capitalizeFirstLetter } from "../../../Utils/stringCapitalizer";

import styles from "./ModalProdutos.module.css";
import LoadingCentroTelas from "../../../Components/LoadingCentroTelas/LoadingCentroTelas";
import axios from "axios";

const ModalProdutos = ({
  onProductsSelected,
  onClose,
  selectedProducts,
  onLoading,
  cliente,
  vendedor
}) => {
  const { token, codemp } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(
    selectedProducts || []
  );
  const [showError, setShowError] = useState(false);
  const [selectedPrices, setSelectedPrices] = useState({});
  const [invalidProductNames, setInvalidProductNames] = useState([]);
  const [noProductsSelected, setNoProductsSelected] = useState(false);
  const [produtosCabecalho, setProdutosCabecalho] = useState([]);
  const [loading, setLoading] = useState(false)
  const tableRef = useRef(null);
  const [semDados, setSemDados] = useState('')

  useEffect(() => {
    setAuthToken(token);
  }, [token]);
  // useEffect(() => {
  //   setAuthToken(token);
  //   setLoading(true)
  //   if (produtosCabecalho.length === 0) {
  //     api
  //       .get(
  //         `/produtos/listar/produtos/pedido?CODEMP=${codemp}&CODCLI=${cliente}&CODVEND=${Number(
  //           vendedor
  //         )}`
  //       )
  //       .then((response) => {
  //         setProdutosCabecalho(response.data.filter((item, index, self) =>
  //           index === self.findIndex(t => t.CODPROD === item.CODPROD) && item.QTDESTOQUE !== 0
  //         ));
  //         setLoaded(true);
  //         setShowModal(true);
  //       })
  //       .catch((error) => {
  //         console.error("Erro ao obter produtos:", error);
  //       })
  //       .finally(()=>{
  //         setLoading(false)
  //       })
  //   } else {
  //     setLoaded(true);
  //     setShowModal(true);
  //   }
  // }, [codemp, token, cliente, vendedor]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    setProdutosCabecalho([])
    setSemDados('')
    onLoading(true,'produtos')
    const getProdutos = async () => {
      try {
        const response = await api.get(`/produtos/listar/produtos/pedido?CODEMP=${CODEMP}&CODCLI=${cliente}&CODVEND=${Number(
          vendedor
        )}`, { cancelToken: source.token });
        if(response.data.length === 0 ){
          setSemDados("Sem dados")
        } else {
          setProdutosCabecalho(response.data.filter((item, index, self) =>
            index === self.findIndex(t => t.CODPROD === item.CODPROD) && item.QTDESTOQUE !== 0
          ));
        }
        setShowModal(true);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
      } finally {
        onLoading(false,'produtos')
      }
    };
    getProdutos();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp]);

  useEffect(() => {
    if (
      produtosCabecalho.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
          emptyTable:
            "⚠️ Sem produtos encontrados, verifique se a empresa, o cliente e/ou vendedor selecionados possuem tabelas de preço vinculadas.",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
        order:[2,'asc']
      });
    }
  }, [produtosCabecalho]);

  useEffect(() => {
    if (selectedProducts) {
      setSelectedProduct(selectedProducts.map((p) => ({ ...p })));
      const prices = {};
      selectedProducts.forEach((p) => {
        prices[p.CODPROD] = {
          tableName: p.tableName,
          price: p.PRECO,
        };
      });
      setSelectedPrices(prices);
    }
  }, [selectedProducts]);

  const handleSave = () => {
    const filterStock = selectedProduct.filter(
      (product) => product.QTDESTOQUE === 0
    );

    if (selectedProduct.length === 0) {
      setNoProductsSelected(true);
      setShowError(false);
      return;
    }
    setShowError(false);

    if (filterStock.length > 0) {
      setShowError(true);
      setNoProductsSelected(false);
      setInvalidProductNames(filterStock.map((p) => p.PRODUTO));
      return;
    }

    setShowError(false);
    setNoProductsSelected(false);

// Remover produtos do selectedProduct que já estavam no selectedProducts
const saveProducts = selectedProduct.filter(
  (selectedProd) => !selectedProducts.some((prod) => prod.CODPROD === selectedProd.CODPROD)
);

    const updatedSelectedProduct = saveProducts.map((p) => ({
      ...p,
      PRECO: selectedPrices[p.CODPROD]
        ? selectedPrices[p.CODPROD].price
        : "N/A",
      tableName: selectedPrices[p.CODPROD]
        ? selectedPrices[p.CODPROD].tableName
        : "",
    }));

    if (onProductsSelected) {
      onProductsSelected(updatedSelectedProduct);
      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleProductSelection = (product) => {

    if (product.QTDESTOQUE !== null) {
      const isSelected = selectedProduct.some(
        (selectedProduct) => selectedProduct.CODPROD === product.CODPROD
      );

      setSelectedProduct((prevSelectedProduct) => {
        if (isSelected) {

          return prevSelectedProduct.filter(
            (selectedProduct) => selectedProduct.CODPROD !== product.CODPROD
          );

        } else {
          return [
            ...prevSelectedProduct,
            {
              ...product,
              FABRICANTE: product.FABRICANTE,
              DESCRICAO: product.DESCRICAO,
              CODPROD: product.CODPROD,
              PRECO: selectedPrices[product.CODPROD]
                ? selectedPrices[product.CODPROD].price
                : "N/A",
              QTD: 1,
            },
          ];
        }
      });

    }
  };

  const productPriceMap = {};
  produtosCabecalho.forEach((tabelaPreco) => {
    tabelaPreco.ItensTabela.forEach((item) => {
      const key = `${item.CODPROD}-${tabelaPreco.DESCRICAO}`;
      productPriceMap[key] = item.PRECOVENDA;
    });
  });

  const allProducts = produtosCabecalho.reduce((acc, tabelaPreco) => {

    tabelaPreco.ItensTabela.forEach((produto) => {

      const existingProduct = selectedProducts.some(
        (selectedProduct) =>
          selectedProduct.CODPROD === produto.CODPROD
      )
      
      if (!existingProduct) {
        acc.push({
          CODPROD: produto.CODPROD,
          PRODUTO: produto.tabelaPreco
            ? tabelaPreco.PRODUTO
            : produto.produto.PRODUTO,
          QTDESTOQUE: produto.tabelaPreco
            ? tabelaPreco.QTDESTOQUE
            : produto.produto.QTDESTOQUE,
          DESCRICAO: tabelaPreco.DESCRICAO,
          FABRICANTE: produto.tabelaPreco
            ? tabelaPreco.FABRICANTE
            : produto.produto.FABRICANTE,
          NCM: produto.tabelaPreco ? tabelaPreco.NCM : produto.produto.NCM,
          VALORUNIT: produto.PRECOVENDA
            ? produto.PRECOVENDA
            : produto.produto.VLRVENDA,
          VLRVENDA: produto.PRECOVENDA,
          TABELAS: tabelaPreco.OUTRAS_TABS,
        });
      }
    });
    return acc;
  }, []);

  return (
    <>
      <Modal
        show={showModal}
        size="xl"
        className="custom-modal-size"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title className={styles["modal-title"]}>
            Selecione os produtos
          </Modal.Title>
          <Button variant="none" className="btn btn-none" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Modal.Header>
        <Modal.Body style={{ overflow: "auto" }}>
          <table
            ref={tableRef}
            className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
          >
            <thead>
              <tr>
                <th className="text-center">Selecionar</th>
                <th className="text-center">Código</th>
                <th td className="text-center">
                  Produto
                </th>
                <th td className="text-center">
                  Referência
                </th>
                <th td className="text-center">
                  Estoque
                </th>
              </tr>
            </thead>
            <tbody>
              {produtosCabecalho.length === 0 && semDados === '' ? <tr>
                <td colSpan="8">
                  <LoadingCentroTelas />
                </td>
              </tr> : semDados === 'Sem dados' ? <tr>
                <td style={{ textAlign: "center" }} colSpan="8">
                A tabela está vazia no momento.
                </td>
              </tr> : allProducts.map((product, index) => (
                <tr key={index}>
                  <td className="col-1 text-center">
                    <div
                      className={`${styles.select} ${
                        product.QTDESTOQUE === null ? "select-disabled" : ""
                      }`}
                      onClick={() => handleProductSelection(product)}
                    >
                      <i
                        className={`bi ${
                          selectedProduct.some(
                            (selectedProduct) =>
                              selectedProduct.CODPROD === product.CODPROD
                          )
                            ? "bi-check-circle-fill"
                            : product.QTDESTOQUE === null
                            ? "bi bi-circle-fill text-muted"
                            : "bi-circle"
                        }`}
                      ></i>
                    </div>
                  </td>
                  <td className="col-1 text-center">{product.CODPROD}</td>
                  <td className="text-center">
                    {capitalizeFirstLetter(product.PRODUTO)}
                  </td>
                  <td className="text-center">{product.NCM ? product.NCM : '-'}</td>
                  <td className="text-center">
                    {product.QTDESTOQUE !== null ? product.QTDESTOQUE : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        {showError && (
          <div className={styles.errorContainer}>
            <span>⚠️ Os seguintes produtos não possuem estoque:</span>
            <span style={{ color: "red", marginLeft: "5px" }}>
              {invalidProductNames.join(", ")}
            </span>
          </div>
        )}
        {noProductsSelected && (
          <div className={styles.errorContainer}>
            <span style={{ color: "black" }}>
              ⚠️Você não selecionou nenhum produto. Para salvar:{" "}
              <span style={{ color: "red" }}>
                selecione pelo menos um produto.
              </span>
            </span>
          </div>
        )}
        <Modal.Footer>
          <Button variant="primary" onClick={handleSave}>
            {produtosCabecalho.length === 0 || semDados === "Sem Dados" ? "Fechar" : "Salvar"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalProdutos;
