// ESTILO
import styles from './NovaCategoria.module.css'

// MODULOS
import Form from "react-bootstrap/Form";

// HOOKS
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

// COMPONENTES
import Cabecalho from '../../Components/Cabecalho/Cabecalho';
import CaixaConteudo from '../../Components/CaixaConteudo/CaixaConteudo';
import Menu from '../../Components/Menu/Menu';
import { AuthContext } from '../../Services/AuthContext';
import api, {setAuthToken} from '../../Services/api';
import MessageModal from '../../Components/MessageModal/MessageModal';

const NovaCategoria = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const [nomeCategoria, setNomeCategoria] = useState("");
  const [descricao, setDescricao] = useState("");

  const [erro, setErro] = useState("");

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [botaoSalvar,setBotaoSalvar] = useState("Salvar")

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const novaCategoria = {
    CODEMP: +codemp,
    CATEGORIA: nomeCategoria,
    DESCRICAO: descricao
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setErro('')

    if (nomeCategoria === "") {
      setModalMessage("Preencha o campo obrigatório: Categoria!");
      setShowMessageModal(true);
      return;
    }

    setBotaoSalvar("Carregando...")

    api.post(`/categoria/cadastrar/pai`, novaCategoria)
    .then((response) => {
      console.log("Categoria criada com sucesso:", response.data);

      resetarInputs();

      setModalMessage("Categoria criada com sucesso!");
      setIsSuccess(true);
      setShowMessageModal(true);
      setBotaoSalvar("Salvar")
    })
    .catch((error) => {
      let errorMessage = "";
      if (error.response.status === 409) {
        errorMessage = "Conflito: Categoria já cadastrada.";
        setModalMessage(errorMessage);
        setShowMessageModal(true);
      } else if (error.response && error.response.status === 500) {
        errorMessage =
          "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
      } else {
        errorMessage = "Erro ao criar categoria. Tente novamente!";
      }
      setModalMessage(errorMessage);
      setIsSuccess(false);
      setShowMessageModal(true);
      setBotaoSalvar("Salvar")
    });
  }

  const resetarInputs = () => {
    setNomeCategoria("");
    setDescricao('');
    setErro('')
  }

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/categoria");
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            class="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/categoria");
            }}
          ></i>
          Nova Categoria
        </h1>
      </div>

      <div className={styles.corpo}>
        <Form className={styles.form} id="novaCategoriaForm">
          <h1>Campos obrigatórios(*)</h1>
          <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="nomeCategoria" className={styles.label}>
                Categoria*:
              </Form.Label>
              <Form.Control
                className={styles.inputStyle}
                id="nomeCategoria"
                value={nomeCategoria}
                onChange={(e) => setNomeCategoria(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="descricao" className={styles.label}>
                Descrição:
              </Form.Label>
              <div className={styles.flexItemDescricao}>
                <Form.Control
                  className={styles.inputStyle}
                  id="descricao"
                  value={descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                />
              </div>
            </Form.Group>
          </fieldset>
          <div className={styles.rodape}>
            <button className={styles.submit} onClick={handleSubmit}>
              <i className="bi bi-play-fill"></i>
              {botaoSalvar}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
}

export default NovaCategoria