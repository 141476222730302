import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const exportToPdf = (data) => {
  const tableBody = [];

  // Cria as linhas da tabela
  data.forEach((produto, index) => {
    tableBody.push([
      index + 1,
      `${produto.Produto}`,
      `${produto.Descricao}`,
      `${produto.Fabricante}`,
      `${produto.Sku}`,
    ]);
  });

  // Define a estrutura do documento PDF
  const docDefinition = {
    content: [
      { text: 'Lista de Produtos', style: 'header' },
      {
        table: {
          headerRows: 1,
          body: [
            ['#', 'Produto', 'Descricao', 'Fabricante', 'Sku', ],
            ...tableBody,
          ],
        },
        layout: 'lightHorizontalLines',
      },
    ],
    styles: {
      header: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 10],
      },
    },
  };

  // Gera o PDF e abre-o em uma nova janela do navegador
  pdfMake.createPdf(docDefinition).open();
};

export default exportToPdf;
