import { BrowserRouter } from "react-router-dom";
import { AuthProvider, AuthContext } from "./Services/AuthContext";
import { useContext } from "react";
import  Router from "./Routes/Router";


function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </BrowserRouter>
  );
}

function AppRoutes() {
  const { codemp } = useContext(AuthContext);
  return <Router />;
}

export default App;
