import styles from "./EditarUnidadeAlternativa.module.css";

import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../../Services/AuthContext";
import api, { setAuthToken } from "../../../Services/api";
import { Form } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../../Utils/stringCapitalizer";
import UnAlternativaTable from "../UnAlternativaTable/UnAlternativaTable";

const EditarUnidadeAlternativa = ({ codUnMedAlt }) => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const location = useLocation();
  const { codUnMed } = location.state;
  const [formValues, setFormValues] = useState({
    unidadeMedida: { codigo: "", descricao: "" },
    EditarUnidadeMedidaAlternativa: { codigo: "", descricao: "" },
  });
  const [unidadesMedida, setUnidadesMedida] = useState([]);
  const [tipo, setTipo] = useState(false);
  console.log(tipo);
  const [fator, setFator] = useState(1);
  const [quantidade, setQuantidade] = useState(0);
  const [controle, setControle] = useState("");
  const [statusUnAlternativa, setStatusUnAlternativa] = useState("");

  const [codProduto, setCodProduto] = useState("");

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    const fetchUnidadesMedida = async () => {
      try {
        const response = await api.get(`/unidademed/listar?CODEMP=${codemp}`);
        setUnidadesMedida(response.data);
        console.log("unidades", response.data);
      } catch (error) {
        console.error("Erro ao obter unidades de medida:", error);
      }
    };

    fetchUnidadesMedida();
  }, [codemp, codUnMed]);

  useEffect(() => {
    console.log("codigo uni", codUnMedAlt);
    api
      .get(
        `/unidademed/buscar/alt/?CODEMP=${Number(codemp)}&CODUNMEDALT=${Number(
          codUnMedAlt
        )}`
      )
      .then((response) => {
        console.log(response.data);
        setFormValues({
          unidadeMedida: {
            codigo: Number(response.data.CODUNMED),
          },
          EditarUnidadeMedidaAlternativa: {
            codigo: Number(response.data.CODUNMED),
          },
        });
        setCodProduto(response.data.CODPROD);
        setTipo(response.data.MULTIPLICACAO ? "Multiplica" : "Divide");
        setFator(response.data.VALORMULTDIV);
        setQuantidade(response.data.QUANTIDADE);
        setControle(response.data.CONTROLE);
        setStatusUnAlternativa(response.data.STATUS);
      })
      .catch((error) => {
        console.error("Erro ao obter unidade medida alternativa:", error);
      });
  }, [codemp, codUnMedAlt]);

  const handleSelectChange = (event, campo) => {
    setFormValues({
      ...formValues,
      [campo]: { codigo: event.target.value, descricao: "" },
    });
  };

  const handleEditarSubmit = (e) => {
    e.preventDefault();
    setErro("");

    // checar se há inputs vazios e setar erro
    if (
      formValues.EditarUnidadeMedidaAlternativa.codigo === "" ||
      tipo === "" ||
      quantidade === "" ||
      fator === "" ||
      capitalizeFirstLetter === ""
    ) {
      setModalMessage("Preencha todos os campos!");
      setShowMessageModal(true);
      return;
    }

    // criar objeto com novoMarca a ser enviado para o banco de dados
    const editarUnidadeAlternativa = {
      CODEMP: Number(codemp),
      CODUNMEDALT: codUnMedAlt,
      CODPROD: +codProduto,
      CODUNMED: +formValues.EditarUnidadeMedidaAlternativa.codigo,
      QUANTIDADE: quantidade,
      DIVISAO: tipo === "Divide",
      MULTIPLICACAO: tipo === "Multiplica",
      VALORMULTDIV: fator,
      CONTROLE: controle,
      STATUS: statusUnAlternativa,
    };
    console.log(editarUnidadeAlternativa);

    api
      .patch(`/unidademed/atualizar/alt`, editarUnidadeAlternativa)
      .then((response) => {
        console.log(
          "Unidade alternativa atualizada com sucesso:",
          response.data
        );
        setMessage("Unidade alternativa atualizada com sucesso!");
        setErro("");
        setShowMessage(true);
        setShowTable(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao atualizar unidade alternativa. Tente novamente!");
        }
        setMessage("");
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      });
  };

  const handleSelectFocus = () => {
    if (tipo === "") {
      setTipo("");
    }
  };

  const handleVoltar = () => {
    setShowTable(true);
  };

  return (
    <>
      {showTable ? (
        <UnAlternativaTable />
      ) : (
        <div className={styles.grid}>
          <Form className={styles.form}>
            <fieldset className={styles.fieldsetTopo}>
              <h1>Campos obrigatórios(*)</h1>
              {showMessage && (
                <div
                  className={`${styles.message} ${
                    message ? styles.successMessage : styles.errorMessage
                  }`}
                >
                  <p>{message || erro}</p>
                </div>
              )}
              <div className={styles.inputsFlex}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="unidadeMedida" className={styles.label}>
                    Unidade de Medida Padrão*
                  </Form.Label>
                  <Form.Control
                    as="select"
                    id="unidadeMedida"
                    value={formValues.unidadeMedida.codigo}
                    disabled
                    onChange={(e) => handleSelectChange(e, "unidadeMedida")}
                  >
                    <option value="">Selecione uma opção</option>
                    {unidadesMedida &&
                      unidadesMedida.map((unidade) => (
                        <option key={unidade.CODUNMED} value={unidade.CODUNMED}>
                          {capitalizeFirstLetter(unidade.TIPO)}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="unidadeMedida" className={styles.label}>
                    Unidade de Medida alternativa*
                  </Form.Label>
                  <Form.Control
                    as="select"
                    id="unidadeMedida"
                    value={formValues.EditarUnidadeMedidaAlternativa.codigo}
                    onChange={(e) =>
                      handleSelectChange(e, "EditarUnidadeMedidaAlternativa")
                    }
                  >
                    <option value="">Selecione uma opção</option>
                    {unidadesMedida &&
                      unidadesMedida.map((unidade) => (
                        <option key={unidade.CODUNMED} value={unidade.CODUNMED}>
                          {capitalizeFirstLetter(unidade.TIPO)}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="tipo" className={styles.label}>
                    Tipo*
                  </Form.Label>
                  <Form.Select
                    id="tipo"
                    value={tipo}
                    className="form-control"
                    onChange={(e) => setTipo(e.target.value)}
                    onFocus={handleSelectFocus}
                  >
                    <option value="">Selecione o tipo</option>
                    <option value="Divide">Divide</option>
                    <option value="Multiplica">Multiplica</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="fator" className={styles.label}>
                    Fator:
                  </Form.Label>
                  <Form.Control
                    id="fator"
                    type="number"
                    value={fator}
                    onChange={(e) => {
                      const newValue = Math.max(0, parseFloat(e.target.value));
                      setFator(newValue);
                    }}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="quantidade" className={styles.label}>
                    Quantidade*:
                  </Form.Label>
                  <Form.Control
                    id="quantidade"
                    type="number"
                    value={quantidade}
                    onChange={(e) => {
                      const newValue = Math.max(0, parseFloat(e.target.value));
                      setQuantidade(newValue);
                    }}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="controle" className={styles.label}>
                    Controle*:
                  </Form.Label>
                  <Form.Control
                    id="controle"
                    value={controle}
                    onChange={(e) => setControle(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className={styles.rodapeUniAlternativa}>
                <Form.Group className={styles.formCheck}>
                  <Form.Check
                    className={styles.checkbox}
                    type="checkbox"
                    label="Ativar Un. Alternativa"
                    checked={statusUnAlternativa}
                    onClick={() => setStatusUnAlternativa(!statusUnAlternativa)}
                  />
                </Form.Group>
                <div>
                  <button
                    className={`${styles.submit} ${styles.voltarButton}`}
                    type="button"
                    onClick={handleVoltar}
                  >
                    Voltar
                  </button>
                  <button
                    className={`${styles.submit} ${styles.salvarButton} `}
                    type="button"
                    onClick={handleEditarSubmit}
                  >
                    Salvar unidade alternativa
                  </button>
                </div>
              </div>
            </fieldset>
          </Form>
        </div>
      )}
    </>
  );
};

export default EditarUnidadeAlternativa;
