import CaixaConteudo from '../../Components/CaixaConteudo/CaixaConteudo';
import Menu from '../../Components/Menu/Menu';
import Cabecalho from '../../Components/Cabecalho/Cabecalho';
import EditarPerfil from './EditarPerfil';


const PerfilEmpresa = () => {
  
  const children = (
    <EditarPerfil/>
  )



  return (
    <>
    <Menu />
    <Cabecalho /> 
    <CaixaConteudo children={children}/>
    </>
  )
}

export default PerfilEmpresa;