// ESTILO
import styles from "./AddTabelaPreco.module.css";

// MODULOS
import Form from "react-bootstrap/Form";
import CurrencyInput from "react-currency-input-field";

// HOOKS
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useContext } from "react";
import { useState } from "react";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import MessageModal from "../../Components/MessageModal/MessageModal";

// LIBS

const AddTabelaPreco = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, codemp } = useContext(AuthContext);

  const [listaTabelas, setListaTabelas] = useState([]);
  const [tabelas, setTabelas] = useState("");
  const [tabelaSelecionada, setTabelaSelecionada] = useState("");

  const [moeda, setMoeda] = useState("");
  const [precoMinimo, setPrecoMinimo] = useState("");
  const [precoVenda, setPrecoVenda] = useState("");
  const [custo, setCusto] = useState("");
  const { codProd } = location.state;

  const [erro, setErro] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const formatNumberToAPI = (value) => {
    // Verifica se o valor é uma string
    if (typeof value === "string") {
      // Substitui a vírgula pelo ponto para obter o formato correto
      return value.replace(",", ".");
    }
    // Se o valor não for uma string, retorna o valor original
    return value;
  };

  useEffect(() => {
    const getTabelas = async () => {
      try {
        console.log("codemp:", codemp);
        const response = await api.get(
          `/tabela-precos/listar?CODEMP=${codemp}`
        );

        setListaTabelas(response.data);
        console.log("lista de tabelas", response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getTabelas();
  }, [codemp, token]);

  const prefix = () => {
    if (moeda === "dolar") {
      return "$";
    } else if (moeda === "euro") {
      return "€";
    } else if (moeda === "real") {
      return "R$";
    } else {
      return "";
    }
  };

  // TRATAR DADOS VINDO DO BOTÃO SALVAR
  const handleSubmit = async (e) => {
    e.preventDefault();

    setErro("");
    setIsLoading(true); // Inicia o feedback de carregamento

    if (
      moeda === "" ||
      precoMinimo === "" ||
      precoVenda === "" ||
      custo === ""
    ) {
      setModalMessage("Preencha todos os campos!");
      setShowMessageModal(true);
      return;
    }

    const dados = {
      CODTABPREC: +tabelaSelecionada,
      CODEMP: +codemp,
      QTDMINIMA: 1,
      CODPROD: +codProd,
      CUSTO: formatNumberToAPI(Number(custo)),
      PRECOVENDA: formatNumberToAPI(Number(precoVenda)),
      PRECOMINIMO: formatNumberToAPI(Number(precoMinimo)),
    };

    try {
      const response = await api.post(
        `/produtos/tabela-itens/cadastrar`,
        dados
      );
      console.log("cadastrado na tabela de preço", response.data);

      resetarInputs();

      setModalMessage("Produto cadastrado na tabela de preço com sucesso!");
      setIsSuccess(true);
      setShowMessageModal(true);
    } catch (error) {
      console.error("Erro ao cadastrar produto:", error);
      let errorMessage = "";
      if (error.response && error.response.status === 500) {
        errorMessage =
          "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
      } else {
        errorMessage = "Erro ao cadastrar produto na tabela de preço.";
      }
      setModalMessage(errorMessage);
      setIsSuccess(false);
      setShowMessageModal(true);
    } finally {
      setIsLoading(false); // Finaliza o feedback de carregamento, independentemente do resultado
    }
  };

  const resetarInputs = () => {
    setMoeda("");
    setPrecoMinimo("");
    setPrecoVenda("");
    setCusto("");
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/produtos");
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <h1>
              <i
                className="bi bi-arrow-left-circle-fill"
                onClick={() => {
                  navigate("/produtos");
                }}
              ></i>
              Adicionar Produto na Tabela de Preço
            </h1>
          </div>

          <div className={styles.corpo}>
            <Form className={styles.form} onSubmit={handleSubmit}>
              <Form.Group>
                <fieldset className={styles.fieldsetTabela}>
                  <div className={styles.inputsFlex}>
                    <div className={styles.tabela}>
                      <Form.Group>
                        <Form.Label htmlFor="tabelas" className={styles.label}>
                          <i class="bi bi-table"></i>
                          Tabela de Preço:
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          as="select"
                          id="tabelas"
                          value={tabelaSelecionada}
                          onChange={(e) => setTabelaSelecionada(e.target.value)}
                        >
                          <option value="">
                            Selecione uma Tabela de Preço
                          </option>
                          {listaTabelas &&
                            listaTabelas.map((tabela) => (
                              <option
                                key={tabela.CODTABPREC}
                                value={tabela.CODTABPREC}
                              >
                                {capitalizeFirstLetter(tabela.DESCRICAO)}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>
                    </div>
                  </div>

                  <div className={styles.inputsFlex}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="moeda">Moeda</Form.Label>
                      <Form.Select
                        className="form-control"
                        id="moeda"
                        value={moeda}
                        onChange={(e) => setMoeda(e.target.value)}
                      >
                        <option value="">Selecione uma moeda</option>
                        <option value="real">Real</option>
                        <option value="euro">Dólar</option>
                        <option value="euro">Euro</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="precoMinimo">
                        Preço Mínimo
                      </Form.Label>
                      <CurrencyInput
                        id="precoMinimo"
                        className="form-control"
                        prefix={prefix()}
                        value={precoMinimo}
                        onValueChange={(value) => setPrecoMinimo(value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="precoVenda">
                        Preço de Venda
                      </Form.Label>
                      <CurrencyInput
                        id="precoVenda"
                        className="form-control"
                        prefix={prefix()}
                        value={precoVenda}
                        onValueChange={(value) => setPrecoVenda(value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="custo">Custo</Form.Label>
                      <CurrencyInput
                        id="custo"
                        className="form-control"
                        prefix={prefix()}
                        value={custo}
                        onValueChange={(value) => setCusto(value)}
                      />
                    </Form.Group>
                  </div>
                </fieldset>
              </Form.Group>

              <div className={styles.rodape}>
                <button
                  className={styles.submit}
                  type="submit" // Alterado para 'submit'
                  disabled={isLoading} // Desabilita o botão durante a requisição
                >
                  {isLoading ? (
                    <>
                      <i className="bi bi-play-fill"></i>
                      Salvando...
                    </>
                  ) : (
                    <>
                      <i className="bi bi-play-fill"></i>
                      Salvar
                    </>
                  )}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </CaixaConteudo>

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default AddTabelaPreco;
