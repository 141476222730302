import $ from "jquery";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import api, { setAuthToken } from "../../../Services/api";
import { AuthContext } from "../../../Services/AuthContext";
import { capitalizeFirstLetter } from "../../../Utils/stringCapitalizer";

import styles from "./ModalTransportadora.module.css";
import axios from "axios";

const TransportadoraModal = ({ onSelect, onClose, onClear,onLoading }) => {
  const { token, codemp } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [transportadoras, setTransportadoras] = useState([]);
  const [selectedTransportadoras, setSelectedTransportadoras] = useState(null);
  const [showError, setShowError] = useState(false);
  const tableRef = useRef(null);
  const [semDados, setSemDados] = useState('');

  useEffect(() => {
    setAuthToken(token);
  }, [token]);
  // useEffect(() => {
  //   setAuthToken(token);
  //   onLoading(true,'transportadora')
  //   api
  //     .get(`/transportadora/listar?CODEMP=${codemp}`)
  //     .then((response) => {
  //       setTransportadoras(response.data);
  //       setShowModal(true);
  //     })
  //     .catch((error) => {
  //       console.error("Erro ao obter transportadora:", error);
  //     })
  //     .finally(() =>{
  //       onLoading(false,'transportadora')
  //     });
  // }, [codemp, token]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    setTransportadoras([])
    setSemDados('')
    onLoading(true,'transportadora')
    const getTransportadoras = async () => {
      try {
        const response = await api.get(`/transportadora/listar?CODEMP=${CODEMP}`, {
          cancelToken: source.token
        });
        if(response.data.length === 0 ){
          setSemDados("Sem dados")
        } else {
          setTransportadoras(response.data);
        }
        setShowModal(true);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
      } finally {
        onLoading(false,'transportadora')
      }
    };
    getTransportadoras();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp]);

  useEffect(() => {
    if (
      transportadoras.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
      });
    }
  }, [transportadoras]);

  const handleClose = () => {
    console.log("handleClose chamado. Modal será fechado.");
    onClose();
  };

  const clearSelection = () => {
    setSelectedTransportadoras(null);
    handleClose();

    if (onClear) {
      onClear();
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        size="xl"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title className={styles["modal-title"]}>
            Selecione uma Transportadora
          </Modal.Title>
          <Button variant="none" className="btn btn-none" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Modal.Header>
        <Modal.Body style={{ overflow: "auto" }}>
          <table
            ref={tableRef}
            className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
          >
            <thead>
              <tr>
                <th className="text-center">Selecionar</th>
                <th className="text-center">Código</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Cpf/Cnpj</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">
                  <button
                    className={styles.select}
                    type="button"
                    onClick={clearSelection}
                  >
                    <i className={`bi bi-circle`}></i>
                  </button>
                </td>
                <td className="text-center">0</td>
                <td className="text-center">-</td>
                <td className="text-center">-</td>
                <td className="text-center">-</td>
              </tr>

              {semDados === 'Sem dados' ? <tr>
                <td style={{ textAlign: "center" }} colSpan="8">
                A tabela está vazia no momento.
                </td>
              </tr> : transportadoras.map((transportadora, index) => (
                <tr key={index}>
                  <td className="text-center">
                    <button
                      className={styles.select}
                      type="button"
                      onClick={() => {
                        if (!transportadora || !transportadora.Pessoa) {
                          console.error(
                            "Informações da transportadora incompletas."
                          );
                          return;
                        }

                        const newSelectedTransportadora = {
                          CODTRANS: transportadora.CODTRANS,
                          NOME: transportadora.Pessoa.NOME,
                          SOBRENOME: transportadora.Pessoa.SOBRENOME,
                          CPFCNPJ: transportadora.Pessoa.CPFCNPJ,
                        };

                        if (
                          selectedTransportadoras &&
                          selectedTransportadoras.CODTRANS ===
                            newSelectedTransportadora.CODTRANS
                        ) {
                          console.log(
                            "Transportadora já selecionada. Removendo da seleção."
                          );
                          setSelectedTransportadoras(null);
                        } else {
                          console.log(
                            "Transportadora não estava selecionada. Adicionando à seleção.",
                            newSelectedTransportadora
                          );
                          setSelectedTransportadoras(newSelectedTransportadora);

                          // Lógica para salvar aqui
                          setShowError(false);
                          console.log(
                            `Salvando: ${newSelectedTransportadora.NOME} ${newSelectedTransportadora.SOBRENOME}, CODTRANS: ${newSelectedTransportadora.CODTRANS}`
                          );
                          onSelect(newSelectedTransportadora);
                          handleClose(); // Feche o modal após salvar
                        }
                      }}
                    >
                      <i
                        className={`bi ${
                          selectedTransportadoras &&
                          selectedTransportadoras.CODTRANS ===
                            transportadora.CODTRANS
                            ? "bi-check-circle-fill"
                            : "bi-circle"
                        }`}
                      ></i>
                    </button>
                  </td>
                  <td className="text-center">{transportadora.CODTRANS}</td>
                  <td className="text-center">{`${capitalizeFirstLetter(
                    transportadora.Pessoa.NOME
                  )} ${capitalizeFirstLetter(
                    transportadora.Pessoa.SOBRENOME
                  )}`}</td>
                  <td className="text-center">{transportadora.Pessoa.EMAIL}</td>
                  <td className="text-center">
                    {transportadora.Pessoa.CPFCNPJ}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        {showError && (
          <p className={styles.errorContainer} style={{ color: "red" }}>
            ⚠️Selecione uma transportadora antes de salvar
          </p>
        )}
      </Modal>
    </>
  );
};

export default TransportadoraModal;
