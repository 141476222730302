import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";

import styles from "./Meta.module.css";
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

const Metas = () => {
  const navigate = useNavigate();
  const [metas, setMetas] = useState([]);
  const { token, codemp } = useContext(AuthContext);
  const tableRef = useRef(null);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    const fetchMetas = async () => {
      try {
        const response = await api.get(`/metas/listar?CODEMP=${codemp}`);
        setMetas(response.data);
        console.log("retorno", response.data);
      } catch (error) {
        console.error("Erro ao buscar metas:", error);
      }
    };
    fetchMetas();
  }, [codemp]);

  useEffect(() => {
    if (
      metas.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
      });
    }
  }, [metas]);

  const EditarMetas = (CODMETA) => {
    navigate("/editar-metas", { state: { CODMETA: CODMETA } });
  };

  const showTable = () => {
    try {
      return metas.map((item) => (
        <tr key={item.CODMETA}>
          <td style={{width: '50px'}} className="text-center">
            <i
              className="bi bi-pen"
              onClick={() => EditarMetas(item.CODMETA)}
            ></i>
          </td>
          <td className="text-center">{item.CODMETA}</td>
          <td className="col-2 text-center">
            {capitalizeFirstLetter(item.META)}
          </td>
          <td className="text-center">
            {item.MENSAL.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </td>
          <td className="text-center">
            {item.ANUAL.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </td>
          <td className="text-center">
            <div className={styles.buttonContainer}>
              <button
                className={styles.button}
                onClick={() =>
                  navigate("/adicionar-vendedor-meta", {
                    state: { CODMETA: item.CODMETA },
                  })
                }
              >
                <i className="bi bi-plus"></i>
              </button>
            </div>
          </td>

        </tr>
      ));
    } catch (e) {
      alert(e.message);
    }
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <div className={styles.topoButtons}>
          <button onClick={() => navigate("/nova-meta")}>
            Adicionar Metas
          </button>
        </div>
      </div>
      <div className={styles.corpo}>
        <div className={`container-fluid py-4 ${styles.boxTable}`}>
          <div className="table-responsive p-0 pb-2">
            <h1 className={styles.titleTable}>Metas</h1>
            <table
              ref={tableRef}
              id="tableMeta"
              className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
            >
              <thead>
                <tr>
                  <th className="text-center">Ação</th>
                  <th className="text-center">Código</th>
                  <th className="col-2 text-center">Meta</th>
                  <th className="text-center">Valor Mensal</th>
                  <th className="text-center">Valor Anual</th>
                  <th className="col-2 text-center"> Adicionar Vendedores </th>

                </tr>
              </thead>
              <tbody>{showTable()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
    </>
  );
};

export default Metas;
