// ESTILO
import styles from "./EditarTabelaPreco.module.css";

// MODULOS
import Form from "react-bootstrap/Form";
import { Tabs, Tab } from "react-bootstrap";

// HOOKS
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useContext, useRef } from "react";
import { useState } from "react";

// COMPONENTES
import Cabecalho from "../../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../../Components/Menu/Menu";
import { AuthContext } from "../../../Services/AuthContext";
import api, { setAuthToken } from "../../../Services/api";
import { capitalizeFirstLetter } from "../../../Utils/stringCapitalizer";
import { formatarValor } from "../../../Utils/formatarValor";
import MessageModal from "../../../Components/MessageModal/MessageModal";
import ModalProdutosItensTabela from "./ModalProdutoItensTabela";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import { validarCamposVazios } from "../../../Utils/validarCamposVazios";

// LIBS

const EditarTabelaPreco = () => {
  const { token, codemp } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const codTabPrec = searchParams.get('codTabPrec');
  const tableRef = useRef(null);
  const [dataTableInitialized, setDataTableInitialized] = useState(false);

  const [descricao, setDescricao] = useState("");
  const [dtVigor, setDtVigor] = useState("");
  const [tabPadrao, setTabPadrao] = useState("");
  const [tabDesconto, setTabDesconto] = useState("");
  const [itensTabela, setItensTabela] = useState([]);
  const [status, setStatus] = useState(false);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [loading, setLoading] = useState(false)
  const [botaoSalvar,setBotaoSalvar] = useState("Salvar")

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    const getTabelaPreco = async () => {
      const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
      setLoading(true)
      try {
        const response = await api.get(
          `/tabela-precos/buscar?CODTABPREC=${codTabPrec}&CODEMP=${CODEMP}`
        );
        const tabela = response.data;
        console.log("tabela", tabela);
        setDescricao(tabela.DESCRICAO);
        const date = new Date(tabela.DTVIGOR);
        date.setHours(date.getHours() + 3); // Por exemplo, ajuste para UTC+3
        const formattedDate = date
          .toLocaleDateString("pt-BR")
          .split("/")
          .reverse()
          .join("-");
        setDtVigor(formattedDate);
        setTabPadrao(tabela.TABPADRAO === "S" ? "S" : "N");
        setTabDesconto(tabela.TABDESCONTO === "S" ? "S" : "N");
        setItensTabela(tabela.ItensTabela);
        setStatus(tabela.STATUS);
        setLoading(false)
      } catch (error) {
        console.log(error);
        setLoading(false)
      }
    };
    getTabelaPreco();
  }, [codTabPrec, token]);

  const removerItem = (CODPROD) => {
    const novaLista = itensTabela.filter((item) => item.CODPROD !== CODPROD);
    setItensTabela(novaLista);
  };

  const handleAtualizarEstado = () => {
    setStatus((prevStatus) => !prevStatus);
  };

  const handleProductsSelected = (selectedProducts) => {
    const updatedItems = [...itensTabela];
    selectedProducts.forEach((prod) => {
      if (!updatedItems.some((item) => item.CODPROD === prod.CODPROD)) {
        updatedItems.push({
          ...prod.produto,
          CUSTO: '',
          PRECOVENDA: '',
          PRECOMINIMO: '',
          QTDMINIMA: 1,
        });
      }
    });

    setItensTabela(updatedItems);
  };

  const handleOpenModal = (modalType) => {
    setShowModal(modalType);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dadosObrigatorios = {
      'Descriçao': descricao,
      'Data de Vigor': dtVigor,
    }

    if(validarCamposVazios(dadosObrigatorios).length > 0) {
      setModalMessage(`Preencha os campos obrigatorios: ${validarCamposVazios(dadosObrigatorios)}!`);
      setShowMessageModal(true);
      return;
    } 

    for (const item of itensTabela) {
      if (
        !item.CODPROD ||
        item.CUSTO === '' ||
        item.CUSTO === 0 ||
        item.PRECOVENDA === '' ||
        item.PRECOVENDA === 0 ||
        item.PRECOMINIMO === '' ||
        item.PRECOMINIMO === 0
      ) {
        setModalMessage(
          "Todos os itens da tabela devem ter Preço Mínimo, Preço Venda e Custo preenchidos e maiores do que zero."
        );
        setShowMessageModal(true);
        return;
      }
    }

    const itensParaEnviar = itensTabela.map((item) => ({
      CODPROD: item.CODPROD,
      QTDMINIMA: item.QTDMINIMA,
      CUSTO: item.CUSTO,
      PRECOVENDA: item.PRECOVENDA,
      PRECOMINIMO: item.PRECOMINIMO,
      QTDMINIMA: item.QTDMINIMA,
    }));

    const novaTabelaPrecos = {
      CODEMP: Number(codemp),
      CODTABPREC: Number(codTabPrec),
      DESCRICAO: descricao,
      DTVIGOR: new Date(dtVigor).toISOString(),
      TABPADRAO: tabPadrao,
      TABDESCONTO: tabDesconto,
      STATUS: status,
      ItensTabela: itensParaEnviar,
    };

    setBotaoSalvar("Carregando...")

    api
      .patch("/tabela-precos/atualizar", novaTabelaPrecos)
      .then((response) => {
        console.log("Tabela de preço atualizada com sucesso:", response.data);
        setModalMessage("Tabela de preço atualizada com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
        setBotaoSalvar("Salvar")
      })
      .catch((error) => {
        let errorMessage = "Erro desconhecido ao atualizar Tabela de preço.";

        if (error.response) {
          if (error.response.status === 409) {
            errorMessage = "Conflito: " + error.response.data.message;
          } else if (error.response.status === 500) {
            errorMessage =
              "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
          } else {
            errorMessage =
              error.response.data.message ||
              "Erro ao atualizar Tabela de preço. Tente novamente!";
          }
        }

        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
        setBotaoSalvar("Salvar")
      });
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
    setShowModal(null);
  };
  const formatarMoeda = (valor) => {
    return valor.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const desformatarMoeda = (valor) => {
    return Number(valor.replace(/[^0-9,-]+/g, "").replace(",", "."));
  };
  const handleInputChange = (index, field, value) => {
    const updatedItems = [...itensTabela];
    updatedItems[index] = {
      ...updatedItems[index],
      [field]: value,
    };
    setItensTabela(updatedItems);
  };

  const handleInputBlur = (index, field, value) => {
    const updatedItems = [...itensTabela];
    updatedItems[index] = {
      ...updatedItems[index],
      [field]: desformatarMoeda(value),
    };
    setItensTabela(updatedItems);
  };

  const handleRedirecionar = () => {
    navigate("/tabela-preco");
  };


  useEffect(() => {
    if (itensTabela.length > 0 && tableRef.current && !dataTableInitialized) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros"
        },
        lengthChange: false,
        pageLength: 10,
        destroy: true,
        ordering: true,
        order:[1,'asc']
      });
      setDataTableInitialized(true);
    }
  }, [itensTabela, dataTableInitialized]);

  const setarItensTabela = () => {
    return itensTabela.map((item, index) => {
      console.log(itensTabela);
      return (
        <tr key={item.CODPROD}>
        <td className={styles.icone} colSpan="1">
          {item.CODITEMTABSK && item.CODITEMTABSK > 0 ? null : <i
            className="bi bi-trash-fill"
            onClick={() => removerItem(item?.CODPROD)}
          ></i>}
        </td>
        <td>{item.CODPROD}</td>
        <td>
          {capitalizeFirstLetter(
            item?.PRODUTO || item.produto?.PRODUTO
          )}
        </td>
        <td>
          {capitalizeFirstLetter(
            item?.FABRICANTE || item.produto?.FABRICANTE
          )}
        </td>
        <td className="text-center">
        <input
          type="text"
          value={item.QTDMINIMA !== undefined ? item.QTDMINIMA : ''}
          onChange={(e) => handleInputChange(index, 'QTDMINIMA', e.target.value)}
          onBlur={(e) => handleInputBlur(index, 'QTDMINIMA', e.target.value)}
        />
      </td>

        <td className="text-center">
          <input
            type="text"
            value={
              item.PRECOMINIMO !== undefined
                ? formatarMoeda(item.PRECOMINIMO)
                : ""
            }
            onChange={(e) =>
              handleInputChange(
                index,
                "PRECOMINIMO",
                e.target.value
              )
            }
            onBlur={(e) =>
              handleInputBlur(
                index,
                "PRECOMINIMO",
                e.target.value
              )
            }
          />
        </td>
        <td className="text-center">
          <input
            type="text"
            value={
              item.PRECOVENDA !== undefined
                ? formatarMoeda(item.PRECOVENDA)
                : ""
            }
            onChange={(e) =>
              handleInputChange(
                index,
                "PRECOVENDA",
                e.target.value
              )
            }
            onBlur={(e) =>
              handleInputBlur(
                index,
                "PRECOVENDA",
                e.target.value
              )
            }
          />
        </td>
        <td className="text-center">
          <input
            type="text"
            value={
              item.CUSTO !== undefined
                ? formatarMoeda(item.CUSTO)
                : ""
            }
            onChange={(e) =>
              handleInputChange(
                index,
                "CUSTO",
                e.target.value
              )
            }
            onBlur={(e) =>
              handleInputBlur(index, "CUSTO", e.target.value)
            }
          />
        </td>
        
      </tr>
      );
    });
  };
  
  // Exemplo de como usar a função ordenarTabelaPreco
  const showTable = () => (
    <div className={styles.boxTable}>
      <table
        ref={tableRef}
        id="tablePedidos"
        className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
      >
        <thead>
          <tr>
            <th className="text-center">Ação</th>
            <th>Cód. Produto</th>
            <th>Produto</th>
            <th>Fabricante</th>
            <th className="text-center">Qtd. Mínima</th>
            <th className="text-center">Preço Mínimo</th>
            <th className="text-center">Preço de Venda</th>
            <th className="text-center">Custo</th>
          </tr>
        </thead>
        <tbody>
          {setarItensTabela()}
        </tbody>
      </table>
    </div>
  );

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            class="bi bi-arrow-left-circle-fill"
            onClick={() => navigate("/tabela-preco")}
          ></i>
          Editar Tabela de Preço
        </h1>
      </div>

      <div className={styles.corpo}>
        <Form className={styles.form} id="novaTabelaPrecosForm">
          <Tabs defaultActiveKey="dadosTabela" className={styles.abas}>
            <Tab eventKey="dadosTabela" title="Dados Tabela">
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <p style={{fontSize:'.7rem'}}>Campos obrigatórios(*)</p>
              {loading && <p style={{color: 'red'}}>Carregando...</p>}
            </div>
              <fieldset className={styles.fieldsetTopo}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="descricao" className={styles.label}>
                    Descrição*:
                  </Form.Label>
                  <Form.Control
                    id="descricao"
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="dtVigor" className={styles.label}>
                    Data de Vigor*:
                  </Form.Label>
                  <Form.Control
                    type="date"
                    id="dtVigor"
                    value={dtVigor}
                    onChange={(e) => setDtVigor(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="tabPadrao" className={styles.label}>
                    Tabela Padrão?*
                  </Form.Label>
                  <div className={styles.statusFlex}>
                    <Form.Check
                      label="Sim"
                      name="tabPadrao"
                      type="radio"
                      id="simTabPad"
                      value={tabPadrao}
                      onClick={(e) => setTabPadrao(e.target.value)}
                      checked={tabPadrao === "S"}
                    />
                    <Form.Check
                      label="Não"
                      name="tabPadrao"
                      type="radio"
                      id="naoTabPad"
                      value={tabPadrao}
                      className={`${styles.check} is-valid`}
                      onClick={(e) => setTabPadrao(e.target.value)}
                      checked={tabPadrao === "N"}
                    />
                  </div>
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="tabDesconto" className={styles.label}>
                    Tabela de Desconto?*
                  </Form.Label>
                  <div className={styles.statusFlex}>
                    <Form.Check
                      label="Sim"
                      name="tabDesconto"
                      type="radio"
                      id="simTabDesc"
                      value={tabDesconto}
                      onClick={(e) => setTabDesconto(e.target.value)}
                      checked={tabDesconto === "S"}
                    />
                    <Form.Check
                      defaultChecked
                      label="Não"
                      name="tabDesconto"
                      type="radio"
                      id="naoTabDesc"
                      value={tabDesconto}
                      className={`${styles.check} is-valid`}
                      onClick={(e) => setTabDesconto(e.target.value)}
                      checked={tabDesconto === "N"}
                    />
                  </div>
                </Form.Group>
              </fieldset>
            </Tab>
            <Tab className={styles.tabItens} eventKey="ItensTabela" title="Itens Tabela">
              <fieldset className={styles.fieldsetTopo1}>
                <button
                  type="button"
                  onClick={() => handleOpenModal("produtos")}
                  className={`customSelectBtn ${styles.customSelectBtn}`}
                >
                  Adicionar Produtos
                </button>

                {/* Modal para seleção de produtos */}
                {showModal === "produtos" && (
                  <ModalProdutosItensTabela
                    onProductsSelected={handleProductsSelected}
                    selectedProducts={itensTabela}
                    onClose={handleCloseModal}
                  />
                )}
                {showTable()}
                {/* <div className={styles.boxTable}>
                  <table
                    id="tablePedidos"
                    className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                  >
                    <thead>
                      <tr>
                        <th className="text-center">Ação</th>
                        <th>Produto</th>
                        <th>Fabricante</th>
                        <th className="text-center">Qtd. Mínima</th>
                        <th className="text-center">Preço Mínimo</th>
                        <th className="text-center">Preço de Venda</th>
                        <th className="text-center">Custo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itensTabela.map((item, index) => (
                        <tr key={item.CODPROD}>
                          <td className={styles.icone} colSpan="1">
                            {item.CODITEMTABSK === 0 ? <i
                              className="bi bi-trash-fill"
                              onClick={() => removerItem(item?.CODPROD)}
                            ></i> : null}
                          </td>
                          <td>
                            {capitalizeFirstLetter(
                              item?.PRODUTO || item.produto?.PRODUTO
                            )}
                          </td>
                          <td>
                            {capitalizeFirstLetter(
                              item?.FABRICANTE || item.produto?.FABRICANTE
                            )}
                          </td>
                          <td className="text-center">
                          <input
                            type="text"
                            value={item.QTDMINIMA !== undefined ? item.QTDMINIMA : ''}
                            onChange={(e) => handleInputChange(index, 'QTDMINIMA', e.target.value)}
                            onBlur={(e) => handleInputBlur(index, 'QTDMINIMA', e.target.value)}
                          />
                        </td>

                          <td className="text-center">
                            <input
                              type="text"
                              value={
                                item.PRECOMINIMO !== undefined
                                  ? formatarMoeda(item.PRECOMINIMO)
                                  : ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "PRECOMINIMO",
                                  e.target.value
                                )
                              }
                              onBlur={(e) =>
                                handleInputBlur(
                                  index,
                                  "PRECOMINIMO",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="text"
                              value={
                                item.PRECOVENDA !== undefined
                                  ? formatarMoeda(item.PRECOVENDA)
                                  : ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "PRECOVENDA",
                                  e.target.value
                                )
                              }
                              onBlur={(e) =>
                                handleInputBlur(
                                  index,
                                  "PRECOVENDA",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="text"
                              value={
                                item.CUSTO !== undefined
                                  ? formatarMoeda(item.CUSTO)
                                  : ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "CUSTO",
                                  e.target.value
                                )
                              }
                              onBlur={(e) =>
                                handleInputBlur(index, "CUSTO", e.target.value)
                              }
                            />
                          </td>
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div> */}
              </fieldset>
            </Tab>
          </Tabs>

          <div className={styles.rodape}>
            <Form.Group className={styles.formCheck}>
              <Form.Check
                className={styles.checkbox}
                type="checkbox"
                label="Ativar"
                checked={status}
                onClick={handleAtualizarEstado}
              />
            </Form.Group>
            <button
              className={`${styles.submit} ${styles.salvarButton}`}
              onClick={handleSubmit}
            >
              <i className="bi bi-play-fill"></i>
              {botaoSalvar}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default EditarTabelaPreco;
