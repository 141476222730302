import React from "react";
import { Modal, Button } from "react-bootstrap";
import styles from './MessageModal.module.css';

const MessageModal = ({ show, message, onClose, isSuccess, onSuccess, erroImportar }) => {

  const handleOKClick = () => {
    onClose();
    if (isSuccess || erroImportar) {
      onSuccess()
    }
  };

  const messageClassName = isSuccess
    ? styles.successMessage
    : styles.errorMessage;

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header>
        <Modal.Title>Mensagem</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={`${styles.message} ${messageClassName}`}>
          {message}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleOKClick}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageModal;
