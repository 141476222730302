import styles from "./EditarUsuario.module.css";
import Form from "react-bootstrap/Form";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import InputMask from "react-input-mask";

import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import React, { useContext } from "react";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import $ from 'jquery'

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import MessageModal from "../../Components/MessageModal/MessageModal";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";

const EditarUsuario = () => {
  const { token, codemp } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { codusu } = location.state;
  const { codpes } = location.state;

  const [codigoSelected, setCodigoSelected] = useState("");
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");

  const [cpf, setCpf] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cpfOuCnpj, setcpfOuCnpj] = useState("");

  const [telefone, setTelefone] = useState("");
  const [cargo, setCargo] = useState("");
  const [pessoa, setPessoa] = useState(null);
  const [novoCargo, setNovoCargo] = useState("");
  const [email, setEmail] = useState("");

  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [codEnd, setCodEnd] = useState("");
  const [codDet, setCodDet] = useState("");
  const [complemento, setComplemento] = useState("");
  const [referencia, setReferencia] = useState("");

  const [erro, setErro] = useState("");
  const [showAtualizarEstadoModal, setShowAtualizarEstadoModal] =
    useState(false);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [status, setStatus] = useState("");
  const [statusCheckbox, setStatusCheckbox] = useState(false);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    if (codusu !== 0) {
      api
        .get(`/usuarios/buscar/?CODEMP=${codemp}&CODUSU=${codusu}`)
        .then(async (response) => {
          setPessoa(response.data);
          console.log("aaaaaaaaaa", response.data);
          setNome(capitalizeFirstLetter(response.data.Pessoa.NOME));
          setSobrenome(capitalizeFirstLetter(response.data.Pessoa.SOBRENOME));
          const userCpfCnpj = response.data.Pessoa.CPFCNPJ;
          setCpf(userCpfCnpj);
          setCnpj(userCpfCnpj);
          setTelefone(response.data.Pessoa.TELEFONE);
          setCargo(capitalizeFirstLetter(response.data.Pessoa.CARGO));
          setEmail(response.data.EMAIL);
          setCodDet(response.data.Pessoa.CODDET);
          setStatus(response.data.ESTADO ? true : false);
          console.log(status);
          setCodigoSelected(response.data.CODUSU);

          if (userCpfCnpj && userCpfCnpj.length === 14) {
            setcpfOuCnpj("cpf");
          } else if (userCpfCnpj && userCpfCnpj.length === 18) {
            setcpfOuCnpj("cnpj");
          }

          if (codDet !== null) {
            try {
              const enderecoResponse = await api.get(
                `/enderecos/buscar/codend/?CODDET=${codDet}`
              );
              console.log(enderecoResponse.data);

              if (enderecoResponse.data && enderecoResponse.data.Endereco) {
                setCep(enderecoResponse.data.Endereco.CEP);
                setEstado(enderecoResponse.data.Endereco.ESTADO);
                setCidade(enderecoResponse.data.Endereco.CIDADE);
                setBairro(enderecoResponse.data.Endereco.BAIRRO);
                setEndereco(
                  `${enderecoResponse.data.Endereco.TIPOEND} ${enderecoResponse.data.Endereco.NOMEEND}`
                );
                setNumero(enderecoResponse.data.NUMERO);
                setReferencia(enderecoResponse.data.REFERENCIA);
                setComplemento(enderecoResponse.data.COMPLEMENTO);
              }
            } catch (enderecoError) {
              console.error("Erro ao obter endereço:", enderecoError);
            }
          } else {
            console.log("Endereço não encontrado");
          }
        })
        .catch((error) => {
          console.error("Erro ao obter usuário:", error);
        });
    }
  }, [codemp, codusu, codDet]);

  const handleCepChange = async (event) => {
    const newCep = event.target.value.replace(/\D/g, "");
    setCep(newCep);

    try {
      if (newCep.length === 8) {
        const response = await api.get(`/enderecos/buscar?CEP=${newCep}`);
        const enderecoData = response.data;
        setCodEnd(enderecoData.CODEND);
        setEstado(enderecoData.ESTADO);
        setCidade(enderecoData.CIDADE);
        setBairro(enderecoData.BAIRRO);
        setEndereco(`${enderecoData.TIPOEND} ${enderecoData.NOMEEND}`);
      } else {
        setCodEnd("");
        setEstado("");
        setCidade("");
        setBairro("");
        setEndereco("");
        setNumero("");
      }
    } catch (error) {
      console.log("API error:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      (cpfOuCnpj === "cpf" && (cpf === "" || cpf.length !== 14)) ||
      (cpfOuCnpj === "cnpj" && (cnpj === "" || cnpj.length !== 18)) ||
      cargo === "" ||
      email === "" ||
      nome === "" ||
      sobrenome === "" ||
      telefone === "" ||
      cep === "" ||
      endereco === "" ||
      numero === "" ||
      bairro === "" ||
      cidade === "" ||
      estado === ""
    ) {
      setModalMessage("Preencha todos os campos!");
      setShowMessageModal(true);
      return;
    }

    let cargoEnviar = cargo;
    if (cargo === "Outro" && novoCargo !== "") {
      // Verifica se a opção "Outro" foi selecionada e se o campo novoCargo não está vazio
      cargoEnviar = novoCargo; // Atualiza a variável cargoEnviar com o valor digitado no campo novoCargo
    }

    // const statusEnviar = status === "True";

    const usuarioAtualizado = {
      CODEMP: codemp,
      CODPES: codpes,
      Pessoa: {
        NOME: nome,
        SOBRENOME: sobrenome,
        CPFCNPJ: cpfOuCnpj === "cnpj" ? cnpj : cpf,
        TIPO: cpfOuCnpj === "cnpj" ? "J" : "F",
        TELEFONE: telefone,
        CARGO: cargoEnviar,
        EMAIL: email,
      },
      Endereco: {
        CODEND: codEnd,
        NUMERO: numero,
        REFERENCIA: referencia,
        COMPLEMENTO: complemento,
      },
    };
    if (statusCheckbox) {
      handleConfirmEstado();
    }
    console.log("atualizar estado", statusCheckbox);
    console.log("usuarioAtualizado:", usuarioAtualizado);
    api
      .patch(`/pessoas/editar/`, usuarioAtualizado)
      .then((response) => {
        console.log("Usuário atualizado com sucesso:", response.data);
        setModalMessage("Usuário atualizado com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        console.error("Erro ao atualizar usuário:", error);
        let errorMessage = "";
        if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao atualizar usuário.Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
      });
  };

  const handleAtualizarEstado = () => {
    setStatus();
    if (status === true) {
      setStatus(false);
    } else {
      setStatus(true);
    }
    setStatusCheckbox(true);
    console.log("aquiii", status);
  };

  const handleConfirmEstado = () => {
    const requestData = {
      CODEMP: Number(codemp),
      CODUSU: Number(codusu),
    };
    console.log("requestData:", requestData);
    api
      .delete(`/usuarios/remover`, { data: requestData })
      .then((response) => {
        console.log("Usuário desativado com sucesso:", response.data);
      })
      .catch((error) => {
        console.error("Erro ao desativar usuário:", error);
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao desativar usuário.Tente novamente!");
        }
        setMessage("");
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
        console.log("codusu:", codusu, "codemp:", codemp);
      });
  };

  // const handleCloseAtualizarEstadoModal = () => {
  //   setShowAtualizarEstadoModal(false);
  // };

  // Função para alternar entre Pessoa Física e Pessoa Jurídica
  const handleTipoPessoaChange = (e) => {
    const selectedValue = e.target.value;
    setcpfOuCnpj(selectedValue);
    if (selectedValue === "cpf") {
      setCpf("");
    } else if (selectedValue === "cnpj") {
      setCnpj("");
    }
  };

  const handleSelectFocus = () => {
    if (cargo === "" || pessoa === "") {
      setCargo("");
    }
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    
    if(!tableRef.current){
      navigate("/meu-plano");
    }
  };

  const handleEmailChange = (event) => {
    const email = event.target.value.trim();
    const emailRegex = /^[^@]+@[^@]+\.[^@]+$/; // Regex para validar o e-mail

    setEmail(email);

    if (!emailRegex.test(email)) {
      setErro("E-mail inválido!");
    } else {
      setErro("");
    }
  };


//-------------------------------------------------------------------

const handleApiError = (error, customMessage) => {
    console.error(error);
    console.log(`${customMessage}: ${error.message}`);
    console.log("Mensagem de erro da API:", error.response.data);
  };

  const tableRef = useRef(null);
  const [carregando, setCarregando] = useState(true);
  const [dispositivos, setDispositivos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (codemp) {
        try {
          const response = await api.get(`/sessao/listar/perm?CODEMP=${codemp}&CODUSU=${codusu}`);
          setDispositivos(response.data);
          console.log(response.data);
        } catch (error) {
          handleApiError(error, "Houve um erro ao buscar os acessos");
        } finally {
          setCarregando(false);
        }
      }
    };
  
    fetchData();
  }, [codemp]); 

  useEffect(() => {
    if (
      Object.keys(dispositivos).length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        //destroy: true,
        ordering: true,
        order: [[6, 'desc'],[1,'desc']]
      });
    }
  }, [dispositivos]);

const aprovarReprovarDispositivo = (codigo, permissao) => {

  const targetIndex = dispositivos.findIndex(dispositivo => dispositivo.CODACEPER === codigo);

  if (targetIndex !== -1) {
    const updatedDispositivos = [...dispositivos];  // Cria uma cópia do array para não modificar o estado diretamente
    updatedDispositivos[targetIndex].PERMISSAO = !permissao;
  
    setDispositivos(updatedDispositivos);
  }
  
  try {
    const objectPermissao = {
      CODEMP: codemp,
      CODACEPER: codigo,
      PERMISSAO: !permissao
  }

    const response = api.patch(`/sessao/permissao?CODEMP=${codemp}`, objectPermissao);
    console.log(response.data);
    setModalMessage(`Dispositivo ${permissao ? "reprovado" : "aprovado"} com sucesso!`)
    setShowMessageModal(true)
    setIsSuccess(true)
  } catch (error) {
    handleApiError(error, `Houve um erro ao ${permissao ? "reprovar" : "aprovar"} dispositivo.`);
    setModalMessage(`Houve um erro ao ${permissao ? "reprovar" : "aprovar"} dispositivo.`)
    setShowMessageModal(true)
    setIsSuccess(false)
  } finally {
    setCarregando(false);
  }
}



//navigate("/editar-usuario", { state: { codusu, codpes } });

function formatarData(dataString) {
  const data = new Date(dataString);
  
  const hh = String(data.getHours()).padStart(2, '0');
  const mm = String(data.getMinutes()).padStart(2, '0');
  const ss = String(data.getSeconds()).padStart(2, '0');
  const dd = String(data.getDate()).padStart(2, '0');
  const mmFormatado = String(data.getMonth() + 1).padStart(2, '0'); // Os meses em JavaScript são baseados em zero
  const aaaa = data.getFullYear();

  return `${dd}/${mmFormatado}/${aaaa} ${hh}:${mm}:${ss}`;
}
  

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <h1>
              <i
                className="bi bi-arrow-left-circle-fill"
                onClick={() => {
                  navigate("/meu-plano");
                }}
              ></i>
              Editar Usuário
            </h1>
          </div>

          <div className={styles.corpo}>
            <Form className={styles.form} onSubmit={handleSubmit}>
            <Tabs defaultActiveKey="dadosUsuario" className={styles.abas}>
            <Tab eventKey="dadosUsuario" title="Dados Usuário">
              {showMessage && (
                <div
                  className={`${styles.message} ${
                    message ? styles.successMessage : styles.errorMessage
                  }`}
                >
                  <p>{message || erro}</p>
                </div>
              )}
              <fieldset className={styles.fieldsetTopo}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="codigoSelected" className={styles.label}>
                    Código:
                  </Form.Label>
                  <Form.Control
                    id="codigoSelected"
                    value={codigoSelected}
                    onChange={(e) => setCodigoSelected(e.target.value)}
                    disabled
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="nome" className={styles.label}>
                    {cpfOuCnpj === "cnpj" ? "Razão Social*" : "Nome*"}
                  </Form.Label>
                  <Form.Control
                    id="nome"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="sobrenome" className={styles.label}>
                  {cpfOuCnpj === "cnpj" ? "Nome Fantasia*" : "Sobrenome*:"}
                  </Form.Label>
                  <Form.Control
                    id="sobrenome"
                    value={sobrenome}
                    onChange={(e) => setSobrenome(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="telefone" className={styles.label}>
                    Telefone*:
                  </Form.Label>
                  <Form.Control
                    id="telefone"
                    as={InputMask}
                    mask="(99) 99999-9999"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  />
                </Form.Group>
              </fieldset>
              <fieldset className={styles.fieldsetTopo}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="email" className={styles.label}>
                    E-mail*:
                  </Form.Label>
                  <Form.Control
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="tipoPessoa" className={styles.label}>
                    Tipo de Pessoa*:
                  </Form.Label>
                  <Form.Select
                    id="tipoPessoa"
                    className="form-control"
                    value={cpfOuCnpj}
                    onChange={handleTipoPessoaChange}
                  >
                    <option value="cpf">Pessoa Física</option>
                    <option value="cnpj">Pessoa Jurídica</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="cpfCnpj" className={styles.label}>
                    {cpfOuCnpj === "cnpj" ? "CNPJ" : "CPF"}:
                  </Form.Label>
                  <Form.Control
                    id="cpfCnpj"
                    as={InputMask}
                    mask={
                      cpfOuCnpj === "cnpj"
                        ? "99.999.999/9999-99"
                        : "999.999.999-99"
                    }
                    value={cpfOuCnpj === "cnpj" ? cnpj : cpf}
                    onChange={(e) => {
                      if (cpfOuCnpj === "cnpj") {
                        setCnpj(e.target.value);
                      } else {
                        setCpf(e.target.value);
                      }
                    }}
                  />
                </Form.Group>

                {/* <Form.Group className={styles.grupo}>
                <Form.Label htmlFor="permissao" className={styles.label}>Permissão:</Form.Label>
                <Form.Control
                  as="select"
                  id="permissao"
                  value={permissao}
                  onChange={(e) => setPermissao(e.target.value)}
                >
                  <option value="Administrador">Administrador</option>
                  <option value="Gestor">Gestor</option>
                  <option value="Vendedor">Vendedor</option>
                </Form.Control>
              </Form.Group> */}

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="cargo" className={styles.label}>
                    Cargo:
                  </Form.Label>
                  <Form.Select
                    id="cargo"
                    value={cargo}
                    className="form-control"
                    onChange={(e) => setCargo(e.target.value)}
                    onFocus={handleSelectFocus}
                  >
                    <option value="">Selecione um cargo</option>
                    <option value="Diretor">Diretor</option>
                    <option value="Gerente">Gerente</option>
                    <option value="Sócio">Sócio</option>
                    <option value="Colaborador">Colaborador</option>
                    <option value="Suporte">Suporte</option>
                    <option value="Desenvolvedor NodeJS">
                      Desenvolvedor Nodejs
                    </option>
                    <option value="Desenvolvedora ReactJs">
                      Desenvolvedor Reactjs
                    </option>
                    <option value="Outro">Outro</option>
                  </Form.Select>
                </Form.Group>
              </fieldset>
              {cargo === "Outro" && (
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="novoCargo" className={styles.label}>
                    Novo Cargo:
                  </Form.Label>
                  <Form.Control
                    id="novoCargo"
                    value={novoCargo}
                    onChange={(e) => setNovoCargo(e.target.value)}
                    placeholder="Digite o novo cargo"
                  />
                </Form.Group>
              )}

              <fieldset className={styles.fieldsetEndereco}>
                <fieldset className={styles.fieldsetTopo}>
                  <div className={styles.inlineDiv}>
                    <Form.Group className={styles.grupo}>
                      <Form.Label htmlFor="cep" className={styles.label}>
                        CEP*:
                      </Form.Label>
                      <Form.Control
                        id="cep"
                        as={InputMask}
                        mask="99.999-999"
                        value={cep}
                        onChange={handleCepChange}
                      />
                    </Form.Group>

                    <div className={styles.inlineDiv}>
                      <Form.Group className={styles.grupo}>
                        <Form.Label htmlFor="endereco" className={styles.label}>
                          Endereço*:
                        </Form.Label>
                        <Form.Control
                          id="endereco"
                          value={endereco}
                          onChange={(e) => setEndereco(e.target.value)}
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className={styles.grupo}>
                        <Form.Label htmlFor="numero" className={styles.label}>
                          Número*:
                        </Form.Label>
                        <Form.Control
                          id="numero"
                          value={numero}
                          onChange={(e) => setNumero(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className={styles.grupo}>
                        <Form.Label
                          htmlFor="complemento"
                          className={styles.label}
                        >
                          Complemento:
                        </Form.Label>
                        <Form.Control
                          id="complemento"
                          value={complemento}
                          onChange={(e) => setComplemento(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div>
                    <Form.Group className={styles.grupo}>
                      <Form.Label htmlFor="bairro" className={styles.label}>
                        Bairro*:
                      </Form.Label>
                      <Form.Control
                        id="bairro"
                        value={bairro}
                        onChange={(e) => setBairro(e.target.value)}
                        disabled
                      />
                    </Form.Group>

                    <div className={styles.inlineDiv}>
                      <Form.Group className={styles.grupo}>
                        <Form.Label htmlFor="cidade" className={styles.label}>
                          Cidade*:
                        </Form.Label>
                        <Form.Control
                          id="cidade"
                          value={cidade}
                          onChange={(e) => setCidade(e.target.value)}
                          disabled
                        />
                      </Form.Group>

                      <Form.Group className={styles.grupo}>
                        <Form.Label htmlFor="estado" className={styles.label}>
                          Estado*:
                        </Form.Label>
                        <Form.Control
                          id="estado"
                          value={estado}
                          onChange={(e) => setEstado(e.target.value)}
                          disabled
                        />
                      </Form.Group>

                      <Form.Group className={styles.grupo}>
                        <Form.Label className={styles.label}>
                          Referência:
                        </Form.Label>
                        <Form.Control
                          className={styles.InputRef}
                          type="text"
                          name="referencia"
                          id="referencia"
                          value={referencia}
                          onChange={(e) => setReferencia(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </fieldset>
              </fieldset>

              <fieldset className={styles.fieldsetButton}>
                <div className={styles.rodape}>
                  <Form.Group className={styles.formCheck}>
                    <Form.Check
                      className={styles.checkbox}
                      type="checkbox"
                      label="Ativar"
                      checked={status}
                      onClick={handleAtualizarEstado}
                    />
                  </Form.Group>
                  <button
                    className={`${styles.submit} ${styles.salvarButton}`}
                    type="button"
                    onClick={handleSubmit}
                  >
                    <i className="bi bi-play-fill"></i>
                    Salvar
                  </button>
                </div>
              </fieldset>
              </Tab>

                <Tab eventKey="dispositivos" title="Dispositivos">
                  <fieldset className={styles.fieldsetTopoTable}>
                  <table
                    ref={tableRef}
                    id="tableLogs"
                    className={`${styles.table} table align-items-center justify-content-center mb-0`}
                  >
                    <thead>
                      <tr>
                        <th>Ação</th>
                        <th>Cód.Acesso</th>
                        <th>ID</th>
                        <th>Data</th>
                        <th>Usuário</th>
                        <th>Dispositivo</th>
                        <th>Permissão</th>
                      </tr>
                    </thead>
                    <tbody>
                      {carregando ? (
                        // Mostra o indicador de loading se carregando for verdadeiro
                        <tr>
                          <td colSpan="8">
                            <LoadingCentroTelas />
                          </td>
                        </tr>
                      ) : (
                        Object.values(dispositivos).map((dispositivo) => {
                          if (!dispositivo.Usuario) {
                            return null;
                          }
                          return (
                            <React.Fragment key={dispositivo.CODACEPER}>
                                <tr key={dispositivo.CODACEPER}>
                                  <td>
                                      <i
                                        title={dispositivo.PERMISSAO ? "Reprovar" : "Aprovar"}
                                        style={{cursor: "pointer"}}
                                        className={dispositivo.PERMISSAO ? "bi bi-toggle-on" : "bi bi-toggle-off"}
                                        onClick={() => aprovarReprovarDispositivo(dispositivo.CODACEPER, dispositivo.PERMISSAO)}
                                      ></i>
                                  </td>
                                  <td>{dispositivo.CODACEPER}</td>
                                  <td title={dispositivo.CODIGO}>{dispositivo.CODIGO.length > 10 ? `${dispositivo.CODIGO.slice(0, 10)}...` : dispositivo.CODIGO}</td>

                                  <td>{dispositivo.DATAINC ? formatarData(dispositivo.DATAINC) : ""}</td>

                                  <td>
                                    {dispositivo.EMAIL
                                      ? dispositivo.EMAIL.toLowerCase()
                                      : ""}
                                  </td>
                                  {/* <td>
                                    {dispositivo.DISPOSITIVO
                                      ? dispositivo.DISPOSITIVO.toUpperCase()
                                      : ""}
                                  </td> */}
                                  <td title={dispositivo.DISPOSITIVO}>{dispositivo.DISPOSITIVO.length > 10 ? `${dispositivo.DISPOSITIVO.slice(0, 30).toUpperCase()}...` : dispositivo.DISPOSITIVO.toUpperCase()}</td>

                                  <td>{capitalizeFirstLetter(dispositivo.PERMISSAO ? "ATIVO" : "INATIVO")}</td>
                                </tr>
                            
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                  </fieldset>
                </Tab>
                  
              </Tabs>
            </Form>
          </div>
        </div>
      </CaixaConteudo>
      {/* <Modal
        show={showAtualizarEstadoModal}
        onHide={handleCloseAtualizarEstadoModal}
        centered
      >
        <Modal.Header>
          <Modal.Title>Alteração de status</Modal.Title>
        </Modal.Header>
        <Modal.Body>{status ? 'deseja desativar este usuario' : 'deseja ativar este usuario'}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAtualizarEstadoModal}>
            Cancelar
          </Button>
          <Button
            className={`${styles.submit} ${styles.excluirButton}`}
            onClick={handleConfirmEstado}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal> */}

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default EditarUsuario;
