// ESTILOS
import styles from "./EditarFornecedor.module.css";

// HOOKS
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import InputMask from "react-input-mask";

// COMPONENTES
import Cabecalho from "../../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../../Components/Menu/Menu";
import Form from "react-bootstrap/Form";
import LoadingCentroTelas from "../../../Components/LoadingCentroTelas/LoadingCentroTelas";
import MessageModal from "../../../Components/MessageModal/MessageModal";
import { AuthContext } from "../../../Services/AuthContext";
import api, { setAuthToken } from "../../../Services/api";
import estadosBrasil from "../../../Utils/estadosBrasil";
import { validarCamposVazios } from "../../../Utils/validarCamposVazios";
import { validarCPFouCNPJ } from "../../../Utils/validarCPFouCNPJ";

const EditarFornecedor = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const location = useLocation();
  const { codfor } = location.state;

  const [codigoSelected, setCodigoSelected] = useState("");
  const [pessoa, setPessoa] = useState(null);
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [empresaData, setEmpresaData] = useState(null);
  const [cpf, setCpf] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cnpjError, setCnpjError] = useState("");
  const [cpfOuCnpj, setcpfOuCnpj] = useState("");
  const [tempCpfInfo, setTempCpfInfo] = useState({ nome: "", sobrenome: "", email: "" });
  const [tempCnpjInfo, setTempCnpjInfo] = useState({ nome: "", sobrenome: "", email: "" });
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");

  const [cep, setCep] = useState("");
  const [cepError, setCepError] = useState("");
  const [manualAddressEntry, setManualAddressEntry] = useState(false);
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [codEnd, setCodEnd] = useState("");
  const [codDet, setCodDet] = useState("");
  const [complemento, setComplemento] = useState("");
  const [referencia, setReferencia] = useState("");

  const [nomeFornecedor, setNomeFornecedor] = useState("");
  const [descricao, setDescricao] = useState("");
  const [status, setStatus] = useState(false);
  const [statusCheckbox, setStatusCheckbox] = useState(false);

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [botaoSalvar, setBotaoSalvar] = useState("Salvar")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    if (codfor !== 0) {
      const fetchData = async () => {
        setLoading(true)
        try {
          const response = await api.get(`/fornecedor/buscar/?CODEMP=${codemp}&CODFOR=${codfor}`);
          const dados = response.data;
          console.log("dados", dados);
          const fornecedorData = response.data.Pessoa;

          setPessoa(fornecedorData);
          setCodigoSelected(dados.CODFOR);
          setNomeFornecedor(dados.FORNECEDOR);
          setDescricao(dados.DESCRICAO);
          setStatus(dados.STATUS);
          setNome(fornecedorData.NOME);
          setSobrenome(fornecedorData.SOBRENOME);
          setTelefone(fornecedorData.TELEFONE);
          setEmail(fornecedorData.EMAIL);
          setCodDet(fornecedorData.CODDET);

          const fornecedorCpfCnpj = fornecedorData.CPFCNPJ.replace(/[^\d]/g, '');
          setCpf(fornecedorData.CPFCNPJ);
          setCnpj(fornecedorData.CPFCNPJ);
          if (fornecedorData.TIPO === 'F' || fornecedorCpfCnpj.length === 11) {
            setcpfOuCnpj("cpf");
          } else if (fornecedorData.TIPO === 'J' || fornecedorCpfCnpj.length === 14) {
            setcpfOuCnpj("cnpj");
          }

          if (codDet) {
            try {
              const enderecoResponse = await api.get(`/enderecos/buscar/codend/?CODDET=${codDet}`);

              if (enderecoResponse.data && enderecoResponse.data.Endereco) {
                const endereco = enderecoResponse.data.Endereco;
                setCep(endereco.CEP);
                setEstado(endereco.ESTADO);
                setCidade(endereco.CIDADE);
                setBairro(endereco.BAIRRO);
                setEndereco(`${endereco.TIPOEND} ${endereco.NOMEEND}`);
                setNumero(enderecoResponse.data.NUMERO);
                setReferencia(enderecoResponse.data.REFERENCIA);
                setComplemento(enderecoResponse.data.COMPLEMENTO);
              }
            } catch (enderecoError) {
              console.error("Erro ao obter endereço:", enderecoError);
            }
          } else {
            console.log("Endereço não encontrado");
          }
        } catch (error) {
          console.error("Erro ao obter fornecedor:", error);
        } finally {
          setLoading(false)
        }
      };

      fetchData();
    }
  }, [codemp, codfor, codDet]);

  console.log("status", status)

  const buscarCNPJ = async () => {
    try {
      if (cnpj) {
        const { data } = await api.get(`/empresas/buscar/cnpj?CNPJ=${cnpj}`);
        console.log("dadosss", data);

        if (data.error) {
          setCnpjError("⚠️CNPJ não encontrado");
          setTimeout(() => {
            setCnpjError("");
          }, 3000);
        } else {
          setEmpresaData(data);
          setNome(data.RAZAOSOCIAL);
          setSobrenome(data.NOMEFANTASIA);
          setEmail(data.EMAIL);
          setTelefone(data.TELEFONE);
          setNumero(data.NUMERO);
          setComplemento(data.COMPLEMENTO);

          const cepSemPontos = data.CEP ? data.CEP.replace(/\D/g, '') : '';
          setCep(cepSemPontos);

          if (cepSemPontos && cepSemPontos !== "") {
            handleCepChange(cepSemPontos);
          }
        }
      }
    } catch (error) {
      console.error("Erro ao buscar CNPJ:", error);
    }
  };

  const handleCepChange = async (newCep) => {
    newCep = newCep.replace(/\D/g, "");
    setCep(newCep);

    if (newCep === "" || newCep.length !== 8) {
      setCodEnd(0);
      setEstado("");
      setCidade("");
      setBairro("");
      setEndereco("");
      setNumero("");
      setComplemento("");
      setReferencia("");
      setCepError("");
      setManualAddressEntry(false);
      return;
    }


    try {
      if (newCep.length === 8) {
        const response = await api.get(`/enderecos/buscar?CEP=${newCep}`);
        const enderecoData = response.data;
        setCodEnd(enderecoData.CODEND);
        setEstado(enderecoData.ESTADO);
        setCidade(enderecoData.CIDADE);
        setBairro(enderecoData.BAIRRO);
        setEndereco(`${enderecoData.TIPOEND} ${enderecoData.NOMEEND}`);
        setNumero(enderecoData.NUMERO);
        setComplemento(enderecoData.COMPLEMENTO);
        setReferencia(enderecoData.REFERENCIA || "");
        setCepError("");

        if (enderecoData.TIPOEND === undefined && enderecoData.NOMEEND === undefined) {
          setManualAddressEntry(true);
          setCepError("⚠️CEP não encontrado, digite manualmente seu endereço para cadastro.");
          setEndereco('');
          setBairro('');
          setCidade('');
          setEstado('');
        } else {
          setManualAddressEntry(false);
          setEndereco(`${enderecoData.TIPOEND} ${enderecoData.NOMEEND}`);
        }

      }
    } catch (error) {
      console.log("API error:", error);
    }
  };

  const fornecedorAtualizado = {
    CODFOR: codfor,
    CODEMP: codemp,
    Pessoa: {
      NOME: nome,
      SOBRENOME: sobrenome,
      CPFCNPJ: cpfOuCnpj === "cnpj" ? cnpj : cpf,
      TIPO: cpfOuCnpj === "cnpj" ? "J" : "F",
      TELEFONE: telefone,
      EMAIL: email,
    },
    Endereco: {
      CODEND: +codEnd,
      NUMERO: numero,
      REFERENCIA: referencia,
      COMPLEMENTO: complemento,
    },
    Fornecedor: {
      FORNECEDOR: nomeFornecedor,
      DESCRICAO: descricao,
      STATUS: status
    }
  };

  const handleAtualizarFornecedor = (e) => {
    e.preventDefault();

    const dadosObrigatorios = {
      'Fornecedor': nomeFornecedor,
      'Descrição': descricao,
      ...(cpfOuCnpj === "cnpj" ? {
        'Razão Social': nome,
        'Nome Fantasia': sobrenome,
        'CNPJ': cnpj,
      } : {
        'Nome': nome,
        'Sobrenome': sobrenome,
        'CPF': cpf,
      }),
      'Telefone': telefone,
      'E-mail': email,
      "CEP": cep,
      'Número': numero
    };

    if(validarCamposVazios(dadosObrigatorios).length > 0) {
      setModalMessage(`Preencha os campos obrigatorios: ${validarCamposVazios(dadosObrigatorios)}!`);
      setShowMessageModal(true);
      return ;
    } 

    if(validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj) === 'CPF' || validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj) === 'CNPJ') {
      setModalMessage(`${validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj)} inválido!`);
      setShowMessageModal(true);
      return;
    } 

    setBotaoSalvar("Carregando...")

    api
      .patch(`/fornecedor/atualizar`, fornecedorAtualizado)
      .then((response) => {
        console.log("Fornecedor atualizado com sucesso:", response.data);
        setModalMessage("Fornecedor atualizado com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao atualizar fornecedor.Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
      })
      .finally(()=>{
        setBotaoSalvar("Salvar")
      })
  };


  const handleTipoPessoaChange = (e) => {
    const selectedValue = e.target.value;

    if (cpfOuCnpj === "cpf") {
      setTempCpfInfo({ nome, sobrenome, email });
    } else {
      setTempCnpjInfo({ nome, sobrenome, email });
    }
    setcpfOuCnpj(selectedValue);
    if (selectedValue === "cpf") {
      setNome(tempCpfInfo.nome);
      setSobrenome(tempCpfInfo.sobrenome);
      setEmail(tempCpfInfo.email);
    } else {
      setNome(tempCnpjInfo.nome);
      setSobrenome(tempCnpjInfo.sobrenome);
      setEmail(tempCnpjInfo.email);
    }
  };

  const handleEmailChange = (event) => {
    const email = event.target.value.trim();
    const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;

    setEmail(email);

    if (!emailRegex.test(email)) {
      setErro("E-mail inválido!");
    } else {
      setErro("");
    }
  };

  const handleAtualizarEstado = () => {
    setStatus((prevStatus) => !prevStatus);
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/fornecedor");
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        {isLoading ? (
          <LoadingCentroTelas />

        ) : (
          <div className={styles.grid}>
            <div className={styles.topo}>
              <h1>
                <i
                  className="bi bi-arrow-left-circle-fill"
                  onClick={() => {
                    navigate("/fornecedor");
                  }}
                ></i>
                Editar Fornecedor
              </h1>
              
            </div>
            <div className={styles.corpo}>
              <Form className={styles.form} onSubmit={handleAtualizarFornecedor}>
                {showMessage && (
                  <div
                    className={`${styles.message} ${message ? styles.successMessage : styles.errorMessage
                      }`}
                  >
                    <p>{message || erro}</p>
                  </div>
                )}
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <h1>Campos obrigatórios(*)</h1>
                  {loading && <p style={{color: 'red'}}>Carregando...</p>}
                </div>
                <fieldset className={styles.fieldsetTopo}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="codigoSelected" className={styles.label}>
                      Código:
                    </Form.Label>
                    <Form.Control
                      id="codigoSelected"
                      value={codigoSelected}
                      onChange={(e) => setCodigoSelected(e.target.value)}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="nome" className={styles.label}>
                      {cpfOuCnpj === "cnpj" ? "Razão Social*" : "Nome*"}
                    </Form.Label>
                    <Form.Control
                      id="nome"
                      value={nome}
                      onChange={(e) => setNome(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="sobrenome" className={styles.label}>
                      {cpfOuCnpj === "cnpj" ? "Nome Fantasia*" : "Sobrenome*:"}
                    </Form.Label>
                    <Form.Control
                      id="sobrenome"
                      value={sobrenome}
                      onChange={(e) => setSobrenome(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="telefone" className={styles.label}>
                      Telefone*:
                    </Form.Label>
                    <Form.Control
                      id="telefone"
                      as={InputMask}
                      mask="(99) 99999-9999"
                      value={telefone}
                      onChange={(e) => setTelefone(e.target.value)}
                    />
                  </Form.Group>
                </fieldset>
                {cnpjError && <span style={{ color: 'red', textAlign: 'right', marginRight: '10%' }}>{cnpjError}</span>}
                <fieldset className={styles.fieldsetTopo}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="email" className={styles.label}>
                      E-mail*:
                    </Form.Label>
                    <Form.Control
                      id="email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="tipoPessoa" className={styles.label}>
                      Tipo de Pessoa*:
                    </Form.Label>
                    <Form.Select
                      id="tipoPessoa"
                      className="form-control"
                      value={cpfOuCnpj}
                      onChange={handleTipoPessoaChange}
                    >
                      <option value="cpf">Pessoa Física</option>
                      <option value="cnpj">Pessoa Jurídica</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="cpfCnpj" className={styles.label}>
                      {cpfOuCnpj === "cnpj" ? "CNPJ*" : "CPF*"}:
                    </Form.Label>
                    <div className={styles.inputWithIcon}>
                      <Form.Control
                        id="cpfCnpj"
                        as={InputMask}
                        mask={
                          cpfOuCnpj === "cnpj"
                            ? "99.999.999/9999-99"
                            : "999.999.999-99"
                        }
                        value={cpfOuCnpj === "cnpj" ? cnpj : cpf}
                        onChange={(e) => {
                          if (cpfOuCnpj === "cnpj") {
                            setCnpj(e.target.value);
                          } else {
                            setCpf(e.target.value);
                          }
                        }}
                      />
                      {cpfOuCnpj === "cnpj" && (
                        <i
                          className={`bi bi-search`}
                          onClick={buscarCNPJ}
                        ></i>
                      )}
                    </div>
                  </Form.Group>
                </fieldset>
                <fieldset className={styles.fieldsetTopo1}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="nomeFornecedor" className={styles.label}>
                      Fornecedor*:
                    </Form.Label>
                    <Form.Control
                      id="nomeFornecedor"
                      value={nomeFornecedor}
                      onChange={(e) => setNomeFornecedor(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="descricao" className={styles.label}>
                      Descrição*:
                    </Form.Label>
                    <Form.Control
                      id="descricao"
                      value={descricao}
                      onChange={(e) => setDescricao(e.target.value)}
                    />
                  </Form.Group>
                </fieldset>
                {cepError && <span style={{ color: 'red' }}>{cepError}</span>}
                <fieldset className={styles.fieldsetEndereco}>
                  <div className={styles.inlineDiv}>
                    <Form.Group className={`${styles.grupo} w-100`}>
                      <Form.Label htmlFor="cep" className={styles.label}>
                        CEP*:
                      </Form.Label>
                      <Form.Control
                        id="cep"
                        as={InputMask}
                        mask="99.999-999"
                        value={cep}
                        onChange={(e) => handleCepChange(e.target.value)}
                      />
                    </Form.Group>

                    <div className={styles.inlineDiv}>
                      <Form.Group className={`${styles.grupo} w-100`}>
                        <Form.Label htmlFor="endereco" className={styles.label}>
                          Endereço*:
                        </Form.Label>
                        <Form.Control
                          id="endereco"
                          value={endereco}
                          onChange={(e) => setEndereco(e.target.value)}
                          disabled={!manualAddressEntry}
                        />
                      </Form.Group>
                      <Form.Group className={`${styles.grupo} w-100`}>
                        <Form.Label htmlFor="numero" className={styles.label}>
                          Número*:
                        </Form.Label>
                        <Form.Control
                          id="numero"
                          value={numero}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(/[^0-9]/g, '');
                            setNumero(numericValue);
                          }}
                        />
                      </Form.Group>

                      <Form.Group className={`${styles.grupo} w-100`}>
                        <Form.Label
                          htmlFor="complemento"
                          className={styles.label}
                        >
                          Complemento:
                        </Form.Label>
                        <Form.Control
                          id="complemento"
                          value={complemento}
                          onChange={(e) => setComplemento(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div>
                    <Form.Group className={`${styles.grupo} w-100`}>
                      <Form.Label htmlFor="bairro" className={styles.label}>
                        Bairro*:
                      </Form.Label>
                      <Form.Control
                        id="bairro"
                        value={bairro}
                        onChange={(e) => setBairro(e.target.value)}
                        disabled={!manualAddressEntry}
                      />
                    </Form.Group>

                    <div className={styles.inlineDiv}>
                      <Form.Group className={`${styles.grupo} w-100`}>
                        <Form.Label htmlFor="cidade" className={styles.label}>
                          Cidade*:
                        </Form.Label>
                        <Form.Control
                          id="cidade"
                          value={cidade}
                          onChange={(e) => setCidade(e.target.value)}
                          disabled={!manualAddressEntry}
                        />
                      </Form.Group>

                      <Form.Group className={`${styles.grupo} w-100`}>
                        <Form.Label htmlFor="estado" className={styles.label}>
                          Estado*:
                        </Form.Label>
                        {
                          manualAddressEntry
                            ?
                            <Form.Control
                              as="select"
                              id="estado"
                              value={estado}
                              onChange={(e) => setEstado(e.target.value)}
                            >
                              <option value="" disabled>Selecione um estado</option>
                              {estadosBrasil.map(sigla => (
                                <option key={sigla} value={sigla}>
                                  {sigla}
                                </option>
                              ))}
                            </Form.Control>
                            :
                            <Form.Control
                              id="estado"
                              value={estado}
                              disabled={true}
                            />
                        }
                      </Form.Group>

                      <Form.Group className={`${styles.grupo} w-100`}>
                        <Form.Label className={styles.label}>
                          Referência:
                        </Form.Label>
                        <Form.Control
                          className={styles.InputRef}
                          type="text"
                          name="referencia"
                          id="referencia"
                          value={referencia}
                          onChange={(e) => setReferencia(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </fieldset>
                
              </Form>
            </div>
            <fieldset className={styles.fieldsetButton}>
              <div className={styles.rodape}>
                <Form.Group className={styles.formCheck}>
                  <Form.Check
                    className={styles.checkbox}
                    type="checkbox"
                    label="Ativar"
                    checked={status}
                    onClick={handleAtualizarEstado}
                  />
                </Form.Group>
                {erro && <p className={styles.erro}>{erro}</p>}
                <button
                  className={`${styles.submit} ${styles.salvarButton}`}
                  type="submit"
                  onClick={handleAtualizarFornecedor}
                >
                  <i className="bi bi-play-fill"></i>
                  {botaoSalvar}
                </button>
              </div>
            </fieldset>
          </div>
        )}
      </CaixaConteudo>
      {/* <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que deseja excluir este fornecedor?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancelar
          </Button>
          <Button
            className={`${styles.submit} ${styles.excluirButton}`}
            onClick={handleConfirmDelete}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal> */}

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default EditarFornecedor;
