// ESTILOS
import styles from "./EditarSegmento.module.css";

// HOOKS
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";

// COMPONENTES
import Cabecalho from "../../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../../Components/Menu/Menu";
import Form from "react-bootstrap/Form";
import { AuthContext } from "../../../Services/AuthContext";
import api, { setAuthToken } from "../../../Services/api";
import MessageModal from "../../../Components/MessageModal/MessageModal";

const EditarSegmentos = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const location = useLocation();
  const { codseg } = location.state;

  const [nomeSegmento, setNomeSegmento] = useState("");
  const [descricao, setDescricao] = useState("");
  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [botaoSalvar,setBotaoSalvar] = useState("Salvar")
  const [loading,setLoading] = useState(false)

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
      setLoading(true)
    api
      .get(
        `/segmento/buscar/?CODEMP=${Number(CODEMP)}&CODSEG=${Number(codseg)}`
      )
      .then((response) => {
        setDescricao(response.data.DESCRICAO);
        setNomeSegmento(response.data.SEGMENTO);
      })
      .catch((error) => {
        console.error("Erro ao obter segmento:", error);
      })
      .finally(()=>{
        setLoading(false)
      })
  }, [codemp, codseg]);

  const handleAtualizaSegmento = (e) => {
    e.preventDefault();
    if (nomeSegmento === "") {
      setModalMessage("Preencha o campo obrigatório: Segmento!");
      setShowMessageModal(true);
      return;
    }

    const segmentoAtualizado = {
      CODSEG: codseg,
      CODEMP: codemp,
      SEGMENTO: nomeSegmento,
      DESCRICAO: descricao,
    };

    setBotaoSalvar("Carregando...")

    api
      .patch(`/segmento/atualizar`, segmentoAtualizado)
      .then((response) => {
        console.log("Segmento atualizado com sucesso:", response.data);
        setModalMessage("Segmento atualizado com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        console.error("Erro ao atualizar segmento:", error);
        let errorMessage = "";
        if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else if (error.response && error.response.status === 409) {
          errorMessage = error.response.data.message
        } else {
          errorMessage = "Erro ao atualizar segmento.Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
      })
      .finally(()=>{
        setBotaoSalvar("Salvar")
      })
  };

  const handleDeleteSegmento = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(false);

    const requestData = {
      CODEMP: Number(codemp),
      CODSEG: Number(codseg),
    };
    console.log("requestData:", requestData);

    api
      .delete(`/segmento/remover`, { data: requestData })
      .then((response) => {
        console.log("Segmento excluído com sucesso:", response.data);
        setMessage("Segmento excluído com sucesso!");
        setErro("");
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
          navigate("/segmentos");
        }, 3000);
      })
      .catch((error) => {
        console.error("Erro ao excluir segmento:", error);
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao excluir segmento.Tente novamente!");
        }
        setMessage("");
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
        console.log("codseg:", codseg, "codemp:", codemp);
      });
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/segmentos");
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <h1>
              <i
                className="bi bi-arrow-left-circle-fill"
                onClick={() => {
                  navigate("/segmentos");
                }}
              ></i>
              Editar Segmento
            </h1>
          </div>

          <div className={styles.corpo}>
            <Form className={styles.form} onSubmit={handleAtualizaSegmento}>
              {showMessage && (
                <div
                  className={`${styles.message} ${
                    message ? styles.successMessage : styles.errorMessage
                  }`}
                >
                  <p>{message || erro}</p>
                </div>
              )}
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <p style={{fontSize:'.7rem'}}>Campos obrigatórios(*)</p>
                {loading && <p style={{color: 'red'}}>Carregando...</p>}
              </div>
              <fieldset className={styles.fieldsetTopo}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="nomeSegmento" className={styles.label}>
                    Segmento*:
                  </Form.Label>
                  <Form.Control
                    id="nomeSegmento"
                    value={nomeSegmento}
                    onChange={(e) => setNomeSegmento(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="descricao" className={styles.label}>
                    Descrição:
                  </Form.Label>
                  <Form.Control
                    id="descricao"
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                  />
                </Form.Group>
              </fieldset>

              <fieldset className={styles.fieldsetButton}>
                <div className={styles.rodape}>
                  <button
                    className={`${styles.submit} ${styles.excluirButton}`}
                    type="button"
                    onClick={handleDeleteSegmento}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-trash-fill"></i>
                    Excluir
                  </button>
                  <button
                    className={`${styles.submit} ${styles.salvarButton}`}
                    type="submit"
                    onClick={handleAtualizaSegmento}
                  >
                    <i className="bi bi-play-fill"></i>
                    {botaoSalvar}
                  </button>
                </div>
              </fieldset>
            </Form>
          </div>
        </div>
      </CaixaConteudo>
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que deseja excluir este segmento?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancelar
          </Button>
          <Button
            className={`${styles.submit} ${styles.excluirButton}`}
            onClick={handleConfirmDelete}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default EditarSegmentos;
