import styles from "./EditarEmpresas.module.css";
import Form from "react-bootstrap/Form";
import { useNavigate} from "react-router-dom";
import { useState, useEffect, useContext } from 'react';
 
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
 
import { AuthContext } from '../../Services/AuthContext';
import api, { setAuthToken } from '../../Services/api';
import { Modal, Button } from "react-bootstrap";
 

 
  const EditarEmpresas = () => {
    const navigate = useNavigate();
    const { token, codemp } = useContext(AuthContext);
    const [selectedTableId, setSelectedTableId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [razaosocial, setRazaosocial] = useState("");
    const [cnpj, setCNPJ] = useState("");
    const [ramo, setRamo] = useState("");
    const [integrantes, setIntegrantes] = useState();
    const [atividade, setAtividade] = useState("");
    const [erro, setErro] = useState("");

    useEffect(() => {
      setAuthToken(token);
    }, [token]);
 
    useEffect(() => {
        api
          .get(`/empresas/buscar/?CODEMP=${Number(codemp)}`
          )
          .then((response) => {
            console.log(response.data);
            setRazaosocial(response.data.RAZAOSOCIAL);
            setCNPJ(response.data.CNPJ);
            setRamo(response.data.RAMO);
            setIntegrantes(response.data.QTDINTEGRANTES);
            setAtividade(response.data.ATIVIDADE);
          
          })
          .catch((error) => {
            console.error("Erro ao obter empresa:", error);
          });
      }, [codemp]);
 
      const handleAtualizaEmpresa = (e) => {
        e.preventDefault();
        if (
        razaosocial === "" ||
        cnpj === "" ||
        ramo === "" ||
        integrantes === "" ||
        atividade === "" 
    
        ) {
          setErro("Preencha todos os campos!");
          return;
        }
 
        const empresaAtualizada = {
            RAZAOSOCIAL: razaosocial,
            CNPJ: cnpj,
            RAMO: ramo,
            QTDINTEGRANTES: Number(integrantes),
            ATIVIDADE: atividade,
          };
          console.log("empresaAtualizada: ", empresaAtualizada);
      
          api
          .patch(`/empresas/atualizar`, empresaAtualizada)
            .then((response) => {
              console.log("Empresa atualizada com sucesso:", response.data);
              navigate("/Empresas");
            })
            .catch((error) => {
              console.error("Erro ao atualizar empresa:", error);
              console.log("Mensagens de erro:", error.response.data.message);
            });
        };
 
        const handleDeleteEmpresa = () => {
         
          const requestData = {
            CODEMP: Number(codemp),
          };
        
          if (selectedTableId) {
            api
              .delete(`/empresas/remover`, { data: requestData })
                .then((response) => {
                  console.log("Empresa excluída com sucesso", response.data);
                  navigate("/Empresas");
                  setShowModal(false); 
                })
                .catch((error) => {
                  console.error("Erro ao excluir empresa:", error);
                  console.log( "codemp:", codemp);
                  console.log('Mensagem de erro da API:', error.response.data);
                });
            }
          };
 
          const openModal = (tableId) => {
            setSelectedTableId(tableId);
            setShowModal(true);
          };
 
          const renderModal = () => {
            return (
              <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header>
                  <Modal.Title>Confirmar exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>Deseja excluir a empresa?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Cancelar
                  </Button>
                  <Button variant="primary" onClick={handleDeleteEmpresa}>
                  Confirmar
                  </Button>
                </Modal.Footer>
              </Modal>
            );
          };
 
 
          const children = (
            <div className={styles.grid}>
            <div className={styles.topo}>
              <h1>
                <i
                  className="bi bi-arrow-left-circle-fill"
                  onClick={() => {
                    navigate("/Empresas");
                  }}
                ></i>
                Editar Empresa
              </h1>
            </div>
        
            <div className={styles.corpo}>
              <Form className={styles.form} onSubmit={handleAtualizaEmpresa}>
              <fieldset className={styles.fieldsetTopo}>
               
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="razaosocial" className={styles.label}>
                  Razão social:
                  </Form.Label>
                  <Form.Control
                    id="razaosocial"
                    value={razaosocial}
                    onChange={(e) => setRazaosocial(e.target.value)}
                  />
                </Form.Group>
        
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="cnpj" className={styles.label}>
                  CNPJ*: 
                  </Form.Label>
                  <Form.Control
                    id="cnpj"
                    value={cnpj}
                    onChange={(e) => setCNPJ(e.target.value)}
                  />
                </Form.Group>
        
        
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="ramo" className={styles.label}>
                  Ramo:
                  </Form.Label>
                  <Form.Control
                  id="ramo"
                    value={ramo}
                    onChange={(e) => setRamo(e.target.value)}
                  />
                </Form.Group>
                </fieldset>
        
                <fieldset className={styles.fieldsetTopo}>
                <Form.Group className={styles.grupo}>
                <div className={styles.prefix}>
                  <Form.Label htmlFor="integrantes" className={styles.label}>
                  Quant. de integrantes:
                  </Form.Label>
                  <Form.Control
                    id="integrantes"
                    value={integrantes}
                    onChange={(e) => setIntegrantes(e.target.value)}
                  />
                  </div>
                </Form.Group>
        
        
                <Form.Group className={styles.grupo}>
                <div className={styles.suffix}>
                  <Form.Label htmlFor="atividade" className={styles.label}>
                  Atividade:
                  </Form.Label>
                  <Form.Control
                    id="atividade"
                    value={atividade}
                    onChange={(e) => setAtividade(e.target.value)}
                  />
                   </div>
                </Form.Group>
                </fieldset>
        
        <fieldset className={styles.fieldsetButton}>
                <div className={styles.rodape}>
                        <button
                          className={`${styles.submit} ${styles.excluirButton}`}
                          type="button"
                          onClick={() => openModal(codemp)}
                          style={{ cursor: "pointer" }}
                        >
                    <i className="bi bi-trash-fill"></i>
                    Excluir
                  </button>
                        <button className={`${styles.submit} ${styles.salvarButton}`} type="submit"
                         onClick={handleAtualizaEmpresa}>
                          <i className="bi bi-play-fill"></i>
                          Salvar
                        </button>
                        {erro && <p className={styles.erro}>{erro}</p>}
                    </div>
                    </fieldset>
              </Form>
            </div>
          </div>
        
          );
        
          return (
            <>
              <Menu />
              <Cabecalho />
              <CaixaConteudo children={children} />
              {renderModal()}
            </>
          );
        };
        
        export default EditarEmpresas;
        
        
        
        
