import { useNavigate } from 'react-router-dom'
import styles from './ModalLocalInexistente.module.css'
const ModalLocalInexistente = ({linhas, handleClose}) => {
  const navigate = useNavigate()
  return (
    <div className={styles.modal}>
    <div className={styles.topoModal}>
        <h2>Erro: Código de Local!</h2>
        <span onClick={handleClose}>X</span>
    </div>
    {linhas.length > 0 ? (
      <div className={styles.linhas}>
        <p>As seguintes linhas do seu arquivo apresentam Código de Local não cadastrado:</p>
        {linhas.map((linhaInfo) => (
          <p key={linhaInfo}>
            Linha {linhaInfo}.
          </p>
        ))}
      </div>
    ) : null}
    <div className={styles.footer}>
        <p className={styles.erro}>Crie um novo LOCAL ou corrija o CODIGOLOCAL e tente novamante.</p>
        <div className={styles.buttons}>
          <button className={styles.btn} onClick={() => navigate('/locais')}>Ver Locais</button>
          <button className={styles.btn} onClick={()=>navigate('/adicionar-local')}>Criar Local</button>
          <button className={styles.btn} onClick={handleClose}>Fechar</button>
        </div>
    </div>
  </div>
  )
}

export default ModalLocalInexistente