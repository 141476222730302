import { useNavigate } from 'react-router-dom'

import styles from './ModalEmpresa.module.css'


const ModalEmpresa = () => {

  const navigate = useNavigate();
  
    return (
      <>
        <div className={styles.modal}>
          <h1>CADASTRO EFETUADO!</h1>
          <p>Seu cadastro foi realizado com sucesso. <br></br> 
          Entraremos em contato por e-mail assim que possível enviando a proposta.
          </p>
          <button onClick={() => navigate('/')}>Voltar ao início</button>
        </div>
      </>
      )
}

export default ModalEmpresa