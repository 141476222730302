import React from "react";
import styles from "./LoadingCentroTelas.module.css";
 
const LoadingCentroTelas = () => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingSpinner}></div>
    </div>
  );
};
 
export default LoadingCentroTelas;