import styles from './ModalCamposVaziosImport.module.css'
const ModalCamposVaziosImport = ({linhas, handleClose}) => {
  return (
    <div className={styles.modal}>
    <div className={styles.topoModal}>
        <h2>Erro: Campos vazios!</h2>
        <span onClick={handleClose}>X</span>
    </div>
    {linhas.length > 0 ? (
      <div className={styles.linhas}>
        <p>As seguintes linhas do seu arquivo apresentam estes campos obrigatórios vazios:</p>
        {linhas.map((linhaInfo) => (
          <p key={linhaInfo.linha}>
            Linha {linhaInfo.linha}: {linhaInfo.campos.join(', ')}.
          </p>
        ))}
      </div>
    ) : null}
    <div className={styles.footer}>
        <p className={styles.erro}>Preencha os campos e tente novamente.</p>
        <button className={styles.btn} onClick={handleClose}>Fechar</button>
    </div>
  </div>
  )
}

export default ModalCamposVaziosImport