// ESTILO
import styles from "./Transportadora.module.css";

// MODULOS
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";

// HOOKS
import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import { formatarCpfCnpj } from "../../Utils/formatarCpfCnpj";
import { formatarTelefone } from "../../Utils/formatarTelefone";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import axios from "axios";

const Transportadora = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const [transportadora, setTransportadora] = useState([]);
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [semDados, setSemDados] = useState('')
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  // useEffect(() => {
  //   const getTransportadora = async () => {
  //     const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
  //     setLoading(true)
  //     try {
  //       const response = await api.get(
  //         `/transportadora/listar?CODEMP=${CODEMP}`
  //       );
  //       setTransportadora(response.data);
  //       setLoading(false)
  //     } catch (error) {
  //       console.error(error);
  //       setLoading(false)
  //     }
  //   };
  //   getTransportadora();
  // }, [codemp, token]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    setLoading(true);
    const getTransportadora = async () => {
      try {
        const response = await api.get(`/transportadora/listar?CODEMP=${CODEMP}`, {
          cancelToken: source.token
        });
        if(response.data.length === 0 ){
          setTransportadora([])
          setSemDados("Sem dados")
        } else {
          setTransportadora(response.data);
        }
        setLoading(false);
        setReload(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
        setLoading(false);
        setReload(false);
      }
    };
    getTransportadora();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp,reload]);

  useEffect(() => {
    if (
      transportadora.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
          zeroRecords:"Nenhum registro correspondente encontrado."
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
        order:[2,'asc']
      });
    }
  }, [transportadora,reload]);

  const editarTransportadora = (codtrans) => {
    navigate("/editar-transportadora", { state: { codtrans } });
  };

  const showTable = () => {
    return transportadora.map((item, index) => {
      const pessoa = item.Pessoa;
      const NOME = pessoa && pessoa.NOME ? pessoa.NOME : "-";
      const SOBRENOME = pessoa && pessoa.SOBRENOME ? pessoa.SOBRENOME : "-";
      const email = pessoa && pessoa.EMAIL ? pessoa.EMAIL : "-";
      const Telefone = pessoa && pessoa.TELEFONE ? pessoa.TELEFONE : "-";
      const Cpf = pessoa && pessoa.CPFCNPJ ? pessoa.CPFCNPJ : "-";
      // const Cargo = pessoa && pessoa.CARGO ? pessoa.CARGO : "-";
      const tipo =
        pessoa.TIPO &&
        (pessoa.TIPO === "F"
          ? "Pessoa Física"
          : pessoa.TIPO === "J"
          ? "Pessoa Jurídica"
          : "Indefinido");

      return (
        <tr key={index}>
          <td className="text-center">
            <i style={{cursor: 'pointer'}}
              className="bi bi-pen"
              onClick={() => editarTransportadora(item.CODTRANS)}
            ></i>
          </td>
          <td className="text-center">{item.CODTRANS}</td>
          <td>{`${capitalizeFirstLetter(NOME)}
            ${pessoa.TIPO === "F" || Cpf.replace(/\D/g, '').length === 11 
              ? capitalizeFirstLetter(SOBRENOME)
              : ''}`}</td>
          <td style={{whiteSpace: 'nowrap'}}>{`${formatarCpfCnpj(Cpf)}`}</td>
          <td style={{whiteSpace: 'nowrap'}}>{`${formatarTelefone(Telefone)}`}</td>
          <td>{email}</td>
          <td>{tipo}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <div className={styles.topoButtons}>
              <button
                onClick={() => {
                  navigate("/nova-transportadora");
                }}
              >
                Adicionar Transportadora
              </button>
              <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} title="Atualizar" onClick={()=>setReload(true)}></i>
            </div>
          </div>
          <div className={styles.corpo}>
            <div className={`container-fluid py-4 ${styles.boxTable}`}>
              <div className="table-responsive p-0 pb-2">
                <h1 className={styles.titleTable}>Transportadora</h1>
                <table
                  ref={tableRef}
                  className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                >
                  <thead>
                    <tr>
                      <th>Ação</th>
                      <th>Código</th>
                      <th>Nome</th>
                      <th>CPF/CNPJ</th>
                      <th>Telefone</th>
                      <th>E-mail</th>
                      <th>Tipo</th>
                    </tr>
                  </thead>
                  <tbody>{loading ? <tr>
                    <td colSpan="8">
                      <LoadingCentroTelas />
                    </td>
                  </tr> : semDados === 'Sem dados' ? <tr>
                    <td style={{ textAlign: "center" }} colSpan="8">
                    A tabela está vazia no momento.
                    </td>
                  </tr> : showTable()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </CaixaConteudo>
    </>
  );
};

export default Transportadora;
