import React, { useState, useEffect } from "react";
import styles from "./CaixaConteudo.module.css";
import LoadingCentroTelas from "../LoadingCentroTelas/LoadingCentroTelas";
 
const CaixaConteudo = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
 
  useEffect(() => {
    const delay = setTimeout(() => {
      setError(
        "Erro de conexão com o servidor. Verifique sua conexão ou tente novamente mais tarde."
      );
      setLoaded(true);
    }, 50);
 
    return () => clearTimeout(delay);
  }, []);
 
  return (
    <div className={styles.container}>
      {loaded ? (
        children
      ) : error ? (
        <div className={styles.errorContainer}>
          <p>{error}</p>
        </div>
      ) : (
        <LoadingCentroTelas />
      )}
    </div>
  );
};
 
export default CaixaConteudo;