// ESTILO
import styles from "./CadastroCliente.module.css";

// MODULOS
import Form from "react-bootstrap/Form";
import InputMask from "react-input-mask";

// HOOKS
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//COMPONENTES
import logo from "../../Assets/Logos/logoThinkSale.png";

const CadastroCliente = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nomeParam = searchParams.get("nome");
  const telefoneParam = searchParams.get("telefone");
  const emailParam = searchParams.get("email");
  const [nome, setNome] = useState(nomeParam || "");
  const [sobrenome, setSobrenome] = useState("");
  const [email, setEmail] = useState(emailParam || "");
  const [tel, setTel] = useState(telefoneParam || "");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [cargo, setCargo] = useState("");
  const [erro, setErro] = useState(null);
  const [msg, setMsg] = useState("");
  const [sucesso, setSucesso] = useState(false);

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const telRegex =
      /^(?:(?:\+|00)?55\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:(9\d|[2-9])\d{3})-?(\d{4})$/;
    const cpfCnpjRegex =
      /^(\d{2}[.]?\d{3}[.]?\d{3}[/]?\d{4}[-]?\d{2})|(\d{3}[.]?\d{3}[.]?\d{3}[-]?\d{2})$/;

    try {
      if (nome === "") {
        setErro("Digite o seu nome.");
        return;
      }

      if (sobrenome === "") {
        setErro("Digite o seu sobrenome.");
        return;
      }

      if (email === "") {
        setErro("Digite o seu e-mail.");
        return;
      } else if (!emailRegex.test(email)) {
        setErro("Por favor, insira um e-mail válido.");
        return;
      }

      if (tel === "") {
        setErro("Digite o seu telefone.");
        return;
      } else if (!telRegex.test(tel)) {
        setErro("Número de telefone inválido.");
        return;
      }

      if (cpfCnpj === "") {
        setErro("Digite seu CPF ou CNPJ.");
        return;
      } else if (!cpfCnpjRegex.test(cpfCnpj)) {
        setErro("Número de documento inválido.");
        return;
      }

      const newPerson = {
        Proposta: {
          EMAIL: email,
          NOME: nome,
          SOBRENOME: sobrenome,
          CPFCNPJ: cpfCnpj,
          TELEFONE: tel,
          CARGO: cargo
        },
      };
      console.log(newPerson);

      navigate("/cadastro-empresa", { state: { newPerson } });
    } catch {
      setErro("Preencha todos os campos obrigatórios!");
    }
  };

  // const redirecionar = ( pessoa ) => {

  // };

  return (
    <section className={styles.section}>
      <button className={styles.homeButton} onClick={handleHomeClick}>
        <i class="bi bi-house"></i>
      </button>
      <div className={styles.container}>
        <img src={logo} alt="Logo ThinkSale" />
        <p>Cadastre sua nova conta</p>
        <Form className={styles.form} onSubmit={handleSubmit}>
          <Form.Group className={styles.grupo} controlId="nome">
            <Form.Label className={styles.label}>Nome*: </Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite seu nome"
              className={styles.input}
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </Form.Group>

          <Form.Group className={styles.grupo} controlId="sobrenome">
            <Form.Label className={styles.label}>Sobrenome*: </Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite seu sobrenome"
              className={styles.input}
              value={sobrenome}
              onChange={(e) => setSobrenome(e.target.value)}
            />
          </Form.Group>

          <Form.Group className={styles.grupo} controlId="email">
            <Form.Label className={styles.label}>E-mail*: </Form.Label>
            <Form.Control
              type="email"
              placeholder="ex@exemplo.com"
              className={styles.input}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className={styles.grupo} controlId="telefone">
            <Form.Label className={styles.label}>Telefone*: </Form.Label>
            <Form.Control
              type="text"
              as={InputMask}
              mask="(99) 99999-9999"
              placeholder="(99) 99999-9999"
              className={styles.input}
              value={tel}
              onChange={(e) => setTel(e.target.value)}
            />
          </Form.Group>

          <Form.Group className={styles.grupo} controlId="documento">
            <Form.Label className={styles.label}>CPF / CNPJ*: </Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite seu documento"
              className={styles.input}
              value={cpfCnpj}
              onChange={(e) => setCpfCnpj(e.target.value)}
            />
          </Form.Group>

          <input className={styles.submit} type="submit" value="Continuar" />
        </Form>

        {erro !== null && !sucesso && <div className={styles.erro}>{erro}</div>}

        {sucesso && (
          <div className={styles.sucesso}>
            <span>{msg}</span>
          </div>
        )}
      </div>
    </section>
  );
};

export default CadastroCliente;
