import React from 'react';
import styles from './VisualizarPedido.module.css';

import { formatarValor } from '../../Utils/formatarValor';
import { capitalizeFirstLetter } from '../../Utils/stringCapitalizer';

const PedidoDetalhesModal = ({ pedido, closeModal }) => {
  const produtosSelecionados = pedido.produtosSelecionados;

  const {
    dataEmissao,
    numeroPedido,
    vendedor,
    cliente,
    tipoNegociacao,
    transportadora,
  } = pedido;

  const calcularValorTotalPedido = () => {
    let valorTotal = 0;

    if (produtosSelecionados && Array.isArray(produtosSelecionados)) {
      produtosSelecionados.forEach((produto) => {
        valorTotal += produto.valorTotalProduto;
      });
    }

    return valorTotal;
  };

  const valorTotalPedido = calcularValorTotalPedido();
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h3>
        <i class="bi bi-clipboard"></i>
        Detalhes do Pedido</h3>
        <table className={styles.tableDetalhe}>
          <tbody>
            <tr>
              <td className={styles.legenda}>Data da emissão:</td>
              <td style={{textAlign: 'left'}} className={styles.valor}>{dataEmissao}</td>
              <td className={styles.legenda} rowSpan="2">Nº do Pedido:</td>
              <td style={{textAlign: 'left'}} className={styles.valor} rowSpan="2">-</td>
            </tr>
            <tr>
              <td className={styles.legenda}>Tipo de Movimentação:</td>
              <td>Venda</td>
            </tr>
            <tr>
              <td className={styles.legenda}>Vendedor:</td>
              <td style={{textAlign: 'left'}}> {capitalizeFirstLetter(vendedor.nome)} {capitalizeFirstLetter(vendedor.sobrenome)}</td>
              <td className={styles.legenda}>Cliente:</td>
              <td style={{textAlign: 'left'}} className={styles.valor}>{capitalizeFirstLetter(cliente)}</td>
            </tr>
            <tr>
              <td className={styles.legenda}>Tipo de Negociação:</td>
              <td style={{textAlign: 'left'}}>{tipoNegociacao}</td>
              <td className={styles.legenda}>Transportadora:</td>
              <td style={{textAlign: 'left'}} className={styles.valor}>{transportadora && !transportadora.includes('undefined') ? transportadora : "-"}</td>
            </tr>
            <tr>
              <td className={styles.legenda} colSpan="2">Valor total:</td>
              <td colSpan="2" className={styles.valor}>
              {formatarValor(valorTotalPedido)}
              </td>
            </tr>
          </tbody>
        </table>
        <h3>
          <i className="bi bi-box-seam"></i>
          Produtos Selecionados
        </h3>
        <table className={styles.tableDetalhe}>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Descrição</th>
              <th>Fabricante</th>
              <th>Valor Unitário</th>
              <th>Quantidade</th>
              <th>Valor Desconto</th>
              <th>Valor Acréscimo</th>
              <th>Valor Total</th>
            </tr>
          </thead>
          <tbody>
            {produtosSelecionados.map((produto, index) => (
              <tr key={index}>
                <td>{capitalizeFirstLetter(produto.PRODUTO)}</td>
                <td>{capitalizeFirstLetter(produto.DESCRICAO)}</td>
                <td className='text-center'>{produto.FABRICANTE}</td>
                <td className='text-center'>{formatarValor(produto.VLRVENDA
)}</td>
                <td className='text-center'>{produto.quantidade}</td>
                <td className="text-center">
                  {produto.valorDesconto ? formatarValor(produto.valorDesconto) : 'R$0,00'}
                </td>
                <td className="text-center">
                  {produto.valorAcrescimo ? formatarValor(produto.valorAcrescimo) : 'R$0,00'}
                </td>
                <td>{formatarValor(produto.valorTotalProduto)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.buttonContainer}>
          <button onClick={closeModal}>Fechar</button>
        </div>
      </div>
    </div>
  );
};

export default PedidoDetalhesModal;