// ESTILO
import styles from "./EditarOrcamento.module.css";

// MODULOS
import { Form, Tab, Tabs } from "react-bootstrap";

//HOOKS
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

//COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import currentDate from "../../Utils/date";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import { formatarValor } from "../../Utils/formatarValor";
import PedidoDetalhesModal from "./VisualizarOrcamento";
import EnviarPorWhatsapp from "./EnvioWhatsapp";
import ModalProdutos from "../NovoPedido/ModalProduto/ModalProdutos";
import { isArray } from "lodash";

const EditarOrcamento = () => {
  const location = useLocation();
  const { token, codemp } = useContext(AuthContext);
  const [vendedor, setVendedor] = useState({
    nome: "",
    sobrenome: "",
    cpfCnpj: "",
    codigo: "",
  });
  const [codvend, setCodvend] = useState(null);

  const [inserirItemPedido, setInserirItemPedido] = useState(false);
  const [abaAtiva, setAbaAtiva] = useState("cabecalho");
  const [detail, setDetail] = useState(false);
  const [produtosSelecionados, setProdutosSelecionados] = useState([]);
  const [dadosPedido, setDadosPedido] = useState(null);
  const [frete, setFrete] = useState("");
  const [valorFrete, setValorFrete] = useState(0);
  const [codcli, setCodcli] = useState("");
  const [erro, setErro] = useState("");
  const [desconto, setDesconto] = useState(0);
  const [deveRedirecionar, setDeveRedirecionar] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [
    mostrarTabelaProdutosSelecionados,
    setMostrarTabelaProdutosSelecionados,
  ] = useState(true);
  const [produtosCabecalho, setProdutosCabecalho] = useState([]);

  const navigate = useNavigate();
  const pedidoId = location.state?.codped;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    negociacao: { codigo: "", descricao: "" },
    movimentacao: { codigo: "", descricao: "" },
    transportadora: { codigo: "", descricao: "", cpfCnpj: "" },
    cliente: { codigo: "", descricao: "", cpfCnpj: "" },
  });

  const [codTabPrec, setCodTabPrec] = useState("");
  const [selectedPrices, setSelectedPrices] = useState({});

  const [valorUnitario, setValorUnitario] = useState(0);

  const [codped, setCodped] = useState()

  const [loadingOrcamento, setLoadingOrcamento] = useState(false)

  const [produtosIndisponiveis, setProdutosIndisponiveis] = useState(false)

  const [botaoAvancar, setBotaoAvancar] = useState("Avançar")
  const [botaoProdutos, setBotaoProdutos] = useState("Selecionar Produtos")

  const redirecionar = useNavigate();

  useEffect(() => {
    setAuthToken(token);

    if (!token) {
      redirecionar("/orcamento");
    }
  }, [token]);

  useEffect(() => {
    sessionStorage.setItem("cabecalhoOrcamento", JSON.stringify(formValues));
  }, [formValues]);

  useEffect(() => {
    const storedCabecalho = sessionStorage.getItem("cabecalhoOrcamento");
    if (storedCabecalho) {
      setFormValues(JSON.parse(storedCabecalho));
    }
  }, []);

  const calcularValorTotalPedido = () => {
    let valorTotal = 0;

    if (produtosSelecionados && Array.isArray(produtosSelecionados)) {
      produtosSelecionados.forEach((produto) => {
        const selectedPriceInfo = selectedPrices[produto.CODPROD];
        const price = selectedPriceInfo && selectedPriceInfo.price;
        let quantidade;
        if (produto.quantidade) {
          quantidade = produto.quantidade;
        } else {
          quantidade = produto.QTD;
        }
        if (price !== undefined && produto.VALORUNIT === 0) {
          valorTotal += price * quantidade;
        } else {
          if(produto.CODTABPREC){
            valorTotal += produto.VLRVENDA * quantidade;
          } else {
            valorTotal += 0 * quantidade;
          }
        }
      });
    }
    return valorTotal;
  };
  const valorTotalPedido = calcularValorTotalPedido();

  useEffect(() => {
    sessionStorage.setItem(
      "produtosSelecionados",
      JSON.stringify(produtosSelecionados)
    );
  }, [produtosSelecionados]);

  const toggleModal = () => {
    if (!isModalOpen) {
      const objetoParaAPI = construirObjetoParaAPI();

      objetoParaAPI.dataEmissao = currentDate;
      objetoParaAPI.numeroPedido = "Número do Orçamento"; // Trocar pelo número real do pedido
      objetoParaAPI.vendedor = vendedor;
      objetoParaAPI.cliente = formValues.cliente.descricao;
      objetoParaAPI.tipoNegociacao = formValues.negociacao.descricao;
      objetoParaAPI.transportadora = capitalizeFirstLetter(
        formValues.transportadora.descricao
      );
      objetoParaAPI.valorTotal = valorTotalPedido;
      objetoParaAPI.produtosSelecionados = produtosSelecionados;

      setDadosPedido(objetoParaAPI);
    }

    setIsModalOpen(!isModalOpen);
  };

  //fecha modal de visualizar orçamento e se finalizado vai pra orçamento
  const fecharModalVisualizarPedido = () => {
    setIsModalOpen(false);

    if (deveRedirecionar) {
      redirecionar("/orcamento");
      setDeveRedirecionar(false);
    }
  };

  const adicionarProduto = (produto) => {
    setProdutosSelecionados(prevProdutos => prevProdutos.concat(produto));
    handleCloseModal();
  };
  
  const handleOpenModal = (modalType) => {
    setShowModal(modalType);
  };

  const handleCloseModal = () => {
    setShowModal(null);
  };

  useEffect(() => {
    const fetchPedido = async () => {
      setLoadingOrcamento(true)
      try {
        const response = await api.get(
          `/pedidos/buscar/?CODEMP=${codemp}&CODPED=${pedidoId}`
        );
        setProdutosCabecalho(response.data);
        setProdutosSelecionados(response.data.ItensPedido);
        console.log(response.data.ItensPedido);
        setCodped(response.data.CODPED)
        const pedidoData = response.data;

        const clienteInfo = pedidoData.CLIVEND.CLIENTE.Pessoa;
        const clienteData = {
          descricao: `${clienteInfo.NOME} ${clienteInfo.SOBRENOME}`,
          cpfCnpj: clienteInfo.CPFCNPJ,
          codigo: pedidoData.CLIVEND.CLIENTE.CODCLI,
        };

        let transportadoraData = {};
        if(pedidoData.TRANSP != []){
          const transInfo = pedidoData.TRANSP.Pessoa;
          transportadoraData = {
            descricao: `${transInfo?.NOME} ${transInfo?.SOBRENOME}`,
            cpfCnpj: transInfo?.CPFCNPJ,
          };
        } else {
          transportadoraData = {
            descricao: '',
            cpfCnpj: '',
          };
        }

        const vendedorInfo = pedidoData.CLIVEND.VENDEDOR.Usuario.Pessoa;
        const vendedorData = {
          nome: vendedorInfo.NOME,
          sobrenome: vendedorInfo.SOBRENOME,
          cpfCnpj: vendedorInfo.CPFCNPJ,
          codigo: pedidoData.CLIVEND.VENDEDOR.CODVEND,
        };

        const tipoNegociacaoInfo = pedidoData.TipoNegociacao;
        const tipoNegociacaoData = {
          descricao: tipoNegociacaoInfo.DESCRICAO,
        };

        const freteData = pedidoData.FRETE;
        const valorFreteData = pedidoData.VLRFRETE;
        const descontoData = pedidoData.DESCONTO;

        // Updating state variables
        setFormValues({
          ...formValues,
          cliente: clienteData,
          transportadora: transportadoraData,
          negociacao: tipoNegociacaoData,
        });

        setFrete(freteData);
        setValorFrete(valorFreteData);
        setDesconto(descontoData);
        setVendedor(vendedorData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingOrcamento(false)
      }
    };

    fetchPedido();
  }, [codemp, pedidoId]);

  useEffect(() => {
    setCodcli(formValues.cliente.codigo);
    setCodvend(vendedor.codigo);
  }, [formValues, vendedor]);

  const avancarParaProximaAba = async (tab) => {
    if (abaAtiva === "cabecalho" && tab === "itens") {
      onLoading(true,'avancar')
      try {
        const response = await api.get(
          `/produtos/listar/produtos/pedido?CODEMP=${codemp}&CODCLI=${codcli}&CODVEND=${Number(
            codvend
          )}`
        );
        setProdutosIndisponiveis(false);
      } catch (error) {
        console.log(error.response.data.message);
        if(error.response.data.message === 'Nenhum produto encontrado'){
          setProdutosIndisponiveis(true);
        }
      }
    } else if (abaAtiva === "itens") {
      if (produtosSelecionados.length === 0) {
        setErro(
          "Selecione pelo menos um produto antes de avançar para Detalhes."
        );
        setShowMessage(true);
        return;
      } else {
        setErro("");
      }

      let tabelaVazia = false;
      produtosSelecionados.map((item) => {
        if (item.codTabPrec == '' || item.codTabPrec === 0 || item.CODTABPREC == '' || item.CODTABPREC === 0 || !item.CODTABPREC) {
          tabelaVazia = true;
          return;
        } else {
          setErro("");
        }
      })

      if(tabelaVazia){
        setErro(
          "Selecione uma tabela de preço para cada produto antes de avançar para Detalhes."
        );
        setShowMessage(true);
        return
      }
    }
    setShowMessage(false);
    onLoading(false,'avancar')
    setAbaAtiva(tab);
  };

  const voltarParaAbaAnterior = () => {
    if (abaAtiva === "detalhes") {
      setAbaAtiva("itens");
    } else if (abaAtiva === "itens") {
      setAbaAtiva("cabecalho");
    }
  };

  const construirObjetoParaAPI = () => {

    const objetoParaAPI = {
      CODPED: pedidoId,
      CODEMP: codemp,
      ORCAMENTO: true,
      ItensPedidos: produtosSelecionados.map((produto) => ({
        CODPROD: +produto.CODPROD,
        VLRVENDA: +produto.VLRVENDA,
        QTD:
          +produto.quantidade >= 0 || null ? +produto.quantidade : +produto.QTD,
        CODTABPREC: +produto.CODTABPREC || +produto.TABELAS[0]?.CODTABPREC,
        VALORUNIT:
          +produto.VALORUNIT !==
          (selectedPrices[produto.CODPROD] &&
          selectedPrices[produto.CODPROD].price !== undefined
            ? selectedPrices[produto.CODPROD].price
            : +produto.VALORUNIT)
            ? selectedPrices[produto.CODPROD] &&
              selectedPrices[produto.CODPROD].price
            : +produto.VALORUNIT,
      })),
    };

    console.log("objetinho para api", objetoParaAPI);
    setDadosPedido(objetoParaAPI);

    return objetoParaAPI;
  };


  const salvarDadosNaAPI = async (e) => {
    e.preventDefault();

    const objetoParaAPI = construirObjetoParaAPI();
    setErro("");
    api
      .patch("/pedidos/atualizar/pedido", objetoParaAPI)
      .then((response) => {
        console.log("Orçamento atualizado com sucesso:", response.data);
        setMessage("Orçamento atualizado com sucesso!");
        setErro("");
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
          navigate("/orcamento");
        }, 3000);
      })
      .catch((error) => {
        console.error("Erro ao atualizar os dados do orçamento:", error);
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao atualizar os dados do orçamento.Tente novamente!");
        }
        setMessage("");
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      });
  };

  const handleLimparSelecao = () => {
    setProdutosSelecionados([]);
    sessionStorage.removeItem("produtosSelecionados");
    setIsModalOpen(true);
  };

  const handleQuantidadeChange = (index, novaQuantidade) => {
    setProdutosSelecionados((produtosAntigos) =>
      produtosAntigos.map((produto, i) => {
        if (i === index) {
          const desconto = produto.VLRDESCONTO || 0;
          const acrescimo = produto.VLRACRESCIMO || 0;

          // Calcula o valor total com base no valor unitário e na quantidade
          const valorUnitario = produto.VALORUNIT || 0;
          const valorTotal = valorUnitario * novaQuantidade;

          // Adiciona a lógica para calcular o desconto e o acréscimo
          const descontoTotal = desconto * novaQuantidade;
          const acrescimoTotal = acrescimo * novaQuantidade;

          // Retorna o produto com os novos valores atualizados
          return {
            ...produto,
            quantidade: novaQuantidade,
            valorDesconto: descontoTotal,
            valorAcrescimo: acrescimoTotal,
            valorTotalProduto: valorTotal - descontoTotal + acrescimoTotal,
          };
        }
        return produto;
      })
    );
  };

  const handleValorUnitarioChange = (index, novoValorUnitario) => {
    setProdutosSelecionados((produtosAntigos) =>
      produtosAntigos.map((produto, i) => {
        if (i === index) {
          const valorUnitario = isNaN(novoValorUnitario)
            ? 0
            : novoValorUnitario;
          const desconto = produto.VLRDESCONTO || 0;
          const acrescimo = produto.VLRACRESCIMO || 0;

          // Calcula o valor total com base no valor unitário e na quantidade
          const valorTotal = valorUnitario * produto.quantidade;

          // Adiciona a lógica para calcular o desconto e o acréscimo
          const descontoTotal = desconto * produto.quantidade;
          const acrescimoTotal = acrescimo * produto.quantidade;

          // Retorna o produto com os novos valores atualizados
          return {
            ...produto,
            valorUnitario: valorUnitario,
            valorTotalProduto: valorTotal - descontoTotal + acrescimoTotal,
            valorDesconto: descontoTotal,
            valorAcrescimo: acrescimoTotal,
          };
        }

        return produto;
      })
    );
  };

  const handleRemoveProduto = (index) => {
    const newProdutosSelecionados = produtosSelecionados.filter(
      (produto, i) => produto.CODPROD !== index
    );
    setProdutosSelecionados(newProdutosSelecionados);
    setValorUnitario(0);
    sessionStorage.setItem(
      "produtosSelecionados",
      JSON.stringify(newProdutosSelecionados)
    );
  };

  const productPriceMap = {};
  produtosSelecionados.forEach((tabelaPreco) => {
    tabelaPreco.TABELAS?.forEach((item) => {
      const key = `${item.CODPROD}-${item.tabelaPreco?.DESCRICAO}`;
      productPriceMap[key] = item.PRECOVENDA;
    });
  });


  const handlePriceChange = (productId, selectedTableName, novaQuantidade) => {
    const tabelaCode = produtosSelecionados.find(
      (p) => p.CODPROD === productId
    );

    if (tabelaCode && tabelaCode.TABELAS) {
      const itensTabela = tabelaCode.TABELAS.find(
        (t) => t.tabelaPreco.DESCRICAO === selectedTableName
      );

      if (itensTabela) {
        const codTable = itensTabela.CODTABPREC;
        setCodTabPrec(Number(codTable));
        tabelaCode.CODTABPREC = Number(codTable);
        tabelaCode.codTabPrec = Number(codTable);
      }
    }

    const key = `${productId}-${selectedTableName}`;
    const selectedPrice = productPriceMap[key] || "N/A";
    tabelaCode.VLRVENDA = selectedPrice;

tabelaCode.VALORUNIT = selectedPrice
    setSelectedPrices((prevSelectedPrices) => ({
      ...prevSelectedPrices,
      [productId]: {
        tableName: selectedTableName,
        price: selectedPrice,
      },
    }));

    // Update discount and surcharge values based on the selected unit price
    const selectedProduct = produtosSelecionados.find(
      (p) => p.CODPROD === productId
    );

    if (selectedProduct) {
      const newDiscount = selectedPrice * novaQuantidade;
      const newSurcharge = selectedPrice * novaQuantidade;

      setProdutosSelecionados((prevProdutos) =>
        prevProdutos.map((produto) =>
          produto.CODPROD === productId
            ? {
                ...produto,
                valorUnitario: selectedPrice,
                VLRDESCONTO: newDiscount - produto.PRECOVENDA * novaQuantidade,
                VLRACRESCIMO:
                  newSurcharge - produto.PRECOVENDA * novaQuantidade,
              }
            : produto
        )
      );
    }
    if(selectedTableName === ''){
      tabelaCode.codTabPrec = ''
      tabelaCode.valorUnitario = 0
      tabelaCode.CODTABPREC = ''
    }
  };

  const handleValorAcrescimo = (id, venda) => {
    if (selectedPrices[id]?.price - venda < 0) {
      return (selectedPrices[id]?.price - venda) * -1;
    } else {
      return 0;
    }
  };

  const handleValorDesconto = (id, venda) => {
    if (selectedPrices[id]?.price - venda > 0) {
      return selectedPrices[id]?.price - venda;
    } else {
      return 0;
    }
  };

  const getUnitPrice = (produto) => {
    // Se o preço selecionado estiver definido, use-o
    const selectedPrice = selectedPrices[produto.CODPROD]?.price;
    if (selectedPrice !== undefined) return selectedPrice;

    // Caso contrário, verifique se PRECOVENDA existe na tabela de preços correspondente
    const tabelaPrecoVenda = produto.TABELAS?.find(
      (tabela) => tabela.CODTABPREC === produto.CODTABPREC
    )?.PRECOVENDA;

    // Se PRECOVENDA estiver definido na tabela, use-o, caso contrário, use VALORUNIT do produto
    return tabelaPrecoVenda !== undefined
      ? tabelaPrecoVenda
      : produto.VALORUNIT;
  };

  const onLoading = (status, tipo) => {
    if(tipo === "avancar"){
      if(status){
        setBotaoAvancar("Carregando...")
      } else {
        setBotaoAvancar("Avançar")
      }
    }
    if(tipo === "produtos"){
      if(status){
        setBotaoProdutos("Carregando...")
      } else {
        setBotaoProdutos("Selecionar Produtos")
      }
    }
  }

  const children = (
    <div className={inserirItemPedido ? styles.blur : ""}>
      <div className={styles.topo}>
        <h1>
          <i
            class="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/orcamento");
            }}
          ></i>
          Editar Orçamento
        </h1>
      </div>

      <div className={styles.container}>
        {showMessage && (
          <div
            className={`${styles.message} ${
              message ? styles.sucesso : styles.erro
            }`}
          >
            <p>{message || erro}</p>
          </div>
        )}
        <Form className={styles.form} id="novoProdutoForm">
            <div className={styles.topoInfoPedido}>
              <h1>Campos obrigatórios(*)</h1>
              {loadingOrcamento ? <h1 className={styles.loadingTitle}>Carregando...</h1> : <div></div>}
            </div>
          <Tabs
            defaultActiveKey="cabecalho"
            id="uncontrolled-tab-example"
            activeKey={abaAtiva}
            onSelect={(eventKey) => {
              if (abaAtiva === "detalhes") {
                if (eventKey === "itens" || eventKey === "cabecalho") {
                  setAbaAtiva(eventKey);
                }
              } else if (abaAtiva === "itens") {
                if (eventKey === "cabecalho") {
                  setAbaAtiva(eventKey);
                }
              } else {
                if (eventKey !== abaAtiva) {
                  return;
                }
              }
            }}
          >
            <Tab eventKey="cabecalho" title="Cabeçalho do Orçamento">
              <fieldset className={styles.fieldsetClienteCabecalho}>
                <div className={styles.inputsCliente}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="cliente" className={styles.label}>
                      <i className="bi bi-person"></i>
                      Cliente*:
                    </Form.Label>
                    <div className={styles.conteudo}>
                      <Form.Control
                        id="cliente"
                        value={
                          formValues.cliente
                            ? `${formValues.cliente.descricao}`
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="cliente" className={styles.label}>
                      Cliente Cpf/Cnpj:
                    </Form.Label>
                    <div className={styles.conteudo}>
                      <Form.Control
                        id="cliente"
                        value={
                          formValues.cliente
                            ? `${formValues.cliente.cpfCnpj}`
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className={styles.inputsVendedor}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="vendedor" className={styles.label}>
                      <i className="bi bi-building"></i>
                      Vendedor*:
                    </Form.Label>
                    <div className={styles.conteudo}>
                      <Form.Control
                        value={`${vendedor.nome} ${vendedor.sobrenome}`}
                        readOnly
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="vendedor" className={styles.label}>
                      Vendedor Cpf/Cnpj:
                    </Form.Label>
                    <div className={styles.conteudo}>
                      <Form.Control
                        id="vendedor"
                        value={`${vendedor.cpfCnpj} `}
                        readOnly
                      />
                    </div>
                  </Form.Group>
                </div>
              </fieldset>
            </Tab>

            <Tab eventKey="itens" title="Itens do Orçamento">
              <div>
                <fieldset className={styles.fieldsetCliente}>
                {!produtosIndisponiveis ?
                  <div className={styles.formControlButtonContainer2}>
                    <Form.Group className={styles.grupo}>
                      <Form.Label htmlFor="produtos" className={styles.label}>
                        <i className="bi bi-box-seam"></i>
                        Produtos*:
                      </Form.Label>
                      <button
                        type="button"
                        onClick={() => handleOpenModal("produtos")}
                        className={`customSelectBtn ${styles.customSelectBtn}`}
                      >
                        <i className="bi bi-box-seam"></i>
                        {botaoProdutos}
                      </button>
                    </Form.Group>
                    {showModal === "produtos" && (
                      <ModalProdutos
                        onProductsSelected={adicionarProduto}
                        selectedProducts={produtosSelecionados}
                        produtosCabecalho={produtosCabecalho}
                        onClose={handleCloseModal}
                        onLoading={onLoading}
                        cliente={codcli}
                        vendedor={codvend}
                      />
                    )}
                  </div> : 
                  <p style={{marginLeft: '1rem'}}>Este orçamento não pode ser editado pois não há tabela de preço e/ou produtos vinculados ao Cliente/Vendedor.</p>}
                </fieldset>
                <div className={`${styles.baseContainer} `}>
                  {mostrarTabelaProdutosSelecionados && (
                    <div className={`container-fluid py-4 ${styles.boxTable}`}>
                      <div className={styles.text}>Produtos Selecionados</div>
                      <table
                        id="tablePedidos"
                        className={`table align-items-center justify-content-center container-fluid py-4 mb-0 ${styles.table}`}
                      >
                        <thead>
                          <tr>
                            <th className={styles.icone} colSpan="1">
                              Ação
                            </th>
                            <th>Produto</th>
                            <th className="col-1 text-center">Fabricante</th>
                            <th className="col-1 text-center">
                              Tabela de Preço
                            </th>
                            <th className="col-1 text-center">
                              Valor Unitário Tabela
                            </th>
                            <th className="col-1 text-center">
                              Valor Unitário
                            </th>
                            <th className="col-1 text-center">Quantidade</th>
                            <th className="col-1 text-center">
                              Valor Desconto
                            </th>
                            <th className="col-1 text-center">
                              Valor Acréscimo
                            </th>
                            <th>Valor Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {produtosSelecionados &&
                            produtosSelecionados.map((produto, index) => {
                              return (
                                <tr key={index}>
                                  <td className={styles.icone} colSpan="1">
                                    <button
                                    disabled={produtosIndisponiveis || produto.TABELAS.length === 0 ||
                                      (produto.DATAINC && !produto.TABELAS.some(item => item.CODTABPREC == produto.TABANTIGA?.CODTABPREC) && !produto.tabelaAlterada)}  
                                      onClick={() => handleRemoveProduto(produto.CODPROD)}
                                    >
                                      <i className="bi bi-trash-fill"></i>
                                    </button>
                                  </td>
                                  <td>{produto && produto.PRODUTO}</td>
                                  <td>{produto && produto.FABRICANTE}</td>
                                  <td className={styles.selectTables} style={{whiteSpace: 'nowrap'}}>
                                    <select 
                                      style={{ width: "7rem" }}
                                      className="form-control
                                      "
                                      value={produto.CODTABPREC === "" ? "" :
                                        selectedPrices[produto.CODPROD]
                                          ? selectedPrices[produto.CODPROD]
                                              .tableName
                                          : produto.TABELAS?.find(
                                              (tabela) =>
                                                tabela.CODTABPREC ==
                                                produto.CODTABPREC
                                            )?.tabelaPreco.DESCRICAO
                                      }
                                      onChange={(e) => {
                                        const selectedTableName =
                                          e.target.value;
                                        handlePriceChange(
                                          produto.CODPROD,
                                          selectedTableName
                                        );
                                      }}
                                      disabled={produto.QTDESTOQUE === null || produto.TABELAS.length === 0
                                        || (produto.DATAINC && !produto.TABELAS.some(item => item.CODTABPREC == produto.TABANTIGA?.CODTABPREC) && !produto.tabelaAlterada)}
                                    >
                                      {!produto.CODTABPREC || produto.CODTABPREC === '' ? (
                                        <option value="">
                                          {produto.QTDESTOQUE === null
                                            ? "Produto sem estoque"
                                            : "Selecione uma Tabela de Preço"}
                                        </option>
                                      ) : null}
                                        {/*se o produto nao estiver em nenhuma outra tabela*/}
                                         {produto.TABELAS.length === 0 && (
                                        <option value={produto.TABANTIGA?.DESCRICAO}>
                                          {produto.TABANTIGA?.DESCRICAO}
                                        </option>
                                      )}
                                      {/*se o produto nao estiver mais na tabela cadastrada, mas esta em outras*/}
                                      {produto.DATAINC && !produto.TABELAS.some(item => item.CODTABPREC == produto.TABANTIGA?.CODTABPREC) && !produto.tabelaAlterada && <option
                                      key={index}
                                      value={produto.TABANTIGA?.DESCRICAO}
                                    >
                                      {produto.TABANTIGA?.DESCRICAO}
                                    </option>}
                                      {!isArray(produto.TABELAS) ? (
                                        <option
                                          key={index}
                                          value={produto.TABELAS?.DESCRICAO}
                                        >
                                          {produto.TABELAS?.DESCRICAO}
                                        </option>
                                      ) : (
                                        produto.TABELAS?.map(
                                          (tabela, index) => (
                                            
                                            tabela.PRECOVENDA > 0 || tabela.PRECOVENDA != '' ? (
                                              <option
                                                key={index}
                                                value={
                                                  tabela.tabelaPreco
                                                    ? tabela.tabelaPreco?.DESCRICAO
                                                    : tabela.DESCRICAO
                                                }
                                              >
                                                {tabela.tabelaPreco
                                                  ? tabela.tabelaPreco?.DESCRICAO
                                                  : tabela.DESCRICAO}
                                              </option>
                                            ) : null
                                            
 
                                          )
                                        )
                                      )}
                                    </select>
                                    {produto.DATAINC && !produto.TABELAS.some(item => item.CODTABPREC === produto.TABANTIGA?.CODTABPREC) && !produto.tabelaAlterada && (
                                    <i className="bi bi-pen" onClick={() => {
                                      if (window.confirm("Deseja alterar a tabela de preço? Não será possível selecionar novamente a tabela salva atualmente!!")) {
                                        console.log(produtosSelecionados);
                                        const produtosAtualizados = produtosSelecionados.map(p => (p === produto ? {...produto, CODTABPREC: '', VLRDESCONTO: 0, VLRACRESCIMO: 0, VALORUNIT: 0,VLRVENDA:0,tabelaAlterada: true} : p));
                                        setProdutosSelecionados(produtosAtualizados);
                                      }
                                    }}></i>
                                  )}
                                  </td>
                                  <td className="text-center">
                                    {produto.CODTABPREC ? `R$ ${parseFloat(
                                      getUnitPrice(produto)
                                    ).toLocaleString("pt-BR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}` : 'R$0,00'}
                                  </td>
                                  <td>
                                    <input
                                      disabled
                                      className={styles.inputVlorUnitario}
                                      type="text"
                                      value={produto.CODTABPREC ? `R$${produto.VLRVENDA}` : 'R$0,00'}
                                      onChange={(e) => {
                                        const novoValorUnitario =
                                          e.target.value === ""
                                            ? 0
                                            : parseFloat(e.target.value);
                                        handleValorUnitarioChange(
                                          index,
                                          novoValorUnitario
                                        );
                                      }}
                                    />
                                  </td>
                                  <td className={`text-center ${styles.containerQtd}`}>
                                    <button
                                    disabled={produtosIndisponiveis || produto.TABELAS.length === 0
                                      || (produto.DATAINC && !produto.TABELAS.some(item => item.CODTABPREC == produto.TABANTIGA?.CODTABPREC) && !produto.tabelaAlterada)}
                                    type="button"
                                    className={`${styles.quantidadeButton} ${
                                      showMessage ? styles.errorButton : ""
                                    }`}
                                    onClick={() => {
                                      if (produto.quantidade ? produto.quantidade > 1 : produto.QTD > 1) {
                                        handleQuantidadeChange(
                                          index,
                                          produto.quantidade
                                            ? produto.quantidade - 1
                                            : produto.QTD - 1
                                        );
                                      }
                                    }}
                                  >
                                    -
                                  </button>
                                      <input
                                      disabled={produtosIndisponiveis || produto.TABELAS.length === 0
                                        || (produto.DATAINC && !produto.TABELAS.some(item => item.CODTABPREC == produto.TABANTIGA?.CODTABPREC) && !produto.tabelaAlterada)}
                                      type="text"
                                      value={produto.quantidade
                                        ? produto.quantidade.toString()
                                        : produto.QTD.toString()}
                                      onChange={(e) => {
                                        const novaQuantidade =
                                          e.target.value === ""
                                            ? 0
                                            : parseInt(e.target.value, 10);
                                        handleQuantidadeChange(
                                          index,
                                          novaQuantidade
                                        );
                                      }}
                                      onFocus={(e) => {
                                        e.target.value = "";
                                      }}
                                      className={`${styles.quantidadeInput} ${styles.smallInput}`}
                                    />
                                    <button
                                    disabled={produtosIndisponiveis || produto.TABELAS.length === 0
                                      || (produto.DATAINC && !produto.TABELAS.some(item => item.CODTABPREC == produto.TABANTIGA?.CODTABPREC) && !produto.tabelaAlterada)}
                                      type="button"
                                      className={`${styles.quantidadeButton} ${
                                        showMessage ? styles.errorButton : ""
                                      }`}
                                      onClick={() =>
                                        {

                                          if(produto.quantidade){
                                            if(produto.quantidade < produto.QTDESTOQUE){
                                              handleQuantidadeChange(
                                                index,
                                                produto.quantidade
                                                  ? produto.quantidade + 1
                                                  : produto.QTD + 1
                                              )
                                            } else {
                                              alert("Estoque do produto atingido!")
                                            }
                                          } else {
                                            if(produto.QTD < produto.QTDESTOQUE){
                                              handleQuantidadeChange(
                                                index,
                                                produto.quantidade
                                                  ? produto.quantidade + 1
                                                  : produto.QTD + 1
                                              )
                                            } else {
                                              alert("Estoque do produto atingido!")
                                            }
                                          }
                                          }
                                      }
                                    >
                                      +
                                    </button>
                                  </td>
                                  <td className="col-1 text-center">
                                    {`R$ ${(
                                      (produto.VLRDESCONTO ||
                                        handleValorDesconto(
                                          produto.CODPROD,
                                          produto.VLRVENDA
                                        ) ||
                                        0 ||
                                        0) *
                                      (produto.quantidade || produto.QTD || 0)
                                    ).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                                  </td>
                                  <td className="col-1 text-center">
                                    {`R$ ${(
                                      (produto.VLRACRESCIMO ||
                                        handleValorAcrescimo(
                                          produto.CODPROD,
                                          produto.VLRVENDA
                                        ) ||
                                        0 ||
                                        0) *
                                      (produto.quantidade || produto.QTD || 0)
                                    ).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                                  </td>

                                  <td className="col-1 text-center">
                                    {produto.CODTABPREC ? (
                                    produto.VLRVENDA !== 0 ? (
                                      produto.quantidade
                                        ? formatarValor(produto.VLRVENDA * produto.quantidade)
                                        : formatarValor(produto.VLRVENDA * produto.QTD)
                                    ) : 'R$0,00'
                                  ) : 'R$0,00'}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <button
                    disabled={produtosIndisponiveis}
                    type="button"
                    className={styles.limparSelecaoButton}
                    onClick={handleLimparSelecao}
                  >
                    Limpar Seleção
                  </button>
                </div>

                <fieldset className={styles.fieldsetDetalhes}>
                  <div
                    className={styles.detalhesDiv}
                    onClick={() =>
                      detail ? setDetail(false) : setDetail(true)
                    }
                  >
                    <p>
                      <i class="bi bi-info-circle"></i>
                      Detalhes do Orçamento
                    </p>
                    {!detail && <i class="bi bi-chevron-down"></i>}
                    {detail && <i class="bi bi-chevron-up"></i>}
                  </div>

                  <div className={detail ? styles.open : styles.close}>
                    <div className={`container-fluid py-4 ${styles.boxTable}`}>
                      <table
                        id="tablePedidos"
                        className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                      >
                        <tbody>
                          <tr>
                            <td className={styles.legenda}>
                              Data da emissão:{" "}
                            </td>
                            <td className="text-left">{currentDate}</td>
                            <td className={styles.legenda}>Nº do Orçamento: </td>
                            <td className="text-left">{codped}</td>
                          </tr>
                          <tr>
                            <td className={styles.legenda}>
                              Tipo de Movimentação:{" "}
                            </td>
                            <td className="text-left">Venda</td>
                          </tr>
                          <tr>
                            <td className={styles.legenda}>Vendedor: </td>
                            <td className="text-left">
                              {capitalizeFirstLetter(vendedor.nome)} {capitalizeFirstLetter(vendedor.sobrenome)}
                            </td>
                            <td className={styles.legenda}>Cliente: </td>
                            <td className="text-left">
                              {capitalizeFirstLetter(
                                formValues.cliente.descricao
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className={styles.legenda}>
                              Tipo de Negociação:{" "}
                            </td>
                            <td className="text-left">{formValues.negociacao.descricao}</td>
                            <td className={styles.legenda}>Transportadora: </td>
                            <td className="text-left">
                            {formValues.transportadora.descricao && !formValues.transportadora.descricao.includes('undefined') ? capitalizeFirstLetter(
                                formValues.transportadora.descricao
                              ) : "Não selecionada"}
                            </td>
                          </tr>
                          <tr>
                            <td className={styles.legenda} colSpan="3">
                              Valor Total:{" "}
                            </td>
                            <td className={`${styles.valorTotal}`} colSpan="1">
                              {formatarValor(valorTotalPedido)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </fieldset>
              </div>
            </Tab>
            <Tab eventKey="detalhes" title="Detalhes do Orçamento">
              <fieldset className={styles.fieldsetTransportadora}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="transportadora" className={styles.label}>
                    <i className="bi bi-truck"></i>
                    Transportadora:
                  </Form.Label>
                  <div className={styles.conteudo}>
                    <Form.Control
                      id="transportadora"
                      className={styles.select}
                      required
                      value={formValues.transportadora.descricao &&
                        !formValues.transportadora.descricao.includes('undefined')
                          ? `${formValues.transportadora.descricao}`
                          : "Não selecionada."
                      }
                      readOnly
                    />
                  </div>
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label
                    htmlFor="transportadoraCpfCnpj"
                    className={styles.label}
                  >
                    Transportadora Cpf/Cnpj:
                  </Form.Label>
                  <div className={styles.conteudo}>
                    <Form.Control
                      id="transportadoraCpfCnpj"
                      value={formValues.transportadora.cpfCnpj
                        ? `${formValues.transportadora.cpfCnpj}`
                        : "Não selecionada."
                    }
                      readOnly
                    />
                  </div>
                </Form.Group>
              </fieldset>

              <fieldset className={styles.fieldsetCliente}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="tipoNegociacao" className={styles.label}>
                    Tipo de Negociação:
                  </Form.Label>
                  <div className={styles.conteudo}>
                    <Form.Control
                      id="tipoNegociacao"
                      className={styles.select}
                      required
                      value={
                        formValues.negociacao
                          ? `${formValues.negociacao.descricao}`
                          : ""
                      }
                      readOnly
                    />
                  </div>
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="frete" className={styles.label}>
                    Frete:
                  </Form.Label>
                  <div className={styles.conteudo}>
                    <Form.Control id="frete" value={frete} readOnly />
                  </div>
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="valorFrete" className={styles.label}>
                    Valor do Frete (R$):
                  </Form.Label>
                  <Form.Control
                    className={styles.select}
                    type="text"
                    id="valorFrete"
                    value={valorFrete}
                    onChange={(e) => {
                      const valorUsuario = e.target.value;
                      setValorFrete(valorUsuario);
                    }}
                    onBlur={(e) => {
                      const valorDigitado = e.target.value;

                      if (
                        valorDigitado.trim() === "" ||
                        valorDigitado.trim() === "0"
                      ) {
                        setValorFrete("");
                      } else {
                        const valorNumerico = parseFloat(
                          valorDigitado.replace(",", ".")
                        );
                        if (!isNaN(valorNumerico)) {
                          setValorFrete(
                            Number.isInteger(valorNumerico)
                              ? String(valorNumerico)
                              : valorNumerico.toFixed(2).replace(".", ",")
                          );
                        }
                      }
                    }}
                    disabled
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="valorDesconto" className={styles.label}>
                    Valor do Desconto (R$):
                  </Form.Label>
                  <Form.Control
                    className={styles.select}
                    type="text"
                    id="valorDesconto"
                    value={desconto}
                    disabled
                    onChange={(e) => {
                      const valorUsuario = e.target.value;
                      setDesconto(valorUsuario);
                    }}
                    onBlur={(e) => {
                      const valorDigitado = e.target.value;

                      if (
                        (valorDigitado && valorDigitado.trim() === "") ||
                        valorDigitado.trim() === "0"
                      ) {
                        setDesconto("");
                      } else {
                        const valorNumerico = parseFloat(
                          valorDigitado.replace(",", ".")
                        );
                        if (valorNumerico === 0) {
                          setDesconto("0");
                        } else if (!isNaN(valorNumerico)) {
                          setDesconto(
                            Number.isInteger(valorNumerico)
                              ? String(valorNumerico)
                              : valorNumerico.toFixed(2).replace(".", ",")
                          );
                        }
                      }
                    }}
                  />
                </Form.Group>
              </fieldset>
            </Tab>
          </Tabs>
          <div
            className={styles.buttonContainer}
            style={{
              justifyContent:
                abaAtiva === "cabecalho" ? "flex-end" : "space-between",
            }}
          >
            {abaAtiva === "cabecalho" && (
              <div className={styles.buttonAvancar}>
                {abaAtiva === "cabecalho" ? (
                  <button
                    type="button"
                    onClick={() => {
                      if (abaAtiva === "cabecalho") {
                        avancarParaProximaAba("itens");
                      }
                    }}
                  >
                    {botaoAvancar}
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => avancarParaProximaAba("detalhes")}
                  >
                    Avançar
                  </button>
                )}
              </div>
            )}
          </div>
          {abaAtiva === "itens" && (
            <div
              className={styles.buttonAvancar}
              style={{ justifyContent: "space-between" }}
            >
              <div className={styles.buttonContainer}>
                <div className={styles.buttons}>
                  <button
                    type="button"
                    onClick={(e) => voltarParaAbaAnterior(e)}
                  >
                    Voltar
                  </button>
                </div>
              </div>
              <button
                type="button"
                onClick={() => {
                  avancarParaProximaAba("detalhes");
                }}
              >
                Avançar
              </button>
            </div>
          )}

          <div
            className={styles.buttonAvancar}
            style={{ justifyContent: "space-between" }}
          >
            {abaAtiva === "detalhes" && (
              <div className={styles.buttons}>
                <button type="button" onClick={(e) => voltarParaAbaAnterior(e)}>
                  Voltar
                </button>
              </div>
            )}
            {abaAtiva === "detalhes" && (
              <div className={styles.buttonContainer}>
                <button
                  type="button"
                  onClick={toggleModal}
                  style={{ marginRight: "10px" }}
                  className={styles.botao}
                >
                  <i
                    className="bi bi-receipt"
                    style={{ marginRight: "5px" }}
                  ></i>
                  <span>Visualizar</span>
                </button>
                {isModalOpen && dadosPedido && (
                  <PedidoDetalhesModal
                    prices={selectedPrices}
                    pedido={dadosPedido}
                    produtosSelecionados={produtosSelecionados}
                    closeModal={fecharModalVisualizarPedido}
                  />
                )}
                <button
                  style={{ marginRight: "10px" }}
                  className={styles.botao}
                >
                  <i
                    className="bi bi-envelope"
                    style={{ marginRight: "5px" }}
                  ></i>
                  <span>Enviar por e-mail</span>
                </button>
                <EnviarPorWhatsapp
                  pedido={dadosPedido}
                  produtosSelecionados={produtosSelecionados}
                  //refatorar - está dependente de visualizar pedido para renderizar
                />
                {!produtosIndisponiveis ? <button
                  type="button"
                  onClick={salvarDadosNaAPI}
                  style={{ marginLeft: "10px" }}
                >
                  <i
                    className="bi bi-play-fill"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Salvar
                </button> : <button
                  type="button"
                  onClick={() => navigate('/orcamento')}
                  style={{ marginLeft: "10px" }}
                >
                  Fechar
                </button>}
              </div>
            )}
          </div>
        </Form>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
    </>
  );
};

export default EditarOrcamento;
