// ESTILO
import styles from "./Estoque.module.css";

// HOOKS
import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

// MODULOS
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import axios from "axios";

const Estoque = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const tableRef = useRef(null);
  const [estoque, setEstoque] = useState([]);
  const [loading,setLoading] = useState(false)
  const [semDados, setSemDados] = useState("");
  const [reload, setReload] = useState(false);

  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    setAuthToken(token);
  }, [token]);
  console.log(loading);
  // useEffect(() => {
  //   const getEstoque = async () => {
  //     setCarregando(true)
  //     setErro("")
  //     try {
  //       const response = await api.get(`/estoque/listar?CODEMP=${codemp}`);
  //       setEstoque(response.data);
  //       setErro("")
  //     } catch (error) {
  //       console.log(
  //         "Lista de estoque não encontrada, tente novamente mais tarde ou entre em contato conosco."
  //       );
  //     } finally {
  //       setCarregando(false)
  //     }
  //   };
  //   getEstoque();
  // }, [codemp, token]);

  useEffect(() => {
    
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    const getEstoques = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/estoque/listar?CODEMP=${CODEMP}`, {
          cancelToken: source.token
        });
        if(response.data.length === 0 ){
          setEstoque([])
          setSemDados("Sem dados")
        } else {
          setEstoque(response.data);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
      } finally {
        setLoading(false);
        setReload(false)
      }
    };
    getEstoques();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp]);

  useEffect(() => {
    if (
      estoque.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
        order:[[8,'asc'],[2,'asc']]
      });
    }
  }, [estoque]);

  const editarEstoque = (codest) => {
    navigate("/editar-estoque", { state: { codest } });
  };

  function formatarData(data) {
    const dataUTC = new Date(data).toISOString();
    const dataObj = new Date(dataUTC);
    const dia = dataObj.getUTCDate().toString().padStart(2, "0");
    const mes = (dataObj.getUTCMonth() + 1).toString().padStart(2, "0");
    const ano = dataObj.getUTCFullYear();
    return `${dia}/${mes}/${ano}`;
  }

  const showTable = () => {
    return estoque.map((item, index) => {
      const { CODEST, ESTOQUE, LOTE, CODEMPSAN, STATUS } = item;
      const { PRODUTO } = item.Produtos;     
      return (
        <tr key={index}>
          <td style={{width: '50px'}} className="text-center">
            {userData.PERMISSAO.toLowerCase() === "administrador" ? (
              <i
                style={{ cursor: "pointer" }}
                className="bi bi-pen"
                onClick={() => editarEstoque(item.CODEST)}
              ></i>
            ) : (
              <i
                className="bi bi-pen"
                title="Apenas o Administrador pode executar essa ação"
                disabled
                style={{ cursor: "not-allowed" }}
              ></i>
            )}
          </td>
          <td className="col-1 text-center">{CODEST}</td>
          <td>{capitalizeFirstLetter(PRODUTO)}</td>
          <td className="col-1 text-center">{ESTOQUE}</td>
          <td className="col-1 text-center">{capitalizeFirstLetter(LOTE)}</td>
          <td className="col-1 text-center">{formatarData(item.DATAVENC)}</td>
          <td className="col-1 text-center">{item.Locais?.LOCAL ? item.Locais?.LOCAL : "-"}</td>
          <td className="col-1 text-center">{CODEMPSAN ? CODEMPSAN : '-'}</td>
          <td className="col-1 text-center">{STATUS.replace("ESTOQUE_",'')}</td>
        </tr>
      );
    })
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <div className={styles.topoButtons}>
              {userData.PERMISSAO.toLowerCase() === "administrador" ? (
                <button onClick={() => navigate("/adicionar-estoque")}>
                  Adicionar um novo estoque
                </button>
              ) : (
                <button style={{ display: "none" }}>
                  Adicionar um novo estoque
                </button>
              )}
              <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} title="Atualizar" onClick={()=>window.location.reload()}></i>
            </div>
          </div>
          <div className={styles.corpo}>
            <div className={`container-fluid py-4 ${styles.boxTable}`}>
              <div className="table-responsive p-0 pb-2">
                <h1 className={styles.titleTable}>Estoque</h1>
                <table
                  ref={tableRef}
                  className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                >
                  <thead>
                    <tr>
                      <th className="text-center">
                        Ação
                      </th>
                      <th>Código</th>
                      <th>Produto</th>
                      <th>Estoque</th>
                      <th>Lote</th>
                      <th>Data Vencimento</th>
                      <th>Local</th>
                      <th>Empresa Sankhya</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>{estoque.length === 0 && semDados === '' ? <tr>
                      <td colSpan="8">
                        <LoadingCentroTelas />
                      </td>
                    </tr> : semDados === 'Sem dados' ? <tr>
                      <td style={{ textAlign: "center" }} colSpan="8">
                      A tabela está vazia no momento.
                      </td>
                    </tr> : showTable()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </CaixaConteudo>
    </>
  );
};

export default Estoque;
