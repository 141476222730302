import styles from "./EditarMeta.module.css";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import { Modal, Button } from "react-bootstrap";
import MessageModal from "../../Components/MessageModal/MessageModal";

const EditarMetas = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const location = useLocation();
  const { CODMETA } = location.state;
  const [selectedTableId, setSelectedTableId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [meta, setMeta] = useState("");
  const [mensal, setMensal] = useState("");
  const [anual, setAnual] = useState("");

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    api
      .get(`/metas/buscar/?CODEMP=${Number(codemp)}&CODMETA=${Number(CODMETA)}`)
      .then((response) => {
        console.log(response.data);
        setMeta(response.data.META);
        setMensal(response.data.MENSAL);
        setAnual(response.data.ANUAL);
      })
      .catch((error) => {
        console.error("Erro ao obter meta:", error);
      });
  }, [codemp, CODMETA]);

  const handleAtualizaMetas = (e) => {
    e.preventDefault();
    if (meta === "" || mensal === "" || anual === "") {
      setModalMessage("Preencha todos os campos!");
      setShowMessageModal(true);
      return;
    }

    const metaAtualizada = {
      CODEMP: Number(codemp),
      META: meta,
      CODMETA: Number(CODMETA),
      MENSAL: Number(mensal),
      ANUAL: Number(anual),
    };

    console.log("metaAtualizada: ", metaAtualizada);

    api
      .patch(`/metas/atualizar`, metaAtualizada)
      .then((response) => {
        console.log("Metas atualizada com sucesso:", response.data);
        setModalMessage("Metas atualizada com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        console.error("Erro ao atualizar metas:", error);
        let errorMessage = "";
        if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao atualizar metas.Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
      });
  };

  const handleDeleteMeta = () => {
    const deleteMeta = {
      CODEMP: Number(codemp),
      CODMETA: Number(CODMETA),
    };

    if (selectedTableId) {
      api
        .delete(`/metas/remover`, { data: deleteMeta })
        .then(() => {
          setShowModal(false);
          console.log("Meta excluída com sucesso");
          setMessage("Meta excluída com sucesso!");
          setErro("");
          setShowMessage(true);

          setTimeout(() => {
            setShowMessage(false);
            navigate("/metas");
          }, 3000);
        })
        .catch((error) => {
          console.error("Erro ao excluir Meta:", error);
          if (error.response && error.response.status === 500) {
            setErro(
              "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
            );
          } else {
            setErro("Erro ao excluir meta.Tente novamente!");
          }
          setMessage("");
          setShowMessage(true);
          setTimeout(() => {
            setShowMessage(false);
          }, 3000);
        });
    }
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/metas");
  };

  const openModal = (tableId) => {
    setSelectedTableId(tableId);
    setShowModal(true);
  };

  const renderModal = () => {
    return (
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header>
          <Modal.Title>Confirmar exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Deseja excluir a meta?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleDeleteMeta}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            className="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/metas");
            }}
          ></i>
          Editar Metas
        </h1>
      </div>

      <div className={styles.corpo}>
        <Form className={styles.form} onSubmit={handleAtualizaMetas}>
          {showMessage && (
            <div
              className={`${styles.message} ${
                message ? styles.successMessage : styles.errorMessage
              }`}
            >
              <p>{message || erro}</p>
            </div>
          )}
          <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="meta" className={styles.label}>
                Meta:
              </Form.Label>
              <Form.Control
                id="meta"
                value={capitalizeFirstLetter(meta)}
                onChange={(e) => setMeta(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="mensal" className={styles.label}>
                Mensal:
              </Form.Label>
              <Form.Control
                id="mensal"
                value={mensal}
                onChange={(e) => setMensal(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="anual" className={styles.label}>
                Anual:
              </Form.Label>
              <Form.Control
                id="anual"
                value={anual}
                onChange={(e) => setAnual(e.target.value)}
              />
            </Form.Group>
          </fieldset>

          <fieldset className={styles.fieldsetButton}>
            <div className={styles.rodape}>
              <button
                className={`${styles.submit} ${styles.excluirButton}`}
                type="button"
                onClick={() => openModal(codemp)}
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-trash-fill"></i>
                Excluir
              </button>
              <button
                className={`${styles.submit} ${styles.salvarButton}`}
                type="submit"
                onClick={handleAtualizaMetas}
              >
                <i className="bi bi-play-fill"></i>
                Salvar
              </button>
            </div>
          </fieldset>
        </Form>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
      {renderModal()}
      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default EditarMetas;
