import styles from './LogImport.module.css';
import React, { useContext, useEffect, useRef, useState } from 'react'
import Menu from '../../Components/Menu/Menu';
import Cabecalho from '../../Components/Cabecalho/Cabecalho';
import CaixaConteudo from '../../Components/CaixaConteudo/CaixaConteudo';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Services/AuthContext';
import api, { setAuthToken } from '../../Services/api';
import axios from 'axios';
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import LoadingCentroTelas from '../../Components/LoadingCentroTelas/LoadingCentroTelas';
import { capitalizeFirstLetter } from '../../Utils/stringCapitalizer';

const LogImport = () => {
    const { token, codemp } = useContext(AuthContext);
    const tableRef = useRef(null);
    const navigate = useNavigate()
    const location = useLocation();

    const [logs, setLogs] = useState([]);
    const [semDados, setSemDados] = useState('');
    const [reload, setReload] = useState(false);
    const [TIPO, setTIPO] = useState(null)

    useEffect(() => {
        setAuthToken(token);
        setTIPO(location.state ? location.state.tipo : null)
      }, []);

    useEffect(() => {
        const source = axios.CancelToken.source();
        const CODEMP = codemp || sessionStorage.getItem("CODEMP");
        setLogs([])
        setSemDados('')
        if(location.state?.tipo){
            const getLogs = async () => {
                try {
                  let response;
                  if (location.state.tipo === 'clientes') {
                      response = await api.get(`/clientes/importar/cabecalho/listar?CODEMP=${CODEMP}`, {
                        cancelToken: source.token
                      });
                    } else {
                      response = await api.get(`/produtos/importar/cabecalho/listar?CODEMP=${CODEMP}`, {
                        cancelToken: source.token
                      });
                    }
                  if(response.data.length === 0 ){
                    setSemDados("Sem dados")
                  } else {
                    setLogs(response.data);
                  }
                } catch (error) {
                  if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                  } else {
                    console.error(error);
                  }
                } finally {
                  setReload(false)
                }
              };
              getLogs();
            }
        return () => {
          source.cancel('Component unmounted');
        };
      }, [codemp,reload]);  

      useEffect(() => {
        if (
          logs.length > 0 &&
          tableRef.current &&
          !$.fn.DataTable.isDataTable(tableRef.current)
        ) {

          $(tableRef.current).DataTable({
            language: {
              search: "Pesquisar",
              info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
            },
            lengthChange: false,
            //destroy: true,
            ordering: true,
            order: [1, 'desc']
          });
        } 
      }, [logs]);   

      function formatarData(dataString) {
        const data = new Date(dataString);
        
        const hh = String(data.getHours()).padStart(2, '0');
        const mm = String(data.getMinutes()).padStart(2, '0');
        const ss = String(data.getSeconds()).padStart(2, '0');
        const dd = String(data.getDate()).padStart(2, '0');
        const mmFormatado = String(data.getMonth() + 1).padStart(2, '0'); // Os meses em JavaScript são baseados em zero
        const aaaa = data.getFullYear();
      
        return `${dd}/${mmFormatado}/${aaaa} ${hh}:${mm}:${ss}`;
      }

    return (
        <>
          <Menu />
          <Cabecalho />
          <CaixaConteudo>
          <div className={styles.grid}>
        <div className={styles.topo}>
          <div className={styles.topoButtons}>
            <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} onClick={() => window.location.reload()} title="Atualizar" ></i>
          </div>
          </div>
          <div className={styles.corpo}>
            <div className={`${styles.boxTable} container-fluid py-4`}>
              <div className="table-responsive p-0 pb-2">
                <h1 className={styles.titleTable}>Log de Importação - {capitalizeFirstLetter(TIPO)}</h1>
                    <table
                      ref={tableRef}
                      id="tableLogs"
                      className={`${styles.table} table align-items-center justify-content-center mb-0`}
                    >
                      <thead>
                        <tr>
                          <th style={{width:'50px'}} className='text-center'>Ação</th>
                          <th className="text-center">Cód.Log</th>
                          <th className="text-center">Nº. Tentativa</th>
                          <th className="text-center">Data</th>
                        </tr>
                      </thead>
                      <tbody>
                        {logs.length === 0 && semDados === '' ? <tr>
                      <td colSpan="8">
                        <LoadingCentroTelas />
                      </td>
                    </tr> : semDados === 'Sem dados' ? <tr>
                      <td style={{ textAlign: "center" }} colSpan="8">
                      A tabela está vazia no momento.
                      </td>
                    </tr> : (
                          logs.map((log) => {
                            return (
                              <React.Fragment key={log.CODLOGCAB}>
                                  <tr key={log.CODLOGCAB}>
                                    <td>
                                        <i class={`bi bi-eye-fill ${styles.btnEdit}`}
                                        onClick={location.state.tipo === 'clientes' ? 
                                        () => navigate('/detalhe-log-import', {state: {tipo: 'clientes', codlogcab: log.CODLOGCAB}}) : 
                                        () => navigate('/detalhe-log-import', {state: {tipo: 'produtos', codlogcab: log.CODLOGCAB}})}
                                        ></i>
                                    </td>
                                    <td>{log.CODLOGCAB}</td>
                                    <td>{log.TENTATIVA}</td>
                                    <td>{log.DATAINC ? formatarData(log.DATAINC) : ""}</td>
                                  </tr>
                              
                              </React.Fragment>
                            );
                          })
                        )}
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
          </CaixaConteudo>    
        </>
      );
}

export default LogImport