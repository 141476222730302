// ESTILOS
import styles from "./EditarCategoria.module.css";

// HOOKS
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";

// LIBS
import Form from "react-bootstrap/Form";
import { Modal, Button } from "react-bootstrap";
import $ from "jquery";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net";

// COMPONENTES
import Cabecalho from "../../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../../Components/Menu/Menu";
import MessageModal from "../../../Components/MessageModal/MessageModal";
import { AuthContext } from "../../../Services/AuthContext";
import api, { setAuthToken } from "../../../Services/api";
import ModalNovaSubcategoria from "../ModalNovaSubcategoria/ModalNovaSubcategoria";

const EditarCategoria = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const location = useLocation();
  const { codcatprod } = location.state || {};
  const [codCatPai, setCodCatPai] = useState("");
  console.log("---->", codcatprod);
  console.log("aaaaaaaaa", codCatPai);

  const [nomeCategoria, setNomeCategoria] = useState("");
  const [descricao, setDescricao] = useState("");

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteModalSubcategoria, setShowDeleteModalSubcategoria] =
    useState(false);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [subCategoriaVinculada, setSubCategoriaVinculada] = useState([]);
  const [codSub, setCodSub] = useState("");
  const [showSubcategoriaModal, setShowSubcategoriaModal] = useState(false);

  const [botaoSalvar,setBotaoSalvar] = useState("Salvar")
  const [loading, setLoading] = useState(false)

  const tableRef = useRef(null);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
    setLoading(true)
    api
      .get(
        `/categoria/buscar?CODEMP=${Number(CODEMP)}&CODCATPROD=${Number(
          codcatprod
        )}`
      )
      .then((response) => {
        console.log(">>>>>>>", response.data);
        setNomeCategoria(response.data.CATEGORIA);
        setDescricao(response.data.DESCRICAO);
        setSubCategoriaVinculada(response.data.CategoriasFilhos);
        setCodCatPai(response.data.CODCATPROD);
        setLoading(false)
      })
      .catch((error) => {
        console.error("Erro ao obter categoria:", error);
        setLoading(false)
      });
  }, [codemp, codcatprod, isLoading]);

  useEffect(() => {
    if (
      subCategoriaVinculada.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
        order:[3,'asc']
      });
    }
  }, [subCategoriaVinculada]);

  const handleAtualizarCategoria = (e) => {
    e.preventDefault();
    if (nomeCategoria === "") {
      setModalMessage("Preencha o campo obrigatório: Categoria!");
      setShowMessageModal(true);
      return;
    }

    const categoriaAtualizada = {
      CODEMP: +codemp,
      CODCATPROD: +codcatprod,
      CATEGORIA: nomeCategoria,
      DESCRICAO: descricao,
    };

    setBotaoSalvar("Carregando...")

    api
      .patch(`/categoria/atualizar`, categoriaAtualizada)
      .then((response) => {
        console.log("Categoria atualizada com sucesso:", response.data);
        setModalMessage("Categoria atualizada com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
        setBotaoSalvar("Salvar")
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao atualizar categoria.Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
        setBotaoSalvar("Salvar")
      });
  };

  const handleDeleteCategoria = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(false);
    setIsLoading(true);

    const requestData = {
      CODEMP: Number(codemp),
      CODCATPROD: Number(codcatprod),
    };
    console.log("request data", requestData);

    api
      .delete(`/categoria/deletar`, { data: requestData })
      .then((response) => {
        console.log("Categoria deletada com sucesso!", response.data);
        setMessage("Categoria excluída com sucesso!");
        setErro("");
        setShowMessage(true);
        setIsLoading(false);
        

        setTimeout(() => {
          setShowMessage(false);
          navigate("/categoria");
        }, 3000);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response && error.response.status === 400) {
          errorMessage = "Você precisa excluir as subcategorias primeiro.";
          setModalMessage(errorMessage);
          setIsSuccess(false);
          setShowMessageModal(true);
        } else if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao deletar categoria. Tente novamente!");
        }
        setMessage("");
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
        console.error("Erro ao excluir categoria:", error);
        console.log("codcatprod", codcatprod, "codemp", codemp);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/categoria");
  };

  // Modal delete subcategoria
  const handleDeleteSubcategoria = async (codSub) => {
    setShowDeleteModalSubcategoria(true);
    console.log("CODCATPROD", codSub);
    setCodSub(codSub);
  };

  const handleConfirmDeleteSubCategoria = () => {
    setShowDeleteModalSubcategoria(false);
    setIsLoading(true)

    const requestData = {
      CODEMP: Number(codemp),
      CODCATPROD: Number(codSub),
    };
    console.log("request data", requestData);

    api
      .delete(`/categoria/deletar`, { data: requestData })
      .then((response) => {
        console.log("Subcategoria deletada com sucesso!", response.data);
        setMessage("Subcategoria excluída com sucesso!");
        setErro("");
        setShowMessage(true);
        setIsLoading(false)

        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Erro ao excluir subcategoria:", error);
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao deletar subcategoria.Tente novamente");
        }
        setMessage("");
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
        console.log("codSub", codSub, "codemp", codemp);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseDeleteModalSubcategoria = () => {
    setShowDeleteModalSubcategoria(false);
  };

  const handleCloseModalSubcategoria = () => {
    setShowMessageModal(false);
  };

  const handleOpenSubcategoriaModal = () => {
    setShowSubcategoriaModal(true);
  };

   // Função para adicionar uma nova subcategoria à lista existente
   const adicionarNovaSubcategoria = (novaSubcategoria) => {
    setSubCategoriaVinculada([...subCategoriaVinculada, novaSubcategoria]);
  };

  const showTable = () => (
    <div className={styles.corpoTable}>
      <div className={`container-fluid py-4 ${styles.boxTable}`}>
        <div className="table-responsive p-0 pb-2">
          <h1 className={styles.titleTable}>Lista de Subcategorias</h1>
          <table
            ref={tableRef}
            className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
          >
            <thead>
              <tr>
                <th>
                  Ação
                </th>
                <th className="text-center">Código</th>
                <th>Subcategoria</th>
                <th>Descrição</th>
              </tr>
            </thead>
            <tbody>
              {subCategoriaVinculada.map((item, index) => (
                <tr key={index}>
                  <td style={{width:'50px'}} className="text-center">
                    <i 
                    style={{ cursor: "pointer" }}
                    className="bi bi-trash"
                    title="Deletar"
                    onClick={() => handleDeleteSubcategoria(item.CODCATPROD)}></i>
                  </td>
                  <td className="text-center">{item.CODCATPROD}</td>
                  <td>{item.CATEGORIA}</td>
                  <td>{item.DESCRICAO}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <h1>
              <i
                className="bi bi-arrow-left-circle-fill"
                onClick={() => {
                  navigate("/categoria");
                }}
              ></i>
              Editar Categoria
            </h1>

            <div className={styles.topoButtons}>
              <button onClick={handleOpenSubcategoriaModal}>
                Adicionar uma Subcategoria
              </button>
            </div>
          </div>

          <div className={styles.corpo}>
            <Form className={styles.form} onSubmit={handleAtualizarCategoria}>
              {showMessage && (
                <div
                  className={`${styles.message} ${
                    message ? styles.successMessage : styles.errorMessage
                  }`}
                >
                  <p>{message || erro}</p>
                </div>
              )}
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <p style={{fontSize:'.7rem'}}>Campos obrigatórios(*)</p>
                  {loading && <p style={{color: 'red'}}>Carregando...</p>}
                </div>
              <fieldset className={styles.fieldsetTopo}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="nomeCategoria" className={styles.label}>
                    Categoria*:
                  </Form.Label>
                  <Form.Control
                    id="nomeCategoria"
                    value={nomeCategoria}
                    onChange={(e) => setNomeCategoria(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="descricao" className={styles.label}>
                    Descrição:
                  </Form.Label>
                  <Form.Control
                    id="descricao"
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                  />
                </Form.Group>
              </fieldset>

              {showTable()}

              
            </Form>
          </div>
          <fieldset className={styles.fieldsetButton}>
                <div className={styles.rodape}>
                  <button
                    className={`${styles.submit} ${styles.excluirButton}`}
                    type="button"
                    onClick={handleDeleteCategoria}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-trash-fill"></i>
                    Excluir
                  </button>
                  <button
                    className={`${styles.submit} ${styles.salvarButton}`}
                    type="submit"
                    onClick={handleAtualizarCategoria}
                  >
                    <i className="bi bi-play-fill"></i>
                    {botaoSalvar}
                  </button>
                </div>
              </fieldset>
        </div>
        {showSubcategoriaModal && (
      <ModalNovaSubcategoria
        handleFecharModal={() => setShowSubcategoriaModal(false)}
        codCatPai={codCatPai}
        adicionarNovaSubcategoria={adicionarNovaSubcategoria} // Passe a função como prop
      />
    )}
      </CaixaConteudo>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que deseja excluir esta categoria?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancelar
          </Button>
          <Button
            className={`${styles.submit} ${styles.excluirButton}`}
            onClick={handleConfirmDelete}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Delete Subcategoria */}
      <Modal
        show={showDeleteModalSubcategoria}
        onHide={handleCloseDeleteModalSubcategoria}
        centered
      >
        <Modal.Header>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja excluir está subcategoria?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseDeleteModalSubcategoria}
          >
            Cancelar
          </Button>
          <Button
            className={`${styles.submit} ${styles.excluirButton}`}
            onClick={handleConfirmDeleteSubCategoria}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={() => {
          handleCloseModal();
          handleCloseModalSubcategoria();
        }}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default EditarCategoria;
