// ESTILO
import styles from './Segmento.module.css'

// HOOKS
import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

//LIBS
import $ from "jquery";
import LoadingCentroTelas from '../../Components/LoadingCentroTelas/LoadingCentroTelas';
import axios from 'axios';

const Segmento = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const [segmentos, setSegmentos] = useState([])
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [semDados, setSemDados] = useState('')
  const [reload, setReload] = useState(false)

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  // useEffect(() => {
  //   const getSegmentos = async () => {
  //     const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
  //     setLoading(true)
  //     try{
  //       const response = await api.get(`/segmento/listar?CODEMP=${CODEMP}`);
  //       setSegmentos(response.data);
  //       setLoading(false)
  //     } catch (error) {
  //       console.error(error);
  //       setLoading(false)
  //     }
  //   };
  //   getSegmentos();
  // }, [codemp, token]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    setLoading(true);
    const getSegmentos = async () => {
      try {
        const response = await api.get(`/segmento/listar?CODEMP=${CODEMP}`, {
          cancelToken: source.token
        });
        if(response.data.length === 0 ){
          setSegmentos([])
          setSemDados("Sem dados")
        } else {
          setSegmentos(response.data);
        }
        setLoading(false);
        setReload(false)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
        setLoading(false);
        setReload(false)
      }
    };
    getSegmentos();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp,reload]);

  useEffect(() => {
    if (
      segmentos.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
      });
    }
  }, [segmentos]);

  const editarSegmentos = (codseg) => {
    navigate("/editar-segmentos", { state: { codseg } });
  };

  const showTable = () => {
    return segmentos.map((item, index) => {
      const { CODSEG, SEGMENTO, DESCRICAO } = item;
      return (
        <tr key={index}>
          <td style={{width: '50px'}} className="text-center">
            <i
              style={{ cursor: "pointer" }}
              className="bi bi-pen"
              onClick={() => editarSegmentos(item.CODSEG)}
            ></i>
          </td>
          <td className="text-center">{CODSEG}</td>
          <td className="text-center">{SEGMENTO}</td>
          <td className="text-center">{DESCRICAO}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <div className={styles.topoButtons}>
              <button
                onClick={() => {
                  navigate("/adicionar-segmentos");
                }}
              >
               Adicionar um novo segmento
              </button>
              <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} title="Atualizar" onClick={()=>setReload(true)}></i>
            </div>
          </div>
          <div className={styles.corpo}>
            <div className={`container-fluid py-4 ${styles.boxTable}`}>
              <div className="table-responsive p-0 pb-2">
              <h1 className={styles.titleTable}>Segmentos</h1>
                <table
                ref={tableRef}
                  className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                >
                  <thead>
                    <tr>
                      <th className="text-center col-1">
                      Ação
                      </th>
                      <th className="text-center">Código</th>
                      <th className="text-center">Segmento</th>
                      <th className="text-center">Descrição</th>
                    </tr>
                  </thead>
                  <tbody>{loading ? <tr>
                    <td colSpan="8">
                      <LoadingCentroTelas />
                    </td>
                  </tr> : semDados === 'Sem dados' ? <tr>
                    <td style={{ textAlign: "center" }} colSpan="8">
                    A tabela está vazia no momento.
                    </td>
                  </tr> : showTable()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </CaixaConteudo>
    </>
  );
}

export default Segmento