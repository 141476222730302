import React, { useState } from "react";
import styles from "./VisualizarPedido.module.css";

import { formatarValor } from "../../Utils/formatarValor";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";

const PedidoDetalhesModal = ({ pedido, closeModal }) => {
  console.log("entrei no modaaaaaaal");
  console.log("produtos selecionados do modal", pedido.produtosSelecionados);
  const produtosSelecionados = pedido.produtosSelecionados;
  const [selectedPrices, setSelectedPrices] = useState({});

  const {
    dataEmissao,
    vendedor,
    cliente,
    tipoNegociacao,
    transportadora,
    CODPED
  } = pedido;

  const calcularValorTotalPedido = () => {
    let valorTotal = 0;

    if (produtosSelecionados && Array.isArray(produtosSelecionados)) {
      produtosSelecionados.forEach((produto) => {
        const selectedPriceInfo = selectedPrices[produto.CODPROD];
        const price = selectedPriceInfo && selectedPriceInfo.price;
        let quantidade;
        if (produto.quantidade) {
          quantidade = produto.quantidade;
        } else {
          quantidade = produto.QTD;
        }
        if (price !== undefined && produto.VALORUNIT === 0) {
          valorTotal += price * quantidade;
        } else {
          if(produto.CODTABPREC){
            valorTotal += produto.VLRVENDA * quantidade;
          } else {
            valorTotal += 0 * quantidade;
          }        
        }
      });
    }

    return valorTotal;
  };
  const valorTotalPedido = calcularValorTotalPedido();

  const handleValorDesconto = (produto) => {
    if (produto.VALORUNIT - produto.VLRVENDA > 0) {
      return produto.VALORUNIT - produto.VLRVENDA;
    } else {
      return 0;
    }
  };

  const handleValorAcrescimo = (produto) => {
    if (produto.VALORUNIT - produto.VLRVENDA < 0) {
      return (produto.VALORUNIT - produto.VLRVENDA) * -1;
    } else {
      return 0;
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h3>
          <i class="bi bi-clipboard"></i>
          Detalhes do Pedido
        </h3>
        <table className={styles.tableDetalhe}>
          <tbody>
            <tr>
              <td className={styles.legenda}>Data da emissão:</td>
              <td style={{textAlign: 'left'}} className={styles.valor}>{dataEmissao}</td>
              <td className={styles.legenda} rowSpan="2">
                Nº do Pedido:
              </td>
              <td style={{textAlign: 'left'}} className={styles.valor} rowSpan="2">
                {CODPED}
              </td>
            </tr>
            <tr>
              <td className={styles.legenda}>Tipo de Movimentação:</td>
              <td style={{textAlign: 'left'}}>Venda</td>
            </tr>
            <tr>
              <td className={styles.legenda}>Vendedor:</td>
              <td style={{textAlign: 'left'}}>
                {capitalizeFirstLetter(vendedor.nome)} {capitalizeFirstLetter(vendedor.sobrenome)}
              </td>
              <td className={styles.legenda}>Cliente:</td>
              <td style={{textAlign: 'left'}} className={styles.valor}>{capitalizeFirstLetter(cliente)}</td>
            </tr>
            <tr>
              <td className={styles.legenda}>Tipo de Negociação:</td>
              <td style={{textAlign: 'left'}}>{tipoNegociacao}</td>
              <td className={styles.legenda}>Transportadora:</td>
              <td style={{textAlign: 'left'}} className={styles.valor}>{transportadora && !transportadora.includes('undefined') ? transportadora : "Não selecionada"}</td>
            </tr>
            <tr>
              <td className={styles.legenda} colSpan="2">
                Valor total:
              </td>
              <td colSpan="2" className={styles.valor}>
                {formatarValor(valorTotalPedido)}
              </td>
            </tr>
          </tbody>
        </table>
        <h3>
          <i className="bi bi-box-seam"></i>
          Produtos Selecionados
        </h3>
        <table className={styles.tableDetalhe}>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Descrição</th>
              <th>Fabricante</th>
              <th>Valor Unitário</th>
              <th>Quantidade</th>
              <th>Valor Desconto</th>
              <th>Valor Acréscimo</th>
              <th>Valor Total</th>
            </tr>
          </thead>
          <tbody>
            {produtosSelecionados.map((produto, index) => (
              <tr key={index}>
                <td>{capitalizeFirstLetter(produto.PRODUTO)}</td>
                <td>{capitalizeFirstLetter(produto.DESCRICAO)}</td>
                <td className="text-center">{produto.FABRICANTE}</td>
                <td className="text-center">
                  {formatarValor(produto.VALORUNIT)}
                </td>
                <td className="text-center">
                  {produto.quantidade || produto.QTD}
                </td>
                <td className="text-center">
                  {`R$ ${(
                    (produto.VLRDESCONTO ||
                      handleValorDesconto(produto) ||
                      0 ||
                      0) * (produto.quantidade || produto.QTD || 0)
                  ).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                </td>
                <td className="text-center">
                  {`R$ ${(
                    (produto.VLRACRESCIMO ||
                      handleValorAcrescimo(produto) ||
                      0 ||
                      0) * (produto.quantidade || produto.QTD || 0)
                  ).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                </td>
                <td>
                  {produto.quantidade
                    ? formatarValor(produto.VLRVENDA * produto.quantidade)
                    : formatarValor(produto.VLRVENDA * produto.QTD)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.buttonContainer}>
          <button onClick={closeModal}>Fechar</button>
        </div>
      </div>
    </div>
  );
};

export default PedidoDetalhesModal;
