// ESTILO
import styles from "./EditarVendedor.module.css";

// Module
import Form from "react-bootstrap/Form";
import InputMask from "react-input-mask";
import { Tabs, Tab } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";

// HOOKS
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import React, { useContext } from "react";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import MessageModal from "../../Components/MessageModal/MessageModal";
import AddTabelaPrecoVendedor from "./TabelaPreco/AddTabelaPrecoVendedor";

const EditarVendedor = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const location = useLocation();
  const { codVend } = location.state;
  const codpes = location.state?.codpes;

  const [codigoSelected, setCodigoSelected] = useState("");
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [cpfcnpj, setCpfcnpj] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState(null);
  const [comissao, setComissao] = useState("");
  const [codusu, setCodUsu] = useState("");

  const [gestores, setGestores] = useState("");
  const [listaGestores, setListaGestores] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [botaoSalvar, setBotaoSalvar] = useState("Salvar")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    const getGestores = async () => {
      const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
      setLoading(true)
      try {
        const response = await api.get(
          `/vendedor/listar/gestores?CODEMP=${CODEMP}`
        );
        setListaGestores(response.data);
      } catch (error) {
        console.log("Error: ", error);
      } finally {
        setLoading(false)
      }
    };
    getGestores();
  }, [codemp]);

  useEffect(() => {
    const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
    api
      .get(
        `/vendedor/buscar/?CODEMP=${Number(CODEMP)}&CODVEND=${Number(codVend)}`
      )
      .then((response) => {
        console.log("vendedor", response.data);
        const pessoa = response.data.Usuario.Pessoa;
        const NOME = capitalizeFirstLetter(
          pessoa && pessoa.NOME ? pessoa.NOME : "-"
        );
        const SOBRENOME = capitalizeFirstLetter(
          pessoa && pessoa.SOBRENOME ? pessoa.SOBRENOME : "-"
        );
        const email = pessoa && pessoa.EMAIL ? pessoa.EMAIL : "-";
        const Telefone = pessoa && pessoa.TELEFONE ? pessoa.TELEFONE : "-";
        const Cpf = pessoa && pessoa.CPFCNPJ ? pessoa.CPFCNPJ : "-";
        const gestor = response.data;
        const Gestor = gestor && gestor.CODGEST ? gestor.CODGEST : "";

        setCodigoSelected(response.data.CODVEND);
        setNome(NOME);
        setSobrenome(SOBRENOME);
        setCpfcnpj(Cpf);
        setTelefone(Telefone);
        setEmail(email);
        setComissao(response.data.COMISSAOVENDA);
        setCodUsu(response.data.Usuario.Pessoa.CODUSU);
        setGestores(Gestor);
      })
      .catch((error) => {
        console.error("Erro ao obter vendedor:", error);
      });
  }, [codVend, codemp]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const emptyFields = [];

    if (gestores === "") {
      emptyFields.push("Gestor");
    }

    if (emptyFields.length > 0) {
      const emptyFieldsMessage = `Preencha o campo obrigatório: <strong>${emptyFields.join(
        "</strong>, <strong>"
      )}</strong>`;
      setModalMessage(
        <>
          <p dangerouslySetInnerHTML={{ __html: emptyFieldsMessage }} />
        </>
      );
      setShowMessageModal(true);
      return;
    }

    const vendedorAtualizado = {
      CODVEND: codVend,
      CODEMP: codemp,
      CPFCNPJ: cpfcnpj,
      NOME: nome,
      SOBRENOME: sobrenome,
      TELEFONE: telefone,
      CODGEST: Number(gestores),
    };

    setBotaoSalvar("Carregando...")

    api
      .patch(`/vendedor/atualiza`, vendedorAtualizado, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Vendedor atualizado com sucesso:", response.data);
        setModalMessage("Vendedor atualizado com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao atualizar vendedor. Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
      })
      .finally(()=>{
        setBotaoSalvar("Salvar")
      })
  };

  const handleDeleteVendedor = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(false);

    const requestData = {
      CODEMP: Number(codemp),
      CODVEND: Number(codVend),
    };

    api
      .delete(`/vendedor/remover`, { data: requestData })
      .then((response) => {
        console.log("Vendedor excluído com sucesso:", response.data);
        setMessage("Vendedor excluído com sucesso!");
        setErro("");
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
          navigate("/vendedores");
        }, 3000);
      })
      .catch((error) => {
        console.error("Erro ao excluir vendedor:", error);
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao deletar vendedor.Tente novamente!");
        }
        setMessage("");
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
        console.log("codvend:", codVend, "codemp:", codemp);
      });
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/vendedores");
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <h1>
              <i
                className="bi bi-arrow-left-circle-fill"
                onClick={() => {
                  navigate("/vendedores");
                }}
              ></i>
              Editar Vendedor
            </h1>
          </div>

          <div className={styles.corpo}>
            <Tabs defaultActiveKey="geral" id="informacoes-vendedor">
              <Tab eventKey="geral" title="Informações Gerais">
                <Form className={styles.form} onSubmit={handleSubmit}>
                  {showMessage && (
                    <div
                      className={`${styles.message} ${
                        message ? styles.successMessage : styles.errorMessage
                      }`}
                    >
                      <p>{message || erro}</p>
                    </div>
                  )}
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <p style={{fontSize:'.7rem'}}>Campos obrigatórios(*)</p>
                  {loading && <p style={{color: 'red'}}>Carregando...</p>}
                </div>
                  <fieldset className={styles.fieldsetTopo}>
                    <Form.Group className={styles.grupo}>
                      <Form.Label
                        htmlFor="codigoSelected"
                        className={styles.label}
                      >
                        Código:
                      </Form.Label>
                      <Form.Control
                        id="codigoSelected"
                        value={codigoSelected}
                        onChange={(e) => setCodigoSelected(e.target.value)}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group className={styles.grupo}>
                      <Form.Label htmlFor="nome" className={styles.label}>
                        Nome*:
                      </Form.Label>
                      <Form.Control
                        disabled
                        id="nome"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className={styles.grupo}>
                      <Form.Label htmlFor="sobrenome" className={styles.label}>
                        Sobrenome*:
                      </Form.Label>
                      <Form.Control
                        disabled
                        id="sobrenome"
                        value={sobrenome}
                        onChange={(e) => setSobrenome(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className={styles.grupo}>
                      <Form.Label htmlFor="cpfcnpj" className={styles.label}>
                        CPF*:
                      </Form.Label>
                      <Form.Control
                        disabled
                        id="cpfcnpj"
                        as={InputMask}
                        mask="999.999.999-99"
                        value={cpfcnpj}
                        onChange={(e) => setCpfcnpj(e.target.value)}
                      />
                    </Form.Group>
                  </fieldset>

                  <fieldset className={styles.fieldsetTopo}>
                    <Form.Group className={styles.grupo}>
                      <Form.Label htmlFor="telefone" className={styles.label}>
                        Telefone*:
                      </Form.Label>
                      <Form.Control
                        disabled
                        id="telefone"
                        as={InputMask}
                        mask="(99) 99999-9999"
                        value={telefone}
                        onChange={(e) => setTelefone(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className={styles.grupo}>
                      <Form.Label htmlFor="email" className={styles.label}>
                        E-mail*:
                      </Form.Label>
                      <Form.Control
                        disabled
                        id="email"
                        mask=""
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className={styles.grupo}>
                      <div className={styles.segmento}>
                        <Form.Label htmlFor="gestores" className={styles.label}>
                          <i class="bi bi-people"></i>
                          Gestor*:
                        </Form.Label>
                      </div>
                      <Form.Select
                        className="form-control"
                        id="gestores"
                        value={gestores}
                        onChange={(e) => {
                          setGestores(e.target.value);
                        }}
                      >
                        <option value=''>Selecione uma opção</option>
                        {listaGestores.map((gestor) => (
                          <option key={gestor.CODGEST} value={gestor.CODGEST}>
                            {gestor.Usuario.Pessoa.NOME}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </fieldset>

                  <fieldset className={styles.fieldsetButton}>
                    <div className={styles.rodape}>
                      <button
                        className={`${styles.submit} ${styles.excluirButton}`}
                        type="button"
                        onClick={handleDeleteVendedor}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="bi bi-trash-fill"></i>
                        Excluir
                      </button>
                      <button
                        className={`${styles.submit} ${styles.salvarButton}`}
                        type="submit"
                        onClick={handleSubmit}
                      >
                        <i className="bi bi-play-fill"></i>
                        {botaoSalvar}
                      </button>
                    </div>
                  </fieldset>
                </Form>
              </Tab>
              <Tab eventKey="tabelaPreco" title="Tabela de Preços">
                <AddTabelaPrecoVendedor />
              </Tab>
            </Tabs>
          </div>
        </div>
      </CaixaConteudo>
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que deseja excluir este vendedor?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancelar
          </Button>
          <Button
            className={`${styles.submit} ${styles.excluirButton}`}
            onClick={handleConfirmDelete}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default EditarVendedor;
