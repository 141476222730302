// ESTILO
import styles from "./NovoCliente.module.css";

// MODULOS
import { Form } from "react-bootstrap";
import InputMask from "react-input-mask";

// HOOKS
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import MessageModal from "../../Components/MessageModal/MessageModal";
import { validarCamposVazios } from "../../Utils/validarCamposVazios";
import { validarCPFouCNPJ } from "../../Utils/validarCPFouCNPJ";

const NovoCliente = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);

  //CONDICIONA TELA POR CPF OU CNPJ
  const [cpf, setCpf] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cpfOuCnpj, setcpfOuCnpj] = useState('cpf');

  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("")
  const [razaoSocial, setRazaoSocial] = useState("")
  const [tipo, setTipo] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [codEnd, setCodEnd] = useState("");
  const [referencia, setReferencia] = useState("");
  const [complemento, setComplemento] = useState("");

  const [listaTabelaPrecos, setListaTabelaPrecos] = useState([]);
  const [tabelaPreco, setTabelaPreco] = useState("");

  const [listaSegmentos, setListaSegmentos] = useState([]);
  const [segmento, setSegmento] = useState("");

  const [erro, setErro] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [cnpjError, setCnpjError] = useState("");
  const [cepError,setCepError] = useState('')
  const [manualAddressEntry, setManualAddressEntry] = useState(false)

  const [botaoSalvar,setBotaoSalvar] = useState("Salvar")

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

//PEGAR TODOS OS SEGMENTOS CADASTRADOS
useEffect(() => {
  setAuthToken(token);
}, [token]);

useEffect(() => {
  const getSegmentos = async () => {
    try {
      const response = await api.get(`/segmento/listar?CODEMP=${codemp}`);
      setListaSegmentos(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  getSegmentos();
}, [codemp, token]);

//PEGAR TODAS AS TABELAS DE PREÇO CADASTRADAS
  useEffect(() => {
    const getTabelasPreco = async () => {
      try {
        const response = await api.get(`/tabela-precos/listar?CODEMP=${Number(codemp)}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setListaTabelaPrecos(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getTabelasPreco();
  }, [codemp, token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErro("");
    
    const dadosObrigatorios = {
      ...(cpfOuCnpj === 'cnpj'
      ? { 'CNPJ': cnpj,
          'Razão Social': razaoSocial,
          'Nome Fantasia': nomeFantasia,
        }
      : { 'CPF': cpf,
          'Nome': nome,
          'Sobrenome': sobrenome,
        }),
        'Telefone': telefone,
      'E-mail': email,
      'CEP': cep,
      'Número': numero
    };

    if(validarCamposVazios(dadosObrigatorios).length > 0) {
      setModalMessage(`Preencha os campos obrigatorios: ${validarCamposVazios(dadosObrigatorios)}!`);
      setShowMessageModal(true);
      return ;
    } 

    if(validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj) === 'CPF' || validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj) === 'CNPJ') {
      setModalMessage(`${validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj)} inválido!`);
      setShowMessageModal(true);
      return;
    } 

    const novoCliente = {
      CODEMP: codemp,
      Pessoa: {
        NOME: cpfOuCnpj === 'cpf' ? nome : razaoSocial,
        SOBRENOME: cpfOuCnpj === 'cpf' ? sobrenome : nomeFantasia,
        EMAIL: email,
        TELEFONE: telefone,
        CARGO: "Cliente",
        CPFCNPJ: cpfOuCnpj === "cpf" ? cpf : cnpj,
        TIPO: cpfOuCnpj === "cpf" ? "F" : "J",
      },
      Endereco: {
        CODEND: codEnd,
        NUMERO: numero,
        REFERENCIA: referencia,
        COMPLEMENTO: complemento,
      },
      Cliente: {
          CODSEG: Number(segmento)
      }
    };

    setBotaoSalvar("Carregando...")

    if(tabelaPreco){
      const clientePromise = api.post(`/clientes/cadastrar`, novoCliente);

    clientePromise
    .then((response) => {
      const codcli = response.data.CODCLI;

      const vincularTabelaPreco = {
        CODTABPREC: Number(tabelaPreco),
        CODEMP: codemp,
        CODCLI: Number(codcli) 
      };

      const tabelaPrecoPromise = api.post(`/tabela-precos/adicionar/cliente`, vincularTabelaPreco);

      return tabelaPrecoPromise;
    })
    .then((tabelaPrecoResponse) => {
      console.log("Tabela de preço vinculada com sucesso:", tabelaPrecoResponse.data);

      resetarInputs();

      setModalMessage("Cliente criado e tabela de preços vinculada com sucesso!");
      setIsSuccess(true);
      setShowMessageModal(true);
    })
    .catch((error) => {
      console.error("Erro ao vincular Tabela de preço:", error);
  
      if (error.response) {
        if (error.response.status === 409) {
          setModalMessage("Conflito: Cliente já cadastrado.");
        } else if (error.response.status === 500) {
          setModalMessage("Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.");
        } else {
          setModalMessage("Erro ao criar cliente. Tente novamente!");
        }
      } else {
        setModalMessage("Erro ao fazer a solicitação. Verifique sua conexão com a internet.");
      }
  
      setIsSuccess(false);
      setShowMessageModal(true);
    })
    .finally(()=>{
      setBotaoSalvar("Salvar")
    })
    } else {
      api
      .post(`/clientes/cadastrar`, novoCliente)
      .then((response) => {
        console.log("Cliente cadastrado com sucesso:", response.data);
        setModalMessage("Cliente cadastrado com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = "";
        if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao cadastrar cliente.Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
      })
      .finally(()=>{
        setBotaoSalvar("Salvar")
      })
    }
  };

  //RESETAR O VALOR DE TODOS OS INPUTS
  const resetarInputs = () => {
    setCpf("");
    setNome("");
    setSobrenome("");
    setNomeFantasia("")
    setRazaoSocial("")
    setTipo("");
    setTelefone("");
    setEmail("");
    setCep("");
    setEndereco("");
    setNumero("");
    setBairro("");
    setCidade("");
    setEstado("");
    setCodEnd(null);
    setErro("");
    setTabelaPreco("")
    setSegmento("")
  };

// Função para alternar entre Pessoa Física e Pessoa Jurídica
  const handleTipoPessoaChange = (e) => {
    setcpfOuCnpj(e.target.value);
    if (e.target.value === "cnpj") {
      setCpf("");
    } else {
      setCnpj("");
    }
    resetarInputs()
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const resetarCNPJ = () => {
    setCepError('')
    setCnpjError('')

    setRazaoSocial("");
    setNomeFantasia("");
    setEmail("");
    setTelefone("");

    setCep("")
    setNumero("");
    setComplemento("");
    setEstado("");
    setCidade("");
    setBairro("");
    setEndereco("");
    setReferencia("");
    setManualAddressEntry(false);
  }

  //PEGAR DADOS DE EMPRESA POR CNPJ E PREENCHER CAMPOS
  const buscarCNPJ = async () => {

    resetarCNPJ()
    try {
      if (cnpj) {
        const { data } = await api.get(`/empresas/buscar/cnpj?CNPJ=${cnpj}`);
        console.log("dadosss", data);

        if (data.error) {
          setCnpjError(`⚠️${data.message}`);
          setTimeout(() => {
            setCnpjError("");
          }, 3000);
        } else {
          setRazaoSocial(data.RAZAOSOCIAL);
          setNomeFantasia(data.NOMEFANTASIA);
          setEmail(data.EMAIL);
          setTelefone(data.TELEFONE);
          setNumero(data.NUMERO);
          setComplemento(data.COMPLEMENTO);

          const cepSemPontos = data.CEP ? data.CEP.replace(/\D/g, '') : '';
          setCep(cepSemPontos);

          if (cepSemPontos && cepSemPontos !== "") {
            handleCepChange(cepSemPontos);
          }
        }
      }
    } catch (error) {
      console.error("Erro ao buscar CNPJ:", error);
    }
  };

//BUSCAR DADOS DO ENDERECO DE ACORDO COM O CEP E PREENCHER CAMPOS
  const handleCepChange = async (newCep) => {
    newCep = newCep.replace(/\D/g, "");
    setCep(newCep);

    if (newCep === "" || newCep.length !== 8) {
      setCodEnd(0);
      setEstado("");
      setCidade("");
      setBairro("");
      setEndereco("");
      setNumero("");
      setComplemento("");
      setReferencia("");
      setCepError("");
      setManualAddressEntry(false);
      return;
    }

    try {
      if (newCep.length === 8) {
        const response = await api.get(`/enderecos/buscar?CEP=${newCep}`);
        const enderecoData = response.data;
        setCodEnd(enderecoData.CODEND);
        setEstado(enderecoData.ESTADO);
        setCidade(enderecoData.CIDADE);
        setBairro(enderecoData.BAIRRO);
        setEndereco(`${enderecoData.TIPOEND} ${enderecoData.NOMEEND}`);
        setReferencia(enderecoData.REFERENCIA);
        setCepError("");

        if (enderecoData.TIPOEND === undefined && enderecoData.NOMEEND === undefined) {
          setManualAddressEntry(true);
          setCepError("⚠️CEP não encontrado, digite manualmente seu endereço para cadastro.");
          setEndereco('');
          setBairro('');
          setCidade('');
          setEstado('');
        } else {
          setManualAddressEntry(false);
          setEndereco(`${enderecoData.TIPOEND} ${enderecoData.NOMEEND}`);
        }
      } else {
        setCepError("⚠️CEP não encontrado");
      }
    } catch (error) {
      console.log("API error:", error);
    }
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            class="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/clientes");
            }}
          ></i>
          Novo Cliente
        </h1>
      </div>

      <div className={styles.corpo}>
        <Form className={styles.form} id="novoClienteForm">
          <h1>Campos obrigatórios(*)</h1>
          {cnpjError && <span style={{ color: 'red', textAlign: 'right', marginRight: '40%' }}>{cnpjError}</span>}
          <fieldset className={styles.fieldsetTopo1}>
            <Form.Group className={`${styles.grupo}`}>
              <Form.Label htmlFor="tipoPessoa" className={styles.label}>
                Tipo de Pessoa*:
              </Form.Label>
              <Form.Select
                id="tipoPessoa"
                className="form-control"
                value={cpfOuCnpj}
                onChange={handleTipoPessoaChange}
              >
                <option value="cpf">Pessoa Física</option>
                <option value="cnpj">Pessoa Jurídica</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className={`${styles.grupo}`}>
              <Form.Label htmlFor="cpfCnpj" className={styles.label}>
                {cpfOuCnpj === "cnpj" ? "CNPJ*" : "CPF*"}:
              </Form.Label>
              <div className={styles.inputWithIcon}>
              <Form.Control
                id="cpfCnpj"
                as={InputMask}
                mask={
                  cpfOuCnpj === "cnpj" ? "99.999.999/9999-99" : "999.999.999-99"
                }
                value={cpfOuCnpj === "cnpj" ? cnpj : cpf}
                onChange={(e) => {
                  if(e.target.value.length === 14) {
                    setcpfOuCnpj('cpf')
                  } else {
                    setcpfOuCnpj('cnpj')
                  }
                  if (cpfOuCnpj === "cnpj") {
                    setCnpj(e.target.value);
                  } else {
                    setCpf(e.target.value);
                  }
                }}
              />
              {cpfOuCnpj === "cnpj" && (
                <i
                  className={`bi bi-search ${styles.searchIcon}`}
                  onClick={buscarCNPJ}
                ></i>
              )}
              </div>
            </Form.Group>

             <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="tabelaPreco" className={styles.label}>
                Vincular tabela de preço:
              </Form.Label>
              <Form.Select
                className="form-control"
                id="tabelaPreco"
                value={tabelaPreco}
                onChange={(e) => setTabelaPreco(e.target.value)}
              >
                {!tabelaPreco && <option value="">Selecionar tabela</option>}
                {listaTabelaPrecos.map((tabela) => (
                  <option key={tabela.CODTABPREC} value={tabela.CODTABPREC}>
                    {tabela.DESCRICAO}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="segmentos" className={styles.label}>
                Vincular segmento:
              </Form.Label>
              <Form.Select
                className="form-control"
                id="segmentos"
                value={segmento}
                onChange={(e) => setSegmento(e.target.value)}
              >
                {!segmento && <option value="">Selecionar segmentos</option>}
                {listaSegmentos.map((segmento) => (
                  <option key={segmento.CODSEG} value={segmento.CODSEG}>
                    {segmento.DESCRICAO}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </fieldset>

          <fieldset className={styles.fieldsetTopo}>
            {cpfOuCnpj === 'cpf' ? <><Form.Group className={styles.grupo}>
              <Form.Label htmlFor="nome" className={styles.label}>
                Nome*:
              </Form.Label>
              <Form.Control
                id="nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="sobrenome" className={styles.label}>
                Sobrenome*:
              </Form.Label>
              <Form.Control
                id="sobrenome"
                value={sobrenome}
                onChange={(e) => setSobrenome(e.target.value)}
              />
            </Form.Group></>
              :
            <>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="razaoSocial" className={styles.label}>
                Razão Social*:
              </Form.Label>
              <Form.Control
                id="razaoSocial"
                value={razaoSocial}
                onChange={(e) => setRazaoSocial(e.target.value)}
              />
            </Form.Group>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="nomeFantasia" className={styles.label}>
                Nome Fantasia*:
              </Form.Label>
              <Form.Control
                id="nomeFantasia"
                value={nomeFantasia}
                onChange={(e) => setNomeFantasia(e.target.value)}
              />
            </Form.Group></>}

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="telefone" className={styles.label}>
                Telefone*:
              </Form.Label>
              <Form.Control
                id="telefone"
                as={InputMask}
                mask="(99) 99999-9999"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="email" className={styles.label}>
                E-mail*:
              </Form.Label>
              <Form.Control
                // className={styles.inputStyleEmail}
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </fieldset>

          {cepError && <span style={{ color: 'red' }}>{cepError}</span>}
          <fieldset className={styles.fieldsetTopo}>
            <div className={styles.inlineDiv}>
              <Form.Group className={`${styles.grupo} w-100`}>
                <Form.Label htmlFor="cep" className={styles.label}>
                  CEP*:
                </Form.Label>
                <Form.Control
                  id="cep"
                  as={InputMask}
                  mask="99.999-999"
                  value={cep}
                  onChange={(e) => handleCepChange(e.target.value)}
                />
              </Form.Group>

              <div className={styles.inlineDiv}>
                <Form.Group className={`${styles.grupo} w-100`}>
                  <Form.Label htmlFor="endereco" className={styles.label}>
                    Endereço*:
                  </Form.Label>
                  <Form.Control
                    id="endereco"
                    value={endereco}
                    onChange={(e) => setEndereco(e.target.value)}
                    disabled={!manualAddressEntry}
                    className="w-100"
                  />
                </Form.Group>
                <Form.Group className={`${styles.grupo} w-100`}>
                  <Form.Label htmlFor="numero" className={styles.label}>
                    Número*:
                  </Form.Label>
                  <Form.Control
                    id="numero"
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
                    className="w-100"
                  />
                </Form.Group>

                <Form.Group className={`${styles.grupo} w-100`}>
                  <Form.Label htmlFor="complemento" className={styles.label}>
                    Complemento:
                  </Form.Label>
                  <Form.Control
                    id="complemento"
                    value={complemento}
                    onChange={(e) => setComplemento(e.target.value)}
                    className="w-100"
                  />
                </Form.Group>
              </div>
            </div>

            <div>
              <Form.Group className={`${styles.grupo} w-100`}>
                <Form.Label htmlFor="bairro" className={styles.label}>
                  Bairro*:
                </Form.Label>
                <Form.Control
                  id="bairro"
                  value={bairro}
                  onChange={(e) => setBairro(e.target.value)}
                  disabled={!manualAddressEntry}
                  className="w-100"
                />
              </Form.Group>

              <div className={styles.inlineDiv}>
                <Form.Group className={`${styles.grupo} w-100`}>
                  <Form.Label htmlFor="cidade" className={styles.label}>
                    Cidade*:
                  </Form.Label>
                  <Form.Control
                    id="cidade"
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                    disabled={!manualAddressEntry}
                    className="w-100"
                  />
                </Form.Group>

                <Form.Group className={`${styles.grupo} w-100`}>
                  <Form.Label htmlFor="estado" className={styles.label}>
                    Estado*:
                  </Form.Label>
                  <Form.Control
                    id="estado"
                    value={estado}
                    onChange={(e) => setEstado(e.target.value)}
                    disabled={!manualAddressEntry}
                    className="w-100"
                  />
                </Form.Group>

                <Form.Group className={`${styles.grupo} w-100`}>
                  <Form.Label className={styles.label}>
                    Referência:
                  </Form.Label>
                  <Form.Control
                    className={styles.InputRef}
                    type="text"
                    name="referencia"
                    id="referencia"
                    value={referencia}
                    onChange={(e) => setReferencia(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
          </fieldset>

          
        </Form>
      </div>
      <div className={styles.rodape}>
        <button className={styles.submit} onClick={handleSubmit}>
          <i className="bi bi-play-fill"></i>
          {botaoSalvar}
        </button>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={() => {navigate("/clientes")}}
      />
    </>
  );
};

export default NovoCliente;
