// ESTILO
import styles from "./ModalNovaSubcategoria.module.css";

// MODULOS
import Form from "react-bootstrap/Form";

// HOOKS
import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../Services/AuthContext";
import api, { setAuthToken } from "../../../Services/api";

const ModalNovaSubcategoria = ({ handleFecharModal, codCatPai, adicionarNovaSubcategoria }) => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const [descricao, setDescricao] = useState("");
  const [categoria, setCategoria] = useState('');
  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  
  const [botaoSalvar,setBotaoSalvar] = useState("Salvar")

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const handleSubmitSubcategoria = (e) => {
    e.preventDefault();
    setErro("");

    // checar se há inputs vazios e setar erro
    if (categoria === "") {
      setErro("Preencha o campo obrigatório: Categoria!");
      setShowMessage(true);

      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
      return;
    }

    // criar objeto com novaSubcategoria a ser enviado para o banco de dados
    const novaSubcategoria = {
      CODEMP: codemp,
      CATEGORIA: categoria,
      DESCRICAO: descricao,
      CODCATPAI: codCatPai,
    };

    setBotaoSalvar("Carregando...")

    api
      .post("/categoria/cadastrar/filho", novaSubcategoria)
      .then((response) => {
        console.log("Subcategoria criada com sucesso:", response.data);

        // resetar inputs
        resetarInputs();

        setMessage("Subcategoria criada com sucesso:");
        setErro("");
        setShowMessage(true);
        setBotaoSalvar("Salvar")

        setTimeout(() => {
          setShowMessage(false);
          handleFecharModal();
          navigate("/editar-categoria");
        }, 3000);
        adicionarNovaSubcategoria(response.data);
        
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao criar subcategoria.Tente novamente");
        }
        setMessage("");
        setShowMessage(true);
        setBotaoSalvar("Salvar")

        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      })
  };

  const handleFechar = (e) => {
    e.preventDefault();
    resetarInputs();
    handleFecharModal();
  };

  const resetarInputs = () => {
    setDescricao("");
    setCategoria("");
    setErro("");
  };

  return (
    <Form className={styles.form} id="novaSubcategoriaForm">
      <div className={styles.topo}>
        <p>Nova Subcategoria</p>
        <i class="bi bi-x" onClick={handleFechar}></i>
      </div>
      <h1>Campos obrigatórios(*)</h1>
      {showMessage && (
        <div
          className={`${styles.message} ${
            message ? styles.successMessage : styles.errorMessage
          }`}
        >
          <p>{message || erro}</p>
        </div>
      )}
      <fieldset className={styles.fieldsetPessoal}>
        <Form.Group className={styles.grupo}>
          <Form.Label htmlFor="categoria" className={styles.label}>
            Categoria *:
          </Form.Label>
          <Form.Control
            id="categoria"
            value={categoria}
            onChange={(e) => setCategoria(e.target.value)}
          />
        </Form.Group>

        <Form.Group className={styles.grupo}>
          <Form.Label htmlFor="descricao" className={styles.label}>
            Descrição:
          </Form.Label>
          <Form.Control
            id="descricao"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
          />
        </Form.Group>
      </fieldset>

      <div className={styles.rodape}>
        <button className={styles.submit} onClick={handleSubmitSubcategoria}>
          <i className="bi bi-play-fill"></i>
          {botaoSalvar}
        </button>
      </div>
    </Form>
  );
};

export default ModalNovaSubcategoria;
