// ESTILO
import styles from "./NovaFormaPagamento.module.css";

// MODULOS
import Form from "react-bootstrap/Form";

// HOOKS
import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import MessageModal from "../../Components/MessageModal/MessageModal";

const NovaFormaPagamento = () => {
  const navigate = useNavigate();

  const { token, codemp } = useContext(AuthContext);
  const [descricao, setDescricao] = useState("");
  const [status, setStatus] = useState(true);
  const [qtdParcelas, setQtdParcelas] = useState("");
  const [percentualEntrada, setPercentualEntrada] = useState(0);
  const [multa, setMulta] = useState(0);
  const [juros, setJuros] = useState(0);
  const [prazoInicial, setPrazoInicial] = useState(0);
  const [erro, setErro] = useState("");

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErro("");

    if (descricao === "" || qtdParcelas === "") {
      //setModalMessage("Preencha todos os campos obrigatórios!");
      setErro("Preencha todos os campos obrigatórios!")
      //setShowMessageModal(true);
      return;
    }

    const novaFormaPagamento = {
      CODEMP: codemp,
      DESCRICAO: descricao,
      ATIVO: status,
      QTDPARCELAS: Number(qtdParcelas),
      VLRENTRADA: Number(percentualEntrada),
      MULTA: Number(multa),
      JUROS: Number(juros),
    };
    api
      .post("/tipoNegociacao/cadastrar", novaFormaPagamento)
      .then((response) => {
        console.log("Pagamento cadastrado com sucesso:", response.data);
        resetarInputs();
        setModalMessage("Pagamento cadastrado com sucesso!");
        setShowMessageModal(true);
        setIsSuccess(true);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response.status === 409) {
          errorMessage = "Conflito: Forma de pagamento já cadastrado.";
          setModalMessage(errorMessage);
          setShowMessageModal(true);
        } else if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao criar forma de pagamento. Tente novamente!";
        }
        setModalMessage(errorMessage);
        setShowMessageModal(true);
        isSuccess(false);
      });
  };

  //RESETAR O VALOR DE TODOS OS INPUTS
  const resetarInputs = () => {
    setDescricao("");
    setStatus(true);
    setQtdParcelas("");
    setPercentualEntrada("");
    setMulta("");
    setJuros("");
    setPrazoInicial("");
    setPercentualEntrada("");
  };

  const handleClosemodal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/pagamento");
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            class="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/pagamento");
            }}
          ></i>
          Nova forma de Pagamento
        </h1>
      </div>

      <div className={styles.corpo}>
        <Form className={styles.form} id="novaFormaPagamentoForm">
          <h1>Campos obrigatórios(*)</h1>
          {/* <fieldset className={styles.fieldsetTopo}> */}
          {/* <Form.Group className={styles.grupo}>
                            <Form.Label htmlFor="status" className={styles.label}>
                            Status:</Form.Label>
                            <div className={styles.statusFlex}>
                                <Form.Check
                                    defaultChecked
                                    label="ATIVO"
                                    name="status"
                                    type='radio'
                                    id='ativo'
                                    onClick={() => setStatus(true)}
                                />
                                <Form.Check
                                    label="INATIVO"
                                    name="status"
                                    type='radio'
                                    id='inativo'
                                    onClick={() => setStatus(false)}
                                />
                            </div>
                        </Form.Group> */}
          {/* </fieldset> */}

          <fieldset className={styles.fieldsetTipoNeg}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="descricao" className={styles.label}>
                Descrição*:
              </Form.Label>
              <Form.Control
                id="descricao"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupoPercentual}>
              <div className={styles.suffix}>
                <Form.Label
                  htmlFor="percentualEntrada"
                  className={styles.label}
                >
                  Percentual de entrada:*
                </Form.Label>
                <Form.Control
                  type="number"
                  id="percentualEntrada"
                  value={percentualEntrada}
                  onChange={(e) => {
                    const newValue = Math.max(0, parseFloat(e.target.value));
                    setPercentualEntrada(newValue);
                  }}
                />
              </div>
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="qtdParcelas" className={styles.label}>
                Quantidade de parcelas:*
              </Form.Label>
              <Form.Control
                type="number"
                id="qtdParcelas"
                value={qtdParcelas}
                onChange={(e) => {
                  const newValue = Math.max(0, parseFloat(e.target.value));
                  setQtdParcelas(newValue);
                }}
              />
            </Form.Group>
          </fieldset>

          <fieldset className={styles.fieldsetPrazoParcela}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="prazoInicial" className={styles.label}>
                 Prazo de Início (dias):
              </Form.Label>
              <Form.Control
                type="number"
                id="prazoInicial"
                value={prazoInicial}
                onChange={(e) => {
                  const newValue = Math.max(0, parseFloat(e.target.value));
                  setPrazoInicial(newValue);
                }}
              />
            </Form.Group>

            <Form.Group className={styles.grupoPercentual}>
              <div className={styles.suffix}>
                <Form.Label htmlFor="multa" className={styles.label}>
                  Multa:
                </Form.Label>
                <Form.Control
                  type="number"
                  id="multa"
                  value={multa}
                  onChange={(e) => {
                    const newValue = Math.max(0, parseFloat(e.target.value));
                    setMulta(newValue);
                  }}
                />
              </div>
            </Form.Group>

            <Form.Group className={styles.grupoPercentual}>
              <div className={styles.suffix}>
                <Form.Label htmlFor="juros" className={styles.label}>
                  Juros:
                </Form.Label>
                <Form.Control
                  type="number"
                  id="juros"
                  value={juros}
                  onChange={(e) => {
                    const newValue = Math.max(0, parseFloat(e.target.value));
                    setJuros(newValue);
                  }}
                />
              </div>
            </Form.Group>
          </fieldset>

          <div className={styles.rodape}>
            <span className={styles.erro}>{erro}</span>
            <button className={styles.submit} onClick={handleSubmit}>
              <i className="bi bi-play-fill"></i>
              Salvar
            </button>
          </div>
        </Form>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleClosemodal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default NovaFormaPagamento;
