// ESTILO
import styles from "./Locais.module.css";

// HOOKS
import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import axios from "axios";

const Locais = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const [locais, setLocais] = useState([]);
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [semDados, setSemDados] = useState('')
  const [reload, setReload] = useState(false)

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  // useEffect(() => {
  //   const getLocais = async () => {
  //     const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
  //     setLoading(true)
  //     try {
  //       const response = await api.get(`/locais/listar?CODEMP=${CODEMP}`);
  //       setLocais(response.data);
  //       if(response.data.length === 0){
  //         setLocais('Sem dados')
  //       }
  //       console.log(response.data);
  //       setLoading(false)
  //     } catch (error) {
  //       console.error(error);
  //       setLoading(false)
  //     }
  //   };
  //   getLocais();
  // }, [codemp, token]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    setLoading(true);
    const getLocais = async () => {
      try {
        const response = await api.get(`/locais/listar?CODEMP=${CODEMP}`, {
          cancelToken: source.token
        });
        if(response.data.length === 0 ){
          setLocais([])
          setSemDados("Sem dados")
        } else {
          setLocais(response.data);
        }
        setLoading(false);
        setReload(false)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
        setLoading(false);
        setReload(false)
      }
    };
    getLocais();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp,reload]);

  const editarLocal = (codloc) => {
    navigate("/editar-local", { state: { codloc } });
  };

  useEffect(() => {
    if (
      locais.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
          zeroRecords: "Nenhum registro correspondente encontrado.",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
        order: [[2, "asc"]],
      });
    }
  }, [locais,reload]);

  const showTable = () => {
    return locais.map((item, index) => {
      const { CODLOC, LOCAL, DESCRICAO } = item;
      return (
        <tr key={index}>
          <td style={{width: '50px'}} className="text-center">
            <i
              style={{ cursor: "pointer" }}
              className="bi bi-pen"
              onClick={() => editarLocal(item.CODLOC)}
            ></i>
          </td>
          <td className="text-center">{CODLOC}</td>
          <td>{LOCAL}</td>
          <td>{DESCRICAO || '-'}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <div className={styles.topoButtons}>
              <button
                onClick={() => {
                  navigate("/adicionar-local");
                }}
              >
                Adicionar um novo local
              </button>
              <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} title="Atualizar" onClick={()=>setReload(true)}></i>
            </div>
          </div>
          <div className={styles.corpo}>
            <div className={`container-fluid py-4 ${styles.boxTable}`}>
              <div className="table-responsive p-0 pb-2">
                <h1 className={styles.titleTable}>Locais</h1>
                <table
                  ref={tableRef}
                  className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                >
                  <thead>
                    <tr>
                      <th className="text-center">Ação</th>
                      <th className="text-center">Código</th>
                      <th>Nome</th>
                      <th>Descrição</th>
                    </tr>
                  </thead>
                  <tbody>{loading ? <tr>
                    <td colSpan="8">
                      <LoadingCentroTelas />
                    </td>
                  </tr> : semDados === 'Sem dados' ? <tr>
                    <td style={{ textAlign: "center" }} colSpan="8">
                    A tabela está vazia no momento.
                    </td>
                  </tr> : showTable()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </CaixaConteudo>
    </>
  );
};

export default Locais;
