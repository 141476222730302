// ESTILOS
import styles from "./EditarEstoque.module.css";
// HOOKS
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

// COMPONENTES
import Cabecalho from "../../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../../Components/Menu/Menu";
import { Form } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import MessageModal from "../../../Components/MessageModal/MessageModal";

import { AuthContext } from "../../../Services/AuthContext";
import api, { setAuthToken } from "../../../Services/api";
import { validarCamposVazios } from "../../../Utils/validarCamposVazios";

const EditarEstoque = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const location = useLocation();

  const { codest } = location.state;

  const [estoque, setEstoque] = useState("");
  const [dtVencimento, setDtVencimento] = useState("");
  const [lote, setLote] = useState("");
  const [produto, setProduto] = useState("");

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [locais, setLocais] = useState([])
  const [local, setLocal] = useState('')
  const [sankhya, setSankhya] = useState('')

  const [loading, setLoading] = useState(false)
  const [botaoSalvar,setBotaoSalvar] = useState("Salvar")

//PEGAR TODOS OS LOCAIS CADASTRADOS
useEffect(() => {
  setAuthToken(token);
}, [token]);

useEffect(() => {
  const getLocais = async () => {
    const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
    try {
      const response = await api.get(`/locais/listar?CODEMP=${CODEMP}`);
      setLocais(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  getLocais();
}, [codemp, token]);

//PEGAR TODOS OS DADOS DO ESTOQUE ESPECIFICO E PREENCHER CAMPOS
  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
    setLoading(true)
    api
      .get(
        `/estoque/buscar?CODEMP=${Number(CODEMP)}&CODEST=${Number(codest)}`
      )
      .then((response) => {
        console.log("RESPOSTA", response.data);
        setEstoque(response.data.ESTOQUE);
        setLote(response.data.LOTE);
        setDtVencimento(response.data.DATAVENC);
        setProduto(response.data.Produtos.PRODUTO);
        setLocal(response.data.Locais.CODLOC)
        setSankhya(response.data.CODEMPSAN)
        setLoading(false)
      })
      .catch((error) => {
        console.log("ERROR ESTOQUE EDITAR: ", error);
        setLoading(false)
      });
  }, [codemp, codest]);

  // Objeto para atualizar estoque
  const estoqueAtualizado = {
    CODEST: +codest,
    CODEMP: +codemp,
    ESTOQUE: +estoque,

    CODLOC: Number(local),
    CODEMPSAN: sankhya
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const dadosObrigatorios = {
      'Estoque': estoque,
      'Local': local,
    }

    if(validarCamposVazios(dadosObrigatorios).length > 0) {
      setModalMessage(`Preencha os campos obrigatorios: ${validarCamposVazios(dadosObrigatorios)}!`);
      setShowMessageModal(true);
      return;
    } 

    setBotaoSalvar("Carregando...")

    api
      .patch(`/estoque/atualizar`, estoqueAtualizado)
      .then((response) => {
        console.log("Estoque atualizado com sucesso!", response.data);
        setModalMessage("Estoque atualizado com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
        setBotaoSalvar("Salvar")
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao atualizar estoque.Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
        setBotaoSalvar("Salvar")
      });
  };

  const handleDeleteEstoque = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(false);

    const requestData = {
      CODEMP: Number(codemp),
      CODEST: Number(codest),
    };
    console.log("requestData:", requestData);

    api
      .delete(`/estoque/remover`, { data: requestData })
      .then((response) => {
        console.log("Estoque excluído com sucesso:", response.data);
        setMessage("Estoque excluído com sucesso!");
        setErro("");
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
          navigate("/estoque");
        }, 3000);
      })
      .catch((error) => {
        console.error("Erro ao excluir estoque:", error);
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao deletar estoque.Tente novamente");
        }
        setMessage("");
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
        console.log("codest:", codest, "codemp:", codemp);
      });
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/estoque");
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <h1>
              <i
                className="bi bi-arrow-left-circle-fill"
                onClick={() => {
                  navigate("/estoque");
                }}
              ></i>
              Editar Estoque
            </h1>
          </div>

          <div className={styles.corpo}>
            <Form className={styles.form} onSubmit={handleSubmit}>
              {showMessage && (
                <div
                  className={`${styles.message} ${
                    message ? styles.successMessage : styles.errorMessage
                  }`}
                >
                  <p>{message || erro}</p>
                </div>
              )}
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <p style={{fontSize:'.7rem'}}>Campos obrigatórios(*)</p>
              {loading && <p style={{color: 'red'}}>Carregando...</p>}
            </div>
              <fieldset className={styles.fieldsetTopo}>
              <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="produto" className={styles.label}>
                    Produto:
                  </Form.Label>
                  <Form.Control
                    disabled
                    id="produto"
                    value={produto}
                    onChange={(e) => setProduto(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="estoque" className={styles.label}>
                    Estoque*:
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="estoque"
                    value={estoque}
                    onChange={(e) => setEstoque(e.target.value)}
                  />
                </Form.Group>

                
              </fieldset>

              <fieldset className={styles.fieldsetTopo}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="lote" className={styles.label}>
                    Lote:
                  </Form.Label>
                  <Form.Control
                  disabled
                    id="lote"
                    value={lote}
                    onChange={(e) => setLote(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="dtVencimento" className={styles.label}>
                    Data de Vencimento:
                  </Form.Label>
                  <Form.Control
                  type="date"
                    disabled
                    id="dtVencimento"
                    value={dtVencimento ? new Date(dtVencimento).toISOString().substr(0, 10) : ''}
                    onChange={(e) => setDtVencimento(e.target.value)}
                  />
                </Form.Group>
              </fieldset>

              <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={`${styles.grupo}`}>
              <Form.Label htmlFor="local" className={styles.label}>
                Local*
              </Form.Label>
              <Form.Select
                id="local"
                className="form-control"
                value={local}
                onChange={(e) => setLocal(e.target.value)}
              >
                <option value="">Selecionar local</option>
                {locais.map((item) => (
                  <option key={item.CODLOC} value={item.CODLOC}>{item.LOCAL}</option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="sankhya" className={styles.label}>
                Empresa Sankhya
              </Form.Label>
              <Form.Control
                className={styles.inputStyle}
                id="sankhya"
                value={sankhya}
                onChange={(e) => setSankhya(e.target.value)}
              />
            </Form.Group>
          </fieldset>

              <fieldset className={styles.fieldsetButton}>
                <div className={styles.rodape}>
                  <button
                    className={`${styles.submit} ${styles.excluirButton}`}
                    type="button"
                    onClick={handleDeleteEstoque}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-trash-fill"></i>
                    Excluir
                  </button>
                  <button
                    className={`${styles.submit} ${styles.salvarButton}`}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    <i className="bi bi-play-fill"></i>
                    {botaoSalvar}
                  </button>
                </div>
              </fieldset>
            </Form>
          </div>
        </div>
      </CaixaConteudo>
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que deseja excluir este estoque?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancelar
          </Button>
          <Button
            className={`${styles.submit} ${styles.excluirButton}`}
            onClick={handleConfirmDelete}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default EditarEstoque;
