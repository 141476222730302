//HOOKS
import React from "react";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//COMPONENTS
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import styles from "./ImportarCliente.module.css";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import MessageModal from "../../Components/MessageModal/MessageModal";

//LIBS
import Papa from "papaparse";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
//import csvtojson from 'csvtojson';
import CSV from "../../Assets/clienteFormatado.xltx";
import MANUAL from './manualimportcliente.pdf';

import * as XLSX from 'xlsx';
import ModalCamposVaziosImport from "../../Components/ModalCamposVaziosImport/ModalCamposVaziosImport";
import * as importValidate from '../../Utils/importValidate';
import ModalDadosInvalidosImport from "../../Components/ModalDadosInvalidosImport/ModalDadosInvalidosImport";

function ImportarClientes() {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const CODEMP = codemp || sessionStorage.getItem("CODEMP");

  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [erro, setErro] = useState("");

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [linhasComCamposVazios, setLinhasComCamposVazios] = useState([]);
  const [modalCamposVaziosImport, setModalCamposVaziosImport] = useState(false);

  const [linhasDadosInvalidos, setLinhasDadosInvalidos] = useState([]);
  const [modalDadosInvalidosImport, setModalDadosInvalidosImport] = useState(false);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  //baixar o modelo de xltx
  const handleDownloadClick = (arquivo) => {

    const urlDoArquivoCSV = arquivo === 'manual' ? MANUAL : CSV; // Substitua pelo caminho real do seu arquivo CSV

    const link = document.createElement("a");
    link.setAttribute("href", urlDoArquivoCSV);
    link.setAttribute("download", arquivo === 'manual' ? 'manualimportproduto.pdf' : 'clientes.xltx');
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setMessage(arquivo === 'manual' ? 'Manual baixado com sucesso!' : 'Modelo baixado com sucesso!');
    setErro("");
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
      };

  //pegar o arquivo adicionado ao input
  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  //fazer o upload do arquivo
  const handleFileUpload = async (event) => {

    let [selectedFile] = [];
    if(event.target.files){
      [selectedFile] = event.target.files;
    } else {
      [selectedFile] = [file];
    }
    //const fileType = selectedFile.type;
    setIsLoading(true)

    const arrayBuffer = await selectedFile.arrayBuffer();
    const workbook = XLSX.read(arrayBuffer, { type: 'arraybuffer' });

    // Assume que estamos interessados apenas na primeira planilha
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];

    // Obtém os dados da planilha como um array de objetos JSON
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "" });

    const parsedData = jsonData.slice(1).map(row => {
      const obj = {};
      let isEmpty = true; // Flag para verificar se o objeto está vazio
      jsonData[0].forEach((header, index) => {
        obj[header] = row[index];
        if (row[index] !== '') {
          isEmpty = false;
        }
      });
    
      // Verifica se pelo menos um campo do objeto não está vazio antes de retorná-lo
      if (!isEmpty) {
        return {
          ...obj,
          TIPO: row[4] && String(row[4]).replace(/\D/g, '').length === 11 ? 'F' : 'J',
        };
      }
      return null; // Retorna null se o objeto estiver vazio
    }).filter(obj => obj !== null); // Filtra os objetos que não são null
    
    console.log(parsedData);
    
    setLinhasComCamposVazios([])    
    setLinhasDadosInvalidos([])
    let isCamposVazios = false;
    let isDadosInvalidos = false;

    parsedData.forEach((item, index) => {
      const camposVazios = [];
      if (item['NOME/RAZAOSOCIAL'] === '') camposVazios.push('NOME/RAZAOSOCIAL');
      if (item['SOBRENOME/NOMEFANTASIA'] === '') camposVazios.push('SOBRENOME/NOMEFANTASIA');
      if (item['EMAIL'] === '') camposVazios.push('EMAIL');
      if (item['TELEFONE'] === '') camposVazios.push('TELEFONE');
      if (item['CPFCNPJ'] === '') camposVazios.push('CPFCNPJ');
      if (item['CEP'] === '') camposVazios.push('CEP');
      if (item['NUMERO'] === '') camposVazios.push('NUMERO');

      
      if (camposVazios.length > 0) {
        setLinhasComCamposVazios((prevLinhas) => [...prevLinhas, { linha: index + 2, campos: camposVazios }]);
        isCamposVazios = true
      }
      if(importValidate.validarEMAIL(item['EMAIL']) === false){
        setLinhasDadosInvalidos((prevLinhas) => [...prevLinhas, { linha: index + 2, campo: 'EMAIL' }]);
        isDadosInvalidos = true
      }
      if(importValidate.validarTELEFONE(String(item['TELEFONE'])) === false){
        setLinhasDadosInvalidos((prevLinhas) => [...prevLinhas, { linha: index + 2, campo: 'TELEFONE' }]);
        isDadosInvalidos = true
      } else {
        item['TELEFONE'] = String(item['TELEFONE']);
        item['TELEFONE'] = item['TELEFONE'].replace(/^0+/, '');
        item['TELEFONE'] = item['TELEFONE'].replace(/\D/g, '');
      }
      if(importValidate.validarCPFCNPJ(String(item['CPFCNPJ'])) === false){
        setLinhasDadosInvalidos((prevLinhas) => [...prevLinhas, { linha: index + 2, campo: 'CPFCNPJ' }]);
        isDadosInvalidos = true
      } else {
        item['CPFCNPJ'] = String(item['CPFCNPJ']);
        item['CPFCNPJ'] = item['CPFCNPJ'].replace(/\D/g, '');
      }
      if(importValidate.validarCEP(String(item['CEP'])) === false){
        setLinhasDadosInvalidos((prevLinhas) => [...prevLinhas, { linha: index + 2, campo: 'CEP' }]);
        isDadosInvalidos = true
      } else {
        item['CEP'] = String(item['CEP']);
        item['CEP'] = item['CEP'].replace(/\D/g, '');
      }

      if(item['NUMERO']) item['NUMERO'] = String(item['NUMERO']);
    });

    if(isCamposVazios){
      isDadosInvalidos = false
      setLinhasDadosInvalidos([])
      setModalCamposVaziosImport(true);
      setIsLoading(false)
      return
    };

    if(isDadosInvalidos){
      setModalDadosInvalidosImport(true);
      setIsLoading(false)
      return
    }

    // if (fileType === "text/csv") {
      setErro("");

      try {
        //const csvText = await selectedFile.text();
        // let parsedData = Papa.parse(csvText, {
        //   header: true,
        //   delimiter: ';',
        //   transformHeader: (header) => header.trim(),
        // }).data.filter(row => Object.values(row).some(val => val.trim() !== ''));

        // parsedData = parsedData.map((row) => {
        //   return {
        //     ...row,
        //     TIPO: row.CPFCNPJ.replace(/\D/g, '').length === 11 ? 'F' : 'J',
        //   };
        // });
        // console.log(parsedData);

        let CODLOGCAB;
        try {
          const response = await api.post("/clientes/importar/cabecalho");
          console.log(response);
          CODLOGCAB = response.data.CODLOGCAB;
        } catch (error) {
          console.error("Ocorreu um erro na requisição:", error);
          return
        }

        let newArray;
        if (parsedData.length > 1) {
          newArray = parsedData?.map((item) => {
              const newItem = {
                ...item,
                NOME: item['NOME/RAZAOSOCIAL'],
                SOBRENOME: item['SOBRENOME/NOMEFANTASIA'],
                CODLOGCAB: CODLOGCAB,
                CODEMP: CODEMP
              };
              delete newItem['NOME/RAZAOSOCIAL'];
              delete newItem['SOBRENOME/NOMEFANTASIA'];
              return newItem;
            }
          );
        } else {
          const newObj = {
            ...parsedData[0],
            NOME: parsedData[0]['NOME/RAZAOSOCIAL'],
            SOBRENOME: parsedData[0]['SOBRENOME/NOMEFANTASIA'],
            CODLOGCAB: CODLOGCAB,
            CODEMP: CODEMP
          };
          delete newObj['NOME/RAZAOSOCIAL'];
          delete newObj['SOBRENOME/NOMEFANTASIA'];
          newArray = [newObj];
        }

        console.log(newArray);

        const columns = ["NOME", "SOBRENOME", "EMAIL", "TELEFONE", "CPFCNPJ", "TIPO", 'CEP', 'NUMERO'];
        const compareNames = Object.keys(newArray[0]);
        const missingColumns = columns.filter(
          (column) => !compareNames.includes(column)
        );

        if (missingColumns.length === 0) {
          // Divide os clientes em lotes de 100 ou menos
          const fileSize = 100;
          for (let i = 0; i < newArray.length; i += fileSize) {
            const filesImport = newArray.slice(i, i + fileSize);
            console.log(filesImport);
            await api
              .post("/clientes/importar", filesImport, {
                params: {
                  CODEMP: codemp,
                },
                headers: {

                }
              })
              .then((response) => {
                console.log(response);
                if (response.status === 201) {
                  // Status 200 - Sucesso
                  // setModalMessage(
                  //   "Clientes importados com sucesso, você será redirecionado para a tela de clientes."
                  // );
                  // setIsSuccess(true);
                  // setShowMessageModal(true);
                  console.log(response.data);
                }
              })
              .catch((error) => {
                let errorMessage = "";
                console.log(error);
                if (error.response.status === 409) {
                  // Status 409 - Conflito
                  errorMessage = "Conflito: clientes já cadastrados.";
                } else {
                  // Outro status de erro
                  errorMessage = "Erro ao importar o arquivo!";
                }

                setModalMessage(errorMessage);
                setShowMessageModal(true);
              });
          }
          // Mostra o alerta no final do processo
          setIsSuccess(true);
          setModalMessage(
            "Clientes importados com sucesso, você será redirecionado para a tela de clientes."
          );
          setShowMessageModal(true)

        } else {
          let errorMessage = `O arquivo não contém as seguintes colunas necessárias: ${missingColumns.join(
            ", "
          )}. Verifique o modelo de CSV.`;
          setModalMessage(errorMessage);
          setShowMessageModal(true);
        }
      } catch (error) {
        let errorMessage = "Erro ao processar o arquivo CSV.";
        console.log(error);
        setModalMessage(errorMessage);
        setShowMessageModal(true);
      } finally {
        setIsLoading(false)
      }
    // } else {
    //   let errorMessage =
    //     "Tipo de arquivo não suportado. Por favor, selecione um arquivo CSV.";
    //   setModalMessage(errorMessage);
    //   setShowMessageModal(true);
    //   setIsLoading(false)
    // }
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/clientes");
  };

  const handleClose = () => {
    setModalCamposVaziosImport(false);
    setModalDadosInvalidosImport(false);
  }

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          {" "}
          <i
            className="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/clientes");
            }}
          ></i>
          Importar Clientes
        </h1>
        <div className={styles.Buttons}>
          <button className={styles.btnLog} onClick={() => navigate('/log-imports', {state: {tipo: 'clientes'}})}>
            <i class="bi bi-file-earmark-text mr-2"></i>
            Log de importação
          </button>
          <button className={styles.btnManual} onClick={() => handleDownloadClick('manual')}>
            <i className="bi bi-download mr-2"></i>
            Manual de importação
          </button>
          <button className={styles.btnModelo} onClick={() => handleDownloadClick('csv')}>
            <i className="bi bi-download mr-2"></i>
            Baixar modelo
          </button>
        </div>
        {showMessage && (
          <div
            className={`${styles.message} ${
              message ? styles.successMessage : styles.errorMessage
            }`}
          >
            <p>{message || erro}</p>
          </div>
        )}
      </div>

      <div
        className={styles.container}
        onDrop={handleFileDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <div className={styles.Selecao}>
          <div className={styles.upload}>
           {!isLoading ?  <label htmlFor="formFile" className={styles.icone}>
              <i
                className="bi bi-cloud-upload h1"
                style={{ fontSize: "4rem" }}
              ></i>
              <p>Arraste e solte o arquivo aqui ou clique para selecionar</p>
              <span className={styles.legenda}>Formato suportado: XLSX</span>
            </label> : <label className={styles.icone}>
            <LoadingCentroTelas/>
            <p className={styles.processando}>Seu arquivo está sendo processado. Permaneça nesta tela até o final do processo!</p></label>}
            
            <input
              className="form-control"
              type="file"
              id="formFile"
              accept=".xlsx, .xltx, .csv"
              onChange={(e) => {
                console.log(e.target.files[0]);
                setFile(e.target.files[0])
              }}
              hidden
            />
          </div>
          <div className={styles.Buttons}>
          {!isLoading && !file ? <button className={styles.btnEnviar} onClick={() => document.getElementById("formFile").click()}>
              Enviar arquivo
            </button> : <><button className={styles.btnRemover} onClick={() => {
                setFile("")
                const fileInput = document.getElementById("formFile");
                if (fileInput) {
                  fileInput.value = "";
                }}}>
              Remover arquivo
            </button><button className={styles.btnEnviar} onClick={handleFileUpload}>
              Enviar arquivo
            </button></>}
          </div>
          {file && <span className={styles.nomeFile}>Arquivo selecionado: {`${file.name}`}</span>}
        </div>
      </div>

      {modalCamposVaziosImport && <ModalCamposVaziosImport linhas={linhasComCamposVazios} handleClose={handleClose}/>}
      {modalDadosInvalidosImport && <ModalDadosInvalidosImport linhas={linhasDadosInvalidos} handleClose={handleClose}/>}

    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
      
      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />

    </>
  );
}

export default ImportarClientes;
