// ESTILO
import styles from "./ModalNovaUnidade.module.css";

// MODULOS
import Form from "react-bootstrap/Form";

// HOOKS
import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../Services/AuthContext";
import api, { setAuthToken } from "../../../Services/api";

const ModalNovaUnidade = ({ handleFecharModal }) => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const [descricao, setDescricao] = useState("");
  const [unidade, setUnidade] = useState(null);
  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErro("");

    // checar se há inputs vazios e setar erro
    if (descricao === "" || unidade === "") {
      setErro("Preencha todos os campos!");
      setShowMessage(true);

      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
      return;
    }

    // criar objeto com novaUnidade a ser enviado para o banco de dados
    const novaUnidade = {
      CODEMP: codemp,
      TIPO: unidade,
      DESCRICAO: descricao,
    };

    api
      .post("/unidademed/cadastrar/livre", novaUnidade)
      .then((response) => {
        console.log("Unidade de medida criada com sucesso:", response.data);

        // resetar inputs
        resetarInputs();

        setMessage("Unidade de medida criada com sucesso:");
        setErro("");
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
          handleFecharModal();
          navigate('/unidade-medida')
        }, 3000);
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao criar unidade de medida.Tente novamente");
        }
        setMessage("");
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      });
  };

  const handleFechar = (e) => {
    e.preventDefault();
    resetarInputs();
    handleFecharModal();
  };

  const resetarInputs = () => {
    setDescricao("");
    setUnidade("");
    setErro("");
  };

  return (
    <Form className={styles.form} id="novaUnidadeForm">
      <div className={styles.topo}>
        <p>Nova Unidade de Medida Livre</p>
        <i class="bi bi-x" onClick={handleFechar}></i>
      </div>
      <h1>Campos obrigatórios(*)</h1>
      {showMessage && (
        <div
          className={`${styles.message} ${
            message ? styles.successMessage : styles.errorMessage
          }`}
        >
          <p>{message || erro}</p>
        </div>
      )}
      <fieldset className={styles.fieldsetPessoal}>
        <Form.Group className={styles.grupo}>
          <Form.Label htmlFor="descricao" className={styles.label}>
            Descrição*:
          </Form.Label>
          <Form.Control
            id="descricao"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
          />
        </Form.Group>

        <Form.Group className={styles.grupo}>
          <Form.Label htmlFor="unidade" className={styles.label}>
            Unidade de medida*:
          </Form.Label>
          <Form.Control
            id="unidade"
            value={unidade}
            onChange={(e) => setUnidade(e.target.value)}
          />
        </Form.Group>
      </fieldset>

      <div className={styles.rodape}>
        <button className={styles.submit} onClick={handleSubmit}>
          <i className="bi bi-play-fill"></i>
          Salvar
        </button>
      </div>
    </Form>
  );
};

export default ModalNovaUnidade;
