import styles from "./recuperarSenha.module.css";
import React, { useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Alert } from "react-bootstrap";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from '../../Services/api';
import logo from "../../Assets/Logos/logoThinkSale.png";

export default function RecuperarSenha() {
  const { token } = useContext(AuthContext);
  const emailRef = useRef();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [msgEmail, setMsgEmail] = useState(false)
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  setAuthToken(token);

  async function resetPassword() {
    try {
      const response = await api.post('/cadastro/recuperar', {
        EMAIL: email
      });
      console.log(response.data);
      if (response.data.error === "Usuário não cadastrado!") {
        setError("Usuário não encontrado, verifique o email digitado e tente novamente");
      } else {
        setMessage("Uma nova senha foi enviada para o seu e-mail. No próximo acesso, você poderá alterá-la.");
        setMsgEmail(true);
      }
    } catch (error) {
      console.error(error);
      setError("Falha ao resetar a senha");
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailRegex.test(email)) {
      alert('Por favor, insira um e-mail válido.');
      return;
    }

    setLoading(true);
    await resetPassword();
  }

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <img src={logo} className={styles.logo} alt="Logo ThinkSale" />
        <div className={styles.page}>
          <h2>Digite seu endereço de e-mail <br></br> abaixo para redefinir sua senha</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group className={styles.grupo} id="email">
              <Form.Label className={styles.label}>Email:</Form.Label>
              <Form.Control 
                className={styles.input} 
                placeholder="ex@exemplo.com" 
                type="email" 
                ref={emailRef} 
                required 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button 
                onClick={handleSubmit} 
                disabled={loading} 
                className={styles.submit} 
                type="submit"
              >
                <i className="bi bi-envelope"></i>
              </Button>
            </Form.Group>
          </Form>
          <div className={styles.navegacao}>
            <Link className={styles.link} to="/">Fazer Login</Link>
          </div>
        </div>
      </div>
      <div className={styles.navegacao}>
        Não tem uma conta? <Link className={styles.link} to="/cadastro">Cadastre-se</Link>
      </div>
    </section>
  );
}
