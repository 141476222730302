import React from 'react';
import styles from './Trocas.module.css';

import Cabecalho from '../../Components/Cabecalho/Cabecalho';
import CaixaConteudo from '../../Components/CaixaConteudo/CaixaConteudo';
import Menu from '../../Components/Menu/Menu';

const Trocas = () => {


    return (
        <>
            <Menu />
            <Cabecalho />
            <CaixaConteudo>
                <div className={styles.trocas}>
                    <h1>Em breve uma seção para registrar e controlar processo de Trocas na empresa</h1>
                </div>
            </CaixaConteudo>
        </>
    )
}

export default Trocas;