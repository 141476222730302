import styles from "./EditarPagamento.module.css";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { Modal, Button } from "react-bootstrap";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import MessageModal from "../../Components/MessageModal/MessageModal";

const EditarPagamento = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);

  const location = useLocation();
  const { CODTIPNEG } = location.state;
  const [selectedTableId, setSelectedTableId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [codigo, setCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [status, setStatus] = useState(null);
  const [qtdParcelas, setQtdParcelas] = useState("");
  const [percentualEntrada, setPercentualEntrada] = useState("");
  const [multa, setMulta] = useState();
  const [juros, setJuros] = useState("");
  const [prazoInicial, setPrazoInicial] = useState("");
  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [loading, setLoading] = useState(false)
  const [botaoSalvar,setBotaoSalvar] = useState("Salvar")

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
      setLoading(true)
    api
      .get(
        `/tipoNegociacao/buscar/?CODEMP=${Number(CODEMP)}&CODTIPNEG=${Number(
          CODTIPNEG
        )}`
      )
      .then((response) => {
        setCodigo(response.data.CODTIPNEG);
        setDescricao(capitalizeFirstLetter(response.data.DESCRICAO));
        setStatus(response.data.ATIVO ? "ativo" : "inativo");
        setQtdParcelas(response.data.QTDPARCELAS);
        setPercentualEntrada(response.data.VLRENTRADA);
        setMulta(response.data.MULTA);
        setJuros(response.data.JUROS);
        setPrazoInicial(response.data.PRAZO);
      })
      .catch((error) => {
        console.error("Erro ao obter pagamento:", error);
      })
      .finally(()=>{
        setLoading(false)
      })
  }, [codemp, CODTIPNEG]);

  const handleAtualizaPagamento = (e) => {
    e.preventDefault();
    if (descricao === "" || qtdParcelas === "") {
      setModalMessage("Preencha todos os campos!");
      setShowMessageModal(true);
      return;
    }

    const ativo = status === "ativo" ? true : false;

    const pagamentoAtualizado = {
      CODTIPNEG: Number(CODTIPNEG),
      CODEMP: Number(codemp),
      DESCRICAO: descricao,
      ATIVO: ativo,
      QTDPARCELAS: Number(qtdParcelas),
      VLRENTRADA: Number(percentualEntrada),
      MULTA: Number(multa),
      JUROS: Number(juros),
    };

    setBotaoSalvar("Carregando...")

    api
      .patch(`/tipoNegociacao/atualizar`, pagamentoAtualizado)
      .then((response) => {
        console.log("Pagamento atualizado com sucesso:", response.data);
        setModalMessage("Pagamento atualizado com sucesso!");
        setShowMessageModal(true);
        setIsSuccess(true);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao atualizar pagamento.Tente novamente!";
        }
        setModalMessage(errorMessage);
        setShowMessageModal(true);
        isSuccess(false);
      })
      .finally(()=>{
        setBotaoSalvar("Salvar")
      })
  };

  const handleDeletePagamento = () => {
    const requestData = {
      CODEMP: Number(codemp),
      CODTIPNEG: Number(CODTIPNEG),
    };
    if (selectedTableId) {
      api
        .delete(`/tipoNegociacao/remover`, { data: requestData })
        .then((response) => {
          console.log("Pagamento excluído com sucesso:", response.data);
          setMessage("Pagamento excluído com sucesso!");
          setErro("");
          setShowMessage(true);

          setTimeout(() => {
            setShowMessage(false);
            navigate("/pagamento");
          }, 3000);
        })
        .catch((error) => {
          console.error("Erro ao deletar pagamento.", error);
          if (error.response && error.response.status === 500) {
            setErro(
              "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
            );
          } else {
            setErro("Erro ao deletar pagamento.Tente novamente");
          }
          setMessage("");
          setShowMessage(true);
          setTimeout(() => {
            setShowMessage(false);
          }, 3000);
        });
    }
  };
  const handleSelectFocus = () => {
    if (status === "") {
      setStatus("");
    }
  };

  const openModal = (tableId) => {
    setSelectedTableId(tableId);
    setShowModal(true);
  };

  const renderModal = () => {
    return (
      <Modal show={showModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header>
          <Modal.Title>Confirmar exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Deseja excluir o pagamento?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleDeletePagamento}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleCloseDeleteModal = () => {
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/pagamento");
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            className="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/pagamento");
            }}
          ></i>
          Editar Pagamento
        </h1>
      </div>

      <div className={styles.corpo}>
        <Form className={styles.form} onSubmit={handleAtualizaPagamento}>
          {showMessage && (
            <div
              className={`${styles.message} ${
                message ? styles.successMessage : styles.errorMessage
              }`}
            >
              <p>{message || erro}</p>
            </div>
          )}
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <p style={{fontSize:'.7rem'}}>Campos obrigatórios(*)</p>
            {loading && <p style={{color: 'red'}}>Carregando...</p>}
          </div>
          <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="codigo" className={styles.label}>
                Código:
              </Form.Label>
              <Form.Control
                id="codigo"
                value={codigo}
                onChange={(e) => setCodigo(e.target.value)}
                disabled
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="descricao" className={styles.label}>
                Descrição:
              </Form.Label>
              <Form.Control
                id="descricao"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="Status" className={styles.label}>
                Status:
              </Form.Label>
              <Form.Select
                id="Status"
                value={status}
                className="form-control"
                onChange={(e) => setStatus(e.target.value)}
                onFocus={handleSelectFocus}
              >
                <option value=""> Selecione o tipo</option>
                <option value="ativo">Ativo</option>
                <option value="inativo">Inativo</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className={styles.grupoPercentual}>
              <div className={styles.suffix}>
                <Form.Label
                  htmlFor="percentualEntrada"
                  className={styles.label}
                >
                  Percentual de entrada:
                </Form.Label>
                <Form.Control
                  type="number"
                  id="percentualEntrada"
                  value={percentualEntrada}
                  onChange={(e) => {
                    const newValue = Math.max(0, parseFloat(e.target.value));
                    setPercentualEntrada(newValue);
                  }}
                />
              </div>
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="qtdParcelas" className={styles.label}>
                Quantidade de parcelas:
              </Form.Label>
              <Form.Control
                type="number"
                id="qtdParcelas"
                value={qtdParcelas}
                onChange={(e) => {
                  const newValue = Math.max(0, parseFloat(e.target.value));
                  setQtdParcelas(newValue);
                }}
              />
            </Form.Group>
          </fieldset>

          <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="prazoInicial" className={styles.label}>
                Prazo de Início (dias):
              </Form.Label>
              <Form.Control
                type="number"
                id="prazoInicial"
                value={prazoInicial}
                onChange={(e) => {
                  const newValue = Math.max(0, parseFloat(e.target.value));
                  setPrazoInicial(newValue);
                }}
              />
            </Form.Group>

            <Form.Group className={styles.grupoPercentual}>
              <div className={styles.suffix}>
                <Form.Label htmlFor="multa" className={styles.label}>
                  Multa:
                </Form.Label>
                <Form.Control
                  type="number"
                  id="multa"
                  value={multa}
                  onChange={(e) => {
                    const newValue = Math.max(0, parseFloat(e.target.value));
                    setMulta(newValue);
                  }}
                />
              </div>
            </Form.Group>

            <Form.Group className={styles.grupoPercentual}>
              <div className={styles.suffix}>
                <Form.Label htmlFor="juros" className={styles.label}>
                  Juros:
                </Form.Label>
                <Form.Control
                  type="number"
                  id="juros"
                  value={juros}
                  onChange={(e) => {
                    const newValue = Math.max(0, parseFloat(e.target.value));
                    setJuros(newValue);
                  }}
                />
              </div>
            </Form.Group>
          </fieldset>

          <fieldset className={styles.fieldsetButton}>
            <div className={styles.rodape}>
              <button
                className={`${styles.submit} ${styles.excluirButton}`}
                type="button"
                onClick={() => openModal(codemp)}
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-trash-fill"></i>
                Excluir
              </button>
              <button
                className={`${styles.submit} ${styles.salvarButton}`}
                type="submit"
                onClick={handleAtualizaPagamento}
              >
                <i className="bi bi-play-fill"></i>
                {botaoSalvar}
              </button>
            </div>
          </fieldset>
        </Form>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
      {renderModal()}

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default EditarPagamento;
