export function validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj){
    let documento;
    let tipo;
    if (cpfOuCnpj === "cpf" || cpfOuCnpj === '') {
      documento = cpf.replace(/[^\d]/g, '');
      tipo = "CPF";
    } else {
      documento = cnpj.replace(/[^\d]/g, '');
      tipo = "CNPJ";
    }
    if (!documento || documento.length !== (cpfOuCnpj === "cpf" ? 11 : 14)) {
      return tipo;
    }
}  