// ESTILO
import styles from "./MeuPlano.module.css";

// MODULOS
import $ from "jquery";

// HOOKS
import React from "react";
import { NavigationType, useLocation, useNavigate, useNavigation } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import MessageModal from "../../Components/MessageModal/MessageModal";
import axios from "axios";

const MeuPlano = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const [licencasDisponiveis, setLicencasDisponiveis] = useState(0);
  const [licencasTotal, setLicencasTotal] = useState(0);
  const [acessosDisponiveis, setAcessosDisponiveis] = useState(0);
  const [usuarios, setUsuarios] = useState([]);
  const tableRef = useRef(null);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [showDeslogarTodosModal,setShowDeslogarTodosModal] = useState(false)
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [semDadosUsuarios, setSemDadosUsuarios] = useState("");
  const [semDadosAcessos, setSemDadosAcessos] = useState("");

  const tableRefAcessos = useRef(null);
  const [acessos, setAcessos] = useState([]);

  const [activeKey, setActiveKey] = useState(localStorage.getItem("abaAtiva") === "acessosativos" ? "acessosativos" : "usuarios");


  const handleApiError = (error, customMessage) => {
    console.error(error);
    console.log(`${customMessage}: ${error.message}`);
    console.log("Mensagem de erro da API:", error.response.data);
    if(error.response.data.statusCode === 401){
      sessionStorage.clear()
      navigate(`/`)
    }
  };

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  // useEffect(() => {
  //   if (codemp) {
  //     const getDados = async () => {
  //       try {
  //         const [responseLicencas, responseUsuarios] = await Promise.all([
  //           api.get(`/licencas/listar?CODEMP=${codemp}`),
  //           api.get(`/usuarios/listar?CODEMP=${codemp}`)
  //         ]);
  //         setLicencasDisponiveis(responseLicencas.data.disponiveis);
  //         setLicencasTotal(responseLicencas.data.total);
  //         setAcessosDisponiveis(responseLicencas.data.total - responseLicencas.data.sessao_atual);
  //         setUsuarios(responseUsuarios.data);
  //       } catch (error) {
  //         handleApiError(error, "Houve um erro ao buscar os dados");
  //       } finally {
  //         setCarregando(false);
  //       }
  //     };

  //     getDados();
  //   }
  // }, [codemp,token]);  

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    setLoading(true);
    setUsuarios([])
    setAcessos([])
    setSemDadosAcessos('')
    setSemDadosUsuarios('')
    const getDados = async () => {
      try {
        const [responseLicencas, responseUsuarios, responseAcessos] = await Promise.all([
          api.get(`/licencas/listar?CODEMP=${CODEMP}`,{
            cancelToken: source.token
          }),
          api.get(`/usuarios/listar?CODEMP=${CODEMP}`,{
            cancelToken: source.token
          }),
          api.get(`/sessao/listar?CODEMP=${CODEMP}`,{
            cancelToken: source.token
          })]);
          setLicencasDisponiveis(responseLicencas.data.disponiveis);
          setLicencasTotal(responseLicencas.data.total);
          setAcessosDisponiveis(responseLicencas.data.total - responseLicencas.data.sessao_atual);
        if(responseUsuarios.data.length === 0 ){
          setSemDadosUsuarios("Sem dados")
        } else {
          setUsuarios(responseUsuarios.data);
        }
        if(responseAcessos.data.length === 0 ){
          setSemDadosAcessos("Sem dados")
        } else {
          setAcessos(responseAcessos.data);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
      } finally {
        setLoading(false);
        setReload(false)
      }
    };
    getDados();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp,reload]);  

  useEffect(() => {
    if (
      usuarios.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros"
        },
        lengthChange: false,
        //destroy: true,
        ordering: true,
        order:[[6,'asc'],[2,'asc']]
      });
    }
  }, [usuarios]);

  const editarUsuario = (codusu, codpes) =>
    navigate("/editar-usuario", { state: { codusu, codpes } });    

    useEffect(() => {
      if (
        acessos.length > 0 &&
        tableRefAcessos.current &&
        !$.fn.DataTable.isDataTable(tableRefAcessos.current)
      ) {
        $(tableRefAcessos.current).DataTable({
          language: {
            search: "Pesquisar",
            info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
          },
          lengthChange: false,
          destroy: true,
          ordering: true,
          order:[1, 'desc']
        });
      }
    }, [acessos]);  

    const handleCloseModal = () => {
      setShowMessageModal(false);
    };

    const deslogarAcesso = (codacesso) => {

      const acessoRemover = {
        CODEMP: codemp,
        CODACESIM: codacesso
      }
      api
        .delete(`/sessao/remover`, { data: acessoRemover })
        .then((response) => {
            console.log("Acesso deslogado com sucesso:", response.data);
            alert(`Acesso ${codacesso} deslogado com sucesso!`)
            window.location.reload()
          // setModalMessage(`Acesso ${codacesso} deslogado com sucesso!`)
          // setShowMessageModal(true)
          // setIsSuccess(true)
        })
        .catch((error) => {
          handleApiError(error, "Houve um erro ao deslogar este acesso.");
          alert(`Houve um erro ao deslogar o acesso ${codacesso}.`)
          // setModalMessage(`Houve um erro ao deslogar o acesso ${codacesso}.`)
          // setShowMessageModal(true)
          // setIsSuccess(false)
        });
    };

    const deslogarTodosAcessos = () => {
      const acessosRemover = {
        CODEMP: codemp
      }
      api
        .delete(`/sessao/remover/all`, { data: acessosRemover })
        .then((response) => {
          console.log("Todos os acessos foram deslogados com sucesso:", response.data);
          // setModalMessage(`Todos os acessos foram deslogados com sucesso!`)
          // setShowMessageModal(true)
          // setIsSuccess(true)
          alert(`Todos os acessos foram deslogados com sucesso!`)
          window.location.reload()
        })
        .catch((error) => {
          handleApiError(error, "Houve um erro ao deslogar todos os acessos.");
          alert(`Houve um erro ao deslogar todos os acessos.`)
          // setModalMessage(`Houve um erro ao deslogar todos os acessos.`)
          // setShowMessageModal(true)
          // setIsSuccess(false)
        });
    };

    function formatarData(dataString) {
      const data = new Date(dataString);
      
      const hh = String(data.getHours()).padStart(2, '0');
      const mm = String(data.getMinutes()).padStart(2, '0');
      const ss = String(data.getSeconds()).padStart(2, '0');
      const dd = String(data.getDate()).padStart(2, '0');
      const mmFormatado = String(data.getMonth() + 1).padStart(2, '0'); // Os meses em JavaScript são baseados em zero
      const aaaa = data.getFullYear();
    
      return `${hh}:${mm}:${ss} ${dd}/${mmFormatado}/${aaaa}`;
    }

    const handleOKClick = () => {
      setShowMessageModal(false);
      // handleCloseModal()
      // if (isSuccess) {
      //   //window.location.reload()
      // }
    };
  
    const messageClassName = isSuccess
      ? styles.successMessage
      : styles.errorMessage;

      const handleTabSelect = (selectedKey) => {
        localStorage.setItem("abaAtiva", "");
        if (selectedKey === "acessosativos") {
          setActiveKey("acessosativos");
        } else {
          setActiveKey("usuarios")
        }
      };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
          </div>
          <div className={styles.corpo}>
            <div className={`${styles.boxTable} container-fluid py-4`}>
              <div className="table-responsive p-0 pb-2">
                
                <h1 className={styles.titleTable}>Meu Plano</h1>
                <Tabs activeKey={activeKey} defaultActiveKey="usuarios" onSelect={handleTabSelect} className={styles.abas}>

                <Tab className={styles.aba} eventKey="usuarios" title="Usuários">
                    <div className={styles.topoButtons}>
                      <div className={`${styles.licenca} ${styles.closedInfo}`}>
                        {licencasDisponiveis > 0 && licencasDisponiveis > 0 ? (
                          <p>
                            Você possui <span>{licencasDisponiveis}</span> licenças
                            disponíveis do total de <span>{licencasTotal}</span>{" "}
                            licenças
                          </p>
                        ): <p>
                        Você consumiu todas as <span>{licencasTotal}</span> licenças disponíveis{" "}
                      </p>}
                      </div>
                      <div className={`${styles.btnsContainer} ${styles.openedInfo}`}>
                        <i className={`bi bi-info ${styles.btnReload}`} onClick={() => {
                          setShowMessageModal(true)
                          setModalMessage("Teste")
                        }} title="Informação"></i>
                        <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} onClick={() => window.location.reload()} title="Atualizar" ></i>
                      </div>
                      <div className={`${styles.btnsContainer} ${styles.closedInfo}`}>
                        <button disabled={licencasDisponiveis === 0} 
                        style={licencasDisponiveis === 0 ? { background: 'gray' } : {}}
                        onClick={() => navigate("/novo-usuario")}>
                          Adicionar Usuário
                        </button>
                        <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} onClick={() => window.location.reload()} title="Atualizar" ></i>
                      </div>


                    </div>
                        <table
                          ref={tableRef}
                          id="tableUsuarios"
                          className={`${styles.table} table align-items-center justify-content-center mb-0`}
                        >
                          <thead>
                            <tr>
                              <th>Ação</th>
                              <th>Código</th>
                              <th>Nome</th>
                              <th>Telefone</th>
                              <th>E-mail</th>
                              <th>Permissão</th>
                              <th>Status</th>
                              <th>Cargo</th>
                            </tr>
                          </thead>
                          <tbody>
                            {usuarios.length === 0 && semDadosUsuarios === '' ? <tr>
                              <td colSpan="8">
                                <LoadingCentroTelas />
                              </td>
                            </tr> : semDadosUsuarios === 'Sem dados' ? <tr>
                              <td style={{ textAlign: "center" }} colSpan="8">
                              A tabela está vazia no momento.
                              </td>
                            </tr> : (
                              usuarios.map((item) => {
                                console.log(usuarios);
                                if (!item.Usuario) {
                                  return null;
                                }
                                return (
                                  <React.Fragment key={item.Usuario.CODPES}>
                                    {item.Usuario.map((usuario) => (
                                      <tr key={usuario.CODUSU}>
                                        <td>
                                          <i
                                            title="Editar"
                                            style={{cursor: "pointer"}}
                                            className="bi bi-pen"
                                            onClick={() =>
                                              editarUsuario(usuario.CODUSU, usuario.CODPES)
                                            }
                                          ></i>
                                        </td>
                                        <td>{usuario.CODUSU}</td>
                                        <td>
                                          {usuario.Pessoa?.NOME && usuario.Pessoa?.SOBRENOME
                                            ? usuario.Pessoa.NOME.split(" ")
                                                .map((parteNome) =>
                                                  capitalizeFirstLetter(parteNome)
                                                )
                                                .join(" ") +
                                              " " +
                                              usuario.Pessoa.SOBRENOME.split(" ")
                                                .map((parteSobrenome) =>
                                                  capitalizeFirstLetter(parteSobrenome)
                                                )
                                                .join(" ")
                                            : ""}
                                        </td>

                                        <td>{usuario.Pessoa?.TELEFONE || ""}</td>
                                        <td>
                                          {usuario.Pessoa?.EMAIL
                                            ? usuario.Pessoa.EMAIL.toLowerCase()
                                            : ""}
                                        </td>
                                        <td>{capitalizeFirstLetter(usuario.PERMISSAO || "")}</td>
                                        <td>{usuario.ESTADO ? "Ativo" : "Inativo"}</td>
                                        <td>
                                          {capitalizeFirstLetter(usuario.Pessoa?.CARGO || "")}
                                        </td>
                                      </tr>
                                    ))}
                                  </React.Fragment>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                  </Tab>
                  <Tab eventKey="acessosativos" title="Acessos Ativos">
                  <div className={styles.topoButtons}>
                      <div className={styles.licenca}>
                      {acessosDisponiveis > 0 ? 
                          <p>
                            Você possui <span>{acessosDisponiveis}</span> acessos
                            disponíveis do total de <span>{licencasTotal}</span>{" "}
                            acessos
                          </p>
                        : <p>
                        Você consumiu todos os <span>{licencasTotal}</span> acessos disponíveis{" "}
                      </p>}
                      </div>
                      <div className={styles.btnsContainer}>
                        <button onClick={() => {if(window.confirm("Deseja deslogar todos os acessos?")){
                          deslogarTodosAcessos()
                        }}}>
                          Deslogar todos os acessos
                        </button>
                        <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} onClick={() => {
                          localStorage.setItem("abaAtiva", "acessosativos")
                          window.location.reload()
                        }} title="Atualizar" ></i>
                      </div>
                    </div>
                  <table
                      ref={tableRefAcessos}
                      id="tableAcessos"
                      className={`${styles.table} table align-items-center justify-content-center mb-0`}
                    >
                      <thead>
                        <tr>
                          <th>Ação</th>
                          <th>Cód.Acesso</th>
                          <th>UUID</th>
                          <th>Data</th>
                          <th>Usuário</th>
                          <th>Dispositivo/Navegador</th>
                        </tr>
                      </thead>
                      <tbody>
                        {acessos.length === 0 && semDadosAcessos === '' ? <tr>
                          <td colSpan="8">
                            <LoadingCentroTelas />
                          </td>
                        </tr> : semDadosAcessos === 'Sem dados' ? <tr>
                          <td style={{ textAlign: "center" }} colSpan="8">
                          A tabela está vazia no momento.
                          </td>
                        </tr> : (
                          acessos.map((acesso) => {
                            if (!acesso.Usuario) {
                              return null;
                            }
                            return (
                              <React.Fragment key={acesso.CODACESIM}>
                                  <tr key={acesso.CODACESIM}>
                                  <td style={{width: '50px'}}>
                                      {acesso.CODIGO != sessionStorage.getItem('uuid') ? <i
                                        title="Deslogar"
                                        style={{cursor: "pointer"}}
                                        className="bi bi-box-arrow-right"
                                        onClick={() =>
                                          deslogarAcesso(acesso.CODACESIM)
                                        }
                                      ></i> : null}
                                    </td>
                                    <td>{acesso.CODACESIM}</td>
                                    <td title={acesso.CODIGO}>{acesso.CODIGO.length > 15 ? `${acesso.CODIGO.slice(0, 15)}...` : acesso.CODIGO}</td>

                                    <td>{acesso.DATAINC ? formatarData(acesso.DATAINC) : ""}</td>

                                    <td>
                                      {acesso.EMAIL
                                        ? acesso.EMAIL.toLowerCase()
                                        : ""}
                                    </td>
                                    <td>
                                      {acesso.DISPOSITIVO
                                        ? acesso.DISPOSITIVO.toUpperCase()
                                        : ""}
                                    </td>
                                  </tr>
                              
                              </React.Fragment>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </Tab>

                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </CaixaConteudo>

      {/* <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={() => {
          navigate("/meu-plano");
        }}
      /> */}

{showMessageModal && 

<p>teste</p>

    }

      
    </>
  );
};

export default MeuPlano;
