export function capitalizeFirstLetter(string) {
    if (string === null || string === undefined) {
      return "";
    }
  
    // Substitui "_" por espaço
    string = string.replace(/_/g, " ");
  
    // Divide a string em palavras
    const words = string.split(' ');
  
    // Capitaliza a primeira letra de cada palavra
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  
    // Junta as palavras novamente
    return capitalizedWords.join(' ');
  }
  
