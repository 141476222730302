// ESTILO
import styles from "./login.module.css";

// MODULOS
import axios from "axios";
import { Form, Button, Modal } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from 'uuid';
import platform from 'platform';

// HOOKS
import { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";


// COMPONENTES
import logo from "../../Assets/Logos/logoThinkSale.png";
import imgFundoLogin from "./imgFundoLogin.png";
import { AuthContext } from "../../Services/AuthContext";
import api from "../../Services/api"
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";

function Login() {
  const { token, setToken, codemp, updateCodemp } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [erro, setErro] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showCompanies, setShowCompanies] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [licencaVenceu, setLicencaVenceu] = useState(false);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    const fetchCompanies = async () => {
      if (sessionStorage.getItem("token")) {
        const decodedToken = jwt_decode(token);
        const empresas = decodedToken.ACESSO.map((acesso) => acesso.Empresa);
        console.log('Empresas após o login: ', empresas);
        setCompanies(empresas || []);
        setIsLoading(false);
  
        if (empresas.length === 1) {
          updateCodemp(empresas[0].CODEMP);
          setShouldRedirect(true);
        } else {
          setShowCompanies(true);
        }
      } else {
        setIsLoading(false);
      }
    };
  
    fetchCompanies();
  }, [token]);

  const info = platform.parse(window.navigator.userAgent);
  
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const codigo = uuidv4();
      const login = await api.post(
        "/autenticacao/entrar",
        {
          EMAIL: email,
          SENHA: senha,
          CODIGO: codigo,
          DISPOSITIVO: info.name
        }
      );
      const getToken = login.data?.token;
      setToken(getToken);
      localStorage.setItem("token", getToken);
      
      const decodedToken = jwt_decode(getToken);
      localStorage.setItem("userData", JSON.stringify(decodedToken));
      localStorage.setItem("userName", decodedToken.NOME);
      
      sessionStorage.setItem("token", getToken);
      sessionStorage.setItem("uuid", codigo);
      sessionStorage.setItem("dispositivo", info.name);
      sessionStorage.setItem("permissao", decodedToken.PERMISSAO);
      sessionStorage.setItem("CODEMP", decodedToken.CODEMP)

      console.log(sessionStorage);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 403) { 
        setLicencaVenceu(true); 
      } else if (
        error.response && error.response.status === 401
      ) {
        setErro("Usuário ou senha inválidos");
        setShowAlert(true)
      } else {
        setErro(
          "Ocorreu um erro ao fazer login. Tente novamente mais tarde."
        );
        setShowAlert(true)
      }
      setIsLoading(false);
    }finally {
      console.log("Depois da requisição (bloco finally)");
      setIsLoading(false);
   }
  };

  const renderCompanies = () => {
    const decodedToken = JSON.parse(localStorage.getItem('userData'));
    const empresas = decodedToken?.ACESSO || [];

    return (
      <div className={styles.modalContent}>
        <h1>Selecione uma empresa</h1>
        {empresas.map((access, index) => {
          const { Empresa } = access;
          const isSelected = selectedCompanyId === Empresa.CODEMP;

          return (
            <div
              key={index}
              onClick={() => handleCompanySelect(Empresa.CODEMP)}
              className={`${styles.button} ${isSelected ? styles.selectedCompany : ""}`}
            >
              {isSelected ? (
                <i className="bi bi-circle-fill"></i>
              ) : (
                <i className="bi bi-circle"></i>
              )}
              <h2>{Empresa.RAZAOSOCIAL}</h2>
            </div>
          );
        })}
        {selectedCompanyId && (
          <button className={styles.confirmButton} onClick={handleConfirmCompany}>
            Confirmar
          </button>
        )}
      </div>
    );
  };

  const handleCompanySelect = (companyId) => {
    setSelectedCompanyId(companyId);
    updateCodemp(companyId);
  };


  const handleConfirmCompany = () => {
    updateCodemp(selectedCompanyId);
    setShowCompanies(false);
    setShouldRedirect(true);
  };


  if (shouldRedirect) {
    return <Navigate to="/dashboard" />;
  }

  if (isLoading) {
    return <div className={styles.containerLoading}>
      <div className={styles.itemLoading}>
        <LoadingCentroTelas/>
        <p>Carregando...</p>
      </div>
    </div>;
  }

  if (showCompanies) {
    return (
      <div className={styles.modal}>
        {renderCompanies()}
      </div>
    );
  }

  if (licencaVenceu) {
    return (
      <div className={styles.modal}>
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>Erro de Licença</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Sua licença está vencida. Entre em contato para renovar sua licença.</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="success"
              href="https://api.whatsapp.com/send?phone=551236000269"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="bi bi-whatsapp"></i> Entre em contato pelo WhatsApp
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    );
  }

  return (
    <div className={styles.login}>
      <Form className={styles.formLogin} onSubmit={handleLogin}>
        <img
          src={logo}
          className={styles.logo}
          alt="Logo ThinkSale"
        />
        <h1>Faça seu login</h1>
        {showAlert && (
          <div className={styles.alert}>{erro}</div>
        )}
        <Form.Group
          controlId="formBasicEmail"
          className={styles.FormEmail}
        >
          <Form.Label className={styles.camposForms}>
            Email:{" "}
          
          </Form.Label>
          <Form.Control
            className={styles.inputForms}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="ex@exemplo.com"
            autoComplete="current-password"
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"
            onInvalid={(e) => {
              e.target.setCustomValidity("Email inválido");
            }}
            onInput={(e) => {
              e.target.setCustomValidity("");
            }}
          />
          <Button className={styles.botaoEmail} type="submit" tabIndex={-1}>
            <i className="bi bi-envelope" ></i>{" "}
          </Button>
        </Form.Group>
        <Form.Group
          controlId="formBasicPassword"
          className={styles.FormSenha}
        >
          <Form.Label className={styles.camposForms}>
            Senha:
          </Form.Label>
          <Form.Control
            className={styles.inputForms}
            type={mostrarSenha ? "text" : "password"}
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            placeholder="Digite sua senha"
            required
            autoComplete="current-password"
          />
          <Button
          tabIndex={-1}
            className={styles.botaoMostrarSenha}
            onClick={() => setMostrarSenha(!mostrarSenha)}
          >
            {mostrarSenha ? (
              <i className="bi bi-eye-slash-fill"></i>
            ) : (
              <i className="bi bi-eye-fill"></i>
            )}
          </Button>
        </Form.Group>
        <Button
          className={styles.botaoEsqueceuSenha}
          variant="link"
          type="submit"
          href="/recuperar-senha"
        >
          Esqueceu sua senha?
        </Button>
        <Button
          className={styles.botaoLogin}
          variant="primary"
          type="submit"
        >
          Login
        </Button>
        <div className={styles.divisoria}>
          <hr className={styles.linhaDireita} />
          <p>OU</p>
          <hr className={styles.linhaEsquerda} />
        </div>
        <Button
          className={styles.botaoCadastrar}
          variant="link"
          type="submit"
          href="/cadastro"
        >
          Inscreva-se agora
        </Button>
      </Form>
      <div>
        <img
          src={imgFundoLogin}
          className={styles.imgFundoLogin}
          alt="Imagem de fundo: Homem segurando um tablet e sorrindo"
        />
      </div>
    </div>
  );
}

export default Login;
//Ajustar o momento de logar, para aparecer as telas de acordo a permissão(função) do usuario?

// // ESTILO
// import styles from './login.module.css';

// // MODULOS
// import axios from 'axios';
// import { Form, Button } from 'react-bootstrap';
// import jwt_decode from 'jwt-decode';

// // HOOKS
// import { useState, useEffect, useContext } from 'react';
// import { Navigate } from 'react-router-dom';

// // COMPONENTES
// import logo from '../../Assets/Logos/logoThinkSale.png'
// import imgFundoLogin from './imgFundoLogin.png';
// import { AuthContext } from '../../Components/Contexts/AuthContext';

// function Login() {
//   const [email, setEmail] = useState('');
//   const [senha, setSenha] = useState('');
//   const [erro, setErro] = useState('');
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [mostrarSenha, setMostrarSenha] = useState(false);
//   const [showAlert, setShowAlert] = useState(false);
//   const { token, setToken } = useContext(AuthContext);

//   useEffect(() => {
//     if (token) {
//       try {
//         jwt_decode(token);
//         setIsAuthenticated(true);
//       } catch (error) {
//         console.log('Erro não identificado:', error); //retirar depois
//         setErro('Ocorreu um erro ao fazer login. Tente novamente mais tarde.');
//         setShowAlert(true);
//         window.location.reload();
//       }
//     }
//   }, [token]);

//   useEffect(() => {
//     if (showAlert) {
//       setTimeout(() => {
//         setShowAlert(false);
//       }, 3000);
//     }
//   }, [showAlert]);

//   const handleLogin = async () => {
//     try {
//       const login = await axios.post('http://191.252.196.214:3000/autenticacao/entrar', {
//         EMAIL: email,
//         SENHA: senha,
//       });
//       const getToken = login.data?.token;
//       setToken(getToken);
//       console.log(token);

//       const decodedToken = jwt_decode(getToken);
//       localStorage.setItem('userData', JSON.stringify(decodedToken));
//       localStorage.setItem('token', getToken);
//       localStorage.setItem('userName', decodedToken.NOME); // adicionando o nome do usuário no localStorage

//       setIsAuthenticated(true);

//     } catch (error) {

//       if (error.response && error.response.data && error.response.data.error && error.response.data.error.localeCompare("Credênciais invalidas", undefined, { sensitivity: 'base' }) === 0) {
//         setErro('Usuário ou senha inválidos');
//       } else {
//         setErro('Ocorreu um erro ao fazer login. Tente novamente mais tarde.');
//       }
//       setShowAlert(true);
//       setIsAuthenticated(false);
//     }
//   }

//   if (isAuthenticated) {
//     return <Navigate to="/dashboard" />;
//   }
//   if (!token) {

//   return (
//     <div className={styles.login}>

//       <Form className={styles.formLogin}>
//         <img src={logo} className={styles.logo} alt="Logo ThinkSale" />
//         <h1>Faça seu login</h1>
//         {showAlert && <div className={styles.alert}>{erro}</div>}
//         <Form.Group controlId="formBasicEmail" className={styles.FormEmail}>
//           <Form.Label className={styles.camposForms}>Email: </Form.Label>
//           <Form.Control
//             className={styles.inputForms}
//             type="text"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             placeholder="ex@exemplo.com"
//             autoComplete="current-password"
//             required
//             pattern="[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"
//             onInvalid={(e) => {
//               e.target.setCustomValidity("Email inválido");
//             }}
//             onInput={(e) => {
//               e.target.setCustomValidity("");
//             }} />
//           <Button
//             className={styles.botaoEmail}

//           > <i class="bi bi-envelope"></i> </Button>
//         </Form.Group>
//         <Form.Group controlId="formBasicPassword" className={styles.FormSenha}>
//           <Form.Label className={styles.camposForms}>Senha:</Form.Label>
//           <Form.Control className={styles.inputForms}
//             type={mostrarSenha ? "text" : "password"}
//             value={senha}
//             onChange={(e) => setSenha(e.target.value)}
//             placeholder="Digite sua senha" required
//             autoComplete="current-password" />
//           <Button
//             className={styles.botaoMostrarSenha}
//             onClick={() => setMostrarSenha(!mostrarSenha)}
//           >
//             {mostrarSenha ? <i class="bi bi-eye-slash-fill"></i> : <i class="bi bi-eye-fill"></i>}
//           </Button>
//         </Form.Group>
//         <Button className={styles.botaoEsqueceuSenha} variant="link" type="submit" href="/recuperar-senha">Esqueceu sua senha?</Button>
//         <Button className={styles.botaoLogin} variant="primary" onClick={handleLogin}>Login</Button>
//         <div className={styles.divisoria}>
//           <hr className={styles.linhaDireita} />
//           <p>OU</p>
//           <hr className={styles.linhaEsquerda} />
//         </div>
//         <Button className={styles.botaoCadastrar} variant="link" type="submit" href="/cadastro">Inscreva-se agora</Button>
//         {/*<Button className="botaoLoginGoogle" variant="primary" type="submit" href="https://accounts.google.com/signin/v2/identifier?hl=pt-BR&passive=true&continue=https%3A%2F%2Fwww.google.com%2F&ec=GAZAAQ&flowName=GlifWebSignIn&flowEntry=ServiceLogin">Login com Google</Button>*/}

//       </Form>
//       {isAuthenticated ? <Navigate to='/dashboard' /> : null}
//       <div>
//         <img src={imgFundoLogin} className={styles.imgFundoLogin} alt="Imagem de fundo: Homem segurando um tablet e sorrindo" />
//       </div>
//     </div>
//   );
// }
// }

// export default Login;
