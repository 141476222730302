import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Services/AuthContext";
import api, { setAuthToken } from "../../../Services/api";
import moment from "moment";
import Cabecalho from "../../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../../Components/Menu/Menu";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import { CSVLink } from "react-csv";
import exportToPdf from "./ExportPDF";
import { Modal, Button } from "react-bootstrap";
import styles from "./TabelaPreco.module.css";
import LoadingCentroTelas from "../../../Components/LoadingCentroTelas/LoadingCentroTelas";
import axios from "axios";

const TabelaPreco = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);

  const [tabelaPreco, setTabelaPreco] = useState([]);
  const [selectedTableId, setSelectedTableId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const tableRef = useRef(null);

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false)
  const [semDados, setSemDados] = useState('')
  const [reload, setReload] = useState(false)

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  // const fetchTabelaPreco = async () => {
  //   const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
  //     setLoading(true)
  //   try {
  //     const response = await api.get(
  //       `/tabela-precos/listar?CODEMP=${Number(CODEMP)}`
  //     );
  //     console.log(response.data);
  //     setTabelaPreco(response.data);
  //     setLoading(false)    
  //   } catch (error) {
  //     console.error(error);
  //     console.log(`Houve um erro ao buscar tabela de preço: ${error.message}`);
  //     console.log("Mensagem de erro da API:", error.response.data);
  //     setLoading(false)
  //   }
  // };
  // useEffect(() => {
  //   setAuthToken(token);
  //   if (codemp) {
  //     fetchTabelaPreco(); // Busca os dados iniciais da tabela de preço
  //   }
  // }, [token, codemp]);
  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    setLoading(true);
    setSemDados('')
    const getTabelas = async () => {
      try {
        const response = await api.get(`/tabela-precos/listar?CODEMP=${Number(CODEMP)}`, {
          cancelToken: source.token
        });
        if(response.data.length === 0 ){
          setTabelaPreco([])
          setSemDados("Sem dados")
        } else {
          setTabelaPreco(response.data);
        }
        setLoading(false);
        setReload(false)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
        setLoading(false);
        setReload(false)
      }
    };
    getTabelas();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp,reload]);

  const excluirTabelaPreco = () => {
    if (selectedTableId) {
      api
        .delete(`/tabela-precos/remover`, {
          data: { CODEMP: Number(codemp), CODTABPREC: selectedTableId },
        })
        .then(() => {
          //fetchTabelaPreco();
          setMessage("Tabela de preço excluído com sucesso!");
          setErro("");
          setShowMessage(true);

          setTimeout(() => {
            setShowMessage(false);
            navigate('/tabela-preco')
          }, 3000);
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            setErro(
              "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
            );
          } else {
            setErro("Erro ao excluir tabela de preço.");
          }
          setMessage("");
          setShowMessage(true);
          setTimeout(() => {
            setShowMessage(false);
          }, 3000);
        });
    }
    setSelectedTableId(null);
    setShowModal(false);
  };

  const openModal = (tableId) => {
    setSelectedTableId(tableId);
    setShowModal(true);
  };

  const csvData = tabelaPreco.map((item) => ({
    Código: item.CODTABPREC,
    Descrição: item.DESCRICAO,
    DataVigor: moment(item.DTVIGOR).format("DD/MM/YYYY"),
    "Tabela Padrão":
      item.TABPADRAO === "N" ? "Não" : item.TABPADRAO === "S" ? "Sim" : "",
    "Tabela Desconto":
      item.TABDESCONTO === "N" ? "Não" : item.TABDESCONTO === "S" ? "Sim" : "",
  }));

  const pdfData = tabelaPreco.map((item) => ({
    Código: item.CODTABPREC,
    Descrição: item.DESCRICAO,
    DataVigor: moment(item.DTVIGOR).format("DD/MM/YYYY"),
    "Tabela Padrão":
      item.TABPADRAO === "N" ? "Não" : item.TABPADRAO === "S" ? "Sim" : "",
    "Tabela Desconto":
      item.TABDESCONTO === "N" ? "Não" : item.TABDESCONTO === "S" ? "Sim" : "",
  }));

  const exportButtons = (
    <div className={styles.exportButtonsContainer}>
      <CSVLink
        data={csvData}
        filename="tabelaPreco.csv"
        className={`${styles.exportButton} btn btn-sm btn-primary`}
      >
        Exportar CSV
      </CSVLink>
      <button
        className={`${styles.exportButton} btn btn-sm btn-primary`}
        onClick={() => exportToPdf(pdfData)}
      >
        Exportar PDF
      </button>
    </div>
  );

  useEffect(() => {
    if (tabelaPreco.length > 0 && tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
          zeroRecords: "Nenhum registro encontrado",
        },
        lengthChange: false,
        //destroy: true,
        ordering: true,
        order:[[7,'asc'],[4,'desc'],[2,'asc']]
      });
    }
    }, [tabelaPreco,reload]);  
    
  const editarTabela = (codTabPrec) => {
    navigate(`/editar-tabela-preco?codTabPrec=${codTabPrec}`);
  };

  const renderModal = () => {
    return (
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header>
          <Modal.Title>Confirmar exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Deseja excluir a tabela de preço?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={excluirTabelaPreco}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const showTable = () => {
    return tabelaPreco.map((item, index) => {
      console.log(item);
      return (
        <tr key={index}>
          <td style={{width: '50px'}} className="text-center">
            <i
              style={{ cursor: "pointer"}}
              className="bi bi-pen"
              onClick={() => editarTabela(item.CODTABPREC)}
            ></i>
          </td>
          <td className="text-center">{item.CODTABPREC}</td>
          <td>{item.DESCRICAO}</td>
          {/* <td>{moment(item.DTVIGOR).format("DD/MM/YYYY")}</td> */}
          <td>{moment.utc(item.DTVIGOR).format("DD/MM/YYYY")}</td>

          {/* <td>{item.DTVIGOR}</td> */}
          {/* <td>{moment.utc(item.DTVIGOR).utcOffset(3).format("DD/MM/YYYY")}</td> */}
          <td style={{display: 'none'}}>{item.DTVIGOR}</td>
          <td className="text-center">
            {item.TABPADRAO === "N" || item.TABPADRAO === "NAO"
              ? "Não"
              : item.TABPADRAO === "S" || item.TABPADRAO === "SIM"
                ? "Sim"
                : '-'}
          </td>
          <td className="text-center">
            {item.TABDESCONTO === "N"
              ? "Não"
              : item.TABDESCONTO === "S"
                ? "Sim"
                : ""}
          </td>
          <td className="text-center">{item.STATUS ? "ATIVO" : "INATIVO"}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <div className={styles.topoButtons}>
              <button onClick={() => navigate("/nova-tabela-precos")}>
                Adicionar Tabela Preço
              </button>
              <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} title="Atualizar" ></i>
            </div>
          </div>
          <div className={styles.corpo}>
              <div className={`container-fluid ${styles.boxTable}`}>
                <div className="table-responsive p-0 pb-2">
                  <h1 className={styles.titleTable}>Tabela De Preço</h1>
                  <table
                    ref={tableRef}
                    className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                  >
                    <thead>
                      <tr>
                        <th className="text-center">Ação</th>
                        <th>Código</th>
                        <th>Descrição</th>
                        <th>Data Vigor</th>
                        <th style={{display: 'none'}}>DTVIGOR</th>
                        <th>Tabela Padrão</th>
                        <th>Tabela Desconto</th>
                        <th className="text-center">Status</th>
                      </tr>
                    </thead>
                    <tbody>{loading ? <tr>
                      <td colSpan="8">
                        <LoadingCentroTelas />
                      </td>
                    </tr> : semDados === 'Sem dados' ? <tr>
                      <td style={{ textAlign: "center" }} colSpan="8">
                      A tabela está vazia no momento.
                      </td>
                    </tr> : showTable()}</tbody>
                  </table>
                </div>
              </div>
            </div>
            </div>
      </CaixaConteudo>
      {renderModal()}
    </>
  );
};

export default TabelaPreco;
