import React, { useState, useEffect, useContext, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import styles from "./AddTabelaPrecoVendedor.module.css";
import $ from "jquery";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../../Services/AuthContext";
import api, { setAuthToken } from "../../../Services/api";
import { capitalizeFirstLetter } from "../../../Utils/stringCapitalizer";

import MessageModal from "../../../Components/MessageModal/MessageModal";

const AddTabelaPrecoVendedor = () => {
  const location = useLocation();
  const { token, codemp } = useContext(AuthContext);

  const [listaTabelas, setListaTabelas] = useState([]);
  const [tabelaSelecionada, setTabelaSelecionada] = useState("");
  const [tabelaPrecoVinculada, setTabelaPrecoVinculada] = useState([]);
  const [codTabPrec, setCodTabPrec] = useState("");
  const [codVendTab, setCodVendTab] = useState("");

  const { codVend } = location.state;

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const tableRef = useRef(null);

  // Rota de tabela vinculada
  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    if (codemp) {
      api
        .get(`/tabela-precos/listar?CODEMP=${codemp}`)
        .then((response) => {
          setListaTabelas(response.data);

          console.log("lista de tabelas", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [codemp]);

  useEffect(() => {
    if (codemp) {
      const getTabelas = async () => {
        try {
          console.log("codemp:", codemp, "convend", codVend);
          const response = await api.get(
            `/tabela-precos/listar/tabelas/vendedores?CODEMP=${Number(
              codemp
            )}&CODVEND=${Number(codVend)}`
          );

          setTabelaPrecoVinculada(response.data);
          console.log("lista de tabelas vinculadas", response.data);
        } catch (error) {
          console.error(error);
        }
      };
      getTabelas();
    }
  }, [codemp, codVend, isLoading, listaTabelas]);

  const handleDeleteVendedor = async (codTabPrec, codVendTab) => {
    setShowModalDelete(true);

    console.log("CODTABPREC:", codTabPrec);
    console.log("CODVENDTAB", codVendTab);
    setCodTabPrec(codTabPrec);
    setCodVendTab(codVendTab);
  };
  console.log("aaaaa", tabelaPrecoVinculada);
  console.log("aaaaaaaaaaaaaaaaaaaaaaaa", codVend);

  const handleConfirmDelete = () => {
    setShowModalDelete(false);
    setIsLoading(true);
    const requestData = {
      CODEMP: Number(codemp),
      CODVENDTAB: Number(codVendTab),
      CODTABPREC: Number(codTabPrec),
    };
    console.log("request data-->", requestData);
    api
      .delete(`/tabela-precos/remover/vendedor`, { data: requestData })
      .then((response) => {
        console.log("Tabela excluída do vendedor com sucesso:", response.data);
        setMessage("Tabela excluída do vendedor com sucesso!");
        setErro("");
        setShowMessage(true);
        setIsLoading(false);

        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Erro ao excluir tabela do  vendedor:", error);
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao excluir tabela do  vendedor:");
        }
        setMessage("");
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
        console.log("codvend:", codVend, "codemp:", codemp);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseDeleteModal = () => {
    setShowModalDelete(false);
  };

  useEffect(() => {
    if (
      tabelaPrecoVinculada.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
      });
    }
  }, [tabelaPrecoVinculada]);

  const dados = {
    CODTABPREC: +tabelaSelecionada,
    CODEMP: +codemp,
    CODVEND: +codVend,
  };
  console.log(dados);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErro("");
    setIsLoading(true);

    try {
      const response = await api.post(
        `/tabela-precos/adicionar/vendedor`,
        dados
      );
      console.log("Cadastrado na tabela de preço", response.data);

      setModalMessage("Tabela de preço cadastrada com sucesso!");
      setIsSuccess(true);
      setShowMessageModal(true);
      resetarSelect();
    } catch (error) {
      console.error("Erro ao cadastrar vendedor:", error);
      let errorMessage = "";
      if (error.response && error.response.status === 500) {
        errorMessage =
          "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
      } else {
        errorMessage = "Erro ao cadastrar vendedor na tabela de preço.";
      }
      setModalMessage(errorMessage);
      setIsSuccess(false);
      setShowMessageModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {};

  const resetarSelect = () => {
    setTabelaSelecionada("");
  };

  const showTable = () => (
    <div className={styles.corpo}>
      <div className={`container-fluid py-4 ${styles.boxTable}`}>
        <div className="table-responsive p-0 pb-2">
          <h1 className={styles.titleTable}>Tabelas de Preço Vinculadas</h1>
          <table
            ref={tableRef}
            className={`table align-items-center justify-content-center mb-0  ${styles.table}`}
          >
            <thead>
              <tr>
                <th className="text-center">
                  Ação
                </th>
                <th>Código</th>
                <th style={{ display: "none" }}>CódigoVend</th>
                <th>Descrição</th>
                <th>Data Vigor</th>
                <th>Tabela Padrão</th>
                <th>Tabela Desconto</th>
              </tr>
            </thead>
            <tbody>
              {tabelaPrecoVinculada.map((item, index) => (
                <tr key={index}>
                  <td style={{width: '50px'}} className="text-center">
                    <button
                      type="button"
                      onClick={() =>
                        handleDeleteVendedor(
                          item.TabelaPrecos.CODTABPREC,
                          item.CODVENDTAB
                        )
                      }
                      className={styles.deleteButton}
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </td>
                  <td>{item.TabelaPrecos.CODTABPREC}</td>
                  <td style={{ display: "none" }}>{item.CODVENDTAB}</td>
                  <td>{item.TabelaPrecos.DESCRICAO}</td>
                  <td>
                    {moment(item.TabelaPrecos.DTVIGOR).format("DD/MM/YYYY")}
                  </td>
                  <td>
                    {item.TabelaPrecos.TABPADRAO === "N"
                      ? "Não"
                      : item.TabelaPrecos.TABPADRAO === "S"
                      ? "Sim"
                      : ""}
                  </td>
                  <td>
                    {item.TabelaPrecos.TABDESCONTO === "N"
                      ? "Não"
                      : item.TabelaPrecos.TABDESCONTO === "S"
                      ? "Sim"
                      : ""}
                  </td>
                  
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.grid}>
        <div className={styles.corpo}>
          <Form className={styles.form}>
            {showMessage && (
              <div
                className={`${styles.message} ${
                  message ? styles.successMessage : styles.errorMessage
                }`}
              >
                <p>{message || erro}</p>
              </div>
            )}

            <Form.Group>
              <fieldset className={styles.fieldsetTabela}>
                <div className={styles.inputsFlex}>
                  <div className={styles.tabela}>
                    <div className={styles.adicionar}>
                      <Form.Group>
                        <Form.Label htmlFor="tabelas" className={styles.label}>
                          <i class="bi bi-table"></i>
                          Tabela de Preço:
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          as="select"
                          id="tabelas"
                          value={tabelaSelecionada}
                          onChange={(e) => setTabelaSelecionada(e.target.value)}
                        >
                          <option value="">
                            Selecione uma Tabela de Preço
                          </option>
                          {listaTabelas &&
                            listaTabelas.map((tabela) => (
                              <option
                                key={tabela.CODTABPREC}
                                value={tabela.CODTABPREC}
                              >
                                {capitalizeFirstLetter(tabela.DESCRICAO)}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>

                      <button
                        className={styles.submit}
                        type="button"
                        disabled={isLoading}
                        onClick={handleSubmit}
                      >
                        {isLoading ? (
                          <>
                            <i className="bi bi-play-fill"></i>
                            Adicionando...
                          </>
                        ) : (
                          <>
                            <i className="bi bi-play-fill"></i>
                            Adicionar
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </fieldset>
              {showTable()}
            </Form.Group>
          </Form>
        </div>
      </div>
      <Modal show={showModalDelete} onHide={handleCloseDeleteModal} centered>
        <Modal.Header>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja excluir tabela vinculada á este vendedor?
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="secondary"
            onClick={handleCloseDeleteModal}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            className={`${styles.submit} ${styles.excluirButton}`}
            onClick={handleConfirmDelete}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default AddTabelaPrecoVendedor;
