// ESTILO
import styles from "./NovoProduto.module.css";

//MODULOS
import Form from "react-bootstrap/Form";

//HOOKS
import Resizer from "react-image-file-resizer";

import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";

//COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import MessageModal from "../../Components/MessageModal/MessageModal";
import { validarCamposVazios } from "../../Utils/validarCamposVazios";

const NovoProduto = () => {
  const { token, codemp } = useContext(AuthContext);

  const inputRef = useRef();
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState(1);

  const [nome, setNome] = useState("");
  const [sku, setSku] = useState("");
  const [ncm, setNcm] = useState("");
  const [descricao, setDescricao] = useState("");
  const [fornecedor, setFornecedor] = useState("");
  const [fornecedorSelecionado, setFornecedorSelecionado] = useState([]);
  const [cadastrado, setCadastrado] = useState(false);
  const [codProd, setCodProd] = useState("");
  const [salvo, setSalvo] = useState(false);

  const [unidadesMedida, setUnidadesMedida] = useState([]);
  const [unidadeSelecionadaCodigo, setUnidadeSelecionadaCodigo] = useState("");
  const [unidadeSelecionadaNome, setUnidadeSelecionadaNome] = useState("");

  const [abaValida, setAbaValida] = useState(true);

  const [categoria, setCategoria] = useState("");
  const [categoriaSelecionada, setCategoriaSelecionada] = useState([]);

  const [local, setLocal] = useState([]);
  const [localSelecionado, setLocalSelecionado] = useState("");

  const [showDimensionsFields, setShowDimensionsFields] = useState(false);
  const [largura, setLargura] = useState("");
  const [altura, setAltura] = useState("");
  const [profundidade, setProfundidade] = useState("");
  const [comprimento, setComprimento] = useState("");
  const [volume, setVolume] = useState("");

  const [pesoBruto, setPesoBruto] = useState("");
  const [pesoLiquido, setPesoLiquido] = useState("");

  const [erro, setErro] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [botaoSalvar, setBotaoSalvar] = useState("Salvar")

  const fetchFornecedor = useCallback(async () => {
    try {
      const response = await api.get(`/fornecedor/listar/?CODEMP=${codemp}`);
      const activeFornecedores = response.data.filter((fornecedor) => fornecedor.STATUS)
      setFornecedorSelecionado(activeFornecedores);
      console.log("fornecedores", activeFornecedores);
    } catch (error) {
      console.error("Erro ao obter fornecedores:", error);
    }
  }, [codemp]);

  const fetchUnidadesMedida = useCallback(async () => {
    try {
      const response = await api.get(`/unidademed/listar?CODEMP=${codemp}`);
      setUnidadesMedida(response.data);
      console.log("unidades", response.data);
    } catch (error) {
      console.error("Erro ao obter unidades de medida:", error);
    }
  }, [codemp]);

  const fetchCategorias = useCallback(async () => {
    try {
      const response = await api.get(
        `/categoria/listar/?CODEMP=${Number(codemp)}`
      );
      setCategoriaSelecionada(response.data);
      console.log("categorias", response.data);
    } catch (error) {
      console.error("Erro ao obter categorias");
    }
  }, [codemp]);

  const fetchLocais = useCallback(async () => {
    try {
      const response = await api.get(
        `/locais/listar/?CODEMP=${Number(codemp)}`
      );
      setLocal(response.data);
      console.log("locais", response.data);
    } catch (error) {
      console.error("Erro ao obter locais:", error);
    }
  }, [codemp]);

  useEffect(() => {
    const fetchData = async () => {
      setAuthToken(token);
      await fetchUnidadesMedida();
      await fetchLocais();
      await fetchCategorias();
      await fetchFornecedor();
    };

    fetchData();
  }, [
    token,
    codemp,
    fetchUnidadesMedida,
    fetchLocais,
    fetchCategorias,
    fetchFornecedor,
  ]);

  useEffect(() => {
    if (unidadesMedida !== null && unidadeSelecionadaNome !== "") {
      const unidade = unidadesMedida.find(
        (unidade) => unidade.TIPO === unidadeSelecionadaNome
      );
      if (unidade) {
        console.log("entrei aqui", unidade.TIPO);
        setShowDimensionsFields(
          unidade.TIPO === "CX" ||
            unidade.TIPO === "KG" ||
            unidade.TIPO === "L" ||
            unidade.TIPO === "M"
        );
      }
    }
  }, [unidadesMedida, unidadeSelecionadaNome]);

  // const convertStringToBoolean = (string) => {
  //   const mapping = {
  //     divisão: true,
  //     multiplicação: false
  //   };

  //   return mapping[string] ?? null;
  // };

  const handleTabClick = (btn) => {
    setSelectedButton(btn);
  };

  const handleBackClick = () => {
    if (selectedButton === 3) {
      setSelectedButton(1);
    } else {
      setSelectedButton(selectedButton - 1);
    }
  };

  const handleNextTab = () => {
    if (selectedButton < 3) {
      setSelectedButton(selectedButton + 2);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErro("");

    const dadosObrigatorios = {
      'Nome do Produto': nome,
      'Código': sku,
      'Descrição': descricao,
      NCM: ncm,
      'Fornecedor': fornecedor,
      'Unidade de Medida Padrão': unidadeSelecionadaCodigo,
      'Categoria': categoria ? categoria : '',
      'Local Padrão': localSelecionado
    }
    console.log(dadosObrigatorios);

    if(validarCamposVazios(dadosObrigatorios).length > 0) {
      setModalMessage(`Preencha os campos obrigatorios: ${validarCamposVazios(dadosObrigatorios)}!`);
      setShowMessageModal(true);
      return;
    } 

    const adicionarProduto = {
      PRODUTO: nome,
      DESCRICAO: descricao,
      FABRICANTE: fornecedor,
      CODCATPROD: +categoria,
      PESOBRUTO: pesoBruto,
      PESOLIQUIDO: pesoLiquido,
      ALTURA: altura,
      LARGURA: largura,
      COMPRIMENTO: comprimento,
      PROFUNDIDADE: profundidade,
      VOLUME: volume,
      SKU: sku,
      NCM: ncm,
      CODLOC: +localSelecionado,
      CODEMP: Number(codemp),
      CODUNMED: Number(unidadeSelecionadaCodigo),
    };

    setBotaoSalvar("Carregando...")

    api
      .post("/produtos/cadastrar", adicionarProduto)
      .then((response) => {
        console.log("Produto criado com sucesso:", response.data);
        setCodProd(response.data.CODPROD); // Atualiza o estado com o CODPROD gerado
        setSalvo(true);
        resetarInputs();
        setModalMessage("Produto criado com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        console.error("Erro ao criar produto:", error);
        resetarInputs();
        let errorMessage = "";
        if (error.response.status === 409) {
          errorMessage = "Conflito: Produto já cadastrado.";
          setModalMessage(errorMessage);
          setShowMessageModal(true);
        } else if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao criar produto. Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
      })
      .finally(()=>{
        setBotaoSalvar("Salvar")
      })
  };

  const resetarInputs = () => {
    setNome("");
    setSku("");
    setNcm("");
    setUnidadesMedida([]);
    setCategoria("");
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/produtos");
  };

  const children = (
    <>
      <div className={styles.topo}>
        <h1>
          <i
            className="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/produtos");
            }}
          ></i>
          Novo Produto
        </h1>
      </div>

      <div className={styles.abas}>
        {[
          { label: "Descrição", value: 1 },
          { label: "Informações Gerais", value: 3 },
        ].map((tab) => (
          <button
            key={tab.value}
            onClick={() => handleTabClick(tab.value)}
            className={
              selectedButton === tab.value ? styles.selectedButton : ""
            }
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className={styles.corpo}>
        <Form className={styles.form} id="novoProdutoForm">
          <div className={styles.container}>
            {selectedButton === 1 && (
              <fieldset className={styles.fieldsetProduto}>
                <h1>Campos obrigatórios(*)</h1>
                <div className={styles.inputsFlex}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label className={styles.label} htmlFor="nomeProduto">
                      Nome do produto*
                    </Form.Label>
                    <Form.Control
                      ref={inputRef}
                      id="nomeProduto"
                      value={nome}
                      onChange={(e) => setNome(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label
                      className={styles.label}
                      htmlFor="codigoProduto"
                    >
                      Código*
                    </Form.Label>
                    <Form.Control
                      id="codigoProduto"
                      placeholder="sku"
                      value={sku}
                      onChange={(e) => setSku(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className={styles.grupo}>
                    <Form.Label className={styles.label} htmlFor="descricao">
                      Descrição*
                    </Form.Label>
                    <Form.Control
                      id="descricao"
                      value={descricao}
                      onChange={(e) => setDescricao(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label className={styles.label} htmlFor="ncmProduto">
                      NCM*
                    </Form.Label>
                    <Form.Control
                      id="ncmProduto"
                      value={ncm}
                      onChange={(e) => setNcm(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <div className={styles.segmento}>
                      <Form.Label htmlFor="fornecedor" className={styles.label}>
                        Fornecedor*:
                      </Form.Label>
                    </div>
                    <Form.Select
                      className="form-control"
                      id="fornecedor"
                      value={fornecedor}
                      onChange={(e) => {
                        setFornecedor(e.target.value);
                      }}
                    >
                      <option value=''>Selecione um fornecedor</option>
                      {fornecedorSelecionado.map((fornecedor) => (
                        <option
                          key={fornecedor.CODFOR}
                          value={fornecedor.CODFOR}
                        >
                          {fornecedor.FORNECEDOR}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>

                
                {erro && !abaValida && (
                  <p className={`${styles.erro} ${styles.error}`}>{erro}</p>
                )}
              </fieldset>
            )}
            {selectedButton === 3 && (
              <fieldset className={styles.fieldsetInfos}>
                <h1>Campos obrigatórios(*)</h1>
                <div className={styles.inputsFlex}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label
                      className={styles.label}
                      htmlFor="unidadeMedida"
                    >
                      Unidade de Medida Padrão*
                    </Form.Label>
                    <Form.Control
                      as="select"
                      id="unidadeMedida"
                      value={unidadeSelecionadaNome}
                      onChange={(e) => {
                        const selectedUnidade = unidadesMedida.find(
                          (unidade) => unidade.TIPO === e.target.value
                        );
                        if (selectedUnidade) {
                          setUnidadeSelecionadaCodigo(selectedUnidade.CODUNMED);
                          setUnidadeSelecionadaNome(selectedUnidade.TIPO);
                        } else {
                          setUnidadeSelecionadaCodigo("");
                          setUnidadeSelecionadaNome("");
                        }
                      }}
                    >
                      <option value="">Selecione uma unidade padrão</option>
                      {unidadesMedida &&
                        unidadesMedida.map((unidade) => (
                          <option key={unidade.CODUNMED} value={unidade.TIPO}>
                            {capitalizeFirstLetter(unidade.TIPO)}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <div className={styles.segmento}>
                      <Form.Label htmlFor="categoria" className={styles.label}>
                        Categoria*:
                      </Form.Label>
                    </div>
                    <Form.Select
                      className="form-control"
                      id="categoria"
                      value={categoria}
                      onChange={(e) => {
                        setCategoria(e.target.value);
                      }}
                    >
                      <option value=''>Selecione uma categoria</option>
                      {categoriaSelecionada.map((categoria) => (
                        <option
                          key={categoria.CODCATPROD}
                          value={categoria.CODCATPROD}
                        >
                          {categoria.CATEGORIA}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label className={styles.label}>
                      Local padrão*
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={localSelecionado}
                      onChange={(e) => setLocalSelecionado(e.target.value)}
                      disabled={!local.length}
                    ><option value="">Selecione um local</option>
                      {local.length ? (
                        <>
                          
                          {local.map((local) => (
                            <option key={local.CODLOC} value={local.CODLOC}>
                              {local.LOCAL}
                            </option>
                          ))}
                        </>
                      ) : (
                        <option value="" disabled>
                          {local.length === 0
                            ? "Nenhum local cadastrado para empresa"
                            : "Carregando locais..."}
                        </option>
                      )}
                    </Form.Control>
                  </Form.Group>
                  <div className={styles.dimensionsContainer}>
                    <>
                      <h2>Informações opcionais</h2>
                      <div className={styles.dimensionsFields}>
                        <Form.Group className={styles.grupo}>
                          <Form.Label
                            className={styles.label}
                            htmlFor="pesoBruto"
                          >
                            Peso bruto(kg)
                          </Form.Label>
                          <Form.Control
                            id="pesoBruto"
                            type="text"
                            value={pesoBruto}
                            onChange={(e) => setPesoBruto(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className={styles.grupo}>
                          <Form.Label
                            className={styles.label}
                            htmlFor="pesoLiquido"
                          >
                            Peso líquido(kg)
                          </Form.Label>
                          <Form.Control
                            id="pesoLiquido"
                            type="text"
                            value={pesoLiquido}
                            onChange={(e) => setPesoLiquido(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className={styles.grupo}>
                          <Form.Label className={styles.label} htmlFor="altura">
                            Altura
                          </Form.Label>
                          <Form.Control
                            id="altura"
                            type="text"
                            value={altura}
                            onChange={(e) => setAltura(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className={styles.grupo}>
                          <Form.Label
                            className={styles.label}
                            htmlFor="largura"
                          >
                            Largura
                          </Form.Label>
                          <Form.Control
                            id="largura"
                            type="text"
                            value={largura}
                            onChange={(e) => setLargura(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className={styles.grupo}>
                          <Form.Label
                            className={styles.label}
                            htmlFor="comprimento"
                          >
                            Comprimento
                          </Form.Label>
                          <Form.Control
                            id="comprimento"
                            type="text"
                            value={comprimento}
                            onChange={(e) => setComprimento(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className={styles.grupo}>
                          <Form.Label
                            className={styles.label}
                            htmlFor="profundidade"
                          >
                            Profundidade
                          </Form.Label>
                          <Form.Control
                            id="profundidade"
                            type="text"
                            value={profundidade}
                            onChange={(e) => setProfundidade(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className={styles.grupo}>
                          <Form.Label className={styles.label} htmlFor="volume">
                            Volume
                          </Form.Label>
                          <Form.Control
                            id="volume"
                            type="text"
                            value={volume}
                            onChange={(e) => setVolume(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </>
                  </div>
                </div>
                
              </fieldset>
            )}
          </div>
        </Form>
      </div>
      <div className={styles.saveButtons}>
        {selectedButton === 1 ? <><div></div><button onClick={handleNextTab}>Avançar</button></> : 
        <><button onClick={handleBackClick}>Voltar</button><button onClick={handleSubmit}>{botaoSalvar}</button></>}
      </div>
    </>
  );

  return (
    <>
      <Menu children={children} />
      <Cabecalho />
      <CaixaConteudo children={children} />

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default NovoProduto;
