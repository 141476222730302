// ESTILO
import styles from "./NovoOrcamento.module.css";

// MODULOS
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";

//HOOKS
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

//COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import currentDate from "../../Utils/date";
import ClientesModal from "./ModalCliente/ModalCliente";
import VendedoresModal from "./ModalVendedor/ModalVendedor";
import ModalProdutos from "./ModalProduto/ModalProdutos";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import { formatarValor } from "../../Utils/formatarValor";
import PedidoDetalhesModal from "./VisualizarOrcamento";
import EnviarPorWhatsapp from "./EnvioWhatsapp";
import { isNumber, set } from "lodash";
import TransportadoraModal from "./ModalTransportadora/ModalTransportadora";

const NovoOrcamento = () => {
  const { token, codemp } = useContext(AuthContext);
  const navigate = useNavigate();

  const [vendedor, setVendedor] = useState({
    nome: "",
    sobrenome: "",
    cpfCnpj: "",
  });
  const [codvend, setCodvend] = useState(null);

  const [inserirItemPedido, setInserirItemPedido] = useState(false);
  const [abaAtiva, setAbaAtiva] = useState("cabecalho");
  const [detail, setDetail] = useState(false);

  const [listaTransportadoras, setListaTransportadoras] = useState([]);
  const [produtosSelecionados, setProdutosSelecionados] = useState([]);
  const [quantidade, setQuantidade] = useState(1);

  const [tipoNegociacao, setTipoNegociacao] = useState([]);
  const [dadosPedido, setDadosPedido] = useState(null);

  const [frete, setFrete] = useState("");
  const [valorFrete, setValorFrete] = useState(0);
  const [codcli, setCodcli] = useState("");

  const [erro, setErro] = useState("");
  const [desconto, setDesconto] = useState(0);
  const [deveRedirecionar, setDeveRedirecionar] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [
    mostrarTabelaProdutosSelecionados,
    setMostrarTabelaProdutosSelecionados,
  ] = useState(false);
  const [produtosCabecalho, setProdutosCabecalho] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [formValues, setFormValues] = useState({
    negociacao: { codigo: "", descricao: "" },
    movimentacao: { codigo: "", descricao: "" },
    transportadora: { codigo: "", descricao: "", cpfCnpj: "" },
    cliente: { codigo: "", descricao: "", cpfCnpj: "" },
  });

  const [codTabPrec, setCodTabPrec] = useState("");
  const [selectedPrices, setSelectedPrices] = useState({});

  const [valorUnitario, setValorUnitario] = useState(0);

  const redirecionar = useNavigate();

  const [botaoVendedor, setBotaoVendedor] = useState("Selecionar Vendedor")
  const [botaoCliente, setBotaoCliente] = useState("Selecionar Cliente")
  const [botaoProdutos, setBotaoProdutos] = useState("Selecionar Produtos")
  const [botaoTransportadora, setBotaoTransportadora] = useState("Selecionar Transportadora")
  const [botaoAvancar, setBotaoAvancar] = useState("Avançar")

  const [showSemProdutosModal,setShowSemProdutosModal] = useState(false)

  useEffect(() => {
    setAuthToken(token);

    if (!token) {
      redirecionar("/orcamento");
    }
  }, [token]);

  useEffect(() => {
    sessionStorage.setItem("cabecalhoPedido", JSON.stringify(formValues));
  }, [formValues]);

  useEffect(() => {
    const storedCabecalho = sessionStorage.getItem("cabecalhoPedido");
    if (storedCabecalho) {
      setFormValues(JSON.parse(storedCabecalho));
    }
  }, []);

  // useEffect(() => {
  //   let shouldUpdate = false;

  //   const novosProdutos = produtosSelecionados.map((produto) => {
  //     const novoValorTotal = produto.PRECO * produto.quantidade;
  //     console.log("valor", novoValorTotal);

  //     if (produto.valorTotal !== novoValorTotal) {
  //       shouldUpdate = true;
  //       return {
  //         ...produto,
  //         valorTotal: novoValorTotal,
  //       };
  //     }
  //     return produto;
  //   });

  //   if (shouldUpdate) {
  //     setProdutosSelecionados(novosProdutos);

  //     // Calcula o valor total do pedido
  //     const total = calcularValorTotal();
  //     setValorTotalPedido(total);
  //   }
  // }, [produtosSelecionados]);

  // Função para atualizar o valor unitário e o código da tabela de preço em cada produto
  // const atualizarValoresNosProdutos = () => {
  //   console.log("produtos ->>>>>>", produtosSelecionados);
  //   const produtosAtualizados = produtosSelecionados.map((produto) => ({
  //     ...produto,

  //     valorUnitario: +valorUnitario,
  //     codTabPrec: +codTabPrec,
  //   }));

  //   setProdutosSelecionados(produtosAtualizados);
  // };

  // useEffect(() => {
  //   atualizarValoresNosProdutos();
  // }, [valorUnitario, codTabPrec]); // Adicione valorUnitario e codTab

  // const atualizarValorTotalProduto = (produto) => {
  //   // Use o preço da tabela se o valorUnitario for zero, caso contrário, use o valorUnitario
  //   const valorUnit =
  //     produto.valorUnitario === 0 ? produto.PRECOVENDA : produto.valorUnitario;
  //   return valorUnit * produto.quantidade;
  // };

  const calcularValorTotalPedido = () => {
    let valorTotal = 0;

    if (produtosSelecionados && Array.isArray(produtosSelecionados)) {
      produtosSelecionados.forEach((produto) => {
        const selectedPriceInfo = selectedPrices[produto.CODPROD];
        const price = selectedPriceInfo && selectedPriceInfo.price;
        if (price !== undefined && produto.valorUnitario === 0) {
          valorTotal += price * produto.quantidade;
        } else {
          valorTotal += produto.valorUnitario * produto.quantidade;
        }
      });
    }
    return valorTotal;
  };
  const valorTotalPedido = calcularValorTotalPedido();

  useEffect(() => {
    sessionStorage.setItem(
      "produtosSelecionados",
      JSON.stringify(produtosSelecionados)
    );
  }, [produtosSelecionados]);

  const toggleModal = () => {
    if (!isModalOpen) {
      const objetoParaAPI = construirObjetoParaAPI();

      objetoParaAPI.dataEmissao = currentDate;
      objetoParaAPI.numeroPedido = "Número do Orçamento"; // Trocar pelo número real do pedido
      objetoParaAPI.vendedor = vendedor;
      objetoParaAPI.cliente = formValues.cliente.descricao;
      objetoParaAPI.tipoNegociacao = formValues.negociacao.descricao;
      objetoParaAPI.transportadora = capitalizeFirstLetter(
        formValues.transportadora.descricao
      );
      objetoParaAPI.valorTotal = valorTotalPedido;
      objetoParaAPI.produtosSelecionados = produtosSelecionados;

      setDadosPedido(objetoParaAPI);
    }

    setIsModalOpen(!isModalOpen);
  };

  const fecharModalVisualizarPedido = () => {
    setIsModalOpen(false);

    if (deveRedirecionar) {
      redirecionar("/orcamento");
      setDeveRedirecionar(false);
    }
  };

  const onSelectProdutos = (newProdutos) => {
    const existingProducts = [...produtosSelecionados];

    newProdutos.forEach((newProduct) => {
      const existingProductIndex = existingProducts.findIndex(
        (product) => product.CODPROD === newProduct.CODPROD
      );

      if (existingProductIndex !== -1) {
        const atualizaProdutos = {
          ...existingProducts[existingProductIndex],
          codTabPrec,
          valorUnitario,
          quantidade: 1,
        };
        existingProducts[existingProductIndex] = atualizaProdutos;
      } else {
        const newProductsArray = {
          ...newProduct,
          codTabPrec: '',
          valorUnitario: '',
          quantidade: 1,
        };
        existingProducts.push(newProductsArray);
      }
    });
    setProdutosSelecionados(existingProducts);
    setMostrarTabelaProdutosSelecionados(true);
  };

  const handleOpenModal = (modalType) => {
    setShowModal(modalType);
  };

  const handleCloseModal = () => {
    setShowModal(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      setAuthToken(token);

      const fetchAndSet = async (fetchFunc, setFunc) => {
        try {
          const response = await fetchFunc();
          setFunc(response.data);
        } catch (error) {
          console.error(`Erro no fetching data com ${fetchFunc.name}: `, error);
        }
      };

      fetchAndSet(
        () => api.get(`/transportadora/listar?CODEMP=${codemp}`),
        setListaTransportadoras
      );
      fetchAndSet(
        () => api.get(`/tipoNegociacao/listar?CODEMP=${codemp}`),
        setTipoNegociacao
      );
      if (isVendedor()) {
        const codvendResponse = await api.get(
          `/vendedor/buscar?CODEMP=${codemp}&CODUSU=${userData.CODUSU}`
        );
        const { nome, sobrenome } = codvendResponse.data.Usuario.Pessoa;
        setVendedor({ nome, sobrenome });
        setCodvend(codvendResponse.data.CODVEND);
      }
    };

    fetchData();
  }, [codemp, token, userData.CODUSU]);

  const isVendedor = () => {
    const permissao = userData.PERMISSAO.toLowerCase();
    return permissao === "vendedor";
  };

  const isAdministradorOuGestor = () => {
    const permissao = userData.PERMISSAO.toLowerCase();
    return (
      permissao === "administrador" ||
      permissao === "gestor" ||
      permissao === "desenvolvedor"
    );
  };

  const handleTipoNegociacaoChange = (event) => {
    const selectedOption = event.target.value;
    const [codigo, descricao] = selectedOption.split(",");
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      negociacao: { codigo, descricao },
    }));
  };

  const validarCampos = () => {
    //se nao tiver os dois campos
    if (
      (!formValues ||
      !formValues.cliente ||
      formValues.cliente.codigo === "") &&
      (!codvend || codvend === "")
    ) {
      setErro(
        <span style={{ color: "black" }}>
          Selecione os campos obrigatórios:{" "}
          <span style={{ color: "red" }}> Cliente e Vendedor</span>
        </span>
      );
      setShowMessage(true);
      return false;
    }
    //se nao tiver o cliente
    if (
      !formValues ||
      !formValues.cliente ||
      formValues.cliente.codigo === ""
    ) {
      setErro(
        <span style={{ color: "black" }}>
          Selecione o campo obrigatório:{" "}
          <span style={{ color: "red" }}> Cliente</span>
        </span>
      );
      setShowMessage(true);
      return false;
    }
    //se nao tiver o vendedor
    if (!codvend || codvend === "") {
      setErro(
        <span style={{ color: "black" }}>
          Selecione o campo obrigatório:{" "}
          <span style={{ color: "red" }}> Vendedor</span>
        </span>
      );
      setShowMessage(true);
      return false;
    }
    setShowMessage(false);
    return true;
  };

  const avancarParaProximaAba = async (tab) => {
    if (abaAtiva === "cabecalho" && tab === "itens") {
      onLoading(true,'avancar')
      try {
        const response = await api.get(
          `/produtos/listar/produtos/pedido?CODEMP=${codemp}&CODCLI=${formValues.cliente.codigo}&CODVEND=${codvend}`
        );
        if (response.status === 200) {
          setProdutosCabecalho(response.data);
        } else {
          console.error("Erro na solicitação GET");
          return;
        }
      } catch (error) {
        console.error(error.message);
        setErro("Erro ao obter a lista de produtos.");
        setMessage(true);
        setShowSemProdutosModal(true)
        return;
      }
    } else if (abaAtiva === "itens") {
      if (produtosSelecionados.length === 0) {
        setErro(
          "Selecione pelo menos um produto antes de avançar para Detalhes."
        );
        setShowMessage(true);
        return;
      } else {
        setErro("");
      }

      let tabelaVazia = false;
      produtosSelecionados.map((item) => {
        console.log(item.codTabPrec);
        if (item.codTabPrec == '' || item.codTabPrec === 0) {
          tabelaVazia = true;
          return;
        } else {
          setErro("");
        }
      })

      if(tabelaVazia){
        setErro(
          "Selecione uma tabela de preço para cada produto antes de avançar para Detalhes."
        );
        setShowMessage(true);
        return
      }
    }
    setShowMessage(false);
    onLoading(false,'avancar')
    setAbaAtiva(tab);
  };

  const voltarParaAbaAnterior = () => {
    if (abaAtiva === "detalhes") {
      setAbaAtiva("itens");
    } else if (abaAtiva === "itens") {
      setAbaAtiva("cabecalho");
    }
  };

  let ItensPedidos = [];
  for (let index = 0; index < produtosSelecionados.length; index++) {
    const produtoSelecionado = produtosSelecionados[index];
    if (produtoSelecionado) {
      ItensPedidos.push({
        CODPROD: +produtoSelecionado.CODPROD,
        VLRVENDA: +produtoSelecionado.valorUnitario,
        QTD: +produtoSelecionado.quantidade,
        CODTABPREC: +produtoSelecionado.codTabPrec,
        VALORUNIT: +produtoSelecionado.VLRVENDA,
      });
    }
  }
  console.log(produtosSelecionados);

  const construirObjetoParaAPI = () => {
    const objetoParaAPI = {
      CODEMP: +codemp,
      CODVEND: codvend !== null ? +codvend : vendedor.codigo,
      CODCLI: +formValues.cliente.codigo,
      CODTRANS: +formValues.transportadora.codigo,
      VLRFRETE: +valorFrete,
      FRETE: frete,
      DESCONTO: +desconto,
      CODTIPNEG: +formValues.negociacao.codigo,
      ItensPedidos,
      ORCAMENTO: true
    };

    console.log("objetinho para api", objetoParaAPI);
    setDadosPedido(objetoParaAPI);

    return objetoParaAPI;
  };

  const salvarDadosNaAPI = async (e) => {
    e.preventDefault();
    setErro("")
    setShowMessage(false)

    //tipo de negociação e frete vazios
    if (!formValues.negociacao ||
      !formValues.negociacao.codigo || frete === "") {
      setErro(
        <span style={{ color: "black" }}>
          Para prosseguir, preencha os campos obrigatórios:{" "}
          <span style={{ color: "red" }}>Tipo de Negociação e Frete</span>
        </span>
      );
      setShowMessage(true);
      return;
    }

    //so tipo de negociação vazio
    if (
      !formValues.negociacao || !formValues.negociacao.codigo
    ) {
      setErro(
        <span style={{ color: "black" }}>
          Para prosseguir, preencha o campo obrigatório:{" "}
          <span style={{ color: "red" }}> Tipo de Negociação</span>
        </span>
      );
      setShowMessage(true);
      return;
    }

    //so frete vazio
    if (frete === "") {
      setErro(
        <span style={{ color: "black" }}>
          Para prosseguir, preencha o campo obrigatório:{" "}
          <span style={{ color: "red" }}>Frete</span>
        </span>
      );
      setShowMessage(true);
      return;
    }

    //se frete for FOB e valor de frete vazio
    if (
      frete === "FOB" &&
      (valorFrete === "" || (valorFrete > 0 && parseFloat(valorFrete.replace(",", ".")) <= 0))
    ) {
      setErro(
        <span style={{ color: "black" }}>
          Para prosseguir, preencha o campo obrigatório para opção FOB:{" "}
          <span style={{ color: "red" }}> Valor do Frete</span>
        </span>
      );
      setShowMessage(true);
      return;
    }

    const objetoParaAPI = construirObjetoParaAPI();
    setErro("");

    api
      .post("/pedidos/cadastrar", objetoParaAPI)
      .then((response) => {
        console.log("Orçamento criado com sucesso:", response.data);

        setDadosPedido(objetoParaAPI);
        toggleModal();
        setDeveRedirecionar(true);
        resetarInputs();
        setMessage("Orçamento criado com sucesso!");
        setErro("");
        setShowMessage(true);
      })
      .catch((error) => {
        console.error("Erro ao criar orçamento:", error);
        resetarInputs();
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro(
            "Erro ao criar orçamento, recarregue a página e tente novamente."
          );
        }
        setMessage("");
        setShowMessage(true);
      });
  };

  const resetarInputs = () => {
    setVendedor("");
    setProdutosSelecionados([]);
    setQuantidade(1);
    setValorUnitario(0);
  };

  const handleLimparSelecao = () => {
    setProdutosSelecionados([]);
    sessionStorage.removeItem("produtosSelecionados");
    setIsModalOpen(true);
  };

  const handleQuantidadeChange = (index, novaQuantidade) => {
    setProdutosSelecionados((produtosAntigos) =>
      produtosAntigos.map((produto, i) => {
        if (i === index) {
          const valorUnitarioAtual =
            produto.valorUnitario ||
            selectedPrices[produto.CODPROD]?.price ||
            produto.PRECOVENDA;
          let desconto = 0;
          let acrescimo = 0;

          const valorTabela = selectedPrices[produto.CODPROD]?.price || 0;

          // Calcula o valor total com base no valor unitário e na quantidade
          let valorTotal = valorUnitarioAtual * novaQuantidade;

          // Se o valorUnitario for 0, use o valor da tabela para calcular o total
          if (valorUnitarioAtual === 0) {
            valorTotal = valorTabela * novaQuantidade;
          }

          // Adiciona a lógica para calcular o desconto e o acréscimo
          if (valorUnitarioAtual > valorTabela) {
            acrescimo = (valorUnitarioAtual - valorTabela) * novaQuantidade;
          } else if (valorUnitarioAtual < valorTabela) {
            desconto = (valorTabela - valorUnitarioAtual) * novaQuantidade;
          }

          // Retorna o produto com os novos valores atualizados
          return {
            ...produto,
            quantidade: novaQuantidade,
            valorDesconto: desconto,
            valorAcrescimo: acrescimo,
            valorTotalProduto: valorTotal,
          };
        }
        return produto;
      })
    );
  };

  const handleValorUnitarioChange = (codprod,index, novoValorUnitario) => {
    console.log(novoValorUnitario);
    if (novoValorUnitario === 0) {
      const novosProdutosSelecionados = [...produtosSelecionados];
    
      const produtoIndex = novosProdutosSelecionados.findIndex(
        (produto) => produto.CODPROD === codprod
      );
    
      if (produtoIndex !== -1) {
        const produto = novosProdutosSelecionados[produtoIndex];
        produto.valorUnitario = null;
        setProdutosSelecionados(novosProdutosSelecionados);
      }
      return
    }
    setProdutosSelecionados((produtosAntigos) =>
      produtosAntigos.map((produto, i) => {
        if (i === index) {
          const valorUnitario = isNaN(novoValorUnitario)
            ? 0
            : novoValorUnitario;

          const valorUnitarioAtual =
            valorUnitario ||
            selectedPrices[produto.CODPROD]?.price ||
            produto.PRECOVENDA;
          let desconto = 0;
          let acrescimo = 0;

          const valorTabela = selectedPrices[produto.CODPROD]?.price || 0;

          // Determine o valor a ser usado para calcular o valor total
          const valorParaCalculo =
            valorUnitario || valorTabela || produto.PRECOVENDA;

          // Calcular o valor total
          const valorTotal = valorParaCalculo * produto.quantidade;

          // Adiciona a lógica para calcular o desconto e o acréscimo
          if (valorUnitarioAtual > valorTabela) {
            acrescimo = (valorUnitarioAtual - valorTabela) * produto.quantidade;
          } else if (valorUnitarioAtual < valorTabela) {
            desconto = (valorTabela - valorUnitarioAtual) * produto.quantidade;
          }

          // Retorna o produto com os novos valores atualizados
          return {
            ...produto,
            valorUnitario: valorUnitario,
            valorTotalProduto: valorTotal,
            valorDesconto: desconto,
            valorAcrescimo: acrescimo,
          };
        }

        return produto;
      })
    );
  };

  const handleRemoveProduto = (index) => {
    const newProdutosSelecionados = produtosSelecionados.filter(
      (produto, i) => i !== index
    );
    setProdutosSelecionados(newProdutosSelecionados);
    setValorUnitario(0);
    sessionStorage.setItem(
      "produtosSelecionados",
      JSON.stringify(newProdutosSelecionados)
    );
  };

  const productPriceMap = {};
  produtosCabecalho.forEach((tabelaPreco) => {
    tabelaPreco.OUTRAS_TABS.forEach((item) => {
      const key = `${item.CODPROD}-${item.tabelaPreco.DESCRICAO}`;
      productPriceMap[key] = item.PRECOVENDA;
    });
  });

  const handlePriceChange = (productId, selectedTableName) => {
    setErro("")
    setShowMessage(false)

    const tabelaCode = produtosCabecalho.find((p) => p.CODPROD === productId);
    const tabelaSelecionados = produtosSelecionados.find((p) => p.CODPROD === productId);

    if (tabelaCode && tabelaCode.OUTRAS_TABS) {
      const itensTabela = tabelaCode.OUTRAS_TABS.find(
        (t) => t.tabelaPreco.DESCRICAO === selectedTableName
      );
      if (itensTabela) {
        const codTable = itensTabela.CODTABPREC;
        setCodTabPrec(codTable);
        tabelaSelecionados.codTabPrec = itensTabela.CODTABPREC;
      }
    }
    const key = `${productId}-${selectedTableName}`;
    const selectedPrice = productPriceMap[key] || "N/A";
    tabelaSelecionados.valorUnitario = 0
    tabelaSelecionados.VLRVENDA = selectedPrice
    tabelaSelecionados.valorUnitario = selectedPrice
    if(selectedTableName === ''){
      tabelaSelecionados.codTabPrec = ''
    }
  
    if(isNumber(tabelaSelecionados.quantidade)){
      tabelaSelecionados.valorTotalProduto = selectedPrice * tabelaSelecionados.quantidade;
    } else {
      tabelaSelecionados.valorTotalProduto = selectedPrice * tabelaSelecionados.QTD;

    }
    
    setSelectedPrices((prevSelectedPrices) => ({
      ...prevSelectedPrices,
      [productId]: {
        tableName: selectedTableName,
        price: selectedPrice,
      },
    }));
  };

  const handleClearTransportadora = () => {
    setFormValues((prevState) => ({
      ...prevState,
      transportadora: {
        codigo: "",
        descricao: "",
        cpfCnpj: "",
      },
    }));
  };

  const onLoading = (status, tipo) => {
    if(tipo === "cliente"){
      if(status){
        setBotaoCliente("Carregando...")
        setBotaoVendedor("Selecionar Vendedor")
      } else {
        setBotaoCliente("Selecionar Cliente")
      }
    }
    if(tipo === "vendedor"){
      if(status){
        setBotaoVendedor("Carregando...")
        setBotaoCliente("Selecionar Cliente")
      } else {
        setBotaoVendedor("Selecionar Vendedor")
      }
    }
    if(tipo === "produtos"){
      if(status){
        setBotaoProdutos("Carregando...")
      } else {
        setBotaoProdutos("Selecionar Produtos")
      }
    }
    if(tipo === "transportadora"){
      if(status){
        setBotaoTransportadora("Carregando...")
      } else {
        setBotaoTransportadora("Selecionar Vendedor")
      }
    }
    if(tipo === "avancar"){
      if(status){
        setBotaoAvancar("Carregando...")
      } else {
        setBotaoAvancar("Avançar")
      }
    }
  }

  const children = (
    <div className={inserirItemPedido ? styles.blur : ""}>
      <div className={styles.topo}>
        <h1>
          <i
            class="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/orcamento");
            }}
          ></i>
          Novo Orçamento
        </h1>
      </div>
      {showMessage && (
          <div
            className={`${styles.message} ${
              message ? styles.sucesso : styles.erro
            }`}
          >
            <p>{message || erro}</p>
          </div>
        )}
      <div className={styles.container}>

        <Form className={styles.form} id="novoProdutoForm">
          <h1>Campos obrigatórios(*)</h1>
          <Tabs
            defaultActiveKey="cabecalho"
            id="uncontrolled-tab-example"
            activeKey={abaAtiva}
            onSelect={(eventKey) => {
              if (abaAtiva === "detalhes") {
                if (eventKey === "itens" || eventKey === "cabecalho") {
                  setAbaAtiva(eventKey);
                }
              } else if (abaAtiva === "itens") {
                if (eventKey === "cabecalho") {
                  setAbaAtiva(eventKey);
                }
              } else {
                if (eventKey !== abaAtiva) {
                  return;
                }
              }
            }}
          >
            <Tab eventKey="cabecalho" title="Cabeçalho do Orçamento">
              <fieldset className={styles.fieldsetClienteCabecalho}>
                <div className={styles.inputsCliente}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="cliente" className={styles.label}>
                      <i className="bi bi-person"></i>
                      Cliente*:
                    </Form.Label>
                    <div className={styles.conteudo}>
                      <Form.Control
                        id="cliente"
                        value={
                          formValues.cliente
                            ? `${formValues.cliente.descricao}`
                            : ""
                        }
                        readOnly
                      />
                      <button
                        type="button"
                        onClick={() => handleOpenModal("cliente")}
                        className={`customSelectBtn ${styles.customSelectBtn}`}
                      >
                        {botaoCliente}
                      </button>
                    </div>
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="cliente" className={styles.label}>
                      Cliente Cpf/Cnpj:
                    </Form.Label>
                    <div className={styles.conteudo}>
                      <Form.Control
                        id="cliente"
                        value={
                          formValues.cliente
                            ? `${formValues.cliente.cpfCnpj}`
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </Form.Group>
                </div>

                {showModal === "cliente" && (
                  <ClientesModal
                  onLoading={onLoading}
                    onClose={handleCloseModal}
                    onSelect={(selectedCliente) => {
                      setFormValues((prevState) => ({
                        ...prevState,
                        cliente: {
                          codigo: selectedCliente.CODCLI,
                          descricao: `${capitalizeFirstLetter(
                            selectedCliente.NOME
                          )} ${capitalizeFirstLetter(
                            selectedCliente.SOBRENOME
                          )}`,
                          cpfCnpj: selectedCliente.CPFCNPJ,
                        },
                      }));
                      setCodcli(selectedCliente.CODCLI);
                    }}
                  />
                )}

                <div className={styles.inputsVendedor}>
                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="vendedor" className={styles.label}>
                      <i className="bi bi-building"></i>
                      Vendedor*:
                    </Form.Label>
                    <div className={styles.conteudo}>
                      {!isAdministradorOuGestor() ? (
                        <Form.Control
                          id="vendedor"
                          value={`${vendedor.nome} ${vendedor.sobrenome}`}
                          readOnly
                        />
                      ) : (
                        <>
                          <Form.Control
                            value={
                              codvend
                                ? `${vendedor.nome} ${vendedor.sobrenome}`
                                : ""
                            }
                            readOnly
                          />
                          <button
                            type="button"
                            onClick={() => handleOpenModal("vendedor")}
                            className={`customSelectBtn ${styles.customSelectBtn}`}
                          >
                            {botaoVendedor}
                          </button>
                        </>
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group className={styles.grupo}>
                    <Form.Label htmlFor="vendedor" className={styles.label}>
                      Vendedor Cpf/Cnpj:
                    </Form.Label>
                    <div className={styles.conteudo}>
                      <Form.Control
                        id="vendedor"
                        value={`${vendedor.cpfCnpj} `}
                        readOnly
                      />
                    </div>
                  </Form.Group>
                </div>

                {showModal === "vendedor" && (
                  <VendedoresModal
                    onLoading={onLoading}
                    onClose={handleCloseModal}
                    onSelect={(selectedVendedor) => {
                      setCodvend(selectedVendedor.CODVEND);
                      setVendedor({
                        nome: selectedVendedor.NOME,
                        sobrenome: selectedVendedor.SOBRENOME,
                        cpfCnpj: selectedVendedor.CPFCNPJ,
                      });
                    }}
                  />
                )}
              </fieldset>
            </Tab>

            <Tab eventKey="itens" title="Itens do Orçamento">
              <div>
                <fieldset className={styles.fieldsetCliente}>
                  <div className={styles.formControlButtonContainer2}>
                    <Form.Group className={styles.grupo}>
                      <Form.Label htmlFor="produtos" className={styles.label}>
                        <i className="bi bi-box-seam"></i>
                        Produtos*:
                      </Form.Label>
                      <button
                        type="button"
                        onClick={() => handleOpenModal("produtos")}
                        className={`customSelectBtn ${styles.customSelectBtn}`}
                      >
                        <i className="bi bi-box-seam"></i>
                        {botaoProdutos}
                      </button>
                    </Form.Group>
                    {showModal === "produtos" && (
                      <ModalProdutos
                        onProductsSelected={onSelectProdutos}
                        selectedProducts={produtosSelecionados}
                        produtosCabecalho={produtosCabecalho}
                        onClose={handleCloseModal}
                        cliente={codcli}
                        vendedor={codvend}
                        onLoading={onLoading}
                        />
                    )}
                  </div>
                </fieldset>
                <div
                  className={`${styles.baseContainer} ${
                    produtosSelecionados.length === 0 ? styles.hide : ""
                  }`}
                >
                  {mostrarTabelaProdutosSelecionados && (
                    <div className={`container-fluid py-4 ${styles.boxTable}`}>
                      <div className={styles.text}>Produtos Selecionados</div>
                      <table
                        id="tablePedidos"
                        className={`table align-items-center justify-content-center container-fluid py-4 mb-0 ${styles.table}`}
                      >
                        <thead>
                          <tr>
                          <th className={styles.icone} colSpan="1">
                              Ação
                            </th>
                            <th>Produto</th>
                            <th className="col-1 text-center">Fabricante</th>
                            <th className="col-1 text-center">
                              Tabela de Preço
                            </th>
                            <th className="col-1 text-center">
                              Valor Unitário Tabela
                            </th>
                            <th className="col-1 text-center">
                              Valor Unitário
                            </th>
                            <th className="col-1 text-center">Quantidade</th>
                            <th className="col-1 text-center">
                              Valor Desconto
                            </th>
                            <th className="col-1 text-center">
                              Valor Acréscimo
                            </th>
                            <th>Valor Total</th>
                           
                          </tr>
                        </thead>
                        <tbody>
                          {produtosSelecionados &&
                            produtosSelecionados.map((produto, index) => {
                              return (
                                <tr key={index}>
                                   <td className={styles.icone} colSpan="1">
                                    <button
                                      onClick={() => handleRemoveProduto(index)}
                                    >
                                      <i className="bi bi-trash-fill"></i>
                                    </button>
                                  </td>
                                  <td>{produto && produto.PRODUTO}</td>
                                  <td>{produto && produto.FABRICANTE}</td>
                                  <td>
                                    <select
                                      style={{ width: "7rem" }}
                                      className="form-control"
                                      value={
                                        selectedPrices[produto.CODPROD] && produto.codTabPrec != ''
                                          ? selectedPrices[produto.CODPROD]
                                              .tableName
                                          : ""
                                      }
                                      onChange={(e) => {
                                        const selectedTableName = e.target.value;
                                        handlePriceChange(
                                          produto.CODPROD,
                                          selectedTableName
                                        );
                                      }}
                                      disabled={produto.QTDESTOQUE === null}
                                    >
                                      <option value="">
                                        {produto.QTDESTOQUE === null
                                          ? "Produto sem estoque"
                                          : "Selecione uma Tabela de Preço"}
                                      </option>
                                      {produto.TABELAS?.map(
                                        (tableName, index) => (
                                          tableName.PRECOVENDA > 0 || tableName.PRECOVENDA != ''  ? (
                                            <option key={index} value={tableName.tabelaPreco?.DESCRICAO}>
                                              {tableName.tabelaPreco?.DESCRICAO}
                                            </option>
                                          ) : null
                                        )
                                      )}
                                    </select>
                                  </td>
                                  <td className="text-center">
                                    {selectedPrices[produto.CODPROD] && produto.codTabPrec != ''
                                      ? isNaN(
                                          selectedPrices[produto.CODPROD].price
                                        )
                                        ? "-"
                                        : `R$ ${parseFloat(
                                            selectedPrices[produto.CODPROD]
                                              .price
                                          ).toLocaleString("pt-BR", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}`
                                      : "-"}  
                                  </td>
                                  <td className={styles.containerQtd}><span>R$</span>
                                    <input
                                      className={styles.inputVlorUnitario}
                                      type="text"
                                      value={
                                        produto.valorUnitario === 0 && produto.codTabPrec
                                          ? produto.VLRVENDA
                                          : produto.valorUnitario
                                      }
                                      onChange={(e) => {
                                        const novoValorUnitario = e.target.value === "" ? 0 : parseFloat(e.target.value);
                                        handleValorUnitarioChange(produto.CODPROD,index, novoValorUnitario);
                                      }}
                                    />
                                  </td>

                                  <td className={styles.containerQtd}>
                                    <button
                                      type="button"
                                      className={`${styles.quantidadeButton} ${
                                        showMessage ? styles.errorButton : ""
                                      }`}
                                      onClick={() => {
                                        const newQuantity = Math.max(
                                          produto && produto.quantidade - 1,
                                          1
                                        );
                                        handleQuantidadeChange(
                                          index,
                                          newQuantity
                                        );
                                      }}
                                    >
                                      -
                                    </button>
                                    <input
                                      type="text"
                                      value={produto.quantidade.toString()}
                                      onChange={(e) => {
                                        const novaQuantidade =
                                          e.target.value === ""
                                            ? 0
                                            : parseInt(e.target.value, 10);
                                        handleQuantidadeChange(
                                          index,
                                          novaQuantidade
                                        );
                                      }}
                                      onFocus={(e) => {
                                        e.target.value = "";
                                      }}
                                      className={`${styles.quantidadeInput} ${styles.smallInput}`}
                                    />
                                    <button
                                      type="button"
                                      className={`${styles.quantidadeButton} ${
                                        showMessage ? styles.errorButton : ""
                                      }`}
                                      onClick={() =>
                                        handleQuantidadeChange(
                                          index,
                                          produto && produto.quantidade + 1
                                        )
                                      }
                                    >
                                      +
                                    </button>
                                  </td>
                                  <td className="col-1 text-center">
                                    {produto.valorDesconto !== undefined
                                      ? `R$ ${produto.valorDesconto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                      : "R$ 0,00"}
                                  </td>
                                  <td className="col-1 text-center">
                                    {produto.valorAcrescimo !== undefined
                                      ? `R$ ${produto.valorAcrescimo.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                      : "R$ 0,00"}
                                  </td>

                                  <td className="col-1 text-center">
                                    {produto.valorTotalProduto !== undefined &&
                                    produto.valorTotalProduto !== 0
                                      ? formatarValor(produto.valorTotalProduto)
                                      : `R$ ${ isNumber(selectedPrices[
                                        produto.CODPROD
                                      ]?.price) && produto.codTabPrec != '' ?
                                          selectedPrices[
                                            produto.CODPROD
                                          ]?.price.toFixed(2) : "0.00"
                                        }`}
                                  </td>
                                 
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <button
                    type="button"
                    className={styles.limparSelecaoButton}
                    onClick={handleLimparSelecao}
                  >
                    Limpar Seleção
                  </button>
                </div>

                <fieldset className={styles.fieldsetDetalhes}>
                  <div
                    className={styles.detalhesDiv}
                    onClick={() =>
                      detail ? setDetail(false) : setDetail(true)
                    }
                  >
                    <p>
                      <i class="bi bi-info-circle"></i>
                      Detalhes do Orçamento
                    </p>
                    {!detail && <i class="bi bi-chevron-down"></i>}
                    {detail && <i class="bi bi-chevron-up"></i>}
                  </div>
                 
                  <div className={detail ? styles.open : styles.close}>
                    <div className={`container-fluid py-4 ${styles.boxTable}`}>
                      <table
                        id="tablePedidos"
                        className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                      >
                        <tbody>
                          <tr>
                            <td className={styles.legenda}>
                              Data da emissão:{" "}
                            </td>
                            <td className="text-left">{currentDate}</td>
                            <td className={styles.legenda}>Nº do Orçamento: </td>
                            <td className="text-left">{'-'}</td>
                          </tr>
                          <tr>
                            <td className={styles.legenda}>
                              Tipo de Movimentação:{" "}
                            </td>
                            <td className="text-left">Venda</td>
                          </tr>
                          <tr>
                            <td className={styles.legenda}>Vendedor: </td>
                            <td className="text-left">
                              {capitalizeFirstLetter(vendedor.nome)} {capitalizeFirstLetter(vendedor.sobrenome)}
                            </td>
                            <td className={styles.legenda}>Cliente: </td>
                            <td className="text-left">
                              {capitalizeFirstLetter(
                                formValues.cliente.descricao
                              )}
                            </td>
                          </tr>
                          
                          <tr>
                            <td className={styles.legenda}>
                              Tipo de Negociação:{" "}
                            </td>
                            <td className="text-left">{formValues.negociacao.descricao}</td>
                            <td className={styles.legenda}>Transportadora: </td>
                            <td className="text-left">
                              {capitalizeFirstLetter(
                                formValues.transportadora.descricao
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className={styles.legenda} colSpan="3">
                              Valor Total:{" "}
                            </td>
                            <td className={`${styles.valorTotal}`} colSpan="1">
                              {formatarValor(valorTotalPedido)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </fieldset>
              </div>
            </Tab>
            <Tab eventKey="detalhes" title="Detalhes do Orçamento">
              <fieldset className={styles.fieldsetTransportadora}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="transportadora" className={styles.label}>
                    <i className="bi bi-truck"></i>
                    Transportadora:
                  </Form.Label>
                  <div className={styles.conteudo}>
                    <Form.Control
                      id="transportadora"
                      className={styles.select}
                      
                      value={
                        formValues.transportadora
                          ? `${formValues.transportadora.descricao}`
                          : ""
                      }
                      readOnly
                    />
                  </div>
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label
                    htmlFor="transportadoraCpfCnpj"
                    className={styles.label}
                  >
                    Transportadora Cpf/Cnpj:
                  </Form.Label>
                  <div className={styles.conteudo}>
                    <Form.Control
                      id="transportadoraCpfCnpj"
                      value={
                        formValues.transportadora
                          ? `${formValues.transportadora.cpfCnpj}`
                          : ""
                      }
                      readOnly
                    />
                    <button
                      type="button"
                      onClick={() => handleOpenModal("transportadora")}
                      className={`customSelectBtn ${styles.customSelectBtn}`}
                    >
                      {botaoTransportadora}
                    </button>
                  </div>
                </Form.Group>

                {showModal === "transportadora" && (
                  <TransportadoraModal
                    onLoading={onLoading}
                    onClose={handleCloseModal}
                    onSelect={(selectedTransportadora) => {
                      setFormValues((prevState) => ({
                        ...prevState,
                        transportadora: {
                          codigo: selectedTransportadora.CODTRANS,
                          descricao: `${capitalizeFirstLetter(
                            selectedTransportadora.NOME
                          )} ${capitalizeFirstLetter(
                            selectedTransportadora.SOBRENOME
                          )}`,
                          cpfCnpj: selectedTransportadora.CPFCNPJ,
                        },
                      }));
                    }}
                    onClear={handleClearTransportadora}
                  />
                )}
              </fieldset>

              <fieldset className={styles.fieldsetCliente}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="tipoNegociacao" className={styles.label}>
                    Tipo de Negociação:*
                  </Form.Label>
                  <Form.Select
                    className={styles.select}
                    id="tipoNegociacao"
                    required
                    value={
                      formValues.negociacao
                        ? `${formValues.negociacao.codigo},${formValues.negociacao.descricao}`
                        : ""
                    }
                    onChange={handleTipoNegociacaoChange}
                  >
                    <option value="">Selecione uma opção</option>
                    {tipoNegociacao.map((item) => (
                      <option
                        key={item.CODTIPNEG}
                        value={`${item.CODTIPNEG},${item.DESCRICAO}`}
                      >
                        {capitalizeFirstLetter(item.DESCRICAO)}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="frete" className={styles.label}>
                    Frete:*
                  </Form.Label>
                  <Form.Select
                    className={styles.select}
                    id="frete"
                    value={frete}
                    onChange={(e) => setFrete(e.target.value)}
                  >
                    <option value="">Selecione uma opção</option>
                    <option value="SEM_FRETE">Sem Frete</option>
                    <option value="CIF">CIF</option>
                    <option value="FOB">FOB</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="valorFrete" className={styles.label}>
                    Valor do Frete (R$):
                  </Form.Label>
                  <Form.Control
                    className={styles.select}
                    type="text"
                    id="valorFrete"
                    value={valorFrete}
                    onChange={(e) => {
                      const valorUsuario = e.target.value;
                      setValorFrete(valorUsuario);
                    }}
                    onBlur={(e) => {
                      const valorDigitado = e.target.value;

                      if (
                        valorDigitado.trim() === "" ||
                        valorDigitado.trim() === "0"
                      ) {
                        setValorFrete("");
                      } else {
                        const valorNumerico = parseFloat(
                          valorDigitado.replace(",", ".")
                        );
                        if (!isNaN(valorNumerico)) {
                          setValorFrete(
                            Number.isInteger(valorNumerico)
                              ? String(valorNumerico)
                              : valorNumerico.toFixed(2).replace(".", ",")
                          );
                        }
                      }
                    }}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="valorDesconto" className={styles.label}>
                    Valor do Desconto (R$):
                  </Form.Label>
                  <Form.Control
                    className={styles.select}
                    type="text"
                    id="valorDesconto"
                    value={desconto}
                    // disabled={descontoHabilitado !== "Sim"}
                    onChange={(e) => {
                      const valorUsuario = e.target.value;
                      setDesconto(valorUsuario);
                    }}
                    onBlur={(e) => {
                      const valorDigitado = e.target.value;

                      if (
                        (valorDigitado && valorDigitado.trim() === "") ||
                        valorDigitado.trim() === "0"
                      ) {
                        setDesconto("");
                      } else {
                        const valorNumerico = parseFloat(
                          valorDigitado.replace(",", ".")
                        );
                        if (valorNumerico === 0) {
                          setDesconto("0");
                        } else if (!isNaN(valorNumerico)) {
                          setDesconto(
                            Number.isInteger(valorNumerico)
                              ? String(valorNumerico)
                              : valorNumerico.toFixed(2).replace(".", ",")
                          );
                        }
                      }
                    }}
                  />
                </Form.Group>
              </fieldset>
            </Tab>
          </Tabs>
          <div
            className={styles.buttonContainer}
            style={{
              justifyContent:
                abaAtiva === "cabecalho" ? "flex-end" : "space-between",
            }}
          >
            {abaAtiva === "cabecalho" && (
              <div className={styles.buttonAvancar}>
                {abaAtiva === "cabecalho" ? (
                  <button
                    type="button"
                    onClick={() => {
                      if (abaAtiva === "cabecalho") {
                        if (validarCampos()) {
                          avancarParaProximaAba("itens");
                        } else {
                          console.error(
                            "Preencha todos os campos obrigatórios antes de avançar."
                          );
                        }
                      }
                    }}
                  >
                    {botaoAvancar}
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => avancarParaProximaAba("detalhes")}
                  >
                    Avançar
                  </button>
                )}
              </div>
            )}
          </div>
          {abaAtiva === "itens" && (
            <div
              className={styles.buttonAvancar}
              style={{ justifyContent: "space-between" }}
            >
              <div className={styles.buttonContainer}>
                <div className={styles.buttons}>
                  <button
                    type="button"
                    onClick={(e) => voltarParaAbaAnterior(e)}
                  >
                    Voltar
                  </button>
                </div>
              </div>
              <button
                type="button"
                onClick={() => {
                  avancarParaProximaAba("detalhes");
                }}
              >
                Avançar
              </button>
            </div>
          )}

          <div
            className={styles.buttonAvancar}
            style={{ justifyContent: "space-between" }}
          >
            {abaAtiva === "detalhes" && (
              <div className={styles.buttons}>
                <button type="button" onClick={(e) => voltarParaAbaAnterior(e)}>
                  Voltar
                </button>
              </div>
            )}
            {abaAtiva === "detalhes" && (
              <div className={styles.buttonContainer}>
                <button
                  type="button"
                  onClick={toggleModal}
                  style={{ marginRight: "10px" }}
                  className={styles.botao}
                >
                  <i
                    className="bi bi-receipt"
                    style={{ marginRight: "5px" }}
                  ></i>
                  <span>Visualizar</span>
                </button>
                {isModalOpen && dadosPedido && (
                  <PedidoDetalhesModal
                    pedido={dadosPedido}
                    produtosSelecionados={produtosSelecionados}
                    closeModal={fecharModalVisualizarPedido}
                  />
                )}
                <button
                  style={{ marginRight: "10px" }}
                  className={styles.botao}
                >
                  <i
                    className="bi bi-envelope"
                    style={{ marginRight: "5px" }}
                  ></i>
                  <span>Enviar por e-mail</span>
                </button>
                <EnviarPorWhatsapp
                  pedido={dadosPedido}
                  produtosSelecionados={produtosSelecionados}
                  //refatorar - está dependente de visualizar pedido para renderizar
                />
                <button
                  type="button"
                  onClick={salvarDadosNaAPI}
                  style={{ marginLeft: "10px" }}
                >
                  <i
                    className="bi bi-play-fill"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Salvar
                </button>
              </div>
            )}
          </div>
        </Form>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
      <Modal show={showSemProdutosModal} onHide={() => setShowSemProdutosModal(false)} centered>
        <Modal.Header>
          <Modal.Title>Mensagem</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">Não há tabela de preço e/ou produtos vinculados ao Cliente e Vendedor selecionados!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSemProdutosModal(false)}>
            Fechar
          </Button>
          <Button className={`${styles.icone}`} onClick={() => navigate('/pedidos')}>
            Voltar para pedidos
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NovoOrcamento;
