import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

import styles from "./Licencas.module.css";
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";

const Licencas = () => {
  const navigate = useNavigate();
  const { token, codemp, userData } = useContext(AuthContext);
  const [licencas, setLicencas] = useState([]);
  const [iconStatus, setIconStatus] = useState({});
  const [empresa, setEmpresa] = useState([]);
  const [disponivel, setDisponivel] = useState([]);
  const [erro, setErro] = useState(null);
  const tableRef = useRef(null);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    const fetchLicencas = async () => {
      try {
        const response = await api.get(`/licencas/listar?CODEMP=${codemp}`);
        setLicencas(response.data.licencas);
        setEmpresa(response.data.empresa);
        setDisponivel(response.data.licencas_disponiveis);
        console.log("retorno", response.data);
      } catch (error) {
        // Trate o erro aqui
      }
    };
    fetchLicencas();
  }, [codemp]);

  const Editarlicencas = (CODLICE) => {
    console.log(CODLICE, "retorno");
    setIconStatus((prevStatus) => ({
      ...prevStatus,
      [CODLICE]: !prevStatus[CODLICE],
    }));
    navigate("/editar-licenca", { state: { CODLICE: CODLICE } });
  };

  const handleDeleteClick = (CODLICE) => {
    if (iconStatus[CODLICE] && userData?.permissao === "COMERCIAL") {
      // Verifica a permissão
      console.log("Excluir licença:", CODLICE);
    } else {
      setErro("Você não tem permissão para excluir licenças!");
    }
  };

  const getStatus = (item) => {
    const licencaPresente = licencas.some(
      (licenca) => licenca.LICENCA === item.LICENCA
    );
    const licencaDisponivel = disponivel.some(
      (licenca) => licenca.LICENCA === item.LICENCA
    );

    if (licencaPresente) {
      if (licencaDisponivel) {
        return "Disponível";
      } else {
        return "Ativa";
      }
    } else {
      const licencaDisponivelItem = disponivel.find(
        (licenca) => licenca.LICENCA === item.LICENCA
      );
      if (licencaDisponivelItem && licencaDisponivelItem.CODUSU === null) {
        return "Disponível";
      } else {
        return "Não Ativa";
      }
    }
  };

  useEffect(() => {
    if (
      licencas.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
      });
    }
  }, [licencas]);

  const formatarData = (dataISO) => {
    const data = new Date(dataISO);
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0");
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const showTable = () => {
    try {
      const sortedLicencas = [...licencas].sort((a, b) => {
        const statusA = getStatus(a);
        const statusB = getStatus(b);
        if (statusA === "Ativa" && statusB !== "Ativa") {
          return -1;
        } else if (statusA !== "Ativa" && statusB === "Ativa") {
          return 1;
        } else {
          return a.CODLICE - b.CODLICE;
        }
      });
      return sortedLicencas.map((item) => (
        <tr key={item.CODLICE}>
          <td className="col-1 text-center">{item.CODLICE}</td>
          <td className="col-3 text-center">{item.LICENCA}</td>
          <td className="col-2 text-center">{getStatus(item)}</td>
          <td className="col-1 text-center">{formatarData(item.DATAVENC)}</td>
          <td className="col-1 text-center">
            <button
              className={styles.icone}
              onClick={() => handleDeleteClick(item.CODLICE)}
              disabled={
                !userData ||
                (userData.permissao !== "COMERCIAL" &&
                  getStatus(item) !== "Ativa")
                  
              }
              title={
                userData && userData.permissao !== "COMERCIAL"
                  ? "Apenas o Comercial pode executar essa ação"
                  : userData && getStatus(item) !== "Ativa"
                  ? "Apenas licenças Ativas podem ser excluídas"
                  : "Apenas o Comercial pode executar essa ação"
              }
            >
              <i  style={{ cursor: "not-allowed" }} className={`bi bi-trash ${styles.trashIcon}`}></i>
            </button>
          </td>
        </tr>
      ));
    } catch (e) {
      alert(e.message);
      return null;
    }
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <div className={styles.topoButtons}>
          <button onClick={() => navigate("/nova-licenca")}>
            Adicionar Licenças
          </button>
        </div>
      </div>
      <div className={styles.corpo}>
        <div className={`container-fluid py-4 ${styles.boxTable}`}>
          <div className="table-responsive p-0 pb-2">
            <h1 className={styles.titleTable}>Licenças </h1>
            <table
              ref={tableRef}
              id="tableEmpresas"
              className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
            >
              <thead>
                <tr>
                  <th className="text-center"> Código </th>
                  <th> Licença </th>
                  <th className="text-center"> Status </th>
                  <th className="text-center"> Data Venc. </th>
                  <th className="text-center">Ação</th>
                </tr>
              </thead>
              <tbody>{showTable()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
    </>
  );
};

export default Licencas;
