export function validarCamposVazios(dataObrigatoria){
    const propriedadesVazias = [];
    for (const propriedade in dataObrigatoria) {
      if (dataObrigatoria.hasOwnProperty(propriedade)) {
        const valor = dataObrigatoria[propriedade];
        if (valor === "" || valor === null || valor === undefined || valor === 0 || valor === NaN || valor === '-') {
          propriedadesVazias.push(propriedade);
        }
      }
    }
    return propriedadesVazias
}    
