import styles from "./EditarLicencas.module.css";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from 'react';
 
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import { Modal, Button } from "react-bootstrap";
 
 
  const formatarData = (dataISO) => {
    const data = new Date(dataISO);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };
  
 
  const EditarLicencas = () => {
    const navigate = useNavigate();
    const { token, codemp } = useContext(AuthContext);
    const [selectedTableId, setSelectedTableId] = useState(null);
    const [showModal, setShowModal] = useState(false);
 
    const [codigo, setCodigo] = useState("");
    const [razaosocial, setRazaosocial] = useState("");
    const [qtd, setQtd] = useState("");
    const [licenca, setLicenca] = useState("");
    const [dtvencimento, setDtvencimento] = useState("");
    const [erro, setErro] = useState("");
    
    useEffect(() => {
      setAuthToken(token);
    }, [token]);

    useEffect(() => {
        api
          .get(`/licencas/buscar/?CODEMP=${Number(codemp)}`
          )
          .then((response) => {
            console.log(response.data);
            setCodigo(response.data.CODLICE);
            setRazaosocial(response.data.RAZAOSOCIAL);
            setQtd(response.data.QTD)
            setLicenca(response.data.LICENCA);
            setDtvencimento(response.data.DATAVENC);
          
          })
          .catch((error) => {
            console.error("Erro ao obter licenca:", error);
          });
      }, [codemp]);
 
      const handleAtualizaLicenca = (e) => {
        e.preventDefault();
        if (
        codigo === "" ||
        razaosocial === "" ||
        qtd === "" ||
        licenca === "" ||
        dtvencimento === "" 
    
        ) {
          setErro("Preencha todos os campos!");
          return;
        }
 
   
        const dataFormatada = formatarData(dtvencimento);
        const licencaAtualizada = {
            CODLICE: codigo,
            RAZAOSOCIAL: razaosocial,
            QTD: qtd,
            LICENCA: licenca,
            DATAVENC: dataFormatada,
          };
          console.log("licencaAtualizada: ", licencaAtualizada);
      
          api
          .patch(`/licencas/atualizar`, licencaAtualizada)
            .then((response) => {
              console.log("Licença atualizada com sucesso:", response.data);
              navigate("/licencas");
            })
            .catch((error) => {
              console.error("Erro ao atualizar licença:", error);
              console.log("Mensagens de erro:", error.response.data.message);
            });
        };
 
        const handleDeleteLicenca = () => {
            if (selectedTableId) {
              api
                .delete(`/licencas/remove/?CODEMP=${Number(codemp)}`)
                  .then(() => {
                    navigate("/licencas");
                    setShowModal(false); 
                    console.log("licenca excluída com sucesso");
                  })
                  .catch((error) => {
                    console.error("Erro ao excluir licenca:", error);
                    console.log( "codemp:", codemp);
                    console.log('Mensagem de erro da API:', error.response.data);
                  });
              }
            };
 
            const openModal = (tableId) => {
                setSelectedTableId(tableId);
                setShowModal(true);
              };
 
   const renderModal = () => {
            return (
              <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header>
                  <Modal.Title>Confirmar exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>Deseja excluir a licença?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Cancelar
                  </Button>
                  <Button variant="primary" onClick={handleDeleteLicenca}>
                  Confirmar
                  </Button>
                </Modal.Footer>
              </Modal>
            );
          };
 
 
 
          const children = (
            <div className={styles.grid}>
            <div className={styles.topo}>
              <h1>
                <i
                  className="bi bi-arrow-left-circle-fill"
                  onClick={() => {
                    navigate("/licencas");
                  }}
                ></i>
                Editar Licenças
              </h1>
            </div>
        
            <div className={styles.corpo}>
              <Form className={styles.form} onSubmit={handleAtualizaLicenca}>
               
              <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="codigo" className={styles.label}>
                  Código:
                  </Form.Label>
                  <Form.Control
                    id="codigo"
                    value={codigo}
                    disabled
                  />
                </Form.Group>
        
                <Form.Group className={styles.grupo}>
                <div className={styles.prefix}>
                  <Form.Label htmlFor="licenca" className={styles.label}>
                  Licença:
                  </Form.Label>
                  <Form.Control
                    id="licenca"
                    value={licenca}
                    disabled
                  />
                  </div>
                </Form.Group>
        
        
                <Form.Group className={styles.grupo}>
                <div className={styles.suffix}>
                  <Form.Label htmlFor="dtvencimento" className={styles.label}>
                  Data Vencimento:
                  </Form.Label>
                  <Form.Control
                 id="dtvencimento"
                 value={formatarData(dtvencimento)}
                 disabled
                  />
                   </div>
                </Form.Group>
        
                <div className={styles.rodape}>
                        <button className={styles.submit} type="submit"
                         onClick={handleAtualizaLicenca}>
                          <i className="bi bi-play-fill"></i>
                          Salvar
                        </button>
                        {erro && <p className={styles.erro}>{erro}</p>}
                        <button
                          className={styles.submit}
                          type="button"
                          onClick={() => openModal(codemp)}
                          style={{ cursor: "pointer" }}
                        >
                    <i className="bi bi-trash-fill"></i>
                    Excluir
                  </button>
                </div>
              </Form>
            </div>
          </div>
        
          );
        
          return (
            <>
              <Menu />
              <Cabecalho />
              <CaixaConteudo children={children} />
              {renderModal()}
            </>
          );
        };
        
        export default EditarLicencas;
        
        
        
        