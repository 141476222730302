import React, { useState, useEffect } from 'react';

const EnviarPorWhatsapp = ({ pedido, produtosSelecionados }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 650);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 650);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const formatarTextoWhatsapp = (pedido) => {
        if (!pedido) {
            return '';
        }

        const {
            cliente,
            vendedor,
            tipoMovimentacao,
            status,
            formaPagamento,
            transportadora,
        } = pedido;


        const clienteNome = cliente ? cliente : 'Cliente não especificado';
        const vendedorNome = vendedor ? `${vendedor.nome} ${vendedor.sobrenome}` : 'Vendedor não especificado';
        const formaPagamentoTexto = formaPagamento ? formaPagamento : 'Tipo de Negociação não especificado';

        let valorTotalPedido = 0;
        let produtosTexto = '*_📦Produtos Selecionados_:*\n';
        produtosSelecionados.forEach((produto, index) => {
            const id = index + 1;
            const valorUnitario = Number(produto.valorUnitario);
            const quantidade = Number(produto.quantidade);
            const valorTotal = valorUnitario * quantidade;
            valorTotalPedido += valorTotal;

            produtosTexto += `
      *Nome:* ${produto.nome}
      *Descrição:* ${produto.descricao}
      *Fabricante:* ${produto.fabricante}
      *Valor Unitário:* R$ ${valorUnitario.toFixed(2)}
      *Quantidade:* ${quantidade}
      `;
        });

        const mensagem = `
      *_📋Detalhes do Pedido_*\n
      *Cliente:* ${clienteNome}
      *Vendedor:* ${vendedorNome}
      *Tipo de Movimentação:* ${tipoMovimentacao}
      *Status:* ${status}
      *Tipo de Negociação:* ${formaPagamentoTexto}
      *Transportadora:* ${transportadora}
      
      ${produtosTexto}
      
      *Valor Total do Pedido:* R$ ${valorTotalPedido.toFixed(2)}`;

        return encodeURI(mensagem);
    };

    console.log("entrando no click", pedido);

    const handleClick = () => {
        const textoWhatsapp = formatarTextoWhatsapp(pedido);
        console.log("pedido whats", textoWhatsapp);
        if (textoWhatsapp) {
            const urlWhatsapp = `https://api.whatsapp.com/send?text=${textoWhatsapp}`;
            window.open(urlWhatsapp, '_blank');
        }
    };

    return (
        <button type="button" onClick={handleClick}>
            <i className="bi bi-whatsapp" style={{ marginRight: '5px' }}></i>
            <span style={{ display: isSmallScreen ? 'none' : 'inline' }}>Enviar por WhatsApp</span>
        </button>
    );
};

export default EnviarPorWhatsapp;