import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const exportToPdf = (data) => {
  const tableBody = [];

  // Cria as linhas da tabela
  data.forEach((item) => {
    tableBody.push([
      `${item.Código}`,
      `${item.Descrição}`,
      `${item.DataVigor}`,
      `${item.TabelaPadrão}`,
      `${item.TabelaDesconto}`,
    ]);
  });

  // Define a estrutura do documento PDF
  const docDefinition = {
    content: [
      { text: "Lista de Tabela de preço", style: "header" },
      {
        table: {
          headerRows: 1,
          body: [
            [
              "Código",
              "Descrição",
              "Data Vigor",
              "Tabela Padrão",
              "Tabela Desconto",
            ],
            ...tableBody,
          ],
        },
        layout: "lightHorizontalLines",
      },
    ],
    styles: {
      header: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 10],
      },
    },
  };

  // Gera o PDF e abre-o em uma nova janela do navegador
  pdfMake.createPdf(docDefinition).open();
};

export default exportToPdf;
