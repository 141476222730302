import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import styles from "./Pagamento.module.css";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import { formatarValor } from "../../Utils/formatarValor";

import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import axios from "axios";

const Pagamento = () => {
  const navigate = useNavigate();
  const [pagamentos, setPagamentos] = useState([]);
  const { token, codemp } = useContext(AuthContext);
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [semDados, setSemDados] = useState('')
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  // useEffect(() => {
  //   const fetchPagamentos = async () => {
  //     const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
  //     setLoading(true)
  //     try {
  //       const response = await api.get(
  //         `/tipoNegociacao/listar?CODEMP=${CODEMP}`
  //       );
  //       setPagamentos(response.data);
  //       console.log("retorno", response.data);
  //       setLoading(false)
  //     } catch (error) {
  //       console.error(error);
  //       setLoading(false)
  //     }
  //   };

  //   fetchPagamentos();
  // }, [codemp]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    setLoading(true);
    const getPagamentos = async () => {
      try {
        const response = await api.get(`/tipoNegociacao/listar?CODEMP=${CODEMP}`, {
          cancelToken: source.token
        });
        if(response.data.length === 0 ){
          setPagamentos([])
          setSemDados("Sem dados")
        } else {
          setPagamentos(response.data);
        }
        setLoading(false);
        setReload(false)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
        setLoading(false);
        setReload(false)
      }
    };
    getPagamentos();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp,reload]);

  const EditarPagamento = (CODTIPNEG) => {
    navigate("/editar-pagamento", { state: { CODTIPNEG: CODTIPNEG } });
  };

  useEffect(() => {
    if (
      pagamentos.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
          zeroRecords: "Nenhum registro correspondente encontrado.",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
        order:[[7,'asc'],[1,'desc']]
      });
    }
  }, [pagamentos,reload]);

  function formatarValorPagamento(valor) {
    return valor.toFixed(1).replace(".",',');
  }  

  const showTable = () => {
    try {
      return pagamentos.map((item, index) => {
        const valorEntrada = item.VLRENTRADA;

        return (
          <tr key={index}>
            <td className="text-center">
              <buttom onClick={() => EditarPagamento(item.CODTIPNEG)}>
                <i className="bi bi-pen"></i>
              </buttom>
            </td>
            <td className="text-center">{item.CODTIPNEG}</td>
            <td>{capitalizeFirstLetter(item.DESCRICAO)}</td>
            <td className="col-2 text-center">{item.QTDPARCELAS}</td>
            <td className="col-2 text-center">
              {isNaN(valorEntrada) ? "0,00" : formatarValorPagamento(valorEntrada)}%
            </td>
            <td className="text-center">{item.MULTA}%</td>
            <td className="col-2 text-center">{item.JUROS}%</td>
            {/* <td>{item.PRAZO}</td> */}
            {/* <td>{item.PERCENTUAL}%</td> */}
            <td>{item.ATIVO ? "ATIVO" : "INATIVO"}</td>
          </tr>
        );
      });
    } catch (e) {
      alert(e.message);
    }
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <div className={styles.topoButtons}>
          <button onClick={() => navigate("/nova-forma-pagamento")}>
            Adicionar Forma de Pagamento
          </button>
          <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} title="Atualizar" onClick={()=>setReload(true)}></i>
        </div>
      </div>
      <div className={styles.corpo}>
        <div className={`container-fluid py-4 ${styles.boxTable}`}>
          <div className="table-responsive p-0 pb-2">
            <h1 className={styles.titleTable}>Pagamento</h1>
            <table
              ref={tableRef}
              id="tablePagamentos"
              className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
            >
              <thead>
                <tr>
                  <th className="text-center">
                  Ação
                  </th>
                  <th className="text-center">Código</th>
                  <th className="text-left">Descrição</th>
                  <th className="text-center">Quantidade de parcelas</th>
                  <th className="text-center">Percentual de entrada</th>
                  <th className="text-center">Multa</th>
                  <th className="text-center">Juros</th>
                  {/* <th>Prazo</th> */}
                  {/* <th>Percentual</th> */}
                  <th className="text-left">Status</th>
                </tr>
              </thead>
              <tbody>{loading ? <tr>
                    <td colSpan="8">
                      <LoadingCentroTelas />
                    </td>
                  </tr> : semDados === 'Sem dados' ? <tr>
                    <td style={{ textAlign: "center" }} colSpan="8">
                    A tabela está vazia no momento.
                    </td>
                  </tr> : showTable()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
    </>
  );
};

export default Pagamento;
