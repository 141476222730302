// ESTILOS
import styles from "./EditarMarcas.module.css";

// HOOKS
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import Form from "react-bootstrap/Form";
import { Modal, Button } from "react-bootstrap";
import MessageModal from "../../Components/MessageModal/MessageModal";

import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

const EditarMarcas = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);

  const location = useLocation();
  const [codigoMarcaSankhya, setCodigoMarcaSankhya] = useState("");
  const { codmarca } = location.state;
  console.log("codmarca:", codmarca);
  console.log("codemp:", codemp);

  const [codigo, setCodigo] = useState("");
  const [nomemarca, setNomeMarca] = useState("");
  const [descricao, setDescricao] = useState("");

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [botaoSalvar,setBotaoSalvar] = useState("Salvar")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    setLoading(true)
    api
      .get(
        `/marcas/buscar/?CODEMP=${Number(codemp)}&CODMARCA=${Number(codmarca)}`
      )
      .then((response) => {
        console.log(response.data);
        setCodigo(response.data.CODMARCA);
        setDescricao(response.data.DESCRICAO);
        setNomeMarca(response.data.MARCA);
        setCodigoMarcaSankhya(response.data.CODMARCASAN);
      })
      .catch((error) => {
        console.error("Erro ao obter marca:", error);
      })
      .finally(()=>{
        setLoading(false)
      })
  }, [codemp, codmarca]);

  const handleAtualizaMarca = (e) => {
    e.preventDefault();

    if (nomemarca === "") {
      setModalMessage("Preencha o campo obrigatório: Nome!");
      setShowMessageModal(true);
      return;
    }

    const marcaAtualizada = {
      CODMARCA: codmarca,
      CODEMP: codemp,
      MARCA: nomemarca,
      DESCRICAO: descricao,
      CODMARCASAN: codigoMarcaSankhya,
    };

    setBotaoSalvar("Carregando...")

    api
      .patch(`/marcas/atualizar`, marcaAtualizada)
      .then((response) => {
        console.log("Marca atualizada com sucesso:", response.data);
        setModalMessage("Marca atualizada com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
        setModalMessage("Marca atualizada com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao atualizar marca.Tente novamente!";
        }
        setMessage("");
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
          navigate("/marcas");
        }, 3000);
      })
      .finally(()=>{
        setBotaoSalvar("Salvar")
      })
  };

  const handleDeleteMarca = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(false);

    const requestData = {
      CODEMP: Number(codemp),
      CODMARCA: Number(codmarca),
    };
    console.log("requestData:", requestData);

    api
      .delete(`/marcas/deletar`, { data: requestData })
      .then((response) => {
        console.log("Marca excluída com sucesso:", response.data);
        setMessage("Marca excluída com sucesso!");
        setErro("");
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
          navigate("/marcas");
        }, 3000);
      })
      .catch((error) => {
        console.error("Erro ao excluir marca:", error);
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao deletar marca.Tente novamente");
        }
        setMessage("");
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
        console.log("codmarca:", codmarca, "codemp:", codemp);
      });
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/marcas");
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <h1>
              <i
                className="bi bi-arrow-left-circle-fill"
                onClick={() => {
                  navigate("/marcas");
                }}
              ></i>
              Editar Marca
            </h1>
          </div>

          <div className={styles.corpo}>
            <Form className={styles.form} onSubmit={handleAtualizaMarca}>
              {showMessage && (
                <div
                  className={`${styles.message} ${
                    message ? styles.successMessage : styles.errorMessage
                  }`}
                >
                  <p>{message || erro}</p>
                </div>
              )}
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <p style={{fontSize:'.7rem'}}>Campos obrigatórios(*)</p>
                {loading && <p style={{color: 'red'}}>Carregando...</p>}
              </div>
              <fieldset className={styles.fieldsetTopo}>
                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="codigo" className={styles.label}>
                    Código:
                  </Form.Label>
                  <Form.Control
                    id="codigo"
                    value={codigo}
                    onChange={(e) => setCodigo(e.target.value)}
                    disabled
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label
                    htmlFor="codigoMarcaSankhya"
                    className={styles.label}
                  >
                    Código Sankhya:
                  </Form.Label>
                  <Form.Control
                    id="codigoMarcaSankhya"
                    value={codigoMarcaSankhya}
                    onChange={(e) => setCodigoMarcaSankhya(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="nomemarca" className={styles.label}>
                    Marca*:
                  </Form.Label>
                  <Form.Control
                    id="nomemarca"
                    value={nomemarca}
                    onChange={(e) => setNomeMarca(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className={styles.grupo}>
                  <Form.Label htmlFor="descricao" className={styles.label}>
                    Descrição:
                  </Form.Label>
                  <Form.Control
                    id="descricao"
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                  />
                </Form.Group>
              </fieldset>

              <fieldset className={styles.fieldsetButton}>
                <div className={styles.rodape}>
                  <button
                    className={`${styles.submit} ${styles.excluirButton}`}
                    type="button"
                    onClick={handleDeleteMarca}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-trash-fill"></i>
                    Excluir
                  </button>
                  <button
                    className={`${styles.submit} ${styles.salvarButton}`}
                    type="submit"
                    onClick={handleAtualizaMarca}
                  >
                    <i className="bi bi-play-fill"></i>
                    {botaoSalvar}
                  </button>
                </div>
              </fieldset>
            </Form>
          </div>
        </div>
      </CaixaConteudo>
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que deseja excluir esta marca?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancelar
          </Button>
          <Button
            className={`${styles.submit} ${styles.excluirButton}`}
            onClick={handleConfirmDelete}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default EditarMarcas;
