// ESTILO
import styles from "./UniAlternativa.module.css";

// HOOKS
import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

// LIBS
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";

// COMPONENTES

import { AuthContext } from "../../../Services/AuthContext";
import api, { setAuthToken } from "../../../Services/api";
import EditarUnidadeAlternativa from "../EditarUnidadeAlternativa/EditarUnidadeAlternativa";

const UnAlternativaTable = ({ onToggleFormVisibility, showForm }) => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const tableRef = useRef(null);

  const [unAlternativaTable, setUnAlternativaTable] = useState([]);
  const [erro, setErro] = useState("");
  const [showEditar, setShowEditar] = useState(false);
  const [codUnAlternativa, setCodUnAlternativa] = useState("");

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    const getUnAlternativa = async () => {
      try {
        const response = await api.get(
          `/unidademed/listar/alt?CODEMP=${codemp}`
        );
        setUnAlternativaTable(response.data);
      } catch (error) {
        setErro(
          "Lista de categorias não encontrada, tente novamente mais tarde ou entre em contato conosco."
        );
      }
    };
    getUnAlternativa();
  }, [codemp, token]);

  useEffect(() => {
    if (
      unAlternativaTable.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
      });
    }
  }, [unAlternativaTable]);

  const showTable = () => {
    return unAlternativaTable.map((item, index) => {
      const {
        CODUNMEDALT,
        QUANTIDADE,
        UnidadeMedidas,
        DIVISAO,
        MULTIPLICACAO,
        VALORMULTDIV,
        CONTROLE,
        STATUS,
      } = item;
      const tipo = UnidadeMedidas ? UnidadeMedidas.TIPO : "";
      return (
        <tr key={index}>
          <td className="text-center">
            <i
              style={{ cursor: "pointer" }}
              className="bi bi-pen"
              onClick={() => handleClick(item.CODUNMEDALT)}
            ></i>
          </td>
          <td className="text-center">{CODUNMEDALT}</td>
          <td className="text-center">{tipo}</td>
          <td className="text-center">{QUANTIDADE}</td>
          <td className="text-center">{CONTROLE}</td>
          <td className="text-center">{STATUS ? "ATIVO" : "INATIVO"}</td>
        </tr>
      );
    });
  };

  const handleClick = (codUnMedAlt) => {
    console.log(codUnMedAlt, "aquiiii");
    setCodUnAlternativa(codUnMedAlt);
    setShowEditar(true);
  };

  return (
    <>
      {showEditar ? (
        <EditarUnidadeAlternativa codUnMedAlt={codUnAlternativa} />
      ) : (
        <div className={styles.grid}>
          <div className={styles.corpo}>
            <div className={`container-fluid py-4 ${styles.boxTable}`}>
              <button
                className={`${styles.submit} ${styles.salvarButton}`}
                onClick={onToggleFormVisibility}
              >
                {showForm ? "Mostrar Data Table" : "Nova unidade alternativa"}
              </button>
              <div className="table-responsive p-0 pb-2">
                <table
                  ref={tableRef}
                  className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                >
                  <thead>
                    <tr>
                      <th className="text-center">Ação</th>
                      <th className="text-center">Código</th>
                      <th className="text-center">Unidade</th>
                      <th className="text-center">Quantidade</th>

                      <th className="text-center">Controle</th>
                      <th className="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>{showTable()}</tbody>
                </table>
                {erro && (
                  <div
                    className="alert alert-danger mt-2 text-center"
                    role="alert"
                  >
                    {erro}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UnAlternativaTable;
