// ESTILO
import styles from "./NovaTabelaPrecos.module.css";

// MODULOS
import Form from "react-bootstrap/Form";

// HOOKS
import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import MessageModal from "../../Components/MessageModal/MessageModal";
import { validarCamposVazios } from "../../Utils/validarCamposVazios";

const NovaTabelaPrecos = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);

  const [descricao, setDescricao] = useState("");
  const [dtVigor, setDtVigor] = useState("");
  const [tabPadrao, setTabPadrao] = useState("N");
  const [tabDesconto, setTabDesconto] = useState("N");

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [botaoSalvar,setBotaoSalvar] = useState("Salvar")

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dadosObrigatorios = {
      'Descriçao': descricao,
      'Data de Vigor': dtVigor,
    }

    if(validarCamposVazios(dadosObrigatorios).length > 0) {
      setModalMessage(`Preencha os campos obrigatorios: ${validarCamposVazios(dadosObrigatorios)}!`);
      setShowMessageModal(true);
      return;
    } 

    const novaTabelaPrecos = {
      CODEMP: Number(codemp),
      DESCRICAO: descricao,
      DTVIGOR: new Date(dtVigor).toISOString(),
      TABPADRAO: tabPadrao,
      TABDESCONTO: tabDesconto,
    };

    setBotaoSalvar("Carregando...")

    api
      .post("/tabela-precos/cadastrar", novaTabelaPrecos)
      .then((response) => {
        console.log("Tabela de preço criado com sucesso:", response.data);

        resetarInputs();

        setModalMessage("Tabela de preço criado com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
        setBotaoSalvar("Salvar")
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = "";
        if (error.response.status === 409) {
          errorMessage = "Conflito: Tabela de preço já cadastrada.";
          setModalMessage(errorMessage);
          setShowMessageModal(true);
        } else if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else if (error.response && error.response.status === 300) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = "Erro ao criar Tabela de preço. Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
        setBotaoSalvar("Salvar")
      });
  };

  const resetarInputs = () => {
    setDescricao("");
    setDtVigor("");
    setTabPadrao("N");
    setTabDesconto("N");
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/tabela-preco");
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            class="bi bi-arrow-left-circle-fill"
            onClick={() => navigate("/tabela-preco")}
          ></i>
          Nova Tabela de Preço
        </h1>
      </div>

      <div className={styles.corpo}>
        <Form className={styles.form} id="novaTabelaPrecosForm">
          <h1>Campos obrigatórios(*)</h1>
          <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="descricao" className={styles.label}>
                Descrição*:
              </Form.Label>
              <Form.Control
                id="descricao"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="dtVigor" className={styles.label}>
                Data de Vigor*:
              </Form.Label>
              <Form.Control
                type="date"
                id="dtVigor"
                value={dtVigor}
                onChange={(e) => setDtVigor(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="tabPadrao" className={styles.label}>
                Tabela Padrão?*
              </Form.Label>
              <div className={styles.statusFlex}>
                <Form.Check
                  label="Sim"
                  name="tabPadrao"
                  type="radio"
                  id="simTabPad"
                  value="S"
                  onClick={(e) => setTabPadrao(e.target.value)}
                />
                <Form.Check
                  defaultChecked
                  label="Não"
                  name="tabPadrao"
                  type="radio"
                  id="naoTabPad"
                  value="N"
                  className={`${styles.check} is-valid`}
                  onClick={(e) => setTabPadrao(e.target.value)}
                />
              </div>
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="tabDesconto" className={styles.label}>
                Tabela de Desconto?*
              </Form.Label>
              <div className={styles.statusFlex}>
                <Form.Check
                  label="Sim"
                  name="tabDesconto"
                  type="radio"
                  id="simTabDesc"
                  value="S"
                  onClick={(e) => setTabDesconto(e.target.value)}
                />
                <Form.Check
                  defaultChecked
                  label="Não"
                  name="tabDesconto"
                  type="radio"
                  id="naoTabDesc"
                  value="N"
                  className={`${styles.check} is-valid`}
                  onClick={(e) => setTabDesconto(e.target.value)}
                />
              </div>
            </Form.Group>
          </fieldset>

          <div className={styles.rodape}>
            <button className={styles.submit} onClick={handleSubmit}>
              <i className="bi bi-play-fill"></i>
              {botaoSalvar}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default NovaTabelaPrecos;
