import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./Empresas.module.css";
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";

import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";
import { AuthContext } from '../../Services/AuthContext';
import api, { setAuthToken } from '../../Services/api';
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";

const Empresas = () => {
  const navigate = useNavigate();
  const [empresas, setEmpresas] = useState([]);
  const { token, codemp } = useContext(AuthContext);
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
      setAuthToken(token);
    }, [codemp, token]);

  useEffect(() => {
    setLoading(true)
    if(codemp) {
    const fetchEmpresas = async () => {
      try {
        const response = await api.get(`/empresas/listar?CODEMP=${codemp}`);
        setEmpresas(response.data);
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    };

    fetchEmpresas();
  }
  }, [codemp]);

  useEffect(() => {
    if (
      empresas.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        ordering: true,
        destroy: true,
      });
    }
  }, [empresas]);

  const EditarEmpresas = (CODEMP) => {
    console.log(CODEMP);
    navigate("/editar-empresas", { state: { CODEMP: CODEMP } });
  };

  const showTable = () => {
    try {
      return empresas.map((item) => {
        return (
          <tr>
            <td className="text-center">{item.CODEMP}</td>
            <td>{capitalizeFirstLetter(item.RAZAOSOCIAL)}</td>
            <td>{`${item.CNPJ}`}</td>
            <td className="text-center">{item.QTDINTEGRANTES}</td>
            <td>{item.ATIVIDADE}</td>
            {/* <td>
              <buttom onClick={() => EditarEmpresas(item.CODTIPNEG)}>
                <i className="bi bi-pen"></i>
              </buttom>
            </td> */}
          </tr>
        );
      });
    } catch (e) {
      alert(e.message);
    }
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}></div>
      <div className={styles.corpo}>
        <div className={`container-fluid py-4 ${styles.boxTable}`}>
          <div className="table-responsive p-0 pb-2">
            <h1 className={styles.titleTable}>Empresas</h1>
            <table
              ref={tableRef}
              id="tableEmpresas"
              className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
            >
              <thead>
                <tr>
                  <th className="text-center"> Código</th>
                  <th> Razão social </th>
                  <th> CNPJ </th>
                  <th className="text-center"> N°. máx. integrantes </th>
                  <th> Atividade </th>
                  {/* <th>  <i className="bi bi-pen"></i> </th> */}
                </tr>
              </thead>
              <tbody>{loading ? <tr>
                <td colSpan="8">
                  <LoadingCentroTelas />
                </td>
              </tr> : showTable()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
    </>
  );
};

export default Empresas;