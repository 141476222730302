import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

import styles from "./NovaLicenca.module.css";
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import MessageModal from "../../Components/MessageModal/MessageModal";

const NovaLicenca = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);

  const [codigo, setCodigo] = useState("");
  const [razaosocial, setRazaosocial] = useState("");
  const [qtd, setQtd] = useState("");
  const [licenca, setLicenca] = useState("");
  const [dtvencimento, setDtvencimento] = useState("");
  const [erro, setErro] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      codigo === "" ||
      razaosocial === "" ||
      qtd === "" ||
      licenca === "" ||
      dtvencimento === ""
    ) {
      setModalMessage("Preencha todos os campos!");
      setShowMessageModal(true);
      return;
    }

    const NovaLicenca = {
      CODLICE: codigo,
      RAZAOSOCIAL: razaosocial,
      QTD: qtd,
      LICENCA: licenca,
      DATAVENC: dtvencimento,
    };
    api
      .post("/licencas/cadastra", NovaLicenca)
      .then((response) => {
        console.log("Licença criado com sucesso:", response.data);
        resetarInputs();
        setModalMessage("Licença criado com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response.status === 409) {
          errorMessage = "Conflito: Lincença já cadastrada.";
          setModalMessage(errorMessage);
          setShowMessageModal(true);
        } else if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao criar Licença. Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
      });
  };

  const resetarInputs = () => {
    setCodigo("");
    setRazaosocial(true);
    setQtd("");
    setLicenca("");
    setDtvencimento("");
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/licencas-");
  };
  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            class="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/licencas");
            }}
          ></i>
          Nova Licença
        </h1>
      </div>
      <div className={styles.corpo}>
        <Form className={styles.form} id="novaFormaPagamentoForm">
          <fieldset className={styles.fieldsetTipoNeg}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="razaosocial" className={styles.label}>
                Razão social:
              </Form.Label>
              <Form.Control
                id="razaosocial"
                value={razaosocial}
                onChange={(e) => setRazaosocial(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="qtd" className={styles.label}>
                Quantidade:
              </Form.Label>
              <Form.Control
                id="qtd"
                value={qtd}
                onChange={(e) => setQtd(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <div className={styles.prefix}>
                <Form.Label htmlFor="licenca" className={styles.label}>
                  Licença:
                </Form.Label>
                <Form.Control
                  id="licenca"
                  value={licenca}
                  onChange={(e) => setLicenca(e.target.value)}
                />
              </div>
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <div className={styles.suffix}>
                <Form.Label htmlFor="dtvencimento" className={styles.label}>
                  Data Vencimento:
                </Form.Label>
                <Form.Control
                  id="dtvencimento"
                  value={dtvencimento}
                  onChange={(e) => setDtvencimento(e.target.value)}
                />
              </div>
            </Form.Group>
          </fieldset>

          <div className={styles.rodape}>
            <button className={styles.submit} onClick={handleSubmit}>
              <i className="bi bi-play-fill"></i>
              Salvar
            </button>
          </div>
        </Form>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default NovaLicenca;
