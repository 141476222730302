// ESTILO
import styles from "./CadastroEmpresa.module.css";

// MODULOS
import Form from "react-bootstrap/Form";

// HOOKS
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// COMPONENTES
import logo from "../../Assets/Logos/whiteLogoThinkSale.png";
import ModalEmpresa from "../../Components/Modal/ModalEmpresa";

import api from "../../Services/api";
import { useLocation } from "react-router-dom";

const CadastroEmpresa = ({ nome }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const newPerson = location.state?.newPerson.Proposta;
  console.log("aaaaaaaaaa", newPerson);

  const [modal, setModal] = useState(false);

  const [atv, setAtv] = useState("");
  const [qtdPes, setQtdPes] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [cargo, setCargo] = useState("");
  const [erro, setErro] = useState("");

  const radioAtv = [
    { label: "Indústria", id: "Indústria" },
    { label: "Comércio", id: "Comércio" },
    { label: "Distribuição", id: "Distribuição" },
    { label: "Outros", id: "Outros" },
  ];

  const radioQtdPes = [
    { label: "1 - 10", id: "10" },
    { label: "10 - 30", id: "30" },
    { label: "30 - 50", id: "50" },
    { label: "50 - 100", id: "100" },
    { label: "100 - 200", id: "200" },
    { label: "200 - 500", id: "500" },
  ];

  const radioCargo = [
    { label: "Proprietário de Negócio", id: "Proprietário de Negócio" },
    { label: "Líder de Equipe", id: "Líder de Equipe" },
    { label: "Diretor", id: "Diretor" },
    { label: "Gerente", id: "Gerente" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (atv === "" || qtdPes === "") {
        setErro("Preencha todos os campos.");
        return;
      }

      const dadosUsuario = {
        Proposta: { ...newPerson, CARGO: cargo },
        Empresa: {
          ATIVIDADE: atv,
          QTDINTEGRANTES: +qtdPes,
          RAZAOSOCIAL: razaoSocial,
          // NOMEFANTASIA: nomeFantasia,
          CNPJ: cpfCnpj,
        },
      };
      // console.log(dadosUsuario);

      const response = await api.post("/cadastro/inicial", dadosUsuario);
      console.log(response.data);
      setModal(true);
    } catch (error) {
      console.log(error);
      if (error.response.data.statusCode === 417) {
        setErro("E-mail já cadastrado.");
      } else {
        setErro(
          "Houve um erro no cadastro, tente novamente mais tarde ou entre em contato conosco"
        );
      }
    }
  };

  return (
    <section className={styles.section}>
      <aside className={styles.aside}>
        <img src={logo} alt="Logo ThinkSale" />
      </aside>

      <div className={styles.conteudo}>
        <div className={styles.container}>
          {!modal && (
            <div>
              <p>Olá, {nome}</p>
              <p className={styles.subtitle}>
                {" "}
                <i
                  className="bi bi-arrow-left-circle-fill"
                  onClick={() => {
                    navigate("/cadastro");
                  }}
                ></i>
                Seja bem vindo (a)
              </p>
              <Form className={styles.form}>
                <Form className={styles.formEmpresa}>
                  <Form.Group
                    className={styles.grupoEmpresa}
                    controlId="documento"
                  >
                    <Form.Label className={styles.labelEmpresa}>
                      Cnpj*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite seu documento"
                      className={styles.inputEmpresa}
                      value={cpfCnpj}
                      onChange={(e) => setCpfCnpj(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className={styles.grupoEmpresa}
                    controlId="documento"
                  >
                    <Form.Label className={styles.labelEmpresa}>
                      Razão social*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite a razão social"
                      className={styles.inputEmpresa}
                      value={razaoSocial}
                      onChange={(e) => setRazaoSocial(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className={styles.grupoEmpresa}
                    controlId="documento"
                  >
                    <Form.Label className={styles.labelEmpresa}>
                      Nome Fantasia
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite o nome fantasia"
                      className={styles.inputEmpresa}
                      value={nomeFantasia}
                      onChange={(e) => setNomeFantasia(e.target.value)}
                    />
                  </Form.Group>
                </Form>
                <Form.Label className={styles.label}>
                  Qual o tipo de atividade na sua empresa?
                  <div className={styles.radios}>
                    {radioAtv.map((i) => (
                      <Form.Check
                        className={styles.radio}
                        inline
                        label={i.label}
                        name="atividade"
                        type="radio"
                        id={i.id}
                        key={i.id}
                        value={atv}
                        onClick={(e) => setAtv(e.target.id)}
                      />
                    ))}
                  </div>
                </Form.Label>

                <Form.Label className={styles.label}>
                  Quantas pessoas trabalham na sua empresa?
                  <div className={styles.radios}>
                    {radioQtdPes.map((i) => (
                      <Form.Check
                        className={styles.radio}
                        inline
                        label={i.label}
                        name="qtdpessoas"
                        type="radio"
                        id={i.id}
                        key={i.id}
                        value={qtdPes}
                        onClick={(e) => setQtdPes(e.target.id)}
                      />
                    ))}
                  </div>
                </Form.Label>

                <Form.Label className={styles.label}>
                  Qual área de atuação da sua empresa?
                  <div className={styles.radios}>
                    {radioCargo.map((i) => (
                      <Form.Check
                        className={styles.radio}
                        inline
                        label={i.label}
                        name="cargo"
                        type="radio"
                        id={i.id}
                        key={i.id}
                        value={cargo}
                        onClick={(e) => setCargo(e.target.id)}
                      />
                    ))}
                  </div>
                </Form.Label>

                <input
                  className={styles.submit}
                  type="submit"
                  value="Continuar"
                  onClick={handleSubmit}
                />
              </Form>
            </div>
          )}

          {erro && (
            <div className={styles.erro}>
              <span>{erro}</span>
            </div>
          )}

          {modal && <ModalEmpresa />}
        </div>
      </div>
    </section>
  );
};

export default CadastroEmpresa;
