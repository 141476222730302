// ESTILO
import styles from "./Produtos.module.css";

// MODULOS
import $ from "jquery";

// HOOKS
import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { CSVLink } from "react-csv";
import exportToPdf from "./ExportPDF";
import { semFotoBase64 } from "../../Utils/semFotoBase64";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import axios from "axios";

const Produtos = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const [produtos, setProdutos] = useState([]);
  const [loaded, setLoaded] = useState(false);
  // const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  // const [deletedProduct, setDeletedProduct] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [loading, setLoading] = useState(false)
  const [semDados, setSemDados] = useState('')
  const [reload, setReload] = useState(false)

  const tableRef = useRef(null);

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  // useEffect(() => {
  //   const getProdutos = async () => {
  //     const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
  //     setLoading(true)
  //     try {
  //       const response = await api.get(
  //         `/produtos/listar?CODEMP=${Number(CODEMP)}&PAGE=1`
  //       );
  //       setProdutos(response.data);
  //       console.log(response.data);
  //       setLoading(false)      
  //     } catch (error) {
  //       console.error(error);
  //       setLoading(false)
  //     }
  //   };
  //   if (codemp && token) {
  //     getProdutos();
  //   }
  // }, [codemp, token]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    const getProdutos = async () => {
      setLoading(true);
      setSemDados('')
        try {
          const response = await api.get(`/produtos/listar?CODEMP=${Number(CODEMP)}&PAGE=1`, {
            cancelToken: source.token
          });
          if(response.data.length === 0 ){
            setProdutos([])
            setSemDados("Sem dados")
          } else {
            setProdutos(response.data);
          }
          setLoading(false)
          setReload(false)
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          } else {
            console.error(error);
          } 
          setLoading(false)
          setReload(false)
        }
    };
    getProdutos();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp,reload]);
  

  const csvData = produtos.map((produto) => ({
    Produto: produto.PRODUTO,
    Descricao: produto.DESCRICAO,
    Fabricante: produto.FABRICANTE,
    Sku: produto.SKU,
  }));

  const pdfData = produtos.map((produto) => ({
    Produto: produto.PRODUTO,
    Descricao: produto.DESCRICAO,
    Fabricante: produto.FABRICANTE,
    Sku: produto.SKU,
  }));

  const exportButtons = (
    <div className={styles.exportButtonsContainer}>
      <CSVLink
        data={csvData}
        filename="produtos.csv"
        className={`${styles.exportButton} btn btn-sm btn-primary`}
      >
        Exportar CSV
      </CSVLink>
      <button
        className={`${styles.exportButton} btn btn-sm btn-primary`}
        onClick={() => exportToPdf(pdfData)}
      >
        Exportar PDF
      </button>
    </div>
  );

  useEffect(() => {
    if (
      produtos.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
          zeroRecords: "Nenhum registro correspondente encontrado.",
        },
        lengthChange: false,
        //destroy: true,
        ordering: true,
        pageLength: 10,
        order:[[4,'asc'],[3,'asc']]
      });
    }
  }, [produtos]);  

  const editarProduto = (codProd, codLoc, codUnMed) => {
    console.log(codProd, codLoc, codUnMed, "aquiiii");
    navigate("/editar-produto", {
      state: { codProd: codProd, codLoc: codLoc, codUnMed: codUnMed },
    });
  };

  const handleImageClick = (images, item) => {
    console.log(">>>>>>>>>>>>>>>>>", images);
    const filteredImages = images
      .filter((image) => image)
      .map((image) => `data:image/jpeg;base64,${image}`); // Adicione o prefixo aqui
    setSelectedImages(filteredImages);
    setModalOpen(true);
    setSelectedItem(item);
  };
  const handlePrevImage = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : selectedImages.length - 1
    );
  };

  const handleNextImage = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < selectedImages.length - 1 ? prevIndex + 1 : 0
    );
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImages([]);
  };

  const showTable = () => {
    try {
    return produtos.map((item) => {
      const mainImageUrl = item.ImagemProd?.IMAGEM || semFotoBase64();
      const additionalImages = [
        item.ImagemProd?.IMAGEM1,
        item.ImagemProd?.IMAGEM2,
        item.ImagemProd?.IMAGEM3,
      ].filter((image) => image); // Filtrar imagens indefinidas
  
      return (
        <tr key={item.CODPROD}>
          <td style={{ width: '50px' }}>
            <i
              style={{ cursor: "pointer" }}
              className="bi bi-pen"
              onClick={() =>
                editarProduto(item.CODPROD, item.CODLOC, item.CODUNMED)
              }
            ></i>
          </td>
          <td className="text-center">{item.CODPROD}</td>
          <td className="text-center">
            <img
              className={styles.foto}
              src={`data:image/jpeg;base64,${mainImageUrl}`}
              alt={item.PRODUTO}
              onClick={() =>
                handleImageClick([mainImageUrl, ...additionalImages], item)
              }
            />
          </td>
          <td className="text-left">{item.PRODUTO}</td>
          <td>{item.STATUS ? "ATIVO" : "INATIVO"}</td>
        </tr>
      );
    })
  } catch (e) {
    alert(e.message);
  }
  };
  

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <div className={styles.topoButtons}>
              <button onClick={() => navigate("/novo-produto")}>
                Adicionar Produto
              </button>
              <button onClick={() => navigate("/importar-produtos")}>
                <i className="bi bi-box-arrow-up" /> Importar Produtos
              </button>
              <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} title="Atualizar"></i>
            </div>
          </div>
          <div className={styles.corpo}>
            <div className={styles.container}>
              <div className={styles.exportButtonsContainer}>
                {exportButtons}
              </div>
              
              <div className={styles.corpo}>
                <div className={`container-fluid py-4 ${styles.boxTable}`}>
                  <div className="table-responsive p-0 pb-2">
                    <h1 className={styles.titleTable}>Produtos</h1>
                    <table
                      ref={tableRef}
                      id="tableProdutos"
                      className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">Ação</th>
                          <th className="text-center">Código</th>
                          <th className="text-center">Foto</th>
                          <th>Produto</th>
                          <th className="text-center">Status</th>
                        </tr>
                      </thead>
                      <tbody>{loading ? <tr>
                        <td colSpan="8">
                          <LoadingCentroTelas />
                        </td>
                      </tr> : semDados === "Sem dados" ? <tr>
                        <td style={{ textAlign: "center" }} colSpan="8">
                        A tabela está vazia no momento.
                        </td>
                      </tr> : showTable()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CaixaConteudo>

      {modalOpen && selectedItem && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <button className={styles.closeButton} onClick={closeModal}>
              <i className="bi bi-x"></i>
            </button>
            <div className={styles.imageAndInfoContainer}>
              <div className={styles.imageContainer}>
                <div className={styles.arrowContainer}>
                  <div className={styles.arrowLeft} onClick={handlePrevImage}>
                    <i className="bi bi-chevron-left"></i>
                  </div>
                  <div className={styles.modalImageContainer}>
                    <img
                      className={styles.modalImage}
                      src={selectedImages[activeIndex]}
                      alt={`Imagem ${activeIndex + 1}`}
                    />
                  </div>
                  <div className={styles.arrowRight} onClick={handleNextImage}>
                    <i className="bi bi-chevron-right"></i>
                  </div>
                </div>
                {selectedImages.length > 1 && (
                  <div className={styles.thumbnailsContainer}>
                    {selectedImages.map((imageUrl, index) => (
                      <img
                        key={index}
                        className={styles.thumbnail}
                        src={imageUrl}
                        alt={`Thumbnail ${index}`}
                        onClick={() => setActiveIndex(index)}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className={styles.productInfo}>
                <p>Descrição: {selectedItem.DESCRICAO}</p>
                <p>Fabricante: {selectedItem.FABRICANTE}</p>
                {/* <p>
                  Categoria:{" "}
                  {selectedItem.Categoria ? selectedItem.Categoria.DESCRICAO : "N/A"}
                </p>
                <p>Peso Bruto: {selectedItem.PESOBRUTO || "N/A"} kg</p>
                <p>Peso Líquido: {selectedItem.PESOLIQUIDO || "N/A"} kg</p>
                <p>Quantidade em Estoque: {selectedItem.QTDESTOQUE || "N/A"}</p> */}
                <p>SKU: {selectedItem.SKU || "N/A"}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Produtos;
