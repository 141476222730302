import { useEffect, useState } from 'react'
import styles from './ModalDadosInvalidosImport.module.css'
const ModalDadosInvalidosImport = ({linhas, handleClose}) => {

  const [EMAIL, setEMAIL] = useState([])
  const [TELEFONE, setTELEFONE] = useState([])
  const [CPFCNPJ, setCPFCNPJ] = useState([])
  const [CEP, setCEP] = useState([])

useEffect(() => {
  linhas.map((item) => {
    if(item.campo === 'EMAIL'){
      setEMAIL((prev) => [...prev, item])
    }
    if(item.campo === 'TELEFONE'){
      setTELEFONE((prev) => [...prev, item])
    }
    if(item.campo === 'CPFCNPJ'){
      setCPFCNPJ((prev) => [...prev, item])
    }
    if(item.campo === 'CEP'){
      setCEP((prev) => [...prev, item])
    }
  })
},[linhas])
  return (
    <div className={styles.modal}>
    <div className={styles.topoModal}>
        <h2>Erro: Dados inválidos!</h2>
        <span onClick={handleClose}>X</span>
    </div>
    {linhas.length > 0 ? (
      <div className={styles.linhas}>
        <p>As seguintes colunas do seu arquivo apresentam dados inválidos nas linhas:</p>
        {EMAIL.length > 0 && (
          <>
            <strong>E-MAIL</strong>
            {EMAIL.map((linhaInfo) => (
              <p key={linhaInfo.linha}>
                Linha {linhaInfo.linha}.
              </p>
            ))}
          </>
        )}
        {TELEFONE.length > 0 && (
          <>
            <strong>TELEFONE</strong>
            {TELEFONE.map((linhaInfo) => (
              <p key={linhaInfo.linha}>
                Linha {linhaInfo.linha}.
              </p>
            ))}
          </>
        )}
        {CPFCNPJ.length > 0 && (
          <>
            <strong>CPFCNPJ</strong>
            {CPFCNPJ.map((linhaInfo) => (
              <p key={linhaInfo.linha}>
                Linha {linhaInfo.linha}.
              </p>
            ))}
          </>
        )}
        {CEP.length > 0 && (
          <>
            <strong>CEP</strong>
            {CEP.map((linhaInfo) => (
              <p key={linhaInfo.linha}>
                Linha {linhaInfo.linha}.
              </p>
            ))}
          </>
        )}
      </div>
    ) : null}
    <div className={styles.footer}>
        <p className={styles.erro}>Corrija os campos e tente novamente.</p>
        <button className={styles.btn} onClick={handleClose}>Fechar</button>
    </div>
  </div>
  )
}

export default ModalDadosInvalidosImport