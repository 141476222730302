// ESTILO
import styles from "./Bonificacoes.module.css";

// MODULOS
//import Form from 'react-bootstrap/Form'
import $ from "jquery";

// HOOKS
//import { useNavigate } from 'react-router-dom'
//import { useState } from 'react';
import { useEffect, useRef } from "react";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";

const Bonificacoes = () => {
  //const navigate = useNavigate();
  const tableRef = useRef(null);

  const bonificacoes = [
    {
      cliente: "Paulo Costa",
      vendedor: "Maria Lurdes",
      numPedido: 25789,
      cpf_cnpj: "09149440616",
      email: "paulo@gmail.com",
      data: "29/12/2022",
      status: "ativo",
    },
    {
      cliente: "João Ribeiro",
      vendedor: "Carla Silva",
      numPedido: 99987,
      cpf_cnpj: "05387653212",
      email: "joao.ribeiro@gmail.com",
      data: "01/09/2022",
      status: "ativo",
    },
    {
      cliente: "Luciana Camargo",
      vendedor: "Andréia Zabetti",
      numPedido: 20201,
      cpf_cnpj: "07863651123",
      email: "luhcamargo@gmail.com",
      data: "11/01/2023",
      status: "cancelado",
    },
    {
      cliente: "Marcos Campos",
      vendedor: "Sávio Ferreira",
      numPedido: 58677,
      cpf_cnpj: "03246567898",
      email: "campos.marcos@gmail.com",
      data: "03/03/2021",
      status: "não faturado",
    },
    {
      cliente: "Isabela Lima",
      vendedor: "Luis Freitas",
      numPedido: 10756,
      cpf_cnpj: "09876578487",
      email: "isabelaltda@gmail.com",
      data: "22/08/2022",
      status: "faturado",
    },
  ];

    useEffect(() => {
        if (
          bonificacoes.length > 0 &&
          tableRef.current &&
          !$.fn.DataTable.isDataTable(tableRef.current)
        ) {
          $(tableRef.current).DataTable({
            language: {
              search: "Pesquisar",
              info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
            },
            lengthChange: false,
            ordering: true,
          });
        }
      }, []);

  // BUSCA NO BANCO DE DADOS E SETA CADA LINHA TABELA
  const showTable = () => {
    try {
      return bonificacoes.map((item) => {
        return (
          <tr>
            <td>{item.cliente}</td>
            <td>{item.vendedor}</td>
            <td>{item.numPedido}</td>
            <td>{item.cpf_cnpj}</td>
            <td>{item.email}</td>
            <td>{item.data}</td>
            <td>{item.status}</td>
            <td>
              <i class="bi bi-pen"></i>
            </td>
          </tr>
        );
      });
    } catch (e) {
      alert(e.message);
    }
  };

  const children = (
    <>
      <div className={styles.topo}></div>
      <div className={styles.container}>
        <div className={styles.baseContainer}>
          <div class={`container-fluid py-4 ${styles.boxTable}`}>
            <div class="table-responsive p-0 pb-2">
              <table
                ref={tableRef}
                className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
              >
                <thead>
                  <tr>
                    <th>Cliente</th>
                    <th>Vendedor</th>
                    <th>Número do pedido</th>
                    <th>CPF/CNPJ</th>
                    <th>E-mail</th>
                    <th>Data</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{showTable()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
    </>
  );
};

export default Bonificacoes;
