// ESTILO
import styles from "./Marcas.module.css";

// HOOKS
import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import axios from "axios";

const Marcas = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);
  const tableRef = useRef(null);
  const [marcas, setMarcas] = useState([]);
  const [erro, setErro] = useState("");
  const [loading, setLoading] = useState(false)
  const [semDados, setSemDados] = useState('')
  const [reload, setReload] = useState(false)

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  // useEffect(() => {
  //   const getMarcas = async () => {
  //     const CODEMP = codemp != null ? codemp : sessionStorage.getItem("CODEMP");
  //     setLoading(true)
  //     try {
  //       const response = await api.get(`/marcas/listar?CODEMP=${CODEMP}`);
  //       setMarcas(response.data);
  //       setLoading(false)
  //     } catch (error) {
  //       console.log(error);
  //       setLoading(false)
  //     }
  //   };
  //   getMarcas();
  // }, [codemp, token]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    setLoading(true);
    const getMarcas = async () => {
      try {
        const response = await api.get(`/marcas/listar?CODEMP=${CODEMP}`, {
          cancelToken: source.token
        });
        if(response.data.length === 0 ){
          setMarcas([])
          setSemDados("Sem dados")
        } else {
          setMarcas(response.data);
        }
        setLoading(false);
        setReload(false)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
          setLoading(false);
          setReload(false)
        }
      }
    };
    getMarcas();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp,reload]);

  useEffect(() => {
    if (
      marcas.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
      });
    }
  }, [marcas,reload]);

  const editarMarca = (codmarca) => {
    navigate("/editar-marcas", { state: { codmarca } });
  };

  const showTable = () => {
    return marcas.map((item, index) => {
      const { CODMARCA, MARCA, DESCRICAO } = item;
      return (
        <tr key={index}>
          <td style={{width: '50px'}} className="text-center">
            <i
              style={{ cursor: "pointer" }}
              className="bi bi-pen"
              onClick={() => editarMarca(item.CODMARCA)}
            ></i>
          </td>
          <td className="col-1 text-center">{CODMARCA}</td>
          <td>{MARCA}</td>
          <td>{DESCRICAO || ""}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <div className={styles.topoButtons}>
              <button
                onClick={() => {
                  navigate("/adicionar-marcas");
                }}
              >
                Adicionar uma nova marca
              </button>
              <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} title="Atualizar" onClick={()=>setReload(true)}></i>
            </div>
          </div>
          <div className={styles.corpo}>
            <div className={`container-fluid py-4 ${styles.boxTable}`}>
              <div className="table-responsive p-0 pb-2">
                <h1 className={styles.titleTable}>Marcas</h1>
                <table
                  ref={tableRef}
                  className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                >
                  <thead>
                    <tr>
                      <th className="text-center">
                        Ação
                      </th>
                      <th>Código</th>
                      <th>Marca</th>
                      <th>Descrição</th>
                    </tr>
                  </thead>
                  <tbody>{loading ? <tr>
                    <td colSpan="8">
                      <LoadingCentroTelas />
                    </td>
                  </tr> : semDados === 'Sem dados' ? <tr>
                    <td style={{ textAlign: "center" }} colSpan="8">
                    A tabela está vazia no momento.
                    </td>
                  </tr> : showTable()}</tbody>
                </table>
                {erro && (
                  <div
                    className="alert alert-danger mt-2 text-center"
                    role="alert"
                  >
                    {erro}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CaixaConteudo>
    </>
  );
};

export default Marcas;
