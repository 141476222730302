import $ from "jquery";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import api, { setAuthToken } from "../../Services/api";
import { AuthContext } from "../../Services/AuthContext";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";

import styles from "./ModalVendedor.module.css";

const VendedoresModal = ({ onSelect, onClose, handleSubmit}) => {
  const { token, codemp } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [vendedores, setVendedores] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedVendedor, setSelectedVendedor] = useState(null);
  const [showError, setShowError] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    setAuthToken(token);
    api
      .get(`/vendedor/listar?CODEMP=${codemp}`)
      .then((response) => {
        console.log("Vendedores obtidos com sucesso:", response.data);
        setVendedores(response.data);
        setLoaded(true);
        setShowModal(true);
      })
      .catch((error) => {
        console.error("Erro ao obter vendedores:", error);
      });
  }, [codemp, token, loaded]);

  useEffect(() => {
    if (
      vendedores.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
      });
    }
  }, [vendedores]);

  const handleClose = () => {
    console.log("handleClose chamado. Modal será fechado.");
    onClose();
  };

  return (
    <>
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title className={styles["modal-title"]}>
            Selecione um Vendedor
          </Modal.Title>
          <Button variant="none" className="btn btn-none" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <table
            ref={tableRef}
            className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
          >
            <thead>
              <tr>
                <th className="text-center">Código</th>
                <th>Nome</th>
                <th>Email</th>
                <th className="text-center">Selecionar</th>
              </tr>
            </thead>
            <tbody>
              {vendedores.map((vendedor, index) => (
                <tr key={index}>
                  <td className="text-center">{vendedor.CODVEND}</td>
                  <td className="text-center">{`${capitalizeFirstLetter(
                    vendedor.Usuario.Pessoa.NOME
                  )} ${capitalizeFirstLetter(
                    vendedor.Usuario.Pessoa.SOBRENOME
                  )}`}</td>
                  <td className="text-center">
                    {vendedor.Usuario.Pessoa.EMAIL}
                  </td>
                  <td className="text-center">
                    <button
                      className={styles.select}
                      type="button"
                      onClick={() => {
                        if (!vendedor || !vendedor.Usuario.Pessoa) {
                          console.error("Informações do vendedor incompletas.");
                          return;
                        }

                        if (
                          selectedVendedor &&
                          selectedVendedor.CODVEND === vendedor.CODVEND
                        ) {
                          console.log(
                            "Vendedor já selecionado. Removendo da seleção."
                          );
                          setSelectedVendedor(null);
                        } else {
                          console.log(
                            "Vendedor não estava selecionado. Adicionando à seleção.",
                            vendedor
                          );
                          setSelectedVendedor({
                            CODVEND: vendedor.CODVEND,
                            NOME: vendedor.Usuario.Pessoa.NOME,
                            SOBRENOME: vendedor.Usuario.Pessoa.SOBRENOME,
                          });
                          console.log("selecioneee", selectedVendedor);
                        }
                      }}
                    >
                      <i
                        className={`bi ${
                          selectedVendedor &&
                          selectedVendedor.CODVEND === vendedor.CODVEND
                            ? "bi-check-circle-fill"
                            : "bi-circle"
                        }`}
                      ></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          {showError && (
            <p style={{ color: "red" }}>
              ⚠️Selecione um vendedor antes de salvar
            </p>
          )}
          <Button
            onClick={() => {
              console.log("entrou no onclick", selectedVendedor);
              if (
                !selectedVendedor ||
                !selectedVendedor.NOME ||
                !selectedVendedor.CODVEND
              ) {
                console.error(
                  "Dados do vendedor selecionado estão incompletos ou ausentes."
                );
                setShowError(true);
                return;
              } else {
                onSelect(selectedVendedor);
                handleSubmit(selectedVendedor);
                setShowError(false);
                console.log(
                  `Salvando: ${selectedVendedor.NOME} ${selectedVendedor.SOBRENOME}, CODVEND: ${selectedVendedor.CODVEND}`
                );
                handleClose();
              }
            }}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VendedoresModal;
