export const validarEMAIL = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);    
}

export const validarCEP = (cep) => {
    const cepRegex = /^[0-9]{8}$/;
    const cepSemPontuacao = cep.replace(/\D/g, ''); // Remove todos os caracteres que não são dígitos
    return cepRegex.test(cepSemPontuacao);
  };

export const validarTELEFONE = (telefone) => {
    // Expressão regular que aceita números com ou sem DDD, com ou sem espaços, parênteses e hífens
    const numero = telefone.replace(/^0+/, '');
    const telefoneRegex = /^\(?\d{2}\)?\s?-?\s?\d{4,5}-?\d{4}$/;
    return telefoneRegex.test(numero);
  };

// export const validarCPFCNPJ = (documento) => {
//     // Remove todos os caracteres que não são dígitos
//     const documentoLimpo = documento.replace(/\D/g, '');
  
//     // Expressão regular para CPF (11 dígitos) ou CNPJ (14 dígitos)
//     const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
//     const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
  
//     // Verifica se o documento corresponde a um CPF ou CNPJ válido
//     return cpfRegex.test(documentoLimpo) || cnpjRegex.test(documentoLimpo);
//   };

  export const validarCPFCNPJ = (documento) => {
    // Remove todos os caracteres que não são dígitos
    const documentoLimpo = documento.replace(/\D/g, '');
  console.log(documentoLimpo);
    // Expressões regulares para CPF (11 dígitos) ou CNPJ (14 dígitos)
    const cpfRegex = /^\d{11}$/;
    const cnpjRegex = /^\d{14}$/;
  
    // Verifica se o documento corresponde a um CPF ou CNPJ válido
    if (cpfRegex.test(documentoLimpo) || cnpjRegex.test(documentoLimpo)) {
      // Se for CPF ou CNPJ, calcula os dígitos verificadores
      if (documentoLimpo.length === 11) { // CPF
        let sum = 0;
        for (let i = 0; i < 9; i++) {
          sum += parseInt(documentoLimpo.charAt(i)) * (10 - i);
        }
        let remainder = sum % 11;
        let digit = remainder < 2 ? 0 : 11 - remainder;
        if (parseInt(documentoLimpo.charAt(9)) !== digit) {
          return false;
        }
        sum = 0;
        for (let i = 0; i < 10; i++) {
          sum += parseInt(documentoLimpo.charAt(i)) * (11 - i);
        }
        remainder = sum % 11;
        digit = remainder < 2 ? 0 : 11 - remainder;
        if (parseInt(documentoLimpo.charAt(10)) !== digit) {
          return false;
        }
      } else { // CNPJ
        let sum = 0;
        let weight = 2;
        for (let i = 11; i >= 0; i--) {
          sum += parseInt(documentoLimpo.charAt(i)) * weight;
          weight = weight === 9 ? 2 : weight + 1;
        }
        let remainder = sum % 11;
        let digit = remainder < 2 ? 0 : 11 - remainder;
        if (parseInt(documentoLimpo.charAt(12)) !== digit) {
          return false;
        }
        sum = 0;
        weight = 2;
        for (let i = 12; i >= 0; i--) {
          sum += parseInt(documentoLimpo.charAt(i)) * weight;
          weight = weight === 9 ? 2 : weight + 1;
        }
        remainder = sum % 11;
        digit = remainder < 2 ? 0 : 11 - remainder;
        if (parseInt(documentoLimpo.charAt(13)) !== digit) {
          return false;
        }
      }
      return true;
    }
    return false; // Se não corresponder a CPF nem CNPJ, é inválido
  };
  