import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import api, { setAuthToken } from "../../../Services/api";
import { AuthContext } from "../../../Services/AuthContext";
import { capitalizeFirstLetter } from "../../../Utils/stringCapitalizer";
import $ from "jquery";

import styles from "./ModalProdutoItensTabela.module.css";
import LoadingCentroTelas from "../../../Components/LoadingCentroTelas/LoadingCentroTelas";

const ModalProdutosItensTabela = ({ onProductsSelected, onClose, selectedProducts }) => {
    const { token, codemp } = useContext(AuthContext);
    const [produtos, setProdutos] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(selectedProducts || []);
    const tableRef = useRef(null);
    const [dataTableInitialized, setDataTableInitialized] = useState(false);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setAuthToken(token);
        setLoading(true)
        api
            .get(`/produtos/listar?CODEMP=${codemp}`)
            .then((response) => {
                console.log("Produtos obtidos:", response.data);
                setProdutos(response.data);
            })
            .catch((error) => {
                console.error("Erro ao obter produtos:", error);
            })
            .finally(()=>{
                setLoading(false)
            })
    }, [codemp, token]);

    const handleSave = () => {
        const selectedData = selectedProduct.map(item => ({
            CODPROD: item.CODPROD,
            produto: {
                CODPROD: item.CODPROD,
                PRODUTO: item.PRODUTO,
                FABRICANTE: item.FABRICANTE,
            }
        }));

        console.log("Produtos Selecionados:", selectedData); 
        onProductsSelected(selectedData);
        onClose();
    };

    const handleProductSelection = (product) => {
        const isSelected = selectedProduct.some(
            (selected) => selected.CODPROD === product.CODPROD
        );

        setSelectedProduct((prevSelected) => {
            if (isSelected) {
                return prevSelected.filter(
                    (selected) => selected.CODPROD !== product.CODPROD
                );
            } else {
                return [...prevSelected, product];
            }
        });
    };

    useEffect(() => {
        if (produtos.length > 0 && tableRef.current && !dataTableInitialized) {
          $(tableRef.current).DataTable({
            language: {
              search: "Pesquisar",
              info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
            },
            lengthChange: false,
            destroy: true,
            ordering: true,
            order:[[3,'desc'],[1,'asc']]
          });
          setDataTableInitialized(true);
        }
      }, [produtos, dataTableInitialized]);

      const showProdutos = () => {
        if (loading) {
            return (
              <tr>
                <td colSpan="8">
                  <LoadingCentroTelas />
                </td>
              </tr>
            );
          }
          try{
        
        return produtos.map((item, index) => {
          
          return (
            <tr key={index}>
                <td className="text-center">{item.CODPROD}</td>
                <td className="text-center">{capitalizeFirstLetter(item.PRODUTO)}</td>
                <td className="text-center">{item.QTDESTOQUE || 0}</td>
                <td style={{display:'none'}} className="text-center">{item.QTDESTOQUE && item.QTDESTOQUE > 0 ? 'SIM' : 'NAO'}</td>
                <td className="text-center">
                    <input
                        type="checkbox"
                        checked={selectedProduct.some(p => p.CODPROD === item.CODPROD)}
                        onChange={() => handleProductSelection(item)}
                    />
                </td>
            </tr>
          );
        })
    } catch (e) {
        alert(e.message);
      }
      };

    return (
        <Modal show={true} size="xl" className="custom-modal-size">
            <Modal.Header>
                <Modal.Title className={styles["modal-title"]}>Selecione os produtos</Modal.Title>
                <Button variant="none" className="btn btn-none" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body style={{ overflow: 'auto' }}>
                <table ref={tableRef} className={`table align-items-center justify-content-center mb-0 ${styles.table}`}>
                    <thead>
                        <tr>
                            <th className="text-center">Código</th>
                            <th className="text-center">Produto</th>
                            <th className="text-center">Estoque</th>
                            <th style={{display:'none'}} className="text-center">Tem estoque</th>
                            <th className="text-center">Selecionar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {showProdutos()}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSave}>
                    Salvar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalProdutosItensTabela;
