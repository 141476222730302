// ESTILO
import styles from "./NovaTransportadora.module.css";

// MODULOS
import Form from "react-bootstrap/Form";
import InputMask from "react-input-mask";

// HOOKS
import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";

// COMPONENTES
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";
import MessageModal from "../../Components/MessageModal/MessageModal";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";
import { validarCamposVazios } from "../../Utils/validarCamposVazios";
import { validarCPFouCNPJ } from "../../Utils/validarCPFouCNPJ";

const NovaTransportadora = () => {
  const navigate = useNavigate();
  const { token, codemp } = useContext(AuthContext);

  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cpf, setCpf] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cpfOuCnpj, setcpfOuCnpj] = useState("cpf");

  const [erro, setErro] = useState("");

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [botaoSalvar, setBotaoSalvar] = useState("Salvar")

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErro("");

    const dadosObrigatorios = {
      ...(cpfOuCnpj === "cnpj" ? {
        'Razão Social': nome,
        'Nome Fantasia': sobrenome,
        'CNPJ': cnpj,
      } : {
        'Nome': nome,
        'Sobrenome': sobrenome,
        'CPF': cpf,
      }),
      'Telefone': telefone,
      'E-mail': email
    };

    if(validarCamposVazios(dadosObrigatorios).length > 0) {
      setModalMessage(`Preencha os campos obrigatorios: ${validarCamposVazios(dadosObrigatorios)}!`);
      setShowMessageModal(true);
      return;
    } 

    if(validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj) === 'CPF' || validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj) === 'CNPJ') {
      setModalMessage(`${validarCPFouCNPJ(cpfOuCnpj,cpf,cnpj)} inválido!`);
      setShowMessageModal(true);
      return;
    } 

    const novaTransportadora = {
      NOME: nome,
      SOBRENOME: sobrenome,
      EMAIL: email,
      TELEFONE: telefone,
      CPFCNPJ: cpfOuCnpj === "cnpj" ? cnpj : cpf,
      TIPO: cpfOuCnpj === "cnpj" ? "J" : "F",
      CODEMP: codemp,
    };

    setBotaoSalvar("Carregando...")

    api
      .post("/transportadora/cadastrar/transportadora", novaTransportadora)
      .then((response) => {
        console.log("Transportadora criada com sucesso:", response.data);
        // resetar inputs
        resetarInputs();

        setModalMessage("Transportadora criada com sucesso!");
        setIsSuccess(true);
        setShowMessageModal(true);
      })
      .catch((error) => {
        let errorMessage = "";

        if (error.response.status === 409) {
          errorMessage = "Conflito: Transportadora já cadastrada.";
          setModalMessage(errorMessage);
          setShowMessageModal(true);
        } else if (error.response && error.response.status === 500) {
          errorMessage =
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
        } else {
          errorMessage = "Erro ao criar transportadora. Tente novamente!";
        }
        setModalMessage(errorMessage);
        setIsSuccess(false);
        setShowMessageModal(true);
      })
      .finally(()=>{
        setBotaoSalvar("Salvar")
      })
  };

  //RESETAR O VALOR DE TODOS OS INPUTS
  const resetarInputs = () => {
    setNome("");
    setSobrenome("");
    setEmail("");
    setTelefone("");
    setcpfOuCnpj("");
  };

  // Função para alternar entre Pessoa Física e Pessoa Jurídica
  const handleTipoPessoaChange = (event) => {
    const novoTipo = event.target.value;
    setcpfOuCnpj(novoTipo);

    if (novoTipo === "cnpj") {
      setCpf("");
    } else {
      setCnpj("");
    }
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {
    navigate("/transportadora");
  };

  // Função para buscar CNPJ e extrair nome e email
  console.log(cnpj);
  const buscarCNPJ = () => {
    api
      .get(`/empresas/buscar/cnpj?CNPJ=${cnpj}`)
      .then((response) => {
        const data = response.data;
        console.log("dadosssss", data);

        if (data) {
          const { RAZAOSOCIAL, EMAIL, TELEFONE, NOMEFANTASIA } = data;
          console.log("Nome: " + RAZAOSOCIAL);
          console.log("Email: " + EMAIL);
          console.log("Tel: " + TELEFONE);
          console.log("Nome Fantasia: " + NOMEFANTASIA);
          setNome(RAZAOSOCIAL);
          setEmail(EMAIL);
          setTelefone(TELEFONE);
          setSobrenome(NOMEFANTASIA);
        } else {
          console.log("Dados não encontrados na resposta da API.");
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar CNPJ: " + error);
      });
  };

  const children = (
    <div className={styles.grid}>
      <div className={styles.topo}>
        <h1>
          <i
            class="bi bi-arrow-left-circle-fill"
            onClick={() => {
              navigate("/transportadora");
            }}
          ></i>
          Nova Transportadora
        </h1>
      </div>

      <div className={styles.corpo}>
        <Form className={styles.form} id="novaFormaPagamentoForm">
          <h1>Campos obrigatórios(*)</h1>
          <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="tipoPessoa" className={styles.label}>
                Tipo de Pessoa*:
              </Form.Label>
              <Form.Select
                id="tipoPessoa"
                className="form-control"
                value={cpfOuCnpj}
                onChange={handleTipoPessoaChange}
              >
                <option value="cpf">Pessoa Física</option>
                <option value="cnpj">Pessoa Jurídica</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="cpfCnpj" className={styles.label}>
                {cpfOuCnpj === "cnpj" ? "CNPJ*" : "CPF*"}:
              </Form.Label>
              <div className={styles.inputWithIcon}>
                <Form.Control
                  id="cpfCnpj"
                  as={InputMask}
                  mask={
                    cpfOuCnpj === "cnpj"
                      ? "99.999.999/9999-99"
                      : "999.999.999-99"
                  }
                  value={cpfOuCnpj === "cnpj" ? cnpj : cpf}
                  onChange={(e) => {
                    if (cpfOuCnpj === "cnpj") {
                      setCnpj(e.target.value);
                    } else {
                      setCpf(e.target.value);
                    }
                  }}
                />
                {cpfOuCnpj === "cnpj" && (
                  <i
                    className={`bi bi-search ${styles.searchIcon}`}
                    onClick={buscarCNPJ}
                  ></i>
                )}
              </div>
            </Form.Group>
          </fieldset>
          <fieldset className={styles.fieldsetTopo}>
            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="nome" className={styles.label}>
                {cpfOuCnpj === "cnpj" ? "Razão Social" : "Nome*:"}
              </Form.Label>
              <Form.Control
                className={styles.inputStyle}
                id="nome"
                value={capitalizeFirstLetter(nome)}
                onChange={(e) => setNome(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="sobrenome" className={styles.label}>
                {cpfOuCnpj === "cnpj" ? "Nome Fantasia" : "Sobrenome*:"}
              </Form.Label>
              <Form.Control
                className={styles.inputStyle}
                id="sobrenome"
                value={capitalizeFirstLetter(sobrenome)}
                onChange={(e) => setSobrenome(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="telefone" className={styles.label}>
                Telefone*:
              </Form.Label>
              <Form.Control
                className={styles.inputStyle}
                id="telefone"
                as={InputMask}
                mask="(99) 99999-9999"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
              />
            </Form.Group>

            <Form.Group className={styles.grupo}>
              <Form.Label htmlFor="email" className={styles.label}>
                E-mail*:
              </Form.Label>
              <Form.Control
                className={styles.inputStyleEmail}
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </fieldset>
          <div className={styles.rodape}>
            <button className={styles.submit} onClick={handleSubmit}>
              <i className="bi bi-play-fill"></i>
              {botaoSalvar}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo children={children} />
      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default NovaTransportadora;
