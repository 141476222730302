import { useState, useEffect, useRef } from "react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

import styles from "./Orcamento.module.css";
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";

import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";
import moment from "moment";
import { formatarValor } from "../../Utils/formatarValor";
import { Button, Modal } from "react-bootstrap";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import axios from "axios";

const Orcamento = () => {
  const { token, codemp } = useContext(AuthContext);
  const [orcamentos, setOrcamentos] = useState([]);
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [codorcamento, setCodorcamento] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showFaturarModal, setShowFaturarModal] = useState(false);
  const [codpedFaturar, setCodpedFaturar] = useState('')
  const [faturarErro, setFaturarErro] = useState("")
  const [showSucessoFaturar, setShowSucessoFaturar] = useState(false);

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [reload,setReload] = useState(false)
  const [semDados, setSemDados] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  // useEffect(() => {
  //   const CODEMP = codemp != null ? codemp : sessionStorage.CODEMP;
  //   setCarregando(true)
  //   if (CODEMP) {
  //     const getPedidos = async () => {
  //       try {
  //         const response = await api.get(`/pedidos/listar?CODEMP=${CODEMP}`);
  //         const orcamentos = response.data.filter(pedido => pedido.ORCAMENTO === true);
  //         setOrcamentos(orcamentos);
  //         console.log(orcamentos);
  //       } catch (error) {
  //         console.error(error);
  //       } finally {
  //         setCarregando(false)
  //       }
  //     };
  //     getPedidos();
  //   }
  // }, [codemp]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    setLoading(true);
    setOrcamentos([])
    setSemDados('')
    const getOrcamentos = async () => {
      try {
        const response = await api.get(`/pedidos/listar?CODEMP=${CODEMP}`, {
          cancelToken: source.token
        });
        if(response.data.filter(pedido => pedido.ORCAMENTO === true).length === 0 ){
          setSemDados("Sem dados")
        } else {
          setOrcamentos(response.data.filter(pedido => pedido.ORCAMENTO === true));
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
      } finally {
        setLoading(false);
        setReload(false)
      }
    };
    getOrcamentos();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp,reload]);

  useEffect(() => {
    if (
        orcamentos.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        //destroy: true,
        ordering: true,
      });
    }
  }, [orcamentos]);

  function editarOrcamento(codped, codCli) {
    navigate(`/editar-orcamento/`, { state: { codped, codCli } });
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmFaturar = () => {
    setFaturarErro("")

    const orcamentoFaturar = {
      CODPED: codpedFaturar,
      CODEMP: codemp
    }

    setShowFaturarModal(false)
    setShowSucessoFaturar(true)

    api
    .post("/pedidos/faturar", orcamentoFaturar)
    .then((response) => {
      console.log("Orçamento faturado com sucesso:", response.data);

      setShowFaturarModal(false)
      setShowSucessoFaturar(true)

    })
    .catch((error) => {
      console.error("Erro ao faturar orçamento:", error);
      if (error.response && error.response.status === 500) {
        setFaturarErro(
          "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
        );
      } else {
        setFaturarErro(
          "Erro ao faturar orçamento, recarregue a página e tente novamente."
        );
      }
    })
  }

  const handleDeleteOrcamento = async (codped) => {
    setShowDeleteModal(true);
    setCodorcamento(codped);
  };

  const handleConfirmDelete = async () => {
    setShowDeleteModal(false);

    try {
      await api.delete("/pedidos/remover", {
        data: { CODEMP: codemp, CODPED: codorcamento },
      });

      const updateOrcamentos = orcamentos.filter(
        (orcamento) => orcamento.CODPED !== codorcamento
      );
      setOrcamentos(updateOrcamentos);

      setMessage("Orçamento excluído com sucesso!");
      setErro("");
      setShowMessage(true);

      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErro(
          "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
        );
      } else {
        setErro(`Erro ao remover o orçamento com o código ${codorcamento}:`);
      }
      setMessage("");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    }
  };

  const showTable = () => {
    return orcamentos.map((item, index) => {
      const orcamento = item;
      const CODPED = orcamento && orcamento.CODPED ? orcamento.CODPED : "-";
      const dataAltFormatted = moment(orcamento.DATAALT).format("DD/MM/YYYY");
      const STATUS = orcamento && orcamento.StatusPedido ? orcamento.StatusPedido.DESCRICAO : "-";
      const VALORTOTAL = orcamento && orcamento.VALORTOTAL ? orcamento.VALORTOTAL : "-";
      const TIPONEGOCIACAO = orcamento && orcamento.TipoNegociacao?.DESCRICAO ? orcamento.TipoNegociacao?.DESCRICAO : "-";
      const CLIENTE = orcamento && orcamento.CLIVEND.CLIENTE.Pessoa?.NOME ? orcamento.CLIVEND.CLIENTE.Pessoa?.NOME : "-";
      const CPFCNPJ = orcamento && orcamento.CLIVEND.CLIENTE.Pessoa?.CPFCNPJ ? orcamento.CLIVEND.CLIENTE.Pessoa?.CPFCNPJ : "-";
      const VENDEDOR = orcamento && orcamento.CLIVEND.VENDEDOR && orcamento.CLIVEND.VENDEDOR.Usuario?.Pessoa ? orcamento.CLIVEND.VENDEDOR.Usuario.Pessoa.NOME : "-";

      return (
          <tr key={CODPED}>
            <td className={`text-center ${styles.colunaAcao}`}>
              <i
                style={{ cursor: "pointer" }}
                className="bi bi-pen"
                title="Editar"
                onClick={() => editarOrcamento(CODPED)}
              ></i>
              <i 
                style={{ cursor: "pointer" }}
                className="bi bi-trash"
                title="Deletar"
                onClick={() => handleDeleteOrcamento(CODPED)}></i>
                {orcamento.CODSTATUS !== 6 && <i 
                style={{ cursor: "pointer" }}
                className="bi bi-check-circle"
                title="Faturar"
                onClick={() => {
                  setFaturarErro("")
                  setShowFaturarModal(true)
                  setCodpedFaturar(orcamento.CODPED)
                }}>
                </i>}
            </td>
            <td className="col-1 text-center">{CODPED}</td>
            <td className="col-1 text-center">{dataAltFormatted}</td>
            <td>
                {CLIENTE}
            </td>
            <td>{CPFCNPJ}</td>
            <td>
                {VENDEDOR}
            </td>
            <td className="text-center">{formatarValor(VALORTOTAL)}</td>
            <td className="text-center">
              {capitalizeFirstLetter(TIPONEGOCIACAO)}
            </td>
            <td className="text-center">
              {STATUS}
            </td>
          </tr>
      );
    })
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
        <div className={styles.topo}>
          <div className={styles.topoButtons}>
            <button
              onClick={() => {
                navigate("/novo-orcamento");
              }}
            >
              {" "}
              <i class="bi bi-clipboard"></i> Criar Orçamento
            </button>
            <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} title="Atualizar" onClick={() => window.location.reload()}></i>
          </div>
        </div>          
        <div className={styles.corpo}>
        <div className={styles.baseContainer}>
            <div className={`container-fluid py-4 ${styles.boxTable}`}>
              <div className="table-responsive p-0 pb-2">
                <h1 className={styles.titleTable}>Orçamentos</h1>
                <table
                  ref={tableRef}
                  className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                >
                  <thead>
                    <tr>
                      <th className="text-center">Ação</th>
                      <th>Nº do orçamento</th>
                      <th>Data</th>
                      <th>Cliente</th>
                      <th>Cpf/Cnpj</th>
                      <th>Vendedor</th>
                      <th className="text-center">Total</th>
                      <th className="text-center">Negociação</th>
                      <th className="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>{orcamentos.length === 0 && semDados === '' ? <tr>
                      <td colSpan="8">
                        <LoadingCentroTelas />
                      </td>
                    </tr> : semDados === 'Sem dados' ? <tr>
                      <td style={{ textAlign: "center" }} colSpan="8">
                      A tabela está vazia no momento.
                      </td>
                    </tr> : showTable()}</tbody>
                </table>
              </div>
            </div>
            </div>
          </div>
        </div>
      </CaixaConteudo>
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que deseja excluir este pedido?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancelar
          </Button>
          <Button className={`${styles.icone}`} onClick={handleConfirmDelete}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showFaturarModal} onHide={() => setShowFaturarModal(false)} centered>
        <Modal.Header>
          <Modal.Title>Confirmar Faturamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que deseja faturar o pedido {codpedFaturar}?
        </Modal.Body>
        <Modal.Footer>
          {faturarErro && <p className={styles.errorMessage}>{faturarErro}</p>}
          <Button variant="secondary" onClick={() => setShowFaturarModal(false)}>
            Cancelar
          </Button>
          <Button className={`${styles.icone}`} onClick={handleConfirmFaturar}>
            Faturar
          </Button>
        </Modal.Footer>
        
      </Modal>

      <Modal show={showSucessoFaturar} onHide={() => setShowSucessoFaturar(false)} centered>
        <Modal.Header>
          <Modal.Title>Mensagem</Modal.Title>
        </Modal.Header>
        <Modal.Body>Pedido {codpedFaturar} faturado com sucesso!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSucessoFaturar(false)}>
            Fechar
          </Button>
          <Button className={`${styles.icone}`} onClick={() => navigate("/pedidos")}>
            Ir para tela de pedidos
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Orcamento;
