import { Routes, Route } from "react-router-dom";
import Login from "../Pages/Login/login";
import RecuperarSenha from "../Pages/RecuperarSenha/recuperarSenha";
import CadastroCliente from "../Pages/CadastroCliente/CadastroCliente";
import CadastroEmpresa from "../Pages/CadastroEmpresa/CadastroEmpresa";
import Dashboard from "../Pages/Dashboard/Dashboard";
import PerfilEmpresa from "../Pages/Perfil/PerfilEmpresa";
import Produtos from "../Pages/Produtos/Produtos";
import Pedidos from "../Pages/Pedidos/Pedidos";
import Orcamento from "../Pages/Orcamento/Orcamento";
import Clientes from "../Pages/Clientes/Clientes";
import NovoProduto from "../Pages/NovoProduto/NovoProduto";
import NovoPedido from "../Pages/NovoPedido/NovoPedido";
import NovoOrcamento from "../Pages/NovoOrcamento/NovoOrcamento";
import Vendedores from "../Pages/Vendedores/Vendedores";
import MeuPlano from "../Pages/MeuPlano/MeuPlano";
import NovoUsuario from "../Pages/NovoUsuario/NovoUsuario";
import Pagamento from "../Pages/Pagamento/Pagamento";
import NovaFormaPagamento from "../Pages/NovaFormaPagamento/NovaFormaPagamento";
import Bonificacoes from "../Pages/Bonificacoes/Bonificacoes";
import NovoCliente from "../Pages/NovoCliente/NovoCliente";
import Transportadora from "../Pages/Transportadora/Transportadora";
import NovaTransportadora from "../Pages/NovaTransportadora/NovaTransportadora";
import NovaTabelaPrecos from "../Pages/NovaTabelaPrecos/NovaTabelaPrecos";
import EditarUsuario from "../Pages/MeuPlano/EditarUsuario";
import ImportarClientes from "../Pages/ImportarCliente/ImportarCliente";
import EditarVendedor from "../Pages/Vendedores/EditarVendedor";
import EditarCliente from "../Pages/Clientes/EditarCliente";
import UnidadeMedida from "../Pages/UnidadeMedida/UnidadeMedida";
import AdicionarMedida from "../Pages/UnidadeMedida/AdicionarMedida";
import Locais from "../Pages/Locais/Locais";
import AdicionarLocal from "../Pages/Locais/AdicionarLocal/AdicionarLocal";
import EditarLocal from "../Pages/Locais/EditarLocal";
import EditarTransportadora from "../Pages/Transportadora/EditarTransportadora";
import TabelaPreco from "../Pages/Produtos/TabelaPreco/TabelaPreco";
import AddTabelaPreco from "../Pages/Produtos/AddTabelaPreco";
import EditarProduto from "../Pages/Produtos/EditarProduto";
import Marcas from "../Pages/Marcas/Marcas";
import EditarMarcas from "../Pages/Marcas/EditarMarcas";
import AdicionarMarca from "../Pages/Marcas/AdicionarMarca/AdicionarMarca";
import EditarPagamento from "../Pages/Pagamento/EditarPagamento";
import Fornecedor from "../Pages/Fornecedor/Fornecedor";
import EditarFornecedor from "../Pages/Fornecedor/EditarFornecedor/EditarFornecedor";
import AdicionarFornecedor from "../Pages/Fornecedor/AdicionarFornecedor/AdicionarFornecedor";
import Segmento from "../Pages/Segmento/Segmento";
import EditarSegmentos from "../Pages/Segmento/EditarSegmento/EditarSegmento";
import AdicionarSegmentos from "../Pages/Segmento/AdicionarSegmento/AdicionarSegmento";
import EditarPedido from "../Pages/Pedidos/EditarPedido";
import EditarOrcamento from "../Pages/Orcamento/EditarOrcamento";
import Licencas from "../Pages/Licencas/Licencas";
import EditarLicencas from "../Pages/Licencas/EditarLicencas";
import NovaLicenca from "../Pages/NovaLicenca/NovaLicenca";
import Metas from "../Pages/Meta/Meta";
import EditarMetas from "../Pages/Meta/EditarMeta";
import NovaMeta from "../Pages/NovaMeta/NovaMeta";
import Empresas from "../Pages/Administrador/Empresas";
import EditarEmpresas from "../Pages/Administrador/EditarEmpresas";
import Trocas from "../Pages/Trocas/Trocas";
import AddTabelaPrecoVendedor from "../Pages/Vendedores/TabelaPreco/AddTabelaPrecoVendedor";
import AddTabPrecoCliente from "../Pages/Clientes/TabelaPrecoCliente/AddTabPrecoCliente";
import ImportarProduto from "../Pages/ImportarProduto/ImportarProduto";
import Estoque from "../Pages/Estoque/Estoque";
import NovoEstoque from "../Pages/NovoEstoque/NovoEstoque";
import EditarEstoque from "../Pages/Estoque/EditarEstoque/EditarEstoque";
import Categoria from "../Pages/Categoria/Categoria";
import EditarCategoria from "../Pages/Categoria/EditarCategoria/EditarCategoria";
import NovaCategoria from "../Pages/NovaCategoria/NovaCategoria";
import AddVendedorNaMeta from "../Pages/Meta/AddVendedorNaMeta/AddVendedorNaMeta";
import EditarTabelaPreco from "../Pages/Produtos/TabelaPreco/EditarTabelaPreco";
import LogAcessos from "../Pages/Acessos/LogAcessos";
import DispositivosPendentes from "../Pages/DispositivosPendentes/DispositivosPendentes";
import LogImport from "../Pages/LogImport/LogImport";
import DetalheLogImport from "../Pages/DetalheLogImport/DetalheLogImport";

const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/recuperar-senha" element={<RecuperarSenha />} />
        <Route path="/cadastro" element={<CadastroCliente />} />
        <Route path="/cadastro-empresa" element={<CadastroEmpresa />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/editar-perfil" element={<PerfilEmpresa />} />
        <Route path="/produtos" element={<Produtos />} />
        <Route path="/pedidos" element={<Pedidos />} />
        <Route path="/orcamento" element={<Orcamento />} />
        <Route path="/clientes" element={<Clientes />} />
        <Route path="/importar-clientes" element={<ImportarClientes />} />
        <Route path="/importar-produtos" element={<ImportarProduto />} />
        <Route path="/log-imports" element={<LogImport />} />
        <Route path="/detalhe-log-import" element={<DetalheLogImport />} />
        <Route path="/novo-cliente" element={<NovoCliente />} />
        <Route path="/novo-produto" element={<NovoProduto />} />
        <Route path="/novo-pedido" element={<NovoPedido />} />
        <Route path="/novo-orcamento" element={<NovoOrcamento />} />
        <Route path="/vendedores" element={<Vendedores />} />
        <Route path="/meu-plano" element={<MeuPlano />} />
        <Route path="/novo-usuario" element={<NovoUsuario />} />
        <Route path="/editar-usuario" element={<EditarUsuario />} />
        <Route path="/pagamento" element={<Pagamento />} />
        <Route path="/nova-forma-pagamento" element={<NovaFormaPagamento />} />
        <Route path="/bonificacoes" element={<Bonificacoes />} />
        <Route path="/transportadora" element={<Transportadora />} />
        <Route path="/nova-transportadora" element={<NovaTransportadora />} />
        <Route path="/nova-tabela-precos" element={<NovaTabelaPrecos />} />
        <Route path="/editar-vendedor" element={<EditarVendedor />} />
        <Route path="/editar-cliente" element={<EditarCliente />} />
        <Route path="/unidade-medida" element={<UnidadeMedida />} />
        <Route path="/adicionar-medida" element={<AdicionarMedida />} />
        <Route path="/locais" element={<Locais />} />
        <Route path="/adicionar-local" element={<AdicionarLocal />} />
        <Route path="/editar-local" element={<EditarLocal />} />
        <Route path="/add-tabela-preco" element={<AddTabelaPreco />} />
        <Route
          path="/add-tabela-preco-vendedor"
          element={<AddTabelaPrecoVendedor />}
        />
        <Route
          path="/add-tabela-preco-cliente"
          element={<AddTabPrecoCliente />}
        />
        <Route path="/tabela-preco" element={<TabelaPreco />} />
        <Route
          path="/editar-transportadora"
          element={<EditarTransportadora />}
        />
        <Route path="/editar-produto" element={<EditarProduto />} />
        <Route path="/marcas" element={<Marcas />} />
        <Route path="/editar-marcas" element={<EditarMarcas />} />
        <Route path="/adicionar-marcas" element={<AdicionarMarca />} />
        <Route path="/editar-pagamento" element={<EditarPagamento />} />
        <Route path="/fornecedor" element={<Fornecedor />} />
        <Route path="/editar-fornecedor" element={<EditarFornecedor />} />
        <Route
          path="/adicionar-fornecedores"
          element={<AdicionarFornecedor />}
        />
        <Route path="/segmentos" element={<Segmento />} />
        <Route path="/editar-segmentos" element={<EditarSegmentos />} />
        <Route path="/adicionar-segmentos" element={<AdicionarSegmentos />} />
        <Route path="/editar-pedido" element={<EditarPedido />} />
        <Route path="/editar-orcamento" element={<EditarOrcamento />} />
        <Route path="/licencas" element={<Licencas />} />
        <Route path="/editar-licenca" element={<EditarLicencas />} />
        <Route path="/nova-licenca" element={<NovaLicenca />} />
        <Route path="/metas" element={<Metas />} />
        <Route path="/editar-metas" element={<EditarMetas />} />
        <Route path="/nova-meta" element={<NovaMeta />} />
        <Route
          path="/adicionar-vendedor-meta"
          element={<AddVendedorNaMeta />}
        />
        <Route path="/empresas" element={<Empresas />} />
        <Route path="/editar-empresas" element={<EditarEmpresas />} />
        <Route path="/trocas" element={<Trocas />} />
        <Route path="/estoque" element={<Estoque />} />
        <Route path="adicionar-estoque" element={<NovoEstoque />} />
        <Route path="editar-estoque" element={<EditarEstoque />} />
        <Route path="/categoria" element={<Categoria />} />
        <Route path="/editar-categoria" element={<EditarCategoria />} />
        <Route path="/adicionar-categoria" element={<NovaCategoria />} />
        <Route path="/editar-tabela-preco" element={<EditarTabelaPreco />} />



        <Route path="/log-acessos" element={<LogAcessos />} />
        <Route path="/dispositivos-pendentes" element={<DispositivosPendentes />} />

      </Routes>
    </>
  );
};

export default Router;
