import React, { useState, useEffect, useContext, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import styles from "./AddTabPrecoCliente.module.css";
import $ from "jquery";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../../Services/AuthContext";
import api, { setAuthToken } from "../../../Services/api";
import { capitalizeFirstLetter } from "../../../Utils/stringCapitalizer";
import Cabecalho from "../../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../../Components/Menu/Menu";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import MessageModal from "../../../Components/MessageModal/MessageModal";

const AddTabPrecoCliente = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, codemp } = useContext(AuthContext);

  const [listaTabelas, setListaTabelas] = useState([]);
  const [tabelaSelecionada, setTabelaSelecionada] = useState("");
  const [tabelaPrecoVinculada, setTabelaPrecoVinculada] = useState([]);
  const [codTabPrec, setCodTabPrec] = useState("");
  const [codCliTab, setCodCliTab] = useState("");

  const { codCli } = location.state;

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const tableRef = useRef(null);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  // Rota de tabela vinculada
  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    if (codemp) {
      const getTabelasSelect = async () => {
        try {
          console.log("codemp:", codemp);
          const response = await api.get(
            `/tabela-precos/listar?CODEMP=${codemp}`
          );

          setListaTabelas(response.data);
          console.log("cliente", codCli);
          console.log("lista de tabelas", response.data);
        } catch (error) {
          console.error(error);
        }
      };
      getTabelasSelect();
    }
  }, [codemp]);

  useEffect(() => {
    if (codemp) {
      const getTabelas = async () => {
        try {
          console.log("codemp:", codemp, "codcli", codCli);
          const response = await api.get(
            `/tabela-precos/listar/tabelas/clientes?CODEMP=${Number(
              codemp
            )}&CODCLI=${Number(codCli)}`
          );

          setTabelaPrecoVinculada(response.data);
          console.log("lista de tabelas vinculadas", response.data);
        } catch (error) {
          console.error(error);
        }
      };
      getTabelas();
    }
  }, [codemp, codCli, isLoading, listaTabelas]);

  const handleDeleteCliente = async (codTabPrec, codCliTab) => {
    setShowModalDelete(true);
    console.log("CODTABPREC:", codTabPrec);
    console.log("CODCLITAB:", codCliTab);
    setCodTabPrec(codTabPrec);
    setCodCliTab(codCliTab);
  };

  const handleConfirmDelete = () => {
    setShowModalDelete(false);
    setIsLoading(true);
    const requestData = {
      CODEMP: Number(codemp),
      CODCLITAB: Number(codCliTab),
      CODTABPREC: Number(codTabPrec),
    };
    console.log("request data-->", requestData);
    api
      .delete(`/tabela-precos/remover/cliente`, { data: requestData })
      .then((response) => {
        console.log("Tabela excluída do cliente com sucesso:", response.data);
        setMessage("Tabela excluída do cliente com sucesso!");
        setErro("");
        setShowMessage(true);
        setIsLoading(false);

        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Erro ao excluir tabela do  cliente:", error);
        if (error.response && error.response.status === 500) {
          setErro(
            "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
          );
        } else {
          setErro("Erro ao excluir tabela do  cliente:");
        }
        setMessage("");
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
        console.log("codcli:", codCli, "codemp:", codemp);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseDeleteModal = () => {
    setShowModalDelete(false);
  };

  useEffect(() => {
    if (
      tabelaPrecoVinculada.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
          emptyTable: "Nenhum registro encontrado na tabela",
        },
        lengthChange: false,
        destroy: true,
        ordering: true,
      });
    }
  }, [tabelaPrecoVinculada]);

  const dados = {
    CODTABPREC: +tabelaSelecionada,
    CODEMP: +codemp,
    CODCLI: +codCli,
  };
  console.log(dados);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErro("");
    setIsLoading(true);

    try {
      const response = await api.post(
        `/tabela-precos/adicionar/cliente`,
        dados
      );
      console.log("Cadastrado na tabela de preço", response.data);

      setModalMessage("Tabela de preço cadastrada com sucesso!");
      setIsSuccess(true);
      setShowMessageModal(true);
      resetarSelect();
    } catch (error) {
      console.error("Erro ao cadastrar cliente:", error);
      let errorMessage = "";
      if (error.response && error.response.status === 500) {
        errorMessage =
          "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco.";
      } else {
        errorMessage = "Erro ao cadastrar cliente na tabela de preço.";
      }
      setModalMessage(errorMessage);
      setIsSuccess(false);
      setShowMessageModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  const handleRedirecionar = () => {};

  const resetarSelect = () => {
    setTabelaSelecionada("");
  };

  const showTable = () => (
    <div className={styles.corpo}>
      <div className={`container-fluid py-4 ${styles.boxTable}`}>
        <div className="table-responsive p-0 pb-2">
          <h1 className={styles.titleTable}>Tabelas de Preço Vinculada</h1>
          <table
            ref={tableRef}
            className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
          >
            <thead>
              <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th>Data Vigor</th>
                <th>Tabela Padrão</th>
                <th>Tabela Desconto</th>
                <th>
                  <i className="bi bi-trash"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              {tabelaPrecoVinculada.map((item, index) => (
                <tr key={index}>
                  <td>{item.TabelaPrecos.CODTABPREC}</td>
                  <td>{item.TabelaPrecos.DESCRICAO}</td>
                  <td>
                    {moment(item.TabelaPrecos.DTVIGOR).format("DD/MM/YYYY")}
                  </td>
                  <td>
                    {item.TabelaPrecos.TABPADRAO === "N"
                      ? "Não"
                      : item.TabelaPrecos.TABPADRAO === "S"
                      ? "Sim"
                      : ""}
                  </td>
                  <td>
                    {item.TabelaPrecos.TABDESCONTO === "N"
                      ? "Não"
                      : item.TabelaPrecos.TABDESCONTO === "S"
                      ? "Sim"
                      : ""}
                  </td>
                  <td>
                    <button
                      type="button"
                      onClick={() =>
                        handleDeleteCliente(
                          item.TabelaPrecos.CODTABPREC,
                          item.CODCLITAB
                        )
                      }
                      className={`${styles.deleteButton} btn btn-sm btn-danger`}
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
          <div className={styles.topo}>
            <h1>
              <i
                className="bi bi-arrow-left-circle-fill"
                onClick={() => {
                  navigate("/clientes");
                }}
              ></i>
              Adicionar Cliente na Tabela de Preço
            </h1>
          </div>

          <div className={styles.corpo}>
            <Form className={styles.form}>
              {showMessage && (
                <div
                  className={`${styles.message} ${
                    message ? styles.successMessage : styles.errorMessage
                  }`}
                >
                  <p>{message || erro}</p>
                </div>
              )}
              <Form.Group>
                <fieldset className={styles.fieldsetTabela}>
                  <div className={styles.inputsFlex}>
                    <div className={styles.tabela}>
                      <div className={styles.adicionar}>
                        <Form.Group>
                          <Form.Label
                            htmlFor="tabelas"
                            className={styles.label}
                          >
                            <i class="bi bi-table"></i>
                            Tabela de Preço:
                          </Form.Label>
                          <Form.Control
                            className="form-control"
                            as="select"
                            id="tabelas"
                            value={tabelaSelecionada}
                            onChange={(e) =>
                              setTabelaSelecionada(e.target.value)
                            }
                          >
                            <option value="">
                              Selecione uma Tabela de Preço
                            </option>
                            {listaTabelas &&
                              listaTabelas.map((tabela) => (
                                <option
                                  key={tabela.CODTABPREC}
                                  value={tabela.CODTABPREC}
                                >
                                  {capitalizeFirstLetter(tabela.DESCRICAO)}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                        <button
                          className={styles.submit}
                          type="button"
                          disabled={isLoading}
                          onClick={handleSubmit}
                        >
                          {isLoading ? (
                            <>
                              <i className="bi bi-play-fill"></i>
                              Adicionando...
                            </>
                          ) : (
                            <>
                              <i className="bi bi-play-fill"></i>
                              Adicionar
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </fieldset>
                {showTable()}
              </Form.Group>
            </Form>
          </div>
        </div>
      </CaixaConteudo>

      <Modal show={showModalDelete} onHide={handleCloseDeleteModal} centered>
        <Modal.Header>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja excluir tabela vinculada á este cliente?
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="secondary"
            onClick={handleCloseDeleteModal}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            className={`${styles.submit} ${styles.excluirButton}`}
            onClick={handleConfirmDelete}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <MessageModal
        show={showMessageModal}
        message={modalMessage}
        onClose={handleCloseModal}
        isSuccess={isSuccess}
        onSuccess={handleRedirecionar}
      />
    </>
  );
};

export default AddTabPrecoCliente;
