import { useState, useEffect, useRef } from "react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Services/AuthContext";
import api, { setAuthToken } from "../../Services/api";

import styles from "./Pedidos.module.css";
import Cabecalho from "../../Components/Cabecalho/Cabecalho";
import CaixaConteudo from "../../Components/CaixaConteudo/CaixaConteudo";
import Menu from "../../Components/Menu/Menu";
import { capitalizeFirstLetter } from "../../Utils/stringCapitalizer";

import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";
import moment from "moment";
import { formatarValor } from "../../Utils/formatarValor";
import { Button, Modal } from "react-bootstrap";
import LoadingCentroTelas from "../../Components/LoadingCentroTelas/LoadingCentroTelas";
import { formatarCpfCnpj } from "../../Utils/formatarCpfCnpj";
import axios from "axios";

const Pedidos = () => {
  const { token, codemp } = useContext(AuthContext);
  const [pedidos, setPedidos] = useState([]);
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [codpedido, setCodpedido] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  
  const [showDuplicarModal, setShowDuplicarModal] = useState(false);
  const [codpedDuplicar, setCodpedDuplicar] = useState('')
  const [duplicarErro, setDuplicarErro] = useState("")
  const [showSucessoDuplicar, setShowSucessoDuplicar] = useState(false);

  const [erro, setErro] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [carregando,setCarregando] = useState(false)
  const [reload,setReload] = useState(false)
  const [semDados, setSemDados] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  // useEffect(() => {
  //   const CODEMP = codemp != null ? codemp : sessionStorage.CODEMP;
  //   setCarregando(true)
  //   if (CODEMP) {
  //     const getPedidos = async () => {
  //       try {
  //         const response = await api.get(`/pedidos/listar?CODEMP=${CODEMP}`);
  //         const pedidos = response.data.filter(pedido => pedido.ORCAMENTO === false);
  //         setPedidos(pedidos);
  //       } catch (error) {
  //         console.error(error);
  //       } finally {
  //         setCarregando(false)
  //       }
  //     };
  //     getPedidos();
  //   }
  // }, [codemp]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const CODEMP = codemp || sessionStorage.getItem("CODEMP");
    setLoading(true);
    setPedidos([])
    setSemDados('')
    const getPedidos = async () => {
      try {
        const response = await api.get(`/pedidos/listar?CODEMP=${CODEMP}`, {
          cancelToken: source.token
        });
        if(response.data.length === 0 ){
          setSemDados("Sem dados")
        } else {
          setPedidos(response.data);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error(error);
        }
      } finally {
        setLoading(false);
        setReload(false)
      }
    };
    getPedidos();
    return () => {
      source.cancel('Component unmounted');
    };
  }, [codemp,reload]);

  useEffect(() => {
    if (
      pedidos.length > 0 &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        language: {
          search: "Pesquisar: ",
          decimal: ",",
          thousands: ".",
          info: "Exibindo _START_ a _END_ de _TOTAL_ registros",
        },
        lengthChange: false,
        //destroy: true,
        ordering: true,
        order:[8,'desc']
      });
    }
  }, [pedidos]);

  function editarPedido(codped, codCli) {
    navigate(`/editar-pedido/`, { state: { codped, codCli } });
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  function handleConfirmDuplicar() {
    setDuplicarErro("")

    const pedidoDuplicar = {
      CODPED: codpedDuplicar,
      CODEMP: codemp
    }
    console.log(pedidoDuplicar);

    api
    .post("/pedidos/duplicar", pedidoDuplicar)
    .then((response) => {
      console.log("Pedido duplicado com sucesso:", response.data);

      setShowDuplicarModal(false)
      setShowSucessoDuplicar(true)

    })
    .catch((error) => {
      console.error("Erro ao duplicar pedido:", error);
      if (error.response && error.response.status === 500) {
        setDuplicarErro(
          "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
        );
      } else {
        setDuplicarErro(
          "Erro ao duplicar pedido, recarregue a página e tente novamente."
        );
      }
    })
  }

  const handleDeletePedido = async (codped) => {
    setShowDeleteModal(true);
    setCodpedido(codped);
  };

  const handleConfirmDelete = async () => {
    setShowDeleteModal(false);

    try {
      await api.delete("/pedidos/remover", {
        data: { CODEMP: codemp, CODPED: codpedido },
      });

      const updatedPedidos = pedidos.filter(
        (pedido) => pedido.CODPED !== codpedido
      );
      setPedidos(updatedPedidos);

      setMessage("Pedido excluído com sucesso!");
      setErro("");
      setShowMessage(true);

      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErro(
          "Houve um problema interno no servidor. Tente novamente mais tarde ou entre em contato conosco."
        );
      } else {
        setErro(`Erro ao remover o pedido com o código ${codpedido}:`);
      }
      setMessage("");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    }
  };

  console.log(pedidos);

  const showTable = () => {
    return pedidos.map((item, index) => {
      const pedido = item;
      const CODPED = pedido && pedido.CODPED ? pedido.CODPED : "-";
      const dataAltFormatted = moment(pedido.DATAALT).format("DD/MM/YYYY");
      const STATUS = pedido && pedido.StatusPedido ? pedido.StatusPedido.DESCRICAO : "-";
      const VALORTOTAL = pedido && pedido.VALORTOTAL ? pedido.VALORTOTAL : "-";
      const TIPONEGOCIACAO = pedido && pedido.TipoNegociacao.DESCRICAO ? pedido.TipoNegociacao.DESCRICAO : "-";
      const CLIENTE = pedido && pedido.CLIVEND.CLIENTE.Pessoa?.NOME ? pedido.CLIVEND.CLIENTE.Pessoa?.NOME : "-";
      const CPFCNPJ = pedido && pedido.CLIVEND.CLIENTE.Pessoa?.CPFCNPJ ? pedido.CLIVEND.CLIENTE.Pessoa?.CPFCNPJ : "-";
      const VENDEDOR = pedido && pedido.CLIVEND.VENDEDOR && pedido.CLIVEND.VENDEDOR.Usuario?.Pessoa ? pedido.CLIVEND.VENDEDOR.Usuario.Pessoa.NOME : "-";

      return (
          <tr key={CODPED}>
            <td className={`text-center ${styles.colunaAcao}`}>
              <i
                style={{ cursor: "pointer" }}
                className="bi bi-pen"
                title="Editar"
                onClick={() => editarPedido(CODPED)}
              ></i>
              <i 
                style={{ cursor: "pointer" }}
                className="bi bi-trash"
                title="Deletar"
                onClick={() => handleDeletePedido(CODPED)}></i>
              <i
                style={{ cursor: "pointer" }}
                className="bi bi-clipboard-plus"
                title="Duplicar"
                onClick={() => {
                  setDuplicarErro("")
                  setShowDuplicarModal(true)
                  setCodpedDuplicar(CODPED)
                }}
              ></i>
            </td>
            <td className="col-1 text-center">{CODPED}</td>
            <td className="col-1 text-center">{dataAltFormatted}</td>
            <td>
                {CLIENTE}
            </td>
            <td style={{whiteSpace: 'nowrap'}}>{formatarCpfCnpj(CPFCNPJ)}</td>
            <td>
                {VENDEDOR}
            </td>
            <td className="text-center">{formatarValor(VALORTOTAL)}</td>
            <td className="text-center">
              {capitalizeFirstLetter(TIPONEGOCIACAO)}
            </td>
            <td className="text-center">
              {STATUS.replaceAll("_", " ")}
            </td>
          </tr>
      );
    })
  };

  return (
    <>
      <Menu />
      <Cabecalho />
      <CaixaConteudo>
        <div className={styles.grid}>
        <div className={styles.topo}>
          <div className={styles.topoButtons}>
            <button
              onClick={() => {
                navigate("/novo-pedido");
              }}
            >
              {" "}
              <i class="bi bi-clipboard"></i> Criar Pedido
            </button>
            <i className={`bi bi-arrow-clockwise ${styles.btnReload}`} title="Atualizar" onClick={() => window.location.reload()}></i>
          </div>
        </div>          
        <div className={styles.corpo}>
        <div className={styles.baseContainer}>
            <div className={`container-fluid py-4 ${styles.boxTable}`}>
              <div className="table-responsive p-0 pb-2">
                <h1 className={styles.titleTable}>Pedidos</h1>
                <table
                  ref={tableRef}
                  className={`table align-items-center justify-content-center mb-0 ${styles.table}`}
                >
                  <thead>
                    <tr>
                      <th className="text-center">Ação</th>
                      <th>Nº do pedido</th>
                      <th>Data</th>
                      <th>Cliente</th>
                      <th>Cpf/Cnpj</th>
                      <th>Vendedor</th>
                      <th className="text-center">Total+Frete</th>
                      <th className="text-center">Negociação</th>
                      <th className="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>{pedidos.length === 0 && semDados === '' ? <tr>
                      <td colSpan="8">
                        <LoadingCentroTelas />
                      </td>
                    </tr> : semDados === 'Sem dados' ? <tr>
                      <td style={{ textAlign: "center" }} colSpan="8">
                      A tabela está vazia no momento.
                      </td>
                    </tr> : showTable()}</tbody>
                </table>
              </div>
            </div>
            </div>
          </div>
        </div>
      </CaixaConteudo>
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que deseja excluir este pedido?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancelar
          </Button>
          <Button className={`${styles.icone}`} onClick={handleConfirmDelete}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDuplicarModal} onHide={() => setShowDuplicarModal(false)} centered>
        <Modal.Header>
          <Modal.Title>Confirmar Duplicação</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que deseja duplicar o pedido {codpedDuplicar}?
        </Modal.Body>
        <Modal.Footer>
          {duplicarErro && <p className={styles.errorMessage}>{duplicarErro}</p>}
          <Button variant="secondary" onClick={() => setShowDuplicarModal(false)}>
            Cancelar
          </Button>
          <Button className={`${styles.icone}`} onClick={handleConfirmDuplicar}>
            Duplicar
          </Button>
        </Modal.Footer>
        
      </Modal>

      <Modal show={showSucessoDuplicar} onHide={() => setShowSucessoDuplicar(false)} centered>
        <Modal.Header>
          <Modal.Title>Mensagem</Modal.Title>
        </Modal.Header>
        <Modal.Body>Pedido {codpedDuplicar} duplicado com sucesso!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSucessoDuplicar(false)}>
            Fechar
          </Button>
          <Button className={`${styles.icone}`} onClick={() => editarPedido(codpedDuplicar)}>
            Editar pedido duplicado
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Pedidos;
